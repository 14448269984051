import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { newCallApi } from '../../store/actions/api'
import moment from 'moment'
import { formatDate, formatDiploma } from '../../helpers'
import { translate, Translate } from 'react-i18nify'

import Panel from '../Panel/Panel'
import CheckboxList from '../CheckboxList/CheckboxList'
import Alert from '../Alert/Alert'
import Actions from '../Actions/Actions'
import { Link } from 'react-router-dom'
import ModalForm from '../Modal/ModalForm'
import 'react-dates/lib/css/_datepicker.css'
import Graph from '../Graph/Graph'
import Datepicker from '../Datepicker/Datepicker'

class DiplomaTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            uniqueEvaluators: [],
            inviteWorkProcesses: [],
            inviteModalForm: false,
            inviteEmail: '',
            inviteFirstName: '',
            invitePreset: 'new',
            inviteLastName: '',
            inviteExpirationDate: null,
            inviteItems: {
                personal: [],
                job_functions: [],
                studies: [],
                courses: [],
                references: [],
                competences: [],
                job_interests: [],
                languages: [],
                interests: [],
                skills: [],
                files: [],
                account_fields: [],
                basics: [],
            },
            checkedWorkProcesses: [],
            inviteType: 'teacher',
            loading: false,
            inviteDiploma: null,
            inviteWorkProcess: null,
            allowedWorkProcesses: [],
            inviteDiplomaEvaluator: null,
            workProcessDetails: null,
            invitationErrors: [],
            activeDiploma: null,
            sendInvitations: [
                {
                    preset: 'new',
                    firstName: '',
                    lastName: '',
                    email: '',
                    expiration_date: null,
                    items: [],
                },
            ],
            graphDiploma: null,
        }
        this.possibleBasics = {
            date_of_birth: { name: translate('labels.birthday') },
            place_of_birth: { name: translate('labels.birthplace') },
            email: { name: translate('profile.email') },
            phone_number: { name: translate('labels.phonenumber') },
            mobile_number: { name: translate('labels.mobilenumber') },
            address: { name: translate('profile.address') },
            quote: { name: translate('profile.quote') },
            tagline: { name: translate('profile.function') },
        }

        this.hideGraph = this.hideGraph.bind(this)
    }
    resetSharingItems() {
        this.setState({
            inviteItems: {
                personal: [],
                job_functions: [],
                studies: [],
                courses: [],
                references: [],
                competences: [],
                job_interests: [],
                languages: [],
                interests: [],
                skills: [],
                files: [],
                account_fields: [],
                basics: [],
            },
        })
    }
    componentDidMount() {
        this.resetSharingItems()
        if (this.props.diplomas.length === 1)
            this.setState({ activeDiploma: formatDiploma(this.props.diplomas[0]) })
        this.setUniqueEvaluators()
        newCallApi(
            'GET',
            this.props.config.API_DOCUMENT_AND_TYPES.replace(
                '{account}',
                this.props.user.profile.id,
            ),
        )
            .then((uploadedDocuments) => {
                const allFiles = []
                uploadedDocuments.forEach((type) => {
                    if (type.files && type.files.length)
                        type.files.forEach((file) =>
                            allFiles.push({ ...file, typeName: type.name }),
                        )
                })
                this.setState({ allFiles })
            })
            .catch(() => {})
    }
    componentWillReceiveProps = () => {
        this.setUniqueEvaluators()
    }
    setUniqueEvaluators() {
        const evaluators = []
        this.props.evaluations.forEach((evaluation) => {
            if (evaluation.evaluator.id != this.props.user.profile.id) {
                evaluators.push(evaluation.evaluator)
            }
        })
        const uniqueEvaluators = Array.from(new Set(evaluators.map((x) => x.id))).map((id) => {
            return evaluators.find((x) => x.id === id)
        })
        this.setState({ uniqueEvaluators })
    }
    updateCheckbox(inviteWorkProcesses) {
        inviteWorkProcesses = inviteWorkProcesses.map((item) => item.split('_')[0])
        this.setState({ inviteWorkProcesses })
    }
    getNonExpiredEvaluations() {
        return (this.props.evaluations || []).filter((evaluation) => {
            if (!evaluation.is_own) {
                // Removes the time from the expiration date, because Safari sometimes gives an invalid date
                return (
                    new Date() <
                        new Date(
                            evaluation.expiration_date
                                ? evaluation.expiration_date.replace(' 00:00:00', '')
                                : null,
                        ) || !evaluation.pending
                )
            } else {
                return true
            }
        })
    }
    startWorkProcess(workProcess) {
        const workProcessEvaluations = (this.getNonExpiredEvaluations() || []).filter(
            (evaluation) =>
                evaluation.work_process_id == workProcess.work_process_id &&
                evaluation.diploma_id == this.state.activeDiploma.diploma_id,
        )
        const workProcessEvaluationsByType = {}
        ;['student', 'teacher', 'mentor', 'coach', 'recipient', 'other_student'].forEach((type) => {
            workProcessEvaluationsByType[type] = workProcessEvaluations.filter(
                (evaluation) => evaluation.evaluator_role.name == type,
            )
        })
        this.setState({
            workProcessDetails: { ...workProcess, evaluations: workProcessEvaluationsByType },
        })
    }
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }
    setInvitationData(index, dataType, value) {
        const sendInvitations = this.state.sendInvitations
        sendInvitations[index][dataType] = value
        this.setState({ sendInvitations })
    }
    addAnotherEvaluator() {
        const sendInvitations = this.state.sendInvitations
        sendInvitations.push({ preset: 'new', firstName: '', lastName: '', email: '' })
        this.setState({ sendInvitations })
    }
    removeEvaluator(index) {
        let sendInvitations = [...this.state.sendInvitations]
        sendInvitations = sendInvitations.filter((item, i) => i !== index)
        this.setState({ sendInvitations })
    }
    removeWorkProcess(index) {
        let inviteWorkProcesses = [...this.state.inviteWorkProcesses]
        inviteWorkProcesses = inviteWorkProcesses.filter((item, i) => i !== index)
        this.setState({ inviteWorkProcesses })
    }
    addAnotherWorkProcess() {
        const inviteWorkProcesses = this.state.inviteWorkProcesses
        let done = false
        this.state.activeDiploma.workProcesses.forEach((workProcess) => {
            if (!done && !this.state.inviteWorkProcesses.includes(workProcess.work_process_id)) {
                done = true
                inviteWorkProcesses.push(workProcess.work_process_id)
                this.setState({ inviteWorkProcesses })
            }
        })
    }
    getAverageScoreForEvaluations(evaluations) {
        // Check if there are any pending evaluations
        const number_of_pending = evaluations.filter((evaluation) => evaluation.pending).length
        if (number_of_pending) return { score: 0, number_of_pending }
        let totalScore = 0
        evaluations.forEach((evaluation) => (totalScore += evaluation.score))
        if (totalScore === 0) return { score: 10 }
        return { score: Math.ceil(totalScore / evaluations.length) }
    }
    getEvaluation(workProcess, type) {
        const originalType = type
        let evals = (this.getNonExpiredEvaluations() || []).filter(
            (evaluation) =>
                evaluation.work_process_id == workProcess.work_process_id &&
                evaluation.diploma_id == this.state.activeDiploma.diploma_id &&
                evaluation.evaluator_role.name == type,
        )
        if (originalType === 'student') evals = evals.filter((evaluation) => evaluation.is_own)
        return this.getAverageScoreForEvaluations(evals)
    }
    deleteDiploma(id) {
        if (!window.confirm(translate('diplomas.remove_confirm'))) return
        this.setState({ loading: true })
        newCallApi(
            'DELETE',
            this.props.config.API_DIPLOMAS.replace('{account}', this.props.user.profile.id) +
                '/' +
                id,
        )
            .then(() => {})
            .catch((error) => {
                console.log('Got error', error)
            })
            .then(() => {
                this.setState({ loading: false })
                if (typeof this.props.onUpdated === 'function') {
                    this.props.onUpdated()
                    this.setState({ activeDiploma: null })
                }
            })
    }
    mapScoreToTitle(evaluation) {
        if (evaluation.pending) return 'In afwachting'
        if (evaluation.score === 1) return 'Starter'
        if (evaluation.score === 2) return 'Gevorderd'
        if (evaluation.score === 3) return 'Beroepsbekwaam'
    }
    getShareItems() {
        const allItems = this.state.inviteItems
        const submitItems = []
        for (let key in allItems) {
            if (
                allItems.hasOwnProperty(key) &&
                allItems[key] &&
                typeof allItems[key] === 'object' &&
                allItems[key].length
            ) {
                for (let i = 0; i < allItems[key].length; i++) {
                    if (key === 'basics') {
                        submitItems.push({
                            type: allItems[key][i],
                        })
                    } else {
                        submitItems.push({
                            type: key,
                            id: allItems[key][i],
                        })
                    }
                }
            }
        }
        return submitItems
    }
    sendInvitation_MultipleWorkProcesses() {
        this.setState({ loading: true, invitationErrors: [] })
        let inviteExpirationDate = this.state.inviteExpirationDate
            ? this.state.inviteExpirationDate.format('YYYY-MM-DD')
            : null
        let evaluator = {
            role: this.state.inviteType,
            firstName: this.state.inviteFirstName,
            lastName: this.state.inviteLastName,
            expiration_date: inviteExpirationDate,
            items: this.getShareItems(),
            email: this.state.inviteEmail,
        }
        if (this.state.invitePreset !== 'new') {
            const evaluatorId = this.state.invitePreset
            evaluator = {
                account_id: evaluatorId,
                role: this.state.inviteType,
                items: this.getShareItems(),
                expiration_date: inviteExpirationDate,
            }
        }

        const allPromises = this.state.inviteWorkProcesses.map((work_process_id) => {
            newCallApi(
                'POST',
                this.props.config.API_EVALUATIONS.replace('{account}', this.props.user.profile.id)
                    .replace('{diploma}', this.state.activeDiploma.diploma_id)
                    .replace('{work_process}', work_process_id),
                evaluator,
            )
        })
        Promise.all(allPromises)
            .then(() => {
                this.setState({
                    inviteWorkProcesses: [],
                    invitationErrors: [],
                    sendInvitations: [{ preset: 'new', firstName: '', lastName: '', email: '' }],
                    inviteDiplomaEvaluator: false,
                    inviteType: 'teacher',
                    invitePreset: 'new',
                    inviteFirstName: '',
                    inviteLastName: '',
                    inviteExpirationDate: null,
                    inviteItems: {},
                    inviteEmail: '',
                })
                this.resetSharingItems()
                if (typeof this.props.onUpdated === 'function') this.props.onUpdated()
            })
            .catch((error) => {
                this.setState({ invitationErrors: error.errors })
            })
            .then(() => this.setState({ loading: false }))
    }
    sendInvitation_MultipleEvaluators() {
        this.setState({ loading: true, invitationErrors: [] })
        const allPromises = this.state.sendInvitations.map((invitation) => {
            let inviteExpirationDate = invitation.expiration_date
                ? invitation.expiration_date.format('YYYY-MM-DD')
                : null
            let evaluator = {
                role: this.state.inviteType,
                firstName: invitation.firstName,
                lastName: invitation.lastName,
                expiration_date: inviteExpirationDate,
                items: this.getShareItems(),
                email: invitation.email,
            }
            if (invitation.preset !== 'new') {
                let evaluatorId = invitation.preset
                evaluator = {
                    account_id: evaluatorId,
                    role: this.state.inviteType,
                    items: this.getShareItems(),
                    expiration_date: inviteExpirationDate,
                }
            }

            return newCallApi(
                'POST',
                this.props.config.API_EVALUATIONS.replace('{account}', this.props.user.profile.id)
                    .replace('{diploma}', this.state.activeDiploma.diploma_id)
                    .replace('{work_process}', this.state.inviteWorkProcess.work_process_id),
                evaluator,
            )
        })
        Promise.all(allPromises)
            .then(() => {
                this.setState({
                    invitationErrors: [],
                    sendInvitations: [{ preset: 'new', firstName: '', lastName: '', email: '' }],
                    inviteItems: {},
                    inviteDiplomaEvaluator: false,
                    inviteWorkProcess: null,
                    inviteType: 'teacher',
                })
                this.resetSharingItems()
                if (typeof this.props.onUpdated === 'function') this.props.onUpdated()
            })
            .catch((error) => {
                this.setState({ invitationErrors: error.errors || [] })
            })
            .then(() => this.setState({ loading: false }))
    }
    updateState(category, checked) {
        let items = this.state.inviteItems
        let text = category.replace(/['"]+/g, '')
        items[text] = checked
        this.setState({
            items: items,
        })
    }
    getBasicVal(basic) {
        const profile = this.props.user.profile
        let val = profile[basic]
        if (basic === 'address') {
            let addressValue = ''
            if (!!profile.address) addressValue += profile.address + ' '
            if (!!profile.address_number) addressValue += profile.address_number
            if (!!profile.address_number_suffix) {
                addressValue += ' ' + profile.address_number_suffix + ', '
            } else {
                addressValue += ', '
            }
            if (!!profile.postal_code) addressValue += profile.postal_code + ' '
            if (!!profile.city_name) addressValue += profile.city_name
            val = addressValue
        } else if (basic === 'quote') val = profile.quote_text
        return val
    }
    getShareItemsBox() {
        const basicsList = []
        Object.keys(this.possibleBasics).forEach((basic) => {
            const val = this.getBasicVal(basic)
            if (!!val) {
                basicsList.push({
                    id: basic,
                    name: this.possibleBasics[basic].name,
                    subtext: val,
                })
            }
        })
        return (
            <div>
                <h2>
                    <Translate value="menu.sharing" />
                </h2>
                <ul className="checkbox_list">
                    <CheckboxList
                        itemName="basics"
                        title={translate('profile.personal_data')}
                        itemText="name"
                        itemSubText="subtext"
                        arr={basicsList}
                        edit={true}
                        checked={this.state.inviteItems.basics}
                        onCheckChange={(checked) => {
                            this.updateState('basics', checked)
                        }}
                        onToggleAll={(checked) => {
                            this.updateState('basics', checked)
                        }}
                    />
                    <CheckboxList
                        itemName="job_functions"
                        title={translate('profile.experience')}
                        itemText="function_title"
                        itemSubText="company_name"
                        arr={this.props.user.profile.job_functions || []}
                        edit={this.state.edit}
                        checked={this.state.inviteItems.job_functions}
                        onCheckChange={(checked) => {
                            this.updateState('job_functions', checked)
                        }}
                        onToggleAll={(checked) => {
                            this.updateState('job_functions', checked)
                        }}
                    />

                    <CheckboxList
                        itemName="education"
                        title={translate('profile.education')}
                        itemText="study_id"
                        itemSubText="institute_name"
                        arr={this.props.user.profile.studies || []}
                        edit={this.state.edit}
                        checked={this.state.inviteItems.studies}
                        onCheckChange={(checked) => {
                            this.updateState('studies', checked)
                        }}
                        onToggleAll={(checked) => {
                            this.updateState('studies', checked)
                        }}
                        studies={this.props.user.studies}
                    />

                    <CheckboxList
                        itemName="courses"
                        title={translate('profile.courses')}
                        itemText="name"
                        itemSubText="achieved"
                        arr={this.props.user.profile.courses || []}
                        edit={this.state.edit}
                        checked={this.state.inviteItems.courses}
                        onCheckChange={(checked) => {
                            this.updateState('courses', checked)
                        }}
                        onToggleAll={(checked) => {
                            this.updateState('courses', checked)
                        }}
                    />

                    <CheckboxList
                        itemName="references"
                        title={translate('profile.references')}
                        itemText="name"
                        itemSubText="company"
                        arr={this.props.user.profile.references || []}
                        edit={this.state.edit}
                        checked={this.state.inviteItems.references}
                        onCheckChange={(checked) => {
                            this.updateState('references', checked)
                        }}
                        onToggleAll={(checked) => {
                            this.updateState('references', checked)
                        }}
                    />

                    <CheckboxList
                        itemName="competences"
                        title={translate('profile.competences')}
                        itemText="name"
                        itemSubText=""
                        arr={this.props.user.profile.competences || []}
                        edit={this.state.edit}
                        checked={this.state.inviteItems.competences}
                        onCheckChange={(checked) => {
                            this.updateState('competences', checked)
                        }}
                        onToggleAll={(checked) => {
                            this.updateState('competences', checked)
                        }}
                    />

                    <CheckboxList
                        itemName="job_interests"
                        title={translate('profile.job_interest')}
                        itemText="name"
                        itemSubText=""
                        arr={this.props.user.profile.job_interests || []}
                        edit={this.state.edit}
                        checked={this.state.inviteItems.job_interests}
                        onCheckChange={(checked) => {
                            this.updateState('job_interests', checked)
                        }}
                        onToggleAll={(checked) => {
                            this.updateState('job_interests', checked)
                        }}
                    />

                    <CheckboxList
                        itemName="languages"
                        title={translate('profile.languages')}
                        itemText="name"
                        itemSubText=""
                        arr={this.props.user.profile.languages || []}
                        edit={this.state.edit}
                        checked={this.state.inviteItems.languages}
                        onCheckChange={(checked) => {
                            this.updateState('languages', checked)
                        }}
                        onToggleAll={(checked) => {
                            this.updateState('languages', checked)
                        }}
                    />

                    <CheckboxList
                        itemName="skills"
                        title={translate('profile.skills')}
                        itemText="name"
                        itemSubText=""
                        arr={this.props.user.profile.skills || []}
                        edit={this.state.edit}
                        checked={this.state.inviteItems.skills}
                        onCheckChange={(checked) => {
                            this.updateState('skills', checked)
                        }}
                        onToggleAll={(checked) => {
                            this.updateState('skills', checked)
                        }}
                    />

                    <CheckboxList
                        itemName="interests"
                        title={translate('profile.interests')}
                        itemText="name"
                        itemSubText=""
                        arr={this.props.user.profile.interests || []}
                        edit={this.state.edit}
                        checked={this.state.inviteItems.interests}
                        onCheckChange={(checked) => {
                            this.updateState('interests', checked)
                        }}
                        onToggleAll={(checked) => {
                            this.updateState('interests', checked)
                        }}
                    />

                    {this.state.allFiles && this.state.allFiles.length > 0 && (
                        <CheckboxList
                            itemName="files"
                            title={translate('profile.attachments')}
                            itemText="typeName"
                            itemSubText="name"
                            arr={this.state.allFiles || []}
                            edit={this.state.edit}
                            checked={this.state.inviteItems.files}
                            onCheckChange={(checked) => {
                                this.updateState('files', checked)
                            }}
                            onToggleAll={(checked) => {
                                this.updateState('files', checked)
                            }}
                            expanded={this.props.expandedCategory === 'files'}
                        />
                    )}
                    {this.state.accountFields && this.state.accountFields.length > 0 && (
                        <CheckboxList
                            itemName="account_fields"
                            title={translate('profile.extra_info')}
                            itemText="name"
                            itemSubText=""
                            arr={this.state.accountFields || []}
                            edit={this.state.edit}
                            checked={this.state.inviteItems.account_fields}
                            onCheckChange={(checked) => {
                                this.updateState('account_fields', checked)
                            }}
                            onToggleAll={(checked) => {
                                this.updateState('account_fields', checked)
                            }}
                        />
                    )}
                </ul>
            </div>
        )
    }

    hideGraph() {
        this.setState({ graphDiploma: null })
    }

    getWorkProcessesEligibleForEvaluation() {
        return this.state.activeDiploma.workProcesses.filter((workProcess) => {
            let evaluationStudent = this.getEvaluation(workProcess, 'student')
            return evaluationStudent.score > 0
        })
    }

    render() {
        return (
            <div>
                {!!this.state.inviteDiplomaEvaluator && (
                    <ModalForm
                        hideClose={true}
                        onClose={() => {
                            this.setState({
                                inviteDiplomaEvaluator: false,
                                inviteType: 'teacher',
                                allowedWorkProcesses: [],
                            })
                        }}
                        show={true}
                        title={translate('buttons.add_evaluator')}
                    >
                        <Alert type="alert--error alert--simple">
                            <ul>
                                {this.state.invitationErrors.map((error, index) => {
                                    return <li key={'error_' + index}>{error.message}</li>
                                })}
                            </ul>
                        </Alert>
                        <form
                            className="form"
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) this.sendInvitation_MultipleWorkProcesses()
                            }}
                        >
                            <fieldset className="form__fieldset">
                                <div className="form__item">
                                    <i className="form__icon icons icon-doc" />
                                    <div className="form__select">
                                        <select
                                            className={`form__input--select${
                                                this.state.inviteType ? ' has-value' : ''
                                            }`}
                                            type="email"
                                            id="inviteType"
                                            value={this.state.inviteType}
                                            onChange={(event) =>
                                                this.setState({ inviteType: event.target.value })
                                            }
                                        >
                                            <option value="teacher">
                                                {translate('diplomas.teacher')}
                                            </option>
                                            <option value="mentor">
                                                {translate('diplomas.mentor')}
                                            </option>
                                            <option value="coach">
                                                {translate('diplomas.coach')}
                                            </option>
                                            <option value="recipient">
                                                {translate('diplomas.recipient')}
                                            </option>
                                            <option value="other_student">
                                                {translate('diplomas.other_student')}
                                            </option>
                                        </select>
                                    </div>
                                    <label className="form__label" htmlFor="inviteType">
                                        {translate('labels.evaluator')}
                                    </label>
                                </div>
                                <div>
                                    {!!this.state.uniqueEvaluators.length && (
                                        <div className="form__item">
                                            <i className="form__icon icons icon-doc" />
                                            <div className="form__select">
                                                <select
                                                    className={`form__input--select${
                                                        this.state.invitePreset ? ' has-value' : ''
                                                    }`}
                                                    id="invitePreset"
                                                    value={this.state.invitePreset}
                                                    onChange={(event) =>
                                                        this.setState({
                                                            invitePreset: event.target.value,
                                                        })
                                                    }
                                                >
                                                    <option value="new">
                                                        {translate('diplomas.new_evaluator')}
                                                    </option>
                                                    {this.state.uniqueEvaluators.map(
                                                        (uniqueEvaluator) => {
                                                            return (
                                                                <option
                                                                    value={uniqueEvaluator.id}
                                                                    key={uniqueEvaluator.id}
                                                                >
                                                                    {uniqueEvaluator.name}
                                                                </option>
                                                            )
                                                        },
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    )}
                                    {this.state.invitePreset === 'new' && (
                                        <div>
                                            <div className="form__item">
                                                <i className="form__icon icons icon-user" />
                                                <input
                                                    className={`form__input--text${
                                                        this.state.inviteFirstName
                                                            ? ' has-value'
                                                            : ''
                                                    }`}
                                                    type="text"
                                                    id="input_firstName"
                                                    value={this.state.inviteFirstName}
                                                    onChange={(event) =>
                                                        this.setState({
                                                            inviteFirstName: event.target.value,
                                                        })
                                                    }
                                                />
                                                <label
                                                    className="form__label"
                                                    htmlFor="input_firstName"
                                                >
                                                    <Translate value="labels.first_name" />
                                                </label>
                                            </div>
                                            <div className="form__item">
                                                <input
                                                    className={`form__input--text${
                                                        this.state.inviteLastName
                                                            ? ' has-value'
                                                            : ''
                                                    }`}
                                                    type="text"
                                                    id="input_lastName"
                                                    value={this.state.inviteLastName}
                                                    onChange={(event) =>
                                                        this.setState({
                                                            inviteLastName: event.target.value,
                                                        })
                                                    }
                                                />
                                                <label
                                                    className="form__label"
                                                    htmlFor="input_lastName"
                                                >
                                                    <Translate value="labels.last_name" />
                                                </label>
                                            </div>
                                            <div className="form__item">
                                                <i className="form__icon icons icon-envelope" />
                                                <input
                                                    className={`form__input--text${
                                                        this.state.inviteEmail ? ' has-value' : ''
                                                    }`}
                                                    type="email"
                                                    id="input_email"
                                                    value={this.state.inviteEmail}
                                                    onChange={(event) =>
                                                        this.setState({
                                                            inviteEmail: event.target.value,
                                                        })
                                                    }
                                                />
                                                <label
                                                    className="form__label"
                                                    htmlFor="input_email"
                                                >
                                                    <Translate value="labels.email" />
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="form__item">
                                    <i className="form__icon icons icon-calendar" />
                                    <label
                                        className="form__label"
                                        htmlFor={`input_expiration_date}`}
                                    >
                                        <Translate value="labels.expiration_date" />
                                    </label>
                                    <Datepicker
                                        date={
                                            !this.state.inviteExpirationDate ||
                                            this.state.inviteExpirationDate == '0000-00-00' ||
                                            formatDate(this.state.inviteExpirationDate) ==
                                                'NaN-NaN-NaN'
                                                ? null
                                                : moment(this.state.inviteExpirationDate)
                                        }
                                        onDateChange={(date) =>
                                            this.setState({ inviteExpirationDate: date })
                                        }
                                        classNames={`form__input--text${
                                            this.state.inviteExpirationDate ? ' has-value' : ''
                                        }`}
                                        id={'expiration_date'}
                                        futureOnly={true}
                                    />
                                </div>
                            </fieldset>
                            <h2>
                                <Translate value="labels.work_processes" />
                            </h2>
                            <ul className="checkbox_list">
                                {this.getWorkProcessesEligibleForEvaluation().length > 0 ? (
                                    <CheckboxList
                                        itemName="checked_work_processes"
                                        title={this.state.activeDiploma.title}
                                        itemText="title"
                                        itemSubText="title"
                                        arr={this.getWorkProcessesEligibleForEvaluation().map(
                                            (item, index) => {
                                                item.id = `${item.work_process_id}_${index}`
                                                return item
                                            },
                                        )}
                                        edit={true}
                                        expanded={true}
                                        checked={this.state.checkedWorkProcesses}
                                        onCheckChange={(checked) => {
                                            this.updateCheckbox(checked)
                                        }}
                                        onToggleAll={(checked) => {
                                            this.updateCheckbox(checked)
                                        }}
                                    />
                                ) : (
                                    <div className="text-center">
                                        {translate('diplomas.self_evaluation_first')}
                                    </div>
                                )}
                            </ul>
                            {this.getShareItemsBox()}
                            <Actions>
                                <Link
                                    className="actions__action--cancel"
                                    onClick={() =>
                                        this.setState({
                                            inviteDiplomaEvaluator: false,
                                            inviteType: 'teacher',
                                            allowedWorkProcesses: [],
                                        })
                                    }
                                    tabIndex="4"
                                >
                                    <Translate value="profile.cancel" />
                                </Link>
                                <Link
                                    className={`actions__action--save${
                                        this.state.loading ? ' is-loading' : ''
                                    }`}
                                    onClick={() => this.sendInvitation_MultipleWorkProcesses()}
                                    tabIndex="5"
                                >
                                    <Translate value="profile.save" />
                                </Link>
                            </Actions>
                        </form>
                    </ModalForm>
                )}
                {!!this.state.inviteWorkProcess && (
                    <ModalForm
                        hideClose={true}
                        title={translate('diplomas.ask_feedback')}
                        onClose={() => {
                            this.setState({
                                activeDiploma: formatDiploma(this.state.inviteDiploma),
                                inviteDiploma: false,
                                invitationErrors: [],
                                inviteWorkProcess: false,
                                sendInvitations: [
                                    { preset: 'new', firstName: '', lastName: '', email: '' },
                                ],
                            })
                            setTimeout(() => {
                                const referenceElement = document.querySelector('#diplomasPanel')
                                if (referenceElement)
                                    referenceElement.scrollTo({ behavior: 'smooth' })
                            }, 10)
                        }}
                        show={true}
                    >
                        <Alert type="alert--error alert--simple">
                            <ul>
                                {this.state.invitationErrors.map((error, index) => {
                                    return <li key={'error_' + index}>{error.message}</li>
                                })}
                            </ul>
                        </Alert>
                        <form
                            className="form"
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) this.sendInvitation_MultipleEvaluators()
                            }}
                        >
                            <fieldset className="form__fieldset">
                                <div className="form__item">
                                    <i className="form__icon icons icon-doc" />
                                    <div className="form__select">
                                        <select
                                            className={`form__input--select${
                                                this.state.inviteType ? ' has-value' : ''
                                            }`}
                                            id="inviteType"
                                            value={this.state.inviteType}
                                            onChange={(event) =>
                                                this.setState({ inviteType: event.target.value })
                                            }
                                        >
                                            <option value="teacher">
                                                {translate('diplomas.teacher')}
                                            </option>
                                            <option value="mentor">
                                                {translate('diplomas.mentor')}
                                            </option>
                                            <option value="coach">
                                                {translate('diplomas.coach')}
                                            </option>
                                            <option value="recipient">
                                                {translate('diplomas.recipient')}
                                            </option>
                                            <option value="other_student">
                                                {translate('diplomas.other_student')}
                                            </option>
                                        </select>
                                    </div>
                                    <label className="form__label" htmlFor="inviteType">
                                        <Translate value="labels.evaluator" />
                                    </label>
                                </div>
                                {this.state.sendInvitations.map((invitation, index) => {
                                    return (
                                        <div key={`invitation_${index}`}>
                                            <div className="evaluator-block">
                                                {!!this.state.uniqueEvaluators.length && (
                                                    <div className="form__item">
                                                        <i className="form__icon icons icon-doc" />
                                                        <div className="form__select">
                                                            <select
                                                                className={`form__input--select${
                                                                    invitation.preset
                                                                        ? ' has-value'
                                                                        : ''
                                                                }`}
                                                                type="email"
                                                                id="invitePreset"
                                                                value={invitation.preset}
                                                                onChange={(event) =>
                                                                    this.setInvitationData(
                                                                        index,
                                                                        'preset',
                                                                        event.target.value,
                                                                    )
                                                                }
                                                            >
                                                                <option value="new">
                                                                    {translate(
                                                                        'diplomas.new_evaluator',
                                                                    )}
                                                                </option>
                                                                {this.state.uniqueEvaluators.map(
                                                                    (uniqueEvaluator) => {
                                                                        return (
                                                                            <option
                                                                                value={
                                                                                    uniqueEvaluator.id
                                                                                }
                                                                                key={
                                                                                    uniqueEvaluator.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    uniqueEvaluator.name
                                                                                }
                                                                            </option>
                                                                        )
                                                                    },
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                )}
                                                {invitation.preset === 'new' && (
                                                    <div>
                                                        <div className="form__item">
                                                            <i className="form__icon icons icon-user" />
                                                            <input
                                                                className={`form__input--text${
                                                                    invitation.firstName
                                                                        ? ' has-value'
                                                                        : ''
                                                                }`}
                                                                type="text"
                                                                id={`input_${index}_firstName}`}
                                                                value={invitation.firstName}
                                                                onChange={(event) =>
                                                                    this.setInvitationData(
                                                                        index,
                                                                        'firstName',
                                                                        event.target.value,
                                                                    )
                                                                }
                                                            />
                                                            <label
                                                                className="form__label"
                                                                htmlFor={`input_${index}_firstName}`}
                                                            >
                                                                <Translate value="labels.first_name" />
                                                            </label>
                                                        </div>
                                                        <div className="form__item">
                                                            <input
                                                                className={`form__input--text${
                                                                    invitation.lastName
                                                                        ? ' has-value'
                                                                        : ''
                                                                }`}
                                                                type="text"
                                                                id={`input_${index}_lastName}`}
                                                                value={invitation.lastName}
                                                                onChange={(event) =>
                                                                    this.setInvitationData(
                                                                        index,
                                                                        'lastName',
                                                                        event.target.value,
                                                                    )
                                                                }
                                                            />
                                                            <label
                                                                className="form__label"
                                                                htmlFor={`input_${index}_lastName}`}
                                                            >
                                                                <Translate value="labels.last_name" />
                                                            </label>
                                                        </div>
                                                        <div className="form__item">
                                                            <i className="form__icon icons icon-envelope" />
                                                            <input
                                                                className={`form__input--text${
                                                                    invitation.email
                                                                        ? ' has-value'
                                                                        : ''
                                                                }`}
                                                                type="email"
                                                                id={`input_${index}_email}`}
                                                                value={invitation.email}
                                                                onChange={(event) =>
                                                                    this.setInvitationData(
                                                                        index,
                                                                        'email',
                                                                        event.target.value,
                                                                    )
                                                                }
                                                            />
                                                            <label
                                                                className="form__label"
                                                                htmlFor={`input_${index}_email}`}
                                                            >
                                                                <Translate value="labels.email" />
                                                            </label>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="form__item">
                                                    <i className="form__icon icons icon-calendar" />
                                                    <label
                                                        className="form__label"
                                                        htmlFor={`input_expiration_date}`}
                                                    >
                                                        <Translate value="labels.expiration_date" />
                                                    </label>

                                                    <Datepicker
                                                        date={
                                                            !invitation.expiration_date ||
                                                            invitation.expiration_date ==
                                                                '0000-00-00' ||
                                                            formatDate(
                                                                invitation.expiration_date,
                                                            ) == 'NaN-NaN-NaN'
                                                                ? null
                                                                : moment(invitation.expiration_date)
                                                        }
                                                        onDateChange={(date) =>
                                                            this.setInvitationData(
                                                                index,
                                                                'expiration_date',
                                                                date,
                                                            )
                                                        }
                                                        classNames={`form__input--text${
                                                            invitation.expiration_date
                                                                ? ' has-value'
                                                                : ''
                                                        }`}
                                                        id={'expiration_date'}
                                                        futureOnly={true}
                                                    />
                                                </div>

                                                {this.state.sendInvitations.length !== 1 && (
                                                    <button
                                                        className="button--link--alert"
                                                        type="button"
                                                        onClick={() => this.removeEvaluator(index)}
                                                    >
                                                        <Translate value="buttons.remove_evaluator" />
                                                    </button>
                                                )}
                                            </div>

                                            {this.state.sendInvitations.length - 1 === index && (
                                                <button
                                                    className="button--cta"
                                                    type="button"
                                                    onClick={() => this.addAnotherEvaluator()}
                                                >
                                                    <Translate value="buttons.add_another_evaluator" />
                                                </button>
                                            )}
                                        </div>
                                    )
                                })}
                            </fieldset>
                            {this.getShareItemsBox()}
                            <Actions>
                                <Link
                                    className="actions__action--cancel"
                                    onClick={() => {
                                        this.setState({
                                            activeDiploma: formatDiploma(this.state.inviteDiploma),
                                            inviteDiploma: false,
                                            inviteWorkProcess: false,
                                        })
                                    }}
                                    tabIndex="4"
                                >
                                    <Translate value="profile.cancel" />
                                </Link>
                                <Link
                                    className={`actions__action--save${
                                        this.state.loading ? ' is-loading' : ''
                                    }`}
                                    onClick={() => this.sendInvitation_MultipleEvaluators()}
                                    tabIndex="5"
                                >
                                    <Translate value="profile.save" />
                                </Link>
                            </Actions>
                        </form>
                    </ModalForm>
                )}

                {!!this.state.workProcessDetails && (
                    <ModalForm
                        title={this.state.workProcessDetails.title}
                        onClose={() => this.setState({ workProcessDetails: false })}
                        show={true}
                    >
                        <div>
                            {Object.keys(this.state.workProcessDetails.evaluations).map((role) => {
                                return (
                                    <div
                                        className="text-left"
                                        key={`e_${role}_${this.state.workProcessDetails.work_process_id}`}
                                    >
                                        {!!this.state.workProcessDetails.evaluations[role]
                                            .length && (
                                            <div>
                                                <h3>
                                                    <Translate value={'diplomas.' + role} />
                                                </h3>
                                                {this.state.workProcessDetails.evaluations[
                                                    role
                                                ].map((evaluation) => {
                                                    return (
                                                        <div
                                                            className="modal__list_item"
                                                            key={`e_${role}_${this.state.workProcessDetails.work_process_id}_${evaluation.id}`}
                                                        >
                                                            <p className="is-muted">
                                                                {evaluation.updated_at}
                                                            </p>
                                                            <h4>
                                                                {evaluation.evaluator.first_name}{' '}
                                                                {evaluation.evaluator.last_name}
                                                            </h4>
                                                            <p>
                                                                <Translate value="profile.evaluation" />
                                                                :{' '}
                                                                <span
                                                                    className={this.mapScoreToTitle(
                                                                        evaluation,
                                                                    ).toLowerCase()}
                                                                >
                                                                    {this.mapScoreToTitle(
                                                                        evaluation,
                                                                    )}
                                                                </span>
                                                            </p>
                                                            {!!evaluation.comments && (
                                                                <div className="modal__emph_block">
                                                                    {evaluation.comments}
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                        <button
                            className="button--cta"
                            onClick={() =>
                                this.setState({
                                    workProcessDetails: false,
                                })
                            }
                        >
                            {translate('modal.close_window')}
                        </button>
                    </ModalForm>
                )}

                {this.state.activeDiploma ? (
                    <div>
                        <div className="panel__sub_block">
                            {this.props.diplomas.length !== 1 && (
                                <a
                                    onClick={() => this.setState({ activeDiploma: null })}
                                    className="button--back"
                                >
                                    <i className="icons icon-arrow-left" />{' '}
                                    <Translate value="diplomas.back_to_all" />
                                </a>
                            )}

                            <div className="actions">
                                <button
                                    onClick={() =>
                                        this.deleteDiploma(this.state.activeDiploma.diploma_id)
                                    }
                                    className="actions__action--delete pull-right"
                                >
                                    <Translate value="diplomas.remove" />
                                </button>
                                <button
                                    onClick={() =>
                                        this.setState({ graphDiploma: this.state.activeDiploma })
                                    }
                                    className="actions__action--graph pull-right"
                                >
                                    <Translate value="diplomas.graph" />
                                </button>
                                <span className="actions__title">
                                    {this.state.activeDiploma.title}
                                </span>
                            </div>

                            <ul className="legend">
                                <li>
                                    <span className="dot starter" />
                                    {translate('diplomas.starter')}
                                </li>
                                <li>
                                    <span className="dot advanced" />
                                    {translate('diplomas.advanced')}
                                </li>
                                <li>
                                    <span className="dot professional" />
                                    {translate('diplomas.professional')}
                                </li>
                                <li>
                                    <span className="dot not-invited" />
                                    {translate('diplomas.not_invited')}
                                </li>
                                <li>
                                    <span className="dot pending" />
                                    {this.capitalizeFirstLetter(translate('diplomas.pending'))}
                                </li>
                            </ul>

                            <Panel className={'panel--multi__inner'}>
                                <div className="table-repsonsive">
                                    <table className="panel__table--bordered">
                                        <thead>
                                            <tr>
                                                <th colSpan={2}>
                                                    <button
                                                        className="button--cta"
                                                        onClick={() => {
                                                            this.setState({
                                                                inviteDiplomaEvaluator:
                                                                    this.state.activeDiploma,
                                                                allowedWorkProcesses:
                                                                    this.state.activeDiploma
                                                                        .workProcesses,
                                                            })
                                                            this.addAnotherWorkProcess()
                                                        }}
                                                    >
                                                        <Translate value="buttons.add_evaluator" />
                                                    </button>
                                                </th>
                                                <th>{translate('diplomas.my_evaluation')}</th>
                                                <th>{translate('diplomas.teacher')}</th>
                                                <th>{translate('diplomas.mentor')}</th>
                                                <th>{translate('diplomas.coach')}</th>
                                                <th>{translate('diplomas.recipient')}</th>
                                                <th>{translate('diplomas.other_student')}</th>
                                                <th className="panel__table__icon">&nbsp;</th>
                                            </tr>
                                        </thead>

                                        {this.state.activeDiploma.coreTasks.map((task, idx) => {
                                            return (
                                                <tbody
                                                    key={
                                                        'ct_' +
                                                        this.state.activeDiploma.diploma_id +
                                                        '_' +
                                                        task.id +
                                                        '_' +
                                                        idx
                                                    }
                                                >
                                                    <tr>
                                                        <td colSpan={10}>{task.title}</td>
                                                    </tr>
                                                    {task.workProcesses.map(
                                                        (workProcess, index) => {
                                                            const evluationStudent =
                                                                this.getEvaluation(
                                                                    workProcess,
                                                                    'student',
                                                                )
                                                            // Temporary fix for demo until it is decided how to handle addition of work processes to existing diplomas
                                                            if (evluationStudent.score === 10)
                                                                return null
                                                            return (
                                                                <tr
                                                                    key={
                                                                        'wp_' +
                                                                        this.state.activeDiploma
                                                                            .diploma_id +
                                                                        '_' +
                                                                        workProcess.work_process_id +
                                                                        '_' +
                                                                        index
                                                                    }
                                                                >
                                                                    <td className="no_border"></td>
                                                                    <td className="first-child">
                                                                        {workProcess.title}
                                                                    </td>
                                                                    <td className="panel__dot">
                                                                        <span
                                                                            onClick={() => {
                                                                                if (
                                                                                    typeof this
                                                                                        .props
                                                                                        .onSelected ===
                                                                                    'function'
                                                                                )
                                                                                    return this.props.onSelected(
                                                                                        workProcess,
                                                                                        this.state
                                                                                            .activeDiploma,
                                                                                    )
                                                                            }}
                                                                            className="panel__dot"
                                                                        >
                                                                            {evluationStudent.score ===
                                                                                10 && (
                                                                                <span className="dot pointer not-invited" />
                                                                            )}
                                                                            {evluationStudent.score ===
                                                                                0 && (
                                                                                <span
                                                                                    data-balloon-pos="right"
                                                                                    data-balloon={translate(
                                                                                        'diplomas.evaluate_yourself',
                                                                                    )}
                                                                                >
                                                                                    <span className="dot pointer pending" />
                                                                                </span>
                                                                            )}
                                                                            {evluationStudent.score ===
                                                                                1 && (
                                                                                <span className="dot pointer starter" />
                                                                            )}
                                                                            {evluationStudent.score ===
                                                                                2 && (
                                                                                <span className="dot pointer advanced" />
                                                                            )}
                                                                            {evluationStudent.score ===
                                                                                3 && (
                                                                                <span className="dot pointer professional" />
                                                                            )}
                                                                        </span>
                                                                    </td>

                                                                    {[
                                                                        'teacher',
                                                                        'mentor',
                                                                        'coach',
                                                                        'recipient',
                                                                        'other_student',
                                                                    ].map((type, index2) => {
                                                                        const evaluationData =
                                                                            this.getEvaluation(
                                                                                workProcess,
                                                                                type,
                                                                            )
                                                                        return (
                                                                            <td
                                                                                className="panel__dot"
                                                                                key={
                                                                                    'dp_' +
                                                                                    this.state
                                                                                        .activeDiploma
                                                                                        .diploma_id +
                                                                                    '_' +
                                                                                    index +
                                                                                    '_' +
                                                                                    index2 +
                                                                                    '_' +
                                                                                    type
                                                                                }
                                                                            >
                                                                                {evluationStudent.score ===
                                                                                    0 && (
                                                                                    <span
                                                                                        data-balloon={translate(
                                                                                            'diplomas.self_evaluation_first',
                                                                                        )}
                                                                                    >
                                                                                        <span className="dot not-invited pointer" />
                                                                                    </span>
                                                                                )}
                                                                                {evaluationData.score ===
                                                                                    10 &&
                                                                                    evluationStudent.score >
                                                                                        0 && (
                                                                                        <span
                                                                                            data-balloon={
                                                                                                translate(
                                                                                                    'diplomas.ask_feedback_from_a',
                                                                                                ) +
                                                                                                ' ' +
                                                                                                translate(
                                                                                                    'diplomas.' +
                                                                                                        type,
                                                                                                ).toLowerCase()
                                                                                            }
                                                                                        >
                                                                                            <span
                                                                                                onClick={() => {
                                                                                                    this.setState(
                                                                                                        {
                                                                                                            inviteDiploma:
                                                                                                                this
                                                                                                                    .state
                                                                                                                    .activeDiploma,
                                                                                                            inviteWorkProcess:
                                                                                                                workProcess,
                                                                                                            inviteEmail:
                                                                                                                '',
                                                                                                            inviteFirstName:
                                                                                                                '',
                                                                                                            inviteLastName:
                                                                                                                '',
                                                                                                            inviteExpirationDate:
                                                                                                                null,
                                                                                                            inviteItems:
                                                                                                                {},
                                                                                                            inviteType:
                                                                                                                type,
                                                                                                        },
                                                                                                    )
                                                                                                    this.resetSharingItems()
                                                                                                }}
                                                                                                className="dot not-invited pointer"
                                                                                            />
                                                                                        </span>
                                                                                    )}
                                                                                {evaluationData.score ===
                                                                                    0 &&
                                                                                    evluationStudent.score >
                                                                                        0 &&
                                                                                    evaluationData.number_of_pending && (
                                                                                        <span
                                                                                            onClick={() =>
                                                                                                this.startWorkProcess(
                                                                                                    workProcess,
                                                                                                )
                                                                                            }
                                                                                            data-balloon={
                                                                                                evaluationData.number_of_pending +
                                                                                                ' ' +
                                                                                                translate(
                                                                                                    'diplomas.pending',
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <span className="dot pending" />
                                                                                        </span>
                                                                                    )}
                                                                                {evaluationData.score ===
                                                                                    1 &&
                                                                                    evluationStudent.score >
                                                                                        0 && (
                                                                                        <span
                                                                                            data-balloon={translate(
                                                                                                'diplomas.details',
                                                                                            )}
                                                                                            onClick={() =>
                                                                                                this.startWorkProcess(
                                                                                                    workProcess,
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <span className="dot starter" />
                                                                                        </span>
                                                                                    )}
                                                                                {evaluationData.score ===
                                                                                    2 &&
                                                                                    evluationStudent.score >
                                                                                        0 && (
                                                                                        <span
                                                                                            data-balloon={translate(
                                                                                                'diplomas.details',
                                                                                            )}
                                                                                            onClick={() =>
                                                                                                this.startWorkProcess(
                                                                                                    workProcess,
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <span className="dot advanced" />
                                                                                        </span>
                                                                                    )}
                                                                                {evaluationData.score ===
                                                                                    3 &&
                                                                                    evluationStudent.score >
                                                                                        0 && (
                                                                                        <span
                                                                                            data-balloon={translate(
                                                                                                'diplomas.details',
                                                                                            )}
                                                                                            onClick={() =>
                                                                                                this.startWorkProcess(
                                                                                                    workProcess,
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <span className="dot professional" />
                                                                                        </span>
                                                                                    )}
                                                                            </td>
                                                                        )
                                                                    })}
                                                                    <td className="panel__table__icon last-child">
                                                                        {evluationStudent.score >
                                                                            0 && (
                                                                            <Link
                                                                                data-balloon={translate(
                                                                                    'diplomas.ask_for_feedback',
                                                                                )}
                                                                                data-balloon-pos="left"
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        inviteDiploma:
                                                                                            this
                                                                                                .state
                                                                                                .activeDiploma,
                                                                                        inviteWorkProcess:
                                                                                            workProcess,
                                                                                        inviteEmail:
                                                                                            '',
                                                                                        inviteFirstName:
                                                                                            '',
                                                                                        inviteLastName:
                                                                                            '',
                                                                                        inviteExpirationDate:
                                                                                            null,
                                                                                        inviteItems:
                                                                                            {},
                                                                                        inviteType:
                                                                                            'teacher',
                                                                                    })
                                                                                    this.resetSharingItems()
                                                                                }}
                                                                            >
                                                                                <i className="form__icon icons icon-envelope" />
                                                                            </Link>
                                                                        )}
                                                                    </td>
                                                                    <td className="no_border"></td>
                                                                </tr>
                                                            )
                                                        },
                                                    )}
                                                </tbody>
                                            )
                                        })}
                                    </table>
                                </div>
                            </Panel>
                        </div>
                        <div className="spacer-hr" />
                    </div>
                ) : (
                    <div className="panel__sub_block">
                        <Panel className="panel--multi__inner">
                            <div className="table-repsonsive">
                                <table className="panel__table--bordered panel__table--clickable_rows table__diploma-list">
                                    <thead>
                                        <tr>
                                            <th>
                                                <Translate value="diplomas.title" />
                                            </th>
                                            <th>
                                                <Translate value="diplomas.evaluations" />
                                            </th>
                                            <th>&nbsp;</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    {this.props.diplomas.map((diploma, index) => {
                                        return (
                                            <tbody key={'dp_' + diploma.diploma_id + '_' + index}>
                                                <tr key={'dp_' + diploma.diploma_id + '_' + index}>
                                                    <td
                                                        onClick={() =>
                                                            this.setState({
                                                                activeDiploma:
                                                                    formatDiploma(diploma),
                                                            })
                                                        }
                                                    >
                                                        {diploma.title}
                                                    </td>
                                                    <td
                                                        onClick={() =>
                                                            this.setState({
                                                                activeDiploma:
                                                                    formatDiploma(diploma),
                                                            })
                                                        }
                                                    >
                                                        {
                                                            formatDiploma(diploma).workProcesses
                                                                .length
                                                        }
                                                    </td>
                                                    <td colSpan="2">
                                                        <div className="actions">
                                                            <a
                                                                className="actions__action--graph"
                                                                onClick={() =>
                                                                    this.setState({
                                                                        graphDiploma: diploma,
                                                                    })
                                                                }
                                                            >
                                                                <Translate value="diplomas.graph" />
                                                            </a>
                                                            <a
                                                                onClick={() =>
                                                                    this.deleteDiploma(
                                                                        diploma.diploma_id,
                                                                    )
                                                                }
                                                                className="actions__action--delete"
                                                            >
                                                                <Translate value="diplomas.remove" />
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )
                                    })}
                                </table>
                            </div>
                        </Panel>
                    </div>
                )}

                {this.state.graphDiploma && (
                    <Graph
                        diploma={this.state.graphDiploma}
                        evaluations={this.getNonExpiredEvaluations()}
                        onClose={this.hideGraph}
                    />
                )}
            </div>
        )
    }
}

DiplomaTable.propTypes = {
    diplomas: PropTypes.array.isRequired,
    onUpdated: PropTypes.func.isRequired,
    selectedWorkProcess: PropTypes.object,
    onSelected: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
    }
}
export default connect(mapStateToProps)(DiplomaTable)

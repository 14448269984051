import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import { translate } from 'react-i18nify'

import Button from '../components/Button/Button'
import GridCol from '../components/GridCol/GridCol'
import GridColOffset from '../components/GridCol/GridColOffset'
import Page from '../components/Page/Page'
import history from '../history'

class About extends Component {
    render() {
        // injected by connect call
        const { dispatch } = this.props

        return (
            <div className="react-native-web">
                <Container>
                    <Row>
                        <GridColOffset />
                        <GridCol>
                            <Page>
                                <h1 className="text-center">{translate('about.title')}</h1>
                                <p>
                                    <strong>{translate('about.intro')}</strong>
                                </p>
                                <p>{translate('about.main_text')}</p>
                                <Button
                                    buttonText={translate('buttons.previous_page')}
                                    className="button--cta inline-block"
                                    onClick={history.goBack}
                                />
                            </Page>
                        </GridCol>
                    </Row>
                </Container>
            </div>
        )
    }
}

About.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

const select = (state) => state

// Wrap the component to inject dispatch and state into it
export default connect(select)(About)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Translate, translate } from 'react-i18nify'

import { isRequired } from '../../helpers'

import { editProfileItem } from '../../store/actions/user'
import AlertBox from '../Alert/AlertBox'

class FormDiscoveryMethod extends Component {
    constructor(props) {
        super(props)

        console.log(props.user.profile)

        this.state = {
            errors: [],
            fields: {
                discovery_method: props.user.profile.discovery_method ?? '',
            },
            fieldErrors: {
                discovery_method: false,
            },
            requiredFields: ['discovery_method'],
        }
    }

    handleChange(value, key) {
        let obj = {}
        obj[key] = value.target.value
        let fieldErrors = { ...this.state.fieldErrors }
        fieldErrors[key] = false
        this.setState({
            fields: { ...this.state.fields, ...obj },
            errors: [],
            fieldErrors: fieldErrors,
        })
    }

    cancel() {
        this.setState({
            fields: {
                discovery_method: '',
            },
            fieldErrors: {
                discovery_method: false,
            },
            errors: [],
        })
        this.props.onCancel()
    }

    validate() {
        const fieldErrors = {} // Create a copy of the fieldErrors

        this.state.requiredFields.forEach((field) => {
            if (!this.state.fields[field]) {
                fieldErrors[field] = true // Update the copy
            } else {
                fieldErrors[field] = false // Update the copy
            }
        })

        this.setState({ fieldErrors: fieldErrors }) // Set the state once after the loop

        // Check if all required fields are filled
        if (Object.values(fieldErrors).some((error) => error === true)) {
            this.setState({
                errors: [translate('errors.empty_field')],
            })
            return false
        }

        return true
    }

    save() {
        const { loading, setLoadingValue, dispatch } = this.props

        if (loading) {
            return
        }
        let valid = this.validate()

        if (!valid) {
            setLoadingValue(false)
            return
        }

        setLoadingValue(true)
        dispatch(
            editProfileItem(
                'general',
                {
                    ...this.state.fields,
                },
                this.props.auth,
                () => {
                    this.onSuccess()
                },
                (status, error) => {
                    this.onError(status, error)
                },
            ),
        )
    }

    onError(status, error) {
        this.props.setLoadingValue(false)
        if (error.errors && error.errors.length > 0) {
            let fieldErrors = { ...this.state.fieldErrors }
            let errorMessages = []
            error.errors.forEach((errorObject) => {
                if (errorObject.field) {
                    fieldErrors[errorObject.field] = true
                }
                if (errorObject.message) {
                    errorMessages.push(errorObject.message)
                }
            })
            this.setState({
                errors: errorMessages,
                fieldErrors: fieldErrors,
            })
        } else {
            this.setState({
                errors: [translate('server_error')],
            })
        }
    }

    onSuccess() {
        this.props.setLoadingValue(false)
        this.setState({
            errors: [],
            fieldErrors: {
                discovery_method: false,
            },
        })
        this.props.onSave()
    }

    getClassNames(item, key) {
        let className = ''

        if (this.state.fieldErrors[key]) {
            className += ' error'
        }
        if (item != null && item !== '' && item.toString().length > 0) {
            className += ' has-value'
        }
        return className
    }

    render() {
        return (
            <form
                className={this.props.className}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        //Enter pressed
                        this.save()
                    }
                }}
            >
                <fieldset className="form__fieldset">
                    <div className={'form__item' + isRequired('discovery_method', this.state)}>
                        <textarea
                            className={
                                this.getClassNames(
                                    this.state.fields.discovery_method,
                                    'discovery_method',
                                ) + ' form__input--text'
                            }
                            type="text"
                            id="discovery_method"
                            value={this.state.fields.discovery_method}
                            onChange={(value) => this.handleChange(value, 'discovery_method')}
                            autoFocus
                            tabIndex="3"
                        />
                        <label className="form__label" htmlFor="discovery_method">
                            <Translate value="labels.discovery_method" />
                        </label>
                    </div>
                    {this.state.errors && this.state.errors.length > 0 && (
                        <AlertBox
                            className="full-width"
                            messages={this.state.errors}
                            type="error"
                        />
                    )}
                </fieldset>
            </form>
        )
    }
}

FormDiscoveryMethod.propTypes = {
    className: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    setLoadingValue: PropTypes.func.isRequired,
    loading: PropTypes.bool,
}

FormDiscoveryMethod.defaultProps = {
    loading: false,
}

function mapStateToProps(state) {
    const { auth, user } = state

    return {
        auth,
        user,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, null, null, { forwardRef: true })(FormDiscoveryMethod)

import { logoutUser } from './auth'
import Raven from 'raven-js'
import { store } from '../index'

/**
 *
 * @param method
 * @param endpoint
 * @param body
 * @param authState - The Auth part of the state object
 * @returns {Promise}
 */

const minimumInterval = 30
let previousApiCalls = {}

export function newCallApi(
    method,
    endpoint,
    body,
    authState,
    stringifyRequest = true,
    hasReturnValue = true,
    responseType = 'json',
    force = false,
) {
    return new Promise((resolve, reject) => {
        let currentAuthState = store.getState().auth
        let authenticated = false

        if (authState) {
            authenticated = true
            currentAuthState = authState
        } else {
            if (currentAuthState) {
                if (currentAuthState.isAuthenticated) {
                    if (currentAuthState.token && currentAuthState.token != null) {
                        authenticated = true
                    }
                }
            }
        }

        callApi(
            method,
            endpoint,
            body,
            authenticated ? currentAuthState : null,
            stringifyRequest,
            responseType,
            force,
        )
            .then((success) => {
                if (hasReturnValue) {
                    if (responseType === 'blob') {
                        // console.log('[ API ]', 'blob!', success);
                        success.blob().then((blob) => {
                            resolve(blob)
                        })
                    } else {
                        // console.log('[ API ]', 'no blob', success);
                        success.json().then((json) => {
                            resolve(json)
                        })
                    }
                } else {
                    resolve()
                }
            })
            .catch((error) => {
                if (error instanceof Error) {
                    console.warn('[ API ]', 'Caught error from callApi()', error)
                    reject({
                        status: 500,
                        errors: [
                            {
                                message:
                                    'Er ging iets fout. Probeer het over enkele ogenblikken opnieuw.',
                            },
                        ], // General fetch error
                    })
                } else if (typeof error.json !== 'undefined') {
                    error
                        .json()
                        .then((json) => {
                            reject(
                                Object.assign({}, json, {
                                    status: error.status,
                                }),
                            )
                        })
                        .catch(() => {
                            reject({
                                status: error.status,
                                errors: [
                                    {
                                        message:
                                            'Er ging iets fout. Probeer het over enkele ogenblikken opnieuw.',
                                    },
                                ],
                            })
                        })
                } else {
                    reject({
                        status: 500,
                        errors: [
                            {
                                message:
                                    'Er ging iets fout. Probeer het over enkele ogenblikken opnieuw.',
                            },
                        ],
                    })
                }
            })
    })
}

const callApi = (
    method,
    endpoint,
    body,
    authState,
    stringifyRequest = true,
    responseType = 'json',
    force = false,
) => {
    let methods = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE']

    const authHeader = {}

    if (authState && authState.isAuthenticated) {
        if (authState.token && authState.token != null) {
            authHeader['Authorization'] = `Bearer ${authState.token}`
        } else {
            throw new Error('No token given to the function')
        }
    }

    if (methods.indexOf(method) == -1) {
        throw new Error('No valid method given to the function')
    }

    let config = {
        method: method,
        headers: {
            'X-Requested-With': 'xmlhttprequest',
            'Accept-Language': 'nl',
        },
    }

    if (body && stringifyRequest) {
        config.body = JSON.stringify(body)
        config.headers['Content-Type'] = 'application/json'
    } else if (body) {
        config.body = body
    }

    if (responseType === 'json') {
        config.headers['Accept'] = 'application/json'
    }

    config.headers = Object.assign({}, config.headers, authHeader)

    let allowFetch = force
    if (!previousApiCalls[method + ' ' + endpoint + JSON.stringify(body)]) {
        previousApiCalls[method + ' ' + endpoint + JSON.stringify(body)] = new Date()
        allowFetch = true
    } else {
        if (
            new Date() - previousApiCalls[method + ' ' + endpoint + JSON.stringify(body)] >
            minimumInterval
        ) {
            allowFetch = true
        } else {
            console.log(
                '[ API ] (infinite) loop error: API called again after ' +
                    (
                        new Date() -
                        previousApiCalls[method + ' ' + endpoint + JSON.stringify(body)]
                    ).toString() +
                    'ms',
            )
        }
        previousApiCalls[method + ' ' + endpoint + JSON.stringify(body)] = new Date()
    }

    return new Promise((resolve, reject) => {
        if (allowFetch) {
            fetch(endpoint, config)
                .then((response) => {
                    if (response.ok) {
                        resolve(response)
                    } else {
                        handleError(authState, response)
                        reject(response)
                    }
                })
                .catch((error) => {
                    handleError(authState, error)
                    reject(error)
                })
        } else {
            throw new Error('Fetch of ' + method + ' ' + endpoint + ' disallowed by callApi()')
        }
    })
}

const handleError = (auth, error) => {
    const status = error.status
    switch (status) {
        case 404: // File not found
            break
        case 401: // Unauthorized
            console.log('[ API ]', 'Unauthorized response received, logging out user')
            store.dispatch(logoutUser(auth))
            break
        case 403: // Forbidden
            break
        case 400: // malformed JSON
            break
        case 503: // Service Unavailable
            alert(
                'Probeer het AUB nogmaals binnen een paar minuten.\n\nPlease try again in a few moments.',
            )
            document.body.style.opacity = '0.5'
            setTimeout(() => {
                window.location.reload()
            }, 10000)
            break
        case 500: // Internal server error
            // Sentry message
            console.warn('[ API ]', '500 error')
            Raven.captureMessage('API responded with 500')
            break
    }
}

export default newCallApi

import { connect } from 'react-redux'
import React, { Component } from 'react'
import Panel from '../../Panel/Panel'
import { translate, Translate } from 'react-i18nify'
import ButtonPanel from '../../Button/ButtonPanel'
import { Hidden, Visible } from 'react-grid-system'
import { getLanguageDot } from '../helpers'
import Actions from '../../Actions/Actions'
import { Link } from 'react-router-dom'
import Suggestion from '../../Suggestion/Suggestion'
import { diffProfileItems } from '../../../store/actions/user'
import ModalForm from '../../Modal/ModalForm'
import FormLanguages from '../../Forms/FormLanguages'

class LanguagesPanel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            addLang: false,
            editLang: undefined,
        }
    }

    getLanguageModal(edit = false, lang = {}) {
        if (edit) {
            return (
                <ModalForm
                    title={translate('profile.change_languages')}
                    show
                    onClose={() => {
                        this.setState({
                            editLang: undefined,
                        })
                    }}
                >
                    <FormLanguages
                        className="form"
                        onCancel={() => {
                            this.setState({
                                editLang: undefined,
                            })
                        }}
                        onSave={() => {
                            this.setState({
                                editLang: undefined,
                            })
                            this.props.showAlert()
                        }}
                        dispatch={this.props.dispatch}
                        edit={lang}
                    />
                </ModalForm>
            )
        } else {
            return (
                <ModalForm
                    title={translate('profile.add_languages')}
                    show
                    onClose={() => {
                        this.setState({
                            addLang: false,
                        })
                    }}
                >
                    <FormLanguages
                        className="form"
                        onCancel={() => {
                            this.setState({
                                addLang: false,
                            })
                        }}
                        onSave={() => {
                            this.setState({
                                addLang: false,
                            })
                            this.props.showAlert()
                        }}
                        dispatch={this.props.dispatch}
                    />
                </ModalForm>
            )
        }
    }

    removeLanguage(lang) {
        let c = window.confirm(translate('profile.confirm_delete_item', { item: lang.name }))
        if (c) {
            this.props.dispatch(diffProfileItems('languages', [], [lang.id], this.props.auth))
        }
    }

    render() {
        let { languages } = this.props.user.profile

        if (languages && languages.length > 0) {
            return (
                <Panel
                    className="panel--multi__inner"
                    headerText={translate('profile.languages')}
                    headerIcon="icon-globe"
                    addButton={
                        <ButtonPanel
                            buttonText={translate('profile.add_new')}
                            className="button--cta"
                            onClick={() => {
                                this.setState({
                                    addLang: true,
                                })
                            }}
                        />
                    }
                >
                    <Hidden xs>
                        <table className="panel__table--languages">
                            <thead>
                                <tr>
                                    <th>
                                        <Translate value="profile.languages" />
                                    </th>
                                    <th>
                                        <Translate value="profile.speaking" />
                                    </th>
                                    <th>
                                        <Translate value="profile.reading" />
                                    </th>
                                    <th>
                                        <Translate value="profile.writing" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {languages.map((language) => {
                                    return (
                                        <tr key={language.id + '-' + language.name + '-sm'}>
                                            <td>{language.name}</td>
                                            {getLanguageDot(language.speak)}
                                            {getLanguageDot(language.read)}
                                            {getLanguageDot(language.write)}
                                            <td>
                                                <Actions>
                                                    <Link
                                                        onClick={() => {
                                                            this.setState({
                                                                editLang: language,
                                                            })
                                                        }}
                                                        className="actions__action--edit"
                                                    >
                                                        <Translate value="profile.edit" />
                                                    </Link>
                                                    <Link
                                                        className="actions__action--delete"
                                                        onClick={() => {
                                                            this.removeLanguage(language)
                                                        }}
                                                    >
                                                        <Translate value="profile.remove" />
                                                    </Link>
                                                </Actions>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </Hidden>
                    <Visible xs>
                        {languages.map((language) => {
                            return (
                                <div
                                    className="panel__tables--no_margin list_item"
                                    key={language.id + '-' + language.name + '-xs'}
                                >
                                    <h3>{language.name}</h3>
                                    <table className="panel__table--languages">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <Translate value="profile.speaking" />
                                                </th>
                                                <th>
                                                    <Translate value="profile.reading" />
                                                </th>
                                                <th>
                                                    <Translate value="profile.writing" />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                {getLanguageDot(language.speak)}
                                                {getLanguageDot(language.read)}
                                                {getLanguageDot(language.write)}
                                            </tr>
                                        </tbody>
                                    </table>

                                    <Actions>
                                        <Link
                                            onClick={() => {
                                                this.setState({
                                                    editLang: language,
                                                })
                                            }}
                                            className="actions__action--edit"
                                        >
                                            <Translate value="profile.edit" />
                                        </Link>
                                        <Link
                                            className="actions__action--delete"
                                            onClick={() => {
                                                this.removeLanguage(language)
                                            }}
                                        >
                                            <Translate value="profile.remove" />
                                        </Link>
                                    </Actions>
                                </div>
                            )
                        })}
                    </Visible>

                    {this.state.editLang && this.getLanguageModal(true, this.state.editLang)}

                    {this.state.addLang && this.getLanguageModal()}
                </Panel>
            )
        } else {
            return (
                <Panel
                    className="panel--suggestion panel--multi__inner"
                    headerText={translate('profile.languages')}
                    headerIcon="icon-globe"
                    addButton={
                        <ButtonPanel
                            buttonText={translate('profile.add_new')}
                            className="button--cta"
                            onClick={() => {
                                this.setState({
                                    addLang: true,
                                })
                            }}
                        />
                    }
                >
                    <Suggestion iconName="icon-globe">
                        <Translate value="profile.no_languages" />
                    </Suggestion>

                    {this.state.addLang && this.getLanguageModal()}
                </Panel>
            )
        }
    }
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(LanguagesPanel)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { PATH_PROFILE, PATH_PUBLIC_PROFILE, TESTABLE_TYPE_EELLOO } from '../constants/Constants'
import slugify from 'slugify'
import { translate, Translate } from 'react-i18nify'
import { Link } from 'react-router-dom'
import newCallApi from '../store/actions/api'
import { Col, Container, Hidden, Row, Visible } from 'react-grid-system'
import GridColOffset from '../components/GridCol/GridColOffset'
import Panel from '../components/Panel/Panel'
import Tag from '../components/Tag/Tag'
import ModalWindow from '../components/Modal/Modal'
import DataBlock from '../components/DataBlock/DataBlock'
import FileLister from '../components/FileLister/FileLister'
import ListItem from '../components/ListItem/ListItem'
import Suggestion from '../components/Suggestion/Suggestion'
import Quote from '../components/Quote/Quote'
import Actions from '../components/Actions/Actions'
import { formatDate, formatYear, getPublicProfilePicture } from '../helpers'
import EellooTests from '../components/Profile/Reintegration/EellooTests'
import { saveAs } from 'file-saver'
import { diffProfileItems } from '../store/actions/user'
import history from '../history'

class PublicProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            publicUser: null,
            eellooTests: [],
        }
    }

    fetchPublicProfile() {
        this.username = this.props.match.params.username
        this.accountId = this.username.split('-').pop().trim()
        if (parseInt(this.accountId) === parseInt(this.props.auth.id))
            return history.push('/' + PATH_PROFILE)
        newCallApi('GET', this.props.config.API_PUBLIC_PROFILE.replace('{account}', this.accountId))
            .then((profile) => {
                this.setState({
                    publicUser: profile,
                    eellooTests: profile.tests.filter(
                        (t) => t.testable_type === TESTABLE_TYPE_EELLOO,
                    ),
                })
                // Make sure you're using the correct slug URL
                const actualSlug = slugify(profile.name, { lower: true }) + '-' + profile.id
                if (this.username !== actualSlug) {
                    history.push('/' + PATH_PUBLIC_PROFILE.replace(':username', actualSlug))
                }
                // Fetch profile photo
                getPublicProfilePicture(this.state.publicUser).then((profile_picture) =>
                    this.setState({ publicUser: { ...this.state.publicUser, profile_picture } }),
                )
                // Fetch account types
                newCallApi(
                    'GET',
                    this.props.config.API_DOCUMENT_AND_TYPES.replace('{account}', profile.id),
                ).then((uploaded_documents) => {
                    let total_files = 0
                    uploaded_documents.forEach((documentType) => {
                        total_files += documentType.files.length
                    })
                    this.setState({
                        publicUser: { ...this.state.publicUser, uploaded_documents, total_files },
                    })
                })
            })
            .catch((error) => {
                console.log(error)
                history.push('/' + PATH_PROFILE)
            })
    }

    componentDidMount() {
        this.fetchPublicProfile()
    }

    isCollapsible(length = 0) {
        return length > 2
    }

    getTimeString(start, end, current) {
        let sDate = new Date(start)
        let eDate = new Date(end)
        let months = [
            translate('profile.january'),
            translate('profile.february'),
            translate('profile.march'),
            translate('profile.april'),
            translate('profile.may'),
            translate('profile.june'),
            translate('profile.july'),
            translate('profile.august'),
            translate('profile.september'),
            translate('profile.october'),
            translate('profile.november'),
            translate('profile.december'),
        ]
        if (
            (current != null && current != undefined && current == 1) ||
            ((end == null || end.length == 0) && end !== undefined)
        ) {
            //month - heden
            return (
                months[sDate.getMonth()] +
                ' ' +
                formatYear(sDate.getFullYear()) +
                ' - ' +
                translate('profile.present_day')
            )
        } else if (end != null) {
            return (
                months[sDate.getMonth()] +
                ' ' +
                formatYear(sDate.getFullYear()) +
                ' - ' +
                months[eDate.getMonth()] +
                ' ' +
                formatYear(eDate.getFullYear())
            )
        } else {
            return months[sDate.getMonth()] + ' ' + formatYear(sDate.getFullYear())
        }
    }

    getStudyName(study) {
        let other = study.study_name_other
        let name
        if (other == null || other == undefined || other.length == 0) {
            let studies = this.props.user.studies
            let study_id = study.study_id
            for (var i = 0; i < studies.length; i++) {
                let s = studies[i]
                if (s.id == study_id) {
                    name = s.name
                    break
                }
            }
        } else {
            name = other
        }
        return name
    }

    downloadDiploma(url, name) {
        newCallApi('GET', url, null, /* auth */ null, true, true, 'blob')
            .then((results) => {
                saveAs(results, `${name}.pdf`)
            })
            .catch((error) => {
                console.log('Got err', error)
            })
    }

    getEducationsPanel(studies) {
        if (studies != undefined && studies.length > 0) {
            return (
                <Panel
                    className={
                        this.isCollapsible(studies.length)
                            ? 'panel--expand panel--multi__inner panel--multi__inner__uneditable'
                            : 'panel--multi__inner panel--multi__inner__uneditable'
                    }
                    headerText={translate('profile.education')}
                    headerIcon="icon-graduation"
                >
                    <div className="panel__list">
                        {studies.map((study) => {
                            return (
                                <ListItem
                                    key={study.id}
                                    onEdit={() => {
                                        this.setState({
                                            editEducation: study,
                                        })
                                    }}
                                    onChange={() => {
                                        this.showAlert()
                                    }}
                                    dispatch={this.props.dispatch}
                                    auth={this.props.auth}
                                    type="studies"
                                    item={study}
                                    hideDelete={true}
                                    hideEdit={true}
                                    title={this.getStudyName(study)}
                                >
                                    <p>
                                        {this.getTimeString(
                                            study.start_date,
                                            study.end_date,
                                            study.current,
                                        )}
                                    </p>
                                    <h3>
                                        <span>{this.getStudyName(study)}</span>
                                        <br />
                                        {study.institute_name}
                                    </h3>
                                    <p>
                                        <Translate value="profile.graduated" />-{' '}
                                        {study.finished == 1
                                            ? translate('profile.graduated_yes')
                                            : translate('profile.graduated_no')}
                                    </p>
                                    {study.verified && (
                                        <Actions>
                                            <Link
                                                onClick={() => {
                                                    this.downloadDiploma(
                                                        study.attachment,
                                                        `${this.getStudyName(study)}_${
                                                            study.institute_name
                                                        }`,
                                                    )
                                                }}
                                                className="actions__action--download"
                                            >
                                                <Translate value="profile.emrex.download_diploma" />
                                            </Link>
                                        </Actions>
                                    )}
                                </ListItem>
                            )
                        })}
                    </div>
                    {this.state.editEducation != undefined &&
                        this.getEducationsModal(true, this.state.editEducation)}
                    {this.state.addEducation && this.getEducationsModal()}
                </Panel>
            )
        } else {
            return (
                <Panel
                    className="panel--suggestion panel--multi__inner panel--multi__inner__uneditable"
                    headerText={translate('profile.education')}
                    headerIcon="icon-graduation"
                >
                    <Suggestion iconName="icon-graduation">
                        <Translate value="profile.no_education" />
                    </Suggestion>
                    {this.state.addEducation && this.getEducationsModal()}
                </Panel>
            )
        }
    }

    getCoursesPanel(courses) {
        if (courses != undefined && courses != null && courses.length > 0) {
            return (
                <Panel
                    className={
                        this.isCollapsible(courses.length)
                            ? 'panel--expand panel--multi__inner panel--multi__inner__uneditable'
                            : 'panel--multi__inner panel--multi__inner__uneditable'
                    }
                    headerText={translate('profile.courses')}
                    headerIcon="icon-book-open"
                >
                    <div className="panel__list">
                        {courses.map((course) => {
                            return (
                                <ListItem
                                    key={course.id}
                                    dispatch={this.props.dispatch}
                                    auth={this.props.auth}
                                    type="courses"
                                    item={course}
                                    title={course.name}
                                    hideDelete={true}
                                    hideEdit={true}
                                >
                                    <p>
                                        {this.getTimeString(
                                            course.achieved,
                                            course.expiration_date,
                                            0,
                                        )}
                                    </p>
                                    <h3>
                                        <span>{course.name}</span>
                                    </h3>
                                    <p>
                                        <Translate value="profile.graduated_at" />:{' '}
                                        {this.getTimeString(course.achieved)}
                                        {course.expiration_date && (
                                            <span>
                                                <br />
                                                <Translate value="profile.expires_on" />:{' '}
                                                {this.getTimeString(course.expiration_date)}
                                            </span>
                                        )}
                                    </p>
                                </ListItem>
                            )
                        })}
                    </div>
                </Panel>
            )
        } else {
            return (
                <Panel
                    className="panel--suggestion panel--multi__inner panel--multi__inner__uneditable"
                    headerText={translate('profile.courses')}
                    headerIcon="icon-book-open"
                >
                    <Suggestion iconName="icon-book-open">
                        <Translate value="profile.no_courses" />
                    </Suggestion>
                </Panel>
            )
        }
    }

    getCompetencesPanel(competencies) {
        if (competencies !== undefined && competencies[0] !== null && competencies.length > 0) {
            return (
                <Panel
                    className={
                        competencies.length > 10
                            ? 'panel--expand panel--multi__inner panel--multi__inner__uneditable tags'
                            : 'panel--multi__inner panel--multi__inner__uneditable'
                    }
                    headerText={translate('profile.competences')}
                    headerIcon="icon-badge"
                >
                    <div className="panel__form">
                        <Row>
                            <Col sm={12}>
                                <div className="panel__tags">
                                    {competencies.map((competence) => {
                                        return (
                                            <Tag
                                                key={competence.id}
                                                check={competence.external}
                                                score={competence.score ? competence.score : 1}
                                                hideDelete={true}
                                                showEdit={false}
                                                title={competence.name}
                                            >
                                                {competence.name}
                                            </Tag>
                                        )
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Panel>
            )
        } else {
            return (
                <Panel
                    className="panel--suggestion panel--multi__inner panel--multi__inner__uneditable"
                    headerText={translate('profile.competences')}
                    headerIcon="icon-badge"
                >
                    <Suggestion iconName="icon-badge">
                        <Translate value="profile.no_competences" />
                    </Suggestion>
                </Panel>
            )
        }
    }

    getInterestsPanel(interests) {
        if (interests != undefined && interests.length > 0) {
            return (
                <Panel
                    className={
                        interests.length > 10
                            ? 'panel--expand panel--multi__inner panel--multi__inner__uneditable tags--mobile'
                            : 'panel--multi__inner panel--multi__inner__uneditable'
                    }
                    headerText={translate('profile.interests')}
                    headerIcon="icon-bulb"
                >
                    <div className="panel__form">
                        <div className="panel__tags">
                            {interests.map((interest) => {
                                return (
                                    <Tag
                                        key={interest.id}
                                        hideDelete={true}
                                        showEdit={false}
                                        title={interest.name}
                                    >
                                        {interest.name}
                                    </Tag>
                                )
                            })}
                        </div>
                    </div>
                </Panel>
            )
        } else {
            return (
                <Panel
                    className="panel--suggestion panel--multi__inner panel--multi__inner__uneditable "
                    headerText={translate('profile.interests')}
                    headerIcon="icon-bulb"
                >
                    <Suggestion iconName="icon-bulb">
                        <Translate value="profile.no_interests" />
                        <div className="spacer-s" />
                    </Suggestion>
                </Panel>
            )
        }
    }

    getLanguageDot(grade) {
        let className = 'panel__dot'
        if (grade >= 7) {
            className += ' good'
        } else if (grade >= 4) {
            className += ' medium'
        } else {
            className += ' bad'
        }
        return <td className={className}>&#9679;</td>
    }

    getLanguagesPanel(languages) {
        if (languages && languages.length > 0) {
            return (
                <Panel
                    className="panel--multi__inner panel--multi__inner__uneditable"
                    headerText={translate('profile.languages')}
                    headerIcon="icon-globe"
                >
                    <Hidden xs>
                        <table className="panel__table">
                            <thead>
                                <tr>
                                    <th>
                                        <Translate value="profile.languages" />
                                    </th>
                                    <th>
                                        <Translate value="profile.speaking" />
                                    </th>
                                    <th>
                                        <Translate value="profile.reading" />
                                    </th>
                                    <th>
                                        <Translate value="profile.writing" />
                                    </th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {languages.map((language) => {
                                    return (
                                        <tr key={language.id}>
                                            <td>{language.name}</td>
                                            {this.getLanguageDot(language.speak)}
                                            {this.getLanguageDot(language.read)}
                                            {this.getLanguageDot(language.write)}
                                            <td />
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </Hidden>
                    <Visible xs>
                        {languages.map((language) => {
                            return (
                                <div className="panel__tables" key={language.id}>
                                    <h3>{language.name}</h3>
                                    <table className="panel__table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <Translate value="profile.speaking" />
                                                </th>
                                                <th>
                                                    <Translate value="profile.reading" />
                                                </th>
                                                <th>
                                                    <Translate value="profile.writing" />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                {this.getLanguageDot(language.speak)}
                                                {this.getLanguageDot(language.read)}
                                                {this.getLanguageDot(language.write)}
                                            </tr>
                                        </tbody>
                                    </table>

                                    <Actions>
                                        <Link
                                            onClick={() => {
                                                this.setState({
                                                    editLang: language,
                                                })
                                            }}
                                            className="actions__action--edit"
                                        >
                                            <Translate value="profile.edit" />
                                        </Link>
                                        <Link
                                            className="actions__action--delete"
                                            onClick={() => {
                                                this.removeLanguage(language)
                                            }}
                                        >
                                            <Translate value="profile.remove" />
                                        </Link>
                                    </Actions>
                                </div>
                            )
                        })}
                    </Visible>

                    {this.state.editLang && this.getLanguageModal(true, this.state.editLang)}

                    {this.state.addLang && this.getLanguageModal()}
                </Panel>
            )
        } else {
            return (
                <Panel
                    className="panel--suggestion panel--multi__inner panel--multi__inner__uneditable"
                    headerText={translate('profile.languages')}
                    headerIcon="icon-globe"
                >
                    <Suggestion iconName="icon-globe">
                        <Translate value="profile.no_languages" />
                    </Suggestion>
                    {this.state.addLang && this.getLanguageModal()}
                </Panel>
            )
        }
    }

    getReferencesPanel(refs) {
        if (refs != undefined && refs.length > 0) {
            return (
                <Panel
                    className={this.isCollapsible(refs.length) ? 'panel--expand' : 'panel'}
                    headerText={translate('profile.references')}
                    headerIcon="icon-user"
                >
                    <div className="panel__list text-justify">
                        {refs.map((ref) => {
                            return (
                                <ListItem
                                    key={ref.id}
                                    dispatch={this.props.dispatch}
                                    auth={this.props.auth}
                                    type="references"
                                    item={ref}
                                    hideEdit={true}
                                    hideDelete={true}
                                    title={ref.name}
                                >
                                    <h3>
                                        <span>{ref.name}</span>
                                    </h3>
                                    <p>
                                        <span>{ref.company}</span>
                                    </p>
                                    <p>{ref.description}</p>
                                </ListItem>
                            )
                        })}
                    </div>
                </Panel>
            )
        } else {
            return (
                <Panel
                    className="panel--suggestion"
                    headerText={translate('profile.references')}
                    headerIcon="icon-user"
                >
                    <Suggestion iconName="icon-user">
                        <Translate value="profile.no_references" />
                    </Suggestion>
                </Panel>
            )
        }
    }

    getJobsPanel(job_interests) {
        if (job_interests != undefined && job_interests.length > 0) {
            return (
                <Panel
                    className={job_interests.length > 5 ? 'panel--expand' : 'panel'}
                    headerText={translate('profile.job_interest')}
                    headerIcon="icon-direction"
                >
                    <div className="panel__form">
                        <div className="panel__tags">
                            {job_interests.map((interest) => {
                                return (
                                    <Tag
                                        key={interest.id}
                                        hideDelete={true}
                                        showEdit={false}
                                        title={interest.name}
                                    >
                                        {interest.name}
                                    </Tag>
                                )
                            })}
                        </div>
                    </div>
                </Panel>
            )
        } else {
            return (
                <Panel
                    className="panel--suggestion"
                    headerText={translate('profile.job_interest')}
                    headerIcon="icon-briefcase"
                />
            )
        }
    }

    getDocumentsPanel(files_to_upload) {
        return (
            <Panel
                className="panel"
                headerText={translate('profile.documents')}
                headerIcon="icon-docs"
            >
                {files_to_upload && typeof files_to_upload !== 'undefined' && (
                    <div>
                        {files_to_upload && files_to_upload.length > 0 && (
                            <div>
                                <table className="panel__table">
                                    <tbody>
                                        {files_to_upload.map((uploadDocument) => {
                                            return uploadDocument.files.length &&
                                                uploadDocument.files.length !== 0 ? (
                                                <tr key={uploadDocument.id}>
                                                    <td>{uploadDocument.name}</td>
                                                    <td>
                                                        {uploadDocument.files.length &&
                                                        uploadDocument.files.length !== 0
                                                            ? uploadDocument.files.length
                                                            : ''}
                                                    </td>
                                                    {!!(
                                                        uploadDocument.files.length &&
                                                        parseInt(uploadDocument.files.length) > 0
                                                    ) ? (
                                                        <td>
                                                            <Actions>
                                                                <Link
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            viewdoc: undefined,
                                                                        })
                                                                        setTimeout(() => {
                                                                            this.setState({
                                                                                viewdoc:
                                                                                    uploadDocument,
                                                                            })
                                                                        }, 1)
                                                                    }}
                                                                    className="actions__action--view"
                                                                >
                                                                    {translate('buttons.view')}
                                                                </Link>
                                                            </Actions>
                                                        </td>
                                                    ) : (
                                                        <td />
                                                    )}
                                                </tr>
                                            ) : null
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        {(!files_to_upload || !files_to_upload || files_to_upload.length <= 0) && (
                            <Panel
                                className="panel--multi__inner panel--multi__inner__uneditable"
                                headerText={translate('profile.upload_files')}
                                headerIcon="icon-cloud-upload"
                            >
                                <Translate value="profile.no_uploading" />
                            </Panel>
                        )}
                    </div>
                )}
            </Panel>
        )
    }

    getDocumentsModals() {
        return (
            <div>
                {this.state.viewdoc && (
                    <ModalWindow
                        show={true}
                        title={this.state.viewdoc.name}
                        onCancel={() => {
                            this.setState({
                                viewdoc: undefined,
                            })
                        }}
                    >
                        <FileLister
                            dispatch={this.props.dispatch}
                            files={this.state.viewdoc.files}
                            auth={this.props.auth}
                            userId={this.state.publicUser.id}
                            hideDelete={true}
                        />
                    </ModalWindow>
                )}
            </div>
        )
    }

    getSkillsPanel(skills) {
        if (skills != undefined && skills.length > 0) {
            return (
                <Panel
                    className={
                        skills.length > 10
                            ? 'panel--expand panel--multi__inner panel--multi__inner__uneditable tags--mobile'
                            : 'panel--multi__inner panel--multi__inner__uneditable'
                    }
                    headerText={translate('profile.skills')}
                    headerIcon="icon-star"
                >
                    <div className="panel__form">
                        <div className="panel__tags">
                            {skills.map((skill) => {
                                return (
                                    <Tag
                                        key={skill.id}
                                        onRemove={() => {
                                            this.props.dispatch(
                                                diffProfileItems(
                                                    'skills',
                                                    [],
                                                    [skill.id],
                                                    this.props.auth,
                                                ),
                                            )
                                        }}
                                        showEdit={false}
                                        hideDelete={true}
                                        title={skill.name}
                                    >
                                        {skill.name}
                                    </Tag>
                                )
                            })}
                        </div>
                    </div>
                </Panel>
            )
        } else {
            return (
                <Panel
                    className="panel--suggestion panel--multi__inner panel--multi__inner__uneditable "
                    headerText={translate('profile.skills')}
                    headerIcon="icon-star"
                >
                    <Suggestion iconName="icon-star">
                        <Translate value="profile.no_skills" />
                        <div className="spacer-s" />
                    </Suggestion>
                </Panel>
            )
        }
    }

    getJobFunctionsPanel(functions) {
        if (functions != undefined && functions.length > 0) {
            return (
                <Panel
                    className={this.isCollapsible(functions.length) ? 'panel--expand' : 'panel'}
                    headerText={translate('profile.experience')}
                    headerIcon="icon-settings"
                >
                    <div className="panel__list">
                        {functions.map((item) => {
                            return (
                                <ListItem
                                    key={item.id}
                                    dispatch={this.props.dispatch}
                                    auth={this.props.auth}
                                    type="job_functions"
                                    item={item}
                                    showEdit={false}
                                    hideEdit={true}
                                    hideDelete={true}
                                    title={item.function_title}
                                >
                                    <p>
                                        {this.getTimeString(
                                            item.start_date,
                                            item.end_date,
                                            item.current_position,
                                        )}
                                        , {item.city}
                                    </p>
                                    <h3>
                                        <span>{item.function_title}</span>
                                        <br />
                                        {item.company_name}
                                    </h3>
                                </ListItem>
                            )
                        })}
                    </div>
                </Panel>
            )
        } else {
            return (
                <Panel
                    className="panel--suggestion"
                    headerText={translate('profile.experience')}
                    headerIcon="icon-settings"
                >
                    <Suggestion iconName="icon-settings">
                        <Translate value="profile.no_experience" />
                    </Suggestion>
                </Panel>
            )
        }
    }

    getMailToLink(mail) {
        return 'mailto:' + mail
    }

    getCallToLink(phonenumber) {
        return 'tel:' + phonenumber
    }

    getEmailBlock(profile) {
        if (profile.email != null) {
            return (
                <div>
                    <DataBlock>
                        <Translate value="profile.email" />
                        <br />
                        <a href={this.getMailToLink(profile.email)}>{profile.email}</a>
                    </DataBlock>
                </div>
            )
        } else {
            return ''
        }
    }

    getAddressBlock(profile) {
        let addr = profile.address
        let nr = profile.address_number
        let suffix = profile.address_number_suffix
        let city = profile.city_name
        let postal = profile.postal_code
        let retValue = ''
        if (addr == null || nr == null) {
            return ''
        } else {
            retValue += addr + ' ' + nr
            if (suffix != null) {
                retValue += suffix
            }
            if (postal != null || city != null) {
                retValue += '<br />'
            }
            if (postal != null) {
                retValue += postal

                if (city != null) {
                    retValue += ' '
                }
            }
            if (city != null) {
                retValue += city
            }
            return (
                <DataBlock>
                    <Translate value="profile.address" />
                    <div
                        dangerouslySetInnerHTML={{
                            __html: '<div>' + retValue + '</div>',
                        }}
                    />
                </DataBlock>
            )
        }
    }

    getPhoneNumbersBlock(profile) {
        let phone = profile.phone_number
        let mobile = profile.mobile_number
        let retValue = ''
        if (phone == null && mobile == null) {
            return ''
        } else {
            if (phone != null) {
                retValue += phone

                if (mobile != null) {
                    retValue += '<br />'
                    retValue += mobile
                }
            } else {
                retValue += mobile
            }

            return (
                <div>
                    <DataBlock>
                        <Translate value="profile.phone" />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: '<div>' + retValue + '</div>',
                            }}
                        />
                    </DataBlock>
                </div>
            )
        }
    }

    getBirthBlock(profile) {
        if (
            profile.date_of_birth == null ||
            profile.date_of_birth.length == 0 ||
            profile.date_of_birth == '' ||
            profile.date_of_birth == '0000-00-00'
        ) {
            return ''
        } else {
            let retValue = formatDate(profile.date_of_birth)

            if (profile.place_of_birth != null) {
                retValue += '<br />'
                retValue += profile.place_of_birth
            }

            return (
                <div>
                    <DataBlock>
                        <Translate value="profile.birth_date" />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: '<div>' + retValue + '</div>',
                            }}
                        />
                    </DataBlock>
                </div>
            )
        }
    }

    getTimeString(start, end, current) {
        let sDate = new Date(start)
        let eDate = new Date(end)

        let months = [
            translate('profile.january'),
            translate('profile.february'),
            translate('profile.march'),
            translate('profile.april'),
            translate('profile.may'),
            translate('profile.june'),
            translate('profile.july'),
            translate('profile.august'),
            translate('profile.september'),
            translate('profile.october'),
            translate('profile.november'),
            translate('profile.december'),
        ]

        if (
            (current != null && current != undefined && current == 1) ||
            ((end == null || end.length == 0) && end !== undefined)
        ) {
            //month - heden
            return (
                months[sDate.getMonth()] +
                ' ' +
                formatYear(sDate.getFullYear()) +
                ' - ' +
                translate('profile.present_day')
            )
        } else if (end != null) {
            return (
                months[sDate.getMonth()] +
                ' ' +
                formatYear(sDate.getFullYear()) +
                ' - ' +
                months[eDate.getMonth()] +
                ' ' +
                formatYear(eDate.getFullYear())
            )
        } else {
            return months[sDate.getMonth()] + ' ' + formatYear(sDate.getFullYear())
        }
    }

    render() {
        return (
            <div className="react-native-web">
                <Container>
                    <div>
                        {this.state.publicUser && Object.keys(this.state.publicUser).length && (
                            <div>
                                {this.getDocumentsModals()}
                                <Row className="panel--flex">
                                    {!this.state.publicUser.quote_text && <GridColOffset />}
                                    <Col
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        xl={!!this.state.publicUser.quote_text ? 7 : 8}
                                    >
                                        <Panel className="panel--bg">
                                            <Row style={{ padding: '2rem 0' }}>
                                                <Col sm={12} md={3}>
                                                    <div
                                                        className="panel__profile_img"
                                                        style={{ padding: 0 }}
                                                    >
                                                        <div className="panel__profile_img__img">
                                                            <img
                                                                src={
                                                                    this.state.publicUser
                                                                        .profile_picture
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm={12} md={9}>
                                                    <div
                                                        className="panel__intro"
                                                        style={{
                                                            padding: 0,
                                                            height: '13.5rem',
                                                        }}
                                                    >
                                                        <h2
                                                            style={{
                                                                paddingTop: 0,
                                                                margin: 0,
                                                                paddingLeft: '3rem',
                                                            }}
                                                        >
                                                            {this.state.publicUser.name}
                                                        </h2>
                                                        {!!this.state.publicUser.tagline_text && (
                                                            <p
                                                                style={{
                                                                    paddingBottom: 0,
                                                                    paddingTop: '0.5rem',
                                                                    paddingLeft: '3rem',
                                                                    marginBottom: 0,
                                                                }}
                                                            >
                                                                {this.state.publicUser.tagline_text}
                                                            </p>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Panel>
                                    </Col>
                                    {!!this.state.publicUser.quote_text && (
                                        <Col xs={12} sm={12} md={12} xl={5}>
                                            <Panel className="panel--quote">
                                                <Quote>{this.state.publicUser.quote_text}</Quote>
                                                <Actions />
                                            </Panel>
                                        </Col>
                                    )}
                                </Row>
                                <Row>
                                    {!this.state.publicUser.total_files &&
                                        !this.state.publicUser.job_interests.length &&
                                        !this.state.eellooTests.length && <GridColOffset />}
                                    <Col
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        xl={
                                            !this.state.publicUser.total_files &&
                                            !this.state.publicUser.job_interests.length &&
                                            !this.state.eellooTests.length
                                                ? 8
                                                : 7
                                        }
                                    >
                                        {(this.state.publicUser.email ||
                                            this.state.publicUser.address ||
                                            this.state.publicUser.phone_number ||
                                            this.state.publicUser.mobile_number ||
                                            this.state.publicUser.date_of_birth ||
                                            this.state.publicUser.place_of_birth) && (
                                            <Panel
                                                className="panel"
                                                headerText={translate('profile.personal_data')}
                                                headerIcon={'icon-user'}
                                            >
                                                <Row>
                                                    <Col md={12}>
                                                        {this.state.publicUser.email &&
                                                            this.getEmailBlock(
                                                                this.state.publicUser,
                                                            )}
                                                        {this.state.publicUser.address &&
                                                            this.getAddressBlock(
                                                                this.state.publicUser,
                                                            )}
                                                        {(this.state.publicUser.phone_number ||
                                                            this.state.publicUser.mobile_number) &&
                                                            this.getPhoneNumbersBlock(
                                                                this.state.publicUser,
                                                            )}
                                                        {(this.state.publicUser.date_of_birth ||
                                                            this.state.publicUser.place_of_birth) &&
                                                            this.getBirthBlock(
                                                                this.state.publicUser,
                                                            )}
                                                    </Col>
                                                </Row>
                                            </Panel>
                                        )}
                                        {!!this.state.publicUser.job_functions.length && (
                                            <div id="workExperiencePanel">
                                                {this.getJobFunctionsPanel(
                                                    this.state.publicUser.job_functions,
                                                )}
                                            </div>
                                        )}
                                        {(!!this.state.publicUser.courses.length ||
                                            !!this.state.publicUser.studies.length) && (
                                            <div id="courseEducationPanel">
                                                <Panel
                                                    className="panel--multi"
                                                    // headerText={translate("profile.panel_education")}
                                                    // headerIcon="icon-badge"
                                                >
                                                    {!!this.state.publicUser.studies.length && (
                                                        <div id="educationsPanel">
                                                            {this.getEducationsPanel(
                                                                this.state.publicUser.studies,
                                                            )}
                                                        </div>
                                                    )}
                                                    {!!this.state.publicUser.studies.length &&
                                                        !!this.state.publicUser.courses.length && (
                                                            <div className="spacer-hr" />
                                                        )}
                                                    {!!this.state.publicUser.courses.length && (
                                                        <div id="coursesPanel">
                                                            {this.getCoursesPanel(
                                                                this.state.publicUser.courses,
                                                            )}
                                                        </div>
                                                    )}
                                                </Panel>
                                            </div>
                                        )}
                                        {(!!this.state.publicUser.competences.length ||
                                            !!this.state.publicUser.interests.length ||
                                            !!this.state.publicUser.languages.length) && (
                                            <div id="competenciesPanel">
                                                <Panel
                                                    className="panel--multi"
                                                    // headerText={translate("profile.panel_competences")}
                                                    // headerIcon="icon-equalizer"
                                                >
                                                    {!!this.state.publicUser.competences.length && (
                                                        <div id="competencesPanel">
                                                            {this.getCompetencesPanel(
                                                                this.state.publicUser.competences,
                                                            )}
                                                        </div>
                                                    )}
                                                    {!!this.state.publicUser.skills.length && (
                                                        <div id="skillsPanel">
                                                            {this.getSkillsPanel(
                                                                this.state.publicUser.skills,
                                                            )}
                                                        </div>
                                                    )}
                                                    {!!this.state.publicUser.competences.length &&
                                                        (!!this.state.publicUser.interests.length ||
                                                            !!this.state.publicUser.languages
                                                                .length) && (
                                                            <div className="spacer-hr" />
                                                        )}
                                                    {!!this.state.publicUser.interests.length && (
                                                        <div id="interestsPanel">
                                                            {this.getInterestsPanel(
                                                                this.state.publicUser.interests,
                                                            )}
                                                        </div>
                                                    )}
                                                    {!!this.state.publicUser.interests.length &&
                                                        !!this.state.publicUser.languages
                                                            .length && (
                                                            <div className="spacer-hr" />
                                                        )}
                                                    {!!this.state.publicUser.languages.length && (
                                                        <div id="languagesPanel">
                                                            {this.getLanguagesPanel(
                                                                this.state.publicUser.languages,
                                                            )}
                                                        </div>
                                                    )}
                                                </Panel>
                                            </div>
                                        )}
                                        {!!this.state.publicUser.references.length && (
                                            <div id="ReferencesPanel">
                                                {this.getReferencesPanel(
                                                    this.state.publicUser.references,
                                                )}
                                            </div>
                                        )}
                                    </Col>

                                    {(!!this.state.publicUser.total_files ||
                                        !!this.state.publicUser.job_interests.length ||
                                        !!this.state.eellooTests.length) && (
                                        <Col
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            xl={5}
                                            className="sticky_position"
                                        >
                                            {!!this.state.publicUser.total_files && (
                                                <div id="documentsPanel">
                                                    {this.getDocumentsPanel(
                                                        this.state.publicUser.uploaded_documents,
                                                    )}
                                                </div>
                                            )}
                                            {!!this.state.eellooTests.length && (
                                                <div id="eellooTestsPanel">
                                                    <EellooTests
                                                        profile={this.state.publicUser}
                                                        showDetails={false}
                                                    />
                                                </div>
                                            )}
                                            {!!this.state.publicUser.job_interests.length && (
                                                <div id="JobsPanel">
                                                    {this.getJobsPanel(
                                                        this.state.publicUser.job_interests,
                                                    )}
                                                </div>
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        )}
                    </div>
                </Container>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(PublicProfile)

import {
    ACCOUNT_FIELDS,
    ADDED_DOCUMENT,
    CLEAR_PROFILE,
    DOCUMENTS,
    FILE_UPLOAD_TYPES,
    FILLED_IN_FLEXAPP_DATA,
    FLEXAPP_ORGANIZATIONS,
    HIDE_FLEXAPP_NOTIFICATION,
    ORGANIZATIONS,
    PROFILE_ADD_ERROR,
    PROFILE_ADDED_ITEM,
    PROFILE_ADDING_ITEM,
    PROFILE_DELETED_ITEM,
    PROFILE_EDITED_ITEM,
    PROFILE_EDITING_ITEM,
    PROFILE_FAILURE,
    PROFILE_REQUEST,
    PROFILE_SUCCESS,
    PROFILE_UPDATE_ERROR,
    PROFILE_UPDATE_ITEM,
    PROFILE_UPDATED_ITEM,
    SCHOOLS,
    SCHOOLS_REQUEST,
    SET_EVENTS_OF_INTEREST,
    SET_EVENTS_REGISTERED_FOR,
    SET_POSSIBLE_EVENT_INTERESTS,
    SET_PROFILE_PICTURE,
    SHOW_FLEXAPP_NOTIFICATION,
    SIGN_OUT_FROM_EVENT,
    SIGN_UP_FOR_EVENT,
    STUDIES,
    STUDY_LEVELS,
    VACANCY_REACTION,
    VACANCY_REACTIONS,
    WORKING_AREAS,
    FLEXAPP_ORGANIZATIONS_PUBLIC,
} from '../../constants/Constants'

import { translate } from 'react-i18nify'

/**
 * Reducer to change the application state for all auth state changes
 * @param state
 * @param action
 */
export function user(
    state = {
        isFetching: false,
        error: '',
        flags: {
            add: {
                isAdding: false,
                error: '',
                type: '',
            },
            edit: {
                isEditing: false,
                error: '',
            },
        },
        profile: {},
        working_areas: [],
        studies: [],
        study_levels: [],
        schools: { list: [], loading: false },
        organizations: [],
        file_upload_types: [],
        account_field_names: [],
        documents: [],
        vacancy_reactions: [],
        profile_picture: '/images/avatar-placeholder.png',
        events_registered_for: {},
        events_of_interest: [],
        available_fields_of_interest: [],
        showFlexappNotification: false,
    },
    action,
) {
    switch (action.type) {
        case CLEAR_PROFILE:
            return Object.assign({}, state, {
                isFetching: false,
                error: '',
                flags: {
                    add: {
                        isAdding: false,
                        error: '',
                        type: '',
                    },
                    edit: {
                        isEditing: false,
                        error: '',
                    },
                },
                profile: {},
                working_areas: [],
                studies: [],
                schools: { list: [], loading: false },
                organizations: [],
                documents: [],
                vacancy_reactions: [],
                profile_picture: '/images/avatar-placeholder.png',
                events_registered_for: {},
                events_of_interest: [],
                available_fields_of_interest: [],
                showFlexappNotification: false,
            })
        case PROFILE_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                error: '',
            })
        case PROFILE_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                error: '',
                profile: action.profile,
            })
        case PROFILE_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error,
            })
        case PROFILE_ADDING_ITEM:
            return Object.assign({}, state, {
                flags: {
                    add: {
                        isAdding: true,
                        error: '',
                        type: action.addingType,
                    },
                },
            })
        case PROFILE_ADDED_ITEM:
            return Object.assign({}, state, {
                flags: {
                    add: {
                        isAdding: false,
                        error: '',
                        type: '',
                    },
                    ...state.flags.edit,
                },
                profile: {
                    ...state.profile,
                    [action.field]: state.profile[action.field].concat(action.item),
                },
            })
        case PROFILE_DELETED_ITEM:
            return Object.assign({}, state, {
                flags: {
                    add: {
                        isAdding: false,
                        error: '',
                        type: '',
                    },
                    ...state.flags.edit,
                },
                profile: {
                    ...state.profile,
                    [action.field]: state.profile[action.field].filter(
                        (f) => !action.item.includes(f.id),
                    ),
                },
            })
        case PROFILE_ADD_ERROR:
            return Object.assign({}, state, {
                flags: {
                    add: {
                        isAdding: false,
                        error: action.error,
                        type: '',
                    },
                },
            })
        case PROFILE_EDITING_ITEM:
            return Object.assign({}, state, {
                flags: {
                    edit: {
                        isEditing: true,
                        error: '',
                    },
                },
            })
        case PROFILE_EDITED_ITEM:
            return Object.assign({}, state, {
                flags: {
                    edit: {
                        isEditing: false,
                        error: '',
                    },
                },
            })
        case PROFILE_UPDATE_ERROR:
            return Object.assign({}, state, {
                flags: {
                    add: {
                        isUpdating: false,
                        error: action.error,
                        type: '',
                    },
                },
            })
        case PROFILE_UPDATE_ITEM:
            return Object.assign({}, state, {
                flags: {
                    edit: {
                        isUpdating: true,
                        error: '',
                    },
                },
            })
        case PROFILE_UPDATED_ITEM:
            return Object.assign({}, state, {
                flags: {
                    edit: {
                        isUpdating: false,
                        error: '',
                    },
                },
            })
        case WORKING_AREAS:
            return Object.assign({}, state, {
                working_areas: action.list,
            })
        case STUDIES:
            return Object.assign({}, state, {
                studies: action.list,
            })
        case STUDY_LEVELS:
            return Object.assign({}, state, {
                study_levels: action.list,
            })
        case SCHOOLS_REQUEST:
            return Object.assign({}, state, {
                schools: {
                    list: state.schools.list,
                    loading: true,
                },
            })
        case SCHOOLS:
            return Object.assign({}, state, {
                schools: {
                    list: action.list,
                    loading: false,
                },
            })
        case ORGANIZATIONS:
            let orgs = action.organizations.sort((a, b) => {
                let nameA = a.name.toLowerCase()
                let nameB = b.name.toLowerCase()

                if (nameA < nameB) {
                    return -1
                } else if (nameA > nameB) {
                    return 1
                }

                return 0
            })

            return Object.assign({}, state, {
                organizations: orgs,
            })
        case FLEXAPP_ORGANIZATIONS_PUBLIC:
            let jbs = action.flexapp_organizations.sort((a, b) => {
                let nameA = a.name.toLowerCase()
                let nameB = b.name.toLowerCase()

                if (nameA < nameB) {
                    return -1
                } else if (nameA > nameB) {
                    return 1
                }

                return 0
            })

            jbs.unshift({
                name: translate('profile.no_organization'),
                id: 0,
            })

            return Object.assign({}, state, {
                flexapp_organizations_public: jbs,
            })

        case FLEXAPP_ORGANIZATIONS:
            let forgs = action.flexapp_organizations.sort((a, b) => {
                let nameA = a.name.toLowerCase()
                let nameB = b.name.toLowerCase()

                if (nameA < nameB) {
                    return -1
                } else if (nameA > nameB) {
                    return 1
                }

                return 0
            })

            forgs.unshift({
                name: translate('profile.no_organization'),
                id: 0,
            })

            return Object.assign({}, state, {
                flexapp_organizations: forgs,
            })
        case FILE_UPLOAD_TYPES:
            return Object.assign({}, state, {
                file_upload_types: action.file_upload_types,
            })
        case ACCOUNT_FIELDS:
            return Object.assign({}, state, {
                account_field_names: action.account_fields,
            })
        case SET_EVENTS_REGISTERED_FOR:
            let registeredEvents = action.events

            registeredEvents = registeredEvents.map(processEvent)
            registeredEvents.sort(sort_by_date)

            return Object.assign({}, state, {
                events_registered_for: registeredEvents,
            })
        case SET_EVENTS_OF_INTEREST:
            let interestingEvents = action.events

            interestingEvents = interestingEvents.map(processEvent)
            interestingEvents.sort(sort_by_date)

            return Object.assign({}, state, {
                events_of_interest: interestingEvents,
            })
        case SIGN_UP_FOR_EVENT:
            let res = state.events_registered_for
            res.push(action.event)
            res.sort(sort_by_date)

            let new_avail = state.events_of_interest.filter((ev) => ev.id !== action.event.id)

            return Object.assign({}, state, {
                events_registered_for: res,
                events_of_interest: new_avail,
            })
        case SIGN_OUT_FROM_EVENT:
            let result = state.events_registered_for.filter((ev) => ev.id !== action.eventID)
            let new_available = state.events_of_interest
            new_available.push(state.events_registered_for.find((ev) => ev.id === action.eventID))
            new_available.sort(sort_by_date)

            return Object.assign({}, state, {
                events_registered_for: result,
                events_of_interest: new_available,
            })
        case SET_POSSIBLE_EVENT_INTERESTS:
            return Object.assign({}, state, {
                available_fields_of_interest: action.interests,
            })
        case DOCUMENTS:
            return Object.assign({}, state, {
                documents: action.docs,
            })
        case VACANCY_REACTIONS:
            return Object.assign({}, state, {
                vacancy_reactions: action.reactions,
            })
        case VACANCY_REACTION:
            let reactions = state.vacancy_reactions || []

            if (reactions.find((item) => item.id === action.application.id) !== undefined) {
                //Exists. Update entry.
                let id = reactions.findIndex((obj) => obj.id === action.application.id)
                let old = reactions[id]
                let appl = action.application
                appl.file = old.file
                appl.job_offer = old.job_offer
                reactions[id] = appl
            } else {
                reactions.push(action.application)
            }

            return Object.assign({}, state, {
                vacancy_reactions: reactions,
            })
        case ADDED_DOCUMENT:
            let documents = state.documents
            documents.push(action.document)

            return Object.assign({}, state, {
                documents: documents,
            })
        case SET_PROFILE_PICTURE:
            let url = action.url
            return Object.assign({}, state, {
                profile_picture: url,
            })
        case SHOW_FLEXAPP_NOTIFICATION:
            return Object.assign({}, state, {
                showFlexappNotification: true,
            })
        case HIDE_FLEXAPP_NOTIFICATION:
            return Object.assign({}, state, {
                showFlexappNotification: false,
            })
        case FILLED_IN_FLEXAPP_DATA:
            return Object.assign({}, state, {
                missing_flexapp_data: false,
            })
        default:
            return state
    }
}

function processEvent(ev) {
    ev.start = new Date(ev.item_start)
    ev.content = ev.content.replace(/href="(\.\.\/)+(?=\w)/g, 'href="https://zorgselect.nl/')
    ev.content = ev.content.replace(/{{form name=".+?"}}/, '')
    return ev
}

function sort_by_date(a, b) {
    return b.start - a.start
}

import React, { Component } from 'react'
import { createPopper } from '@popperjs/core'
import flip from '@popperjs/core/lib/modifiers/flip.js'
import PropTypes from 'prop-types'

import Button from '../Button/Button'
import { translate } from 'react-i18nify'
import { DOCUMENTS, EDUCATION, SKILLS, WORKING_EXPERIENCE } from '../../pages/Profile'
import LocalStorage from '../LocalStorage'
import history from '../../history'
import { withRouter } from 'react-router-dom'

class Intro extends Component {
    popperElement = null

    constructor(props) {
        super(props)
        this.tempClassName = 'elt_' + Math.random().toString(36).substr(2, 10)
        this.nextStep = this.nextStep.bind(this)
        this.stopOnboarding = this.stopOnboarding.bind(this)
        this.state = {
            currentRoute: 0,
            visible: false,
            routes: [
                this.props.config.whitelabel.show_suggestion_page
                    ? {
                          number: 1,
                          route: '/suggesties',
                          reference: '.menu--horizontal #menu-path-suggestions a',
                          fader: '.header',
                          title: translate('onboarding.intro.step1.title'),
                          text: translate('onboarding.intro.step1.text'),
                          scroll: false,
                      }
                    : undefined,
                {
                    number: 2,
                    route: '/sollicitaties',
                    reference: '.menu--horizontal #menu-path-applications a',
                    fader: '.header',
                    title: translate('onboarding.intro.step2.title'),
                    text: translate('onboarding.intro.step2.text'),
                    scroll: false,
                },
                {
                    number: 3,
                    route: '/vacatures',
                    reference: '.menu--horizontal #menu-path-vacancies a',
                    fader: '.header',
                    title: translate('onboarding.intro.step3.title'),
                    text: translate('onboarding.intro.step3.text'),
                    scroll: false,
                },
                {
                    number: 4,
                    route: '/profiel',
                    reference: '.menu--horizontal #menu-path-profile a',
                    fader: '.header',
                    title: translate('onboarding.intro.step4.title'),
                    text: translate('onboarding.intro.step4.text'),
                    scroll: false,
                },
                {
                    number: 5,
                    route: '/profiel',
                    reference: '#workExperiencePanel',
                    fader: '#workExperiencePanel',
                    placement: 'bottom',
                    title: translate('onboarding.intro.step5.title'),
                    text: translate('onboarding.intro.step5.text'),
                    page: WORKING_EXPERIENCE,
                },
                {
                    number: 6,
                    route: '/profiel',
                    reference: '#documentsPanelFader',
                    fader: '#documentsPanelFader',
                    placement: 'bottom',
                    title: translate('onboarding.intro.step6.title'),
                    text: translate('onboarding.intro.step6.text'),
                    page: DOCUMENTS,
                },
                {
                    number: 7,
                    route: '/profiel',
                    reference: '#courseEducationPanel',
                    fader: '#courseEducationPanel',
                    placement: 'bottom',
                    title: translate('onboarding.intro.step7.title'),
                    text: translate('onboarding.intro.step7.text'),
                    page: EDUCATION,
                },
                {
                    number: 8,
                    route: '/profiel',
                    reference: '#JobsPanel',
                    fader: '#JobsPanel',
                    placement: 'left',
                    title: translate('onboarding.intro.step8.title'),
                    text: translate('onboarding.intro.step8.text'),
                    page: SKILLS,
                },
                {
                    number: 9,
                    route: '/profiel',
                    reference: '#competenciesPanel',
                    fader: '#competenciesPanel',
                    placement: 'bottom',
                    title: translate('onboarding.intro.step9.title'),
                    text: translate('onboarding.intro.step9.text'),
                    page: SKILLS,
                },
                {
                    number: 10,
                    route: '/profiel',
                    reference: '#ReferencesPanel',
                    fader: '#ReferencesPanel',
                    placement: 'right',
                    title: translate('onboarding.intro.step10.title'),
                    text: translate('onboarding.intro.step10.text'),
                    page: SKILLS,
                },
                {
                    number: 11,
                    route: '/privacy',
                    reference: '#PrivacyPanel',
                    fader: '#PrivacyPanel',
                    placement: 'bottom',
                    title: translate('onboarding.intro.step11.title'),
                    text: translate('onboarding.intro.step11.text'),
                },
                {
                    number: 12,
                    route: '/profiel',
                    reference: '#templatesPanelFader',
                    fader: '#templatesPanelFader',
                    placement: 'bottom',
                    title: translate('onboarding.intro.step12.title'),
                    text: translate('onboarding.intro.step12.text'),
                    page: DOCUMENTS,
                },
            ].filter((r) => r),
        }

        // @screen-sm: 767px;
        if (window.innerWidth < 767) {
            // No onboarding for mobiles, unfortunately
            this.state.visible = false
            this.haltOnBoarding()
        }

        if (!this.props.auth || !this.props.auth.isAuthenticated) {
            this.haltOnBoarding()
        }

        window.addEventListener('resize', () => {
            if (this.state.visible) {
                const referenceElement = document.querySelector(
                    this.state.routes[this.state.currentRoute].reference,
                )
                if (document.body.contains(referenceElement)) {
                    this.popperElement.update()
                } else {
                    this.stopOnboarding()
                }
            }
        })
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.location !== prevProps.location &&
            !!new URLSearchParams(this.props.location.search).get('onboarding') &&
            !new URLSearchParams(prevProps.location.search).get('onboarding')
        ) {
            setTimeout(() => {
                this.initialize().then(() => {
                    this.setCurrentRoute()
                    this.showPopup()
                })
            })
        }
    }

    setCurrentRoute() {
        let sent = false
        this.state.routes.forEach((route, index) => {
            if (
                !sent &&
                route.number === new URLSearchParams(history.location.search).get('number')
            ) {
                sent = true
                this.setState({ currentRoute: index })
            }
        })
    }

    initialize() {
        return new Promise((resolve, reject) => {
            if (new URLSearchParams(history.location.search).get('onboarding')) {
                let idx = Number(new URLSearchParams(history.location.search).get('number')) - 1
                let route = this.state.routes[idx]
                history.push(
                    route.route +
                        `?number=${route.number}` +
                        '&onboarding=true' +
                        (!!route.page ? `&page=${route.page}` : ''),
                )

                this.setState({
                    visible: true,
                    currentRoute: idx,
                })
            }
            resolve()
        })
    }

    resetStates() {
        if (document.querySelector('.popper-active')) {
            document.querySelector('.popper-active').classList.remove('popper-active')
        }
        if (document.querySelector('.sticky_position')) {
            document.querySelector('.sticky_position').classList.add(this.tempClassName)
            document.querySelector('.sticky_position').classList.remove('sticky_position')
        }
        // Added for the Privacy page
        if (document.querySelector('.popper-is-active')) {
            document.querySelector('.popper-is-active').classList.remove('popper-is-active')
        }
    }

    showPopup() {
        if (!this.state.visible) return
        this.resetStates()
        const contentElement = document.querySelector('.content')

        if (contentElement) contentElement.classList.add('popper-is-active')
        const referenceElement = document.querySelector(
            this.state.routes[this.state.currentRoute].reference,
        )
        if (!referenceElement) {
            setTimeout(this.showPopup.bind(this), 10)
        }
        const activeElement = document.querySelector(
            this.state.routes[this.state.currentRoute].fader,
        )

        if (activeElement) activeElement.classList.add('popper-active')
        this.updatePosition()
        if (this.state.routes[this.state.currentRoute].scroll !== false) {
            setTimeout(() => {
                document.querySelector('.intro-card').scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                })
            })
        }
    }

    updatePosition() {
        const referenceElement = document.querySelector(
            this.state.routes[this.state.currentRoute].reference,
        )
        if (!referenceElement) return

        this.setState({ visible: true })
        let placement = this.state.routes[this.state.currentRoute].placement || 'bottom'
        let options = {
            placement: placement,
            modifiers: [
                placement !== 'bottom' && { name: 'offset', options: { offset: [-10, 35] } },
            ],
        }
        this.popperElement = createPopper(
            referenceElement,
            document.querySelector('.intro-card'),
            options,
        )
    }

    isCorrectPage(nextRoute) {
        if (nextRoute.page) {
            return (
                history.location.pathname === nextRoute.route &&
                LocalStorage.getItem('profilePage') === nextRoute.page
            )
        }
        return history.location.pathname === nextRoute.route
    }

    nextStep() {
        let nextRoute = this.state.routes[this.state.currentRoute + 1]
        if (this.state.routes.length <= this.state.currentRoute + 1) return
        if (!this.isCorrectPage(nextRoute)) {
            history.push(
                nextRoute.route +
                    `?number=${nextRoute.number}` +
                    '&onboarding=true' +
                    (!!nextRoute.page ? `&page=${nextRoute.page}` : ''),
            )
        }
        this.setState(
            {
                currentRoute: this.state.currentRoute + 1,
            },
            () => {
                this.showPopup()
            },
        )
    }

    stopOnboarding() {
        this.setState({ visible: false, currentRoute: 0 })
        this.haltOnBoarding()
    }

    haltOnBoarding() {
        this.resetStates()
        history.push(history.location.pathname)
        window.scrollTo(0, 0)
        if (document.querySelector('.' + this.tempClassName)) {
            document.querySelector('.' + this.tempClassName).classList.add('sticky_position')
            document.querySelector('.' + this.tempClassName).classList.remove(this.tempClassName)
        }
    }

    componentDidMount() {
        this.initialize().then(() => {
            this.setCurrentRoute()
            setTimeout(() => {
                this.showPopup()
            }, 10)
        })
    }

    render() {
        return (
            <div>
                {this.state.visible && (
                    <div>
                        <div className="intro-card">
                            <h2>{this.state.routes[this.state.currentRoute].title}</h2>
                            <p>{this.state.routes[this.state.currentRoute].text}</p>
                            {this.state.currentRoute < this.state.routes.length - 1 ? (
                                <div>
                                    <Button
                                        onClick={this.nextStep}
                                        className="button--cta"
                                        buttonText={translate('buttons.continue')}
                                    />
                                    <Button
                                        onClick={this.stopOnboarding}
                                        className="button--link"
                                        buttonText={translate('buttons.close')}
                                        style={{ marginBottom: 0 }}
                                    />
                                </div>
                            ) : (
                                <Button
                                    onClick={this.stopOnboarding}
                                    className="button--cta"
                                    buttonText={translate('buttons.close')}
                                />
                            )}
                        </div>
                        <div className="intro-card-bg" />
                    </div>
                )}
            </div>
        )
    }
}

Intro.propTypes = {
    auth: PropTypes.object,
}

export default withRouter(Intro)

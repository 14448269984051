import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import {
    getVacancyReactions,
    removeConceptApplication,
    saveApplication,
} from '../store/actions/user'
import { logoutUser } from '../store/actions/auth'

import { Col, Container, Row } from 'react-grid-system'

import { Link } from 'react-router-dom'
import { ToastContainer } from 'react-toastr'

import Actions from '../components/Actions/Actions'
import GridColOffset from '../components/GridCol/GridColOffset'
import GridCol from '../components/GridCol/GridCol'
import Panel from '../components/Panel/Panel'
import Modal from 'simple-react-modal'
import ModalForm from '../components/Modal/ModalForm'
import ApplyJobOffer from './ApplyJobOffer'
import { translate } from 'react-i18nify'

import { compareArrays, isSignedIn } from '../helpers'

class MyVacancies extends Component {
    constructor(props) {
        super(props)
        this.state = {
            vacancies: [],
            sentVac: [],
            conceptVac: [],
            expiredVac: [],
            sending: false,
            details: false,
            view_reaction: null,
            loading: true,
            removing: false,
            remove: false,
            toRemove: null,
        }

        this.close = this.close.bind(this)

        this.toastContainer = React.createRef()
    }

    componentWillMount() {
        // Check if the user is signed in.
        if (!isSignedIn(this.props.auth)) {
            this.props.dispatch(logoutUser(this.props.auth))
        }

        let loading = true
        if (this.props.vacancy_reactions !== undefined && this.props.vacancy_reactions.length > 0) {
            loading = false
        }

        if (this.props.vacancy_reactions !== undefined) {
            this.categorizeApplication(this.props.vacancy_reactions)
        }

        this.setState({
            vacancies:
                this.props.vacancy_reactions !== undefined ? this.props.vacancy_reactions : [],
            loading,
        })
        // Fetch job offer details
        this.props.dispatch(
            getVacancyReactions(
                this.props.auth,
                (items) => {
                    this.categorizeApplication(items)

                    console.log('[ Vacancies ]', 'Found items', items)
                    this.setState({
                        vacancies: items,
                        loading: false,
                    })
                },
                () => {},
            ),
        )
    }

    componentWillUpdate(nextProps, nextState) {
        if (!compareArrays(this.props.vacancy_reactions, nextProps.vacancy_reactions)) {
            this.categorizeApplication(nextProps.vacancy_reactions)

            this.setState({
                vacancies: nextProps.vacancy_reactions,
            })
        }
    }

    view(item) {
        this.setState({
            details: true,
            view_reaction: item,
        })
    }

    close() {
        this.setState({
            details: false,
            view_reaction: null,
        })
    }

    confirmDelete(item) {
        this.setState({
            remove: true,
            toRemove: item,
        })
    }

    cancelRemove() {
        this.setState({
            remove: false,
            toRemove: null,
            removing: false,
        })
    }

    showInfoToast(title, message) {
        this.toastContainer.current.info(message, title, {
            timeOut: 10000,
            extendedTimeOut: 1000,
        })
    }

    remove() {
        this.setState({
            removing: true,
        })
        this.props.dispatch(
            removeConceptApplication(
                this.state.toRemove.id,
                this.props.auth,
                () => {
                    let removed = this.state.toRemove
                    this.showInfoToast(
                        'Verwijderd',
                        'We hebben ' +
                            removed.vacancy.name +
                            ' verwijderd uit je lijst met concepten.',
                    )
                    this.cancelRemove()
                },
                (status, resp) => {
                    this.cancelRemove()
                },
            ),
        )
    }

    confirmSend(item) {
        this.setState({
            send: true,
            toSend: item,
        })
    }

    cancelSend() {
        this.setState({
            send: false,
            sending: false,
            toSend: null,
        })
    }

    showSuccessMessage(title, message) {
        this.toastContainer.current.success(message, title, {
            timeOut: 10000,
            extendedTimeOut: 1000,
        })
    }

    sendApplication() {
        this.setState({
            sending: true,
        })

        let item = this.state.toSend
        this.props.dispatch(
            saveApplication(
                item.vacancy.id,
                {
                    concept: false,
                },
                this.props.auth,
                () => {
                    let sent = this.state.toSend
                    this.showInfoToast(
                        translate('vacancies.toast_title_sent'),
                        translate('vacancies.toast_msg_sent', { name: sent.vacancy.name }),
                    )
                    this.cancelSend()
                },
                (status, resp) => {
                    this.cancelSend()
                },
            ),
        )
    }

    categorizeApplication(items) {
        let sentVac = [],
            conceptVac = [],
            expiredVac = []

        items.map((item) => {
            if (!item.concept) {
                sentVac.push(item)
            } else if (item.concept && item.vacancy && item.vacancy.state) {
                conceptVac.push(item)
            } else if (item.concept && (!item.vacancy || !item.vacancy.state)) {
                expiredVac.push(item)
            }
        })

        this.setState({
            conceptVac,
            sentVac,
            expiredVac,
        })
        // console.log("Sent", this.state.sentVac, "concept", this.state.conceptVac, "expired", this.state.expiredVac);
    }

    render() {
        return (
            <Container>
                {this.state.loading &&
                    (this.state.vacancies === null ||
                        this.state.vacancies === undefined ||
                        this.state.vacancies.length === 0) && (
                        <div className="is-loading is-loading--center" />
                    )}

                {(this.state.vacancies === null ||
                    this.state.vacancies === undefined ||
                    this.state.vacancies.length === 0) &&
                    !this.state.loading && (
                        <Row>
                            <GridColOffset />
                            <Col xs={8}>
                                <div className="spacer" />
                                <h2 className="theme">Helaas</h2>
                                <p>Je hebt geen opgeslagen vacatures.</p>
                            </Col>
                        </Row>
                    )}

                {this.state.vacancies !== null && this.state.vacancies.length > 0 && (
                    <div>
                        <Row>
                            <GridColOffset />
                            <GridCol>
                                {this.state.conceptVac !== null &&
                                    this.state.conceptVac !== undefined &&
                                    this.state.conceptVac.length > 0 && (
                                        <Panel
                                            subHeader={
                                                <div class="panel__body line-wrapper">
                                                    <span className="line-breaker" />
                                                </div>
                                            }
                                            className="panel"
                                            headerText="In Afwachting"
                                            headerIcon="icon-note"
                                        >
                                            <div className="panel__list">
                                                {this.state.conceptVac.map((item, index) => {
                                                    if (
                                                        item.vacancy === null ||
                                                        item.vacancy === undefined
                                                    ) {
                                                        return <div key={'reaction-' + index} />
                                                    }

                                                    return (
                                                        <div
                                                            key={'reaction-' + index}
                                                            className="list_item--full_width"
                                                        >
                                                            <Row>
                                                                <Col xs={12}>
                                                                    <h3>
                                                                        <span>
                                                                            {item.vacancy.name}
                                                                        </span>
                                                                        <br />
                                                                        {
                                                                            item.vacancy
                                                                                .organisation.name
                                                                        }
                                                                    </h3>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col xs={12}>
                                                                    <div>
                                                                        <p>
                                                                            {translate(
                                                                                'vacancies.message_todo_intro',
                                                                            )}
                                                                        </p>
                                                                        <ul>
                                                                            {item.account_resume_id ===
                                                                                null && (
                                                                                <li>
                                                                                    {translate(
                                                                                        'vacancies.todo_upload_cv',
                                                                                    )}
                                                                                </li>
                                                                            )}

                                                                            {item.content ===
                                                                                null && (
                                                                                <li>
                                                                                    {translate(
                                                                                        'vacancies.todo_write_motivation',
                                                                                    )}
                                                                                </li>
                                                                            )}

                                                                            {item.content !==
                                                                                null &&
                                                                                item.account_resume_id !==
                                                                                    null && (
                                                                                    <li>
                                                                                        {translate(
                                                                                            'vacancies.todo_send_application',
                                                                                        )}
                                                                                    </li>
                                                                                )}
                                                                        </ul>
                                                                    </div>

                                                                    <div className="spacer-s" />

                                                                    <Actions>
                                                                        <Link
                                                                            className="actions__action"
                                                                            onClick={() => {
                                                                                this.confirmDelete(
                                                                                    item,
                                                                                )
                                                                            }}
                                                                        >
                                                                            {translate(
                                                                                'vacancies.button_delete_concept',
                                                                            )}
                                                                        </Link>

                                                                        <Link
                                                                            className="actions__action green pull-right"
                                                                            onClick={() => {
                                                                                this.view(item)
                                                                            }}
                                                                        >
                                                                            {item.content !==
                                                                                null &&
                                                                            item.account_resume_id !==
                                                                                null &&
                                                                            item.concept &&
                                                                            item.vacancy.state === 1
                                                                                ? translate(
                                                                                      'vacancies.button_send_application',
                                                                                  )
                                                                                : translate(
                                                                                      'vacancies.button_finish_vacancy',
                                                                                  )}
                                                                        </Link>
                                                                    </Actions>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </Panel>
                                    )}
                            </GridCol>
                        </Row>
                        <Row>
                            <GridColOffset />
                            <GridCol>
                                {this.state.sentVac !== null &&
                                    this.state.sentVac !== undefined &&
                                    this.state.sentVac.length > 0 && (
                                        <Panel
                                            subHeader={
                                                <div class="panel__body line-wrapper">
                                                    <span className="line-breaker" />
                                                </div>
                                            }
                                            className="panel"
                                            headerText="Verstuurd"
                                            headerIcon="icon-share-alt"
                                        >
                                            <div className="panel__list">
                                                {this.state.sentVac.map((item, index) => {
                                                    if (
                                                        item.vacancy === null ||
                                                        item.vacancy === undefined
                                                    ) {
                                                        return <div key={'reaction-' + index} />
                                                    }
                                                    return (
                                                        <div
                                                            key={'reaction-' + index}
                                                            className="list_item--full_width"
                                                        >
                                                            <Row>
                                                                <Col xs={12}>
                                                                    <h3>
                                                                        <span>
                                                                            {item.vacancy.name}
                                                                        </span>
                                                                        <br />
                                                                        {
                                                                            item.vacancy
                                                                                .organisation.name
                                                                        }
                                                                    </h3>
                                                                </Col>
                                                            </Row>
                                                            <div className="spacer-s" />
                                                            <Row>
                                                                <Col xs={12}>
                                                                    <Actions>
                                                                        <Link
                                                                            className="actions__action--single green pull-right"
                                                                            onClick={() => {
                                                                                this.view(item)
                                                                            }}
                                                                        >
                                                                            {translate(
                                                                                'vacancies.button_view_vacancy',
                                                                            )}
                                                                        </Link>
                                                                    </Actions>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </Panel>
                                    )}
                            </GridCol>
                        </Row>
                        <Row>
                            <GridColOffset />
                            <GridCol>
                                {this.state.expiredVac !== null &&
                                    this.state.expiredVac !== undefined &&
                                    this.state.expiredVac.length > 0 && (
                                        <Panel
                                            subHeader={
                                                <div class="panel__body line-wrapper">
                                                    <span className="line-breaker" />
                                                </div>
                                            }
                                            className="panel"
                                            headerText="Verlopen"
                                            headerIcon="icon-hourglass"
                                        >
                                            <div className="panel__list">
                                                {this.state.expiredVac.map((item, index) => {
                                                    if (
                                                        item.vacancy === null ||
                                                        item.vacancy === undefined
                                                    ) {
                                                        return <div key={'reaction-' + index} />
                                                    }
                                                    return (
                                                        <div
                                                            key={'reaction-' + index}
                                                            className="list_item--full_width"
                                                        >
                                                            <Row>
                                                                <Col xs={12}>
                                                                    <h3>
                                                                        <span>
                                                                            {item.vacancy.name}
                                                                        </span>
                                                                        <br />
                                                                        {
                                                                            item.vacancy
                                                                                .organisation.name
                                                                        }
                                                                    </h3>
                                                                </Col>
                                                            </Row>
                                                            <div className="spacer-s" />
                                                            <Row>
                                                                <Col xs={12}>
                                                                    <Actions>
                                                                        <Link
                                                                            className="actions__action"
                                                                            onClick={() => {
                                                                                this.confirmDelete(
                                                                                    item,
                                                                                )
                                                                            }}
                                                                        >
                                                                            {translate(
                                                                                'vacancies.button_delete_concept',
                                                                            )}
                                                                        </Link>
                                                                        <Link
                                                                            className="actions__action green pull-right"
                                                                            onClick={() => {
                                                                                this.view(item)
                                                                            }}
                                                                        >
                                                                            {translate(
                                                                                'vacancies.button_view_vacancy',
                                                                            )}
                                                                        </Link>
                                                                    </Actions>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </Panel>
                                    )}
                            </GridCol>
                        </Row>
                    </div>
                )}

                <ToastContainer ref={this.toastContainer} className="toast-bottom-right" />

                {this.state.details && this.state.view_reaction !== null && (
                    <Modal
                        className="modal--vacancy"
                        containerClassName="modal__container"
                        closeOnOuterClick={true}
                        show={this.state.details}
                        onClose={() => {
                            this.close()
                        }}
                    >
                        <ApplyJobOffer
                            dispatch={this.props.dispatch}
                            job_offer={this.state.view_reaction.vacancy.id}
                            content={this.state.view_reaction.content}
                            reaction_id={this.state.view_reaction.id}
                            resume_id={this.state.view_reaction.account_resume_id}
                            reaction={this.state.view_reaction}
                            onClose={(sent, saved) => {
                                this.close()

                                if (sent) {
                                    this.showSuccessMessage(
                                        translate('vacancies.title_success'),
                                        translate('vacancies.message_success'),
                                    )
                                } else if (saved) {
                                    this.showSuccessMessage(
                                        translate('vacancies.title_saved'),
                                        translate('vacancies.message_saved'),
                                    )
                                }

                                // Fetch job offer details
                                this.props.dispatch(
                                    getVacancyReactions(
                                        this.props.auth,
                                        (items) => {
                                            this.categorizeApplication(items)

                                            this.setState({
                                                vacancies: items,
                                                loading: false,
                                            })
                                        },
                                        (status, errors) => {
                                            if (status === 401) {
                                                this.props.dispatch(logoutUser(this.props.auth))
                                            }
                                        },
                                    ),
                                )
                            }}
                            editable={
                                this.state.view_reaction.concept &&
                                this.state.view_reaction.vacancy.state
                            }
                        />

                        <a className="modal__close" onClick={this.close.bind(this)}>
                            ×
                        </a>
                    </Modal>
                )}

                {this.state.remove && (
                    <ModalForm
                        title={this.state.toRemove.vacancy.name}
                        className="modal"
                        closeOnOuterClick={true}
                        show={this.state.remove}
                        onClose={() => this.cancelRemove()}
                    >
                        <p>{translate('vacancies.confirm_remove')}</p>

                        <Actions>
                            <Link
                                className="actions__action action--no-padding"
                                onClick={() => {
                                    this.cancelRemove()
                                }}
                            >
                                {translate('buttons.cancel')}
                            </Link>

                            <Link
                                className={
                                    'actions__action red pull-right action--no-padding' +
                                    (this.state.removing ? ' is-loading' : '')
                                }
                                onClick={() => {
                                    this.remove()
                                }}
                            >
                                {translate('buttons.remove')}
                            </Link>
                        </Actions>
                    </ModalForm>
                )}

                {this.state.send && (
                    <ModalForm
                        title={this.state.toSend.vacancy.name}
                        className="modal"
                        closeOnOuterClick={true}
                        show={this.state.send}
                        onClose={() => this.cancelRemove()}
                    >
                        <p>{translate('vacancies.confirm_send')}</p>

                        <Actions>
                            <Link
                                className="actions__action action--no-padding"
                                onClick={() => {
                                    this.cancelSend()
                                }}
                            >
                                {translate('buttons.cancel')}
                            </Link>

                            <Link
                                className={
                                    'actions__action green pull-right action--no-padding' +
                                    (this.state.sending ? ' is-loading' : '')
                                }
                                onClick={() => {
                                    this.sendApplication()
                                }}
                            >
                                {translate('buttons.apply')}
                            </Link>
                        </Actions>
                    </ModalForm>
                )}

                <ToastContainer ref={this.toastContainer} className="toast-bottom-right" />
            </Container>
        )
    }
}

MyVacancies.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    const { auth, user } = state
    const { documents, vacancy_reactions } = user
    return {
        auth,
        documents,
        vacancy_reactions,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(MyVacancies)

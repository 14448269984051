import React, { Component } from 'react'

export default class Success extends Component {
    render() {
        return (
            <div className="text-center">
                <div>
                    <svg
                        fill="#4abcac"
                        style={{ maxWidth: '125px', width: 100, height: 100, marginTop: 16 }}
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
                        <path d="M0 0h24v24H0z" fill="none" />
                    </svg>
                </div>
                <div>{this.props.text && <div>{this.props.text}</div>}</div>
            </div>
        )
    }
}

import React, { useContext } from 'react'
import { isRequiredField } from '../../../helpers'
import { OnboardStepFormItemContext } from './OnboardStepSchemaContext'
import classNames from 'classnames'
import PropTypes from 'prop-types'

// can be used only in onboard steps that provide the context
export const OnboardStepFormItem = ({ children, fieldName, className }) => {
    const { schema } = useContext(OnboardStepFormItemContext)
    const required = isRequiredField(schema, fieldName)
    return (
        <div
            className={classNames('form__item', className, {
                required,
            })}
        >
            {children}
        </div>
    )
}

OnboardStepFormItem.propTypes = {
    fieldName: PropTypes.string.isRequired,
    classNames: PropTypes.string,
}

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Actions from '../Actions/Actions'
import Select from 'react-select'
import { translate, Translate } from 'react-i18nify'
import { diffProfileItems, fetchSchoolRelatedFields } from '../../store/actions/user'
import Row from 'react-grid-system/build/grid/Row'
import { checkForEmptyFields } from '../../helpers'

class FormSchoolSubjects extends Component {
    constructor(props) {
        super(props)

        this.state = {
            fetching: false,
            loadingSave: false,
            errors: [],
            fields: [],

            available_subjects: [],
            filtered_subjects: [],
            subjects: [],
            new_subject: null,

            available_subject_levels: [],

            added: [],
            deleted: [],

            requiredFields: ['subjects'],
        }
    }

    componentDidMount() {
        let subjects = []
        this.props.user.profile.school_subjects.map((sub) => {
            subjects.push({
                label: sub.school_subject_name,
                value: sub.school_subject_id,
                level: { label: sub.school_subject_level_name, value: sub.school_subject_level_id },
            })
        })
        this.setState({ subjects: subjects, fetching: true })

        fetchSchoolRelatedFields(this.props.auth, (obj) => {
            obj.subjects = obj.subjects.map((subject) => {
                return { value: subject.id, label: subject.name }
            })
            obj.levels = obj.levels.map((level) => {
                return { value: level.id, label: level.name }
            })

            let values = this.state.subjects.map((ss) => ss.value)
            let filtered_subjects = obj.subjects.filter((sub) => !values.includes(sub.value))
            this.setState({
                available_subjects: obj.subjects,
                filtered_subjects: filtered_subjects,
                available_subject_levels: obj.levels,
                fetching: false,
            })
        })
    }

    getClassNames(key) {
        let c = ''
        if (this.state[key]) {
            c += ' has-value'
        }
        if (this.state.fields.includes(key)) {
            c += ' error'
        }
        return c
    }

    save() {
        let empty = checkForEmptyFields(this.state)
        if (empty.length > 0) {
            this.props.addErrors(translate('errors.empty_field'))
            this.setState({ fields: ['new_subject'] })
            this.props.stopLoading()
            return
        }
        this.setState({ loadingSave: true })
        let toAdd = []

        this.state.added.map((id) => {
            let level_id = this.state.subjects.find((sub) => sub.value === id).level.value
            toAdd.push({ school_subject_id: id, school_subject_level_id: level_id })
        })

        if (this.state.deleted.length !== 0 || toAdd.length !== 0) {
            this.props.dispatch(
                diffProfileItems(
                    'school_subjects',
                    toAdd,
                    this.state.deleted,
                    this.props.auth,
                    () => {
                        this.setState({
                            loadingSave: false,
                        })
                        this.onSuccess()
                    },
                    () => {},
                    false,
                ),
            )
        } else {
            this.onSuccess()
        }
    }

    error(status, object) {
        if (this.props.addErrors) {
            this.props.addErrors(object.message)
        } else {
            this.setState({
                errors: object.message,
            })
        }
    }

    onSuccess() {
        this.setState({
            errors: [],
            fetching: false,
            loadingSave: false,
            added: [],
            deleted: [],
        })
        this.props.onSave()
    }

    cancel() {
        this.setState({
            errors: [],
            fetching: false,
            loadingSave: false,
            added: [],
            deleted: [],
        })
        this.props.onCancel()
    }

    getSaveClasses() {
        let c = 'actions__action--save'

        if (this.state.loadingSave) {
            c += ' is-loading'
        }

        return c
    }

    addSubject(value) {
        let new_subject = this.state.new_subject
        new_subject.level = value
        let subjects = this.state.subjects
        subjects.push(new_subject)

        let filtered_subjects = this.state.filtered_subjects.filter(
            (fs) => fs.value !== new_subject.value,
        )

        this.keepTrack(new_subject.value, 'added')
        this.setState({ subjects, filtered_subjects, new_subject: null })
    }

    setNewSubject(value) {
        this.setState({ new_subject: value })
    }

    deleteSubject(subject) {
        let subjects = this.state.subjects.filter((ss) => subject.value !== ss.value)
        let filtered_subjects = this.restore('subjects', subjects)

        let item = this.props.user.profile.school_subjects.find(
            (ss) => ss.school_subject_id === subject.value,
        )
        this.keepTrack(item ? item.id : subject.value, 'deleted')
        this.setState({ subjects, filtered_subjects })
    }

    keepTrack(value, key, oldValue) {
        let other_key = key === 'added' ? 'deleted' : 'added'

        let toRemove = this.state[other_key].slice()
        let toAdd = this.state[key].slice()
        if (toRemove.indexOf(value) >= 0) {
            toRemove.splice(toRemove.indexOf(value), 1)
        } else {
            if (oldValue) {
                toAdd[toAdd.indexOf(oldValue)] = value
            } else {
                toAdd.push(value)
            }
        }

        this.setState({ [key]: toAdd, [other_key]: toRemove })
    }

    restore(key, list) {
        let values = list.map((ss) => ss.value)
        let tmp = this.state['available_' + key]
        return tmp.filter((item) => !values.includes(item.value))
    }

    render() {
        return (
            <form
                className="form"
                id="schoolSubjectForm"
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        //Enter pressed
                        this.save()
                    }
                }}
            >
                <fieldset className="form__fieldset">
                    <div className="form__header">
                        <p>{translate('profile.school_subjects.intro')}</p>
                    </div>
                    {this.state.subjects.map((ss, idx) => (
                        <Row className={'form__item'} key={'subjects-' + ss.value + '-' + ss.label}>
                            <Link
                                className="icons icon-close"
                                onClick={() => this.deleteSubject(ss)}
                            />
                            <div className={'form__item--school-subject'}>
                                <span>{ss.label}</span> - <span>{ss.level.label}</span>
                            </div>
                        </Row>
                    ))}

                    {this.state.fetching ? (
                        <div className="actions min-height">
                            <div className="actions__action is-loading center_align" />
                        </div>
                    ) : (
                        this.state.filtered_subjects.length > 0 && (
                            <Row className="form__item">
                                <Select
                                    className={
                                        'select--alt' +
                                        (this.props.darkMode ? '-black' : '') +
                                        this.getClassNames('new_subject')
                                    }
                                    classNamePrefix="select"
                                    onChange={(e) => this.setNewSubject(e)}
                                    value={this.state.new_subject}
                                    clearable={false}
                                    options={this.state.filtered_subjects}
                                    isSearchable={true}
                                    placeholder={translate(
                                        'profile.school_subjects.subjects.placeholder',
                                    )}
                                />
                                {this.state.new_subject && (
                                    <Select
                                        className={
                                            'select--alt' + (this.props.darkMode ? '-black' : '')
                                        }
                                        classNamePrefix="select"
                                        onChange={(e) => this.addSubject(e)}
                                        value={this.state.new_subject.level}
                                        clearable={false}
                                        options={this.state.available_subject_levels}
                                        isSearchable={false}
                                        placeholder={translate(
                                            'profile.school_subjects.levels.placeholder',
                                        )}
                                    />
                                )}
                            </Row>
                        )
                    )}
                </fieldset>

                {this.props.showActions && (
                    <Actions>
                        <Link
                            className="actions__action--cancel"
                            onClick={() => this.cancel()}
                            tabIndex="5"
                        >
                            <Translate value="profile.cancel" />
                        </Link>
                        <Link
                            className={this.getSaveClasses()}
                            onClick={() => this.save()}
                            tabIndex="6"
                        >
                            <Translate value="profile.save" />
                        </Link>
                    </Actions>
                )}
            </form>
        )
    }
}

function mapStateToProps(state) {
    const { auth, user } = state

    return {
        auth,
        user,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, null, null, { forwardRef: true })(FormSchoolSubjects)

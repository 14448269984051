import { connect } from 'react-redux'
import React, { Component } from 'react'
import Panel from '../../Panel/Panel'
import { translate, Translate } from 'react-i18nify'
import FormPanel from '../../Forms/FormPanel'
import Tag from '../../Tag/Tag'
import { diffProfileItems } from '../../../store/actions/user'
import Suggestion from '../../Suggestion/Suggestion'

class InterestsPanel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            allowEditCompetencies: false,
        }
    }

    render() {
        let { interests } = this.props.user.profile

        if (interests !== undefined && interests.length > 0) {
            return (
                <Panel
                    className={
                        interests.length > 20
                            ? 'panel--expand panel--multi__inner tags--mobile'
                            : 'panel--multi__inner'
                    }
                    headerText={translate('profile.interests')}
                    headerIcon="icon-bulb"
                >
                    <FormPanel
                        placeholderText={translate('profile.add_interests')}
                        iconName="icon-bulb"
                        build={(value) => {
                            return {
                                name: value,
                            }
                        }}
                        item="interests"
                        onSave={() => {
                            this.props.showAlert()
                        }}
                    />

                    <div className="panel__form">
                        <div className="panel__tags">
                            {interests.map((interest) => {
                                return (
                                    <Tag
                                        key={interest.id + '-' + interest.name}
                                        onRemove={() => {
                                            this.props.dispatch(
                                                diffProfileItems(
                                                    'interests',
                                                    [],
                                                    [interest.id],
                                                    this.props.auth,
                                                    () => {},
                                                    (status, errors) => {},
                                                ),
                                            )
                                        }}
                                        showEdit={this.state.allowEditCompetencies}
                                        title={interest.name}
                                    >
                                        {interest.name}
                                    </Tag>
                                )
                            })}
                        </div>
                    </div>
                </Panel>
            )
        } else {
            return (
                <Panel
                    className="panel--suggestion panel--multi__inner "
                    headerText={translate('profile.interests')}
                    headerIcon="icon-bulb"
                >
                    <Suggestion iconName="icon-bulb">
                        <Translate value="profile.no_interests" />
                    </Suggestion>
                    <FormPanel
                        placeholderText={translate('profile.add_interests')}
                        iconName="icon-bulb"
                        build={(value) => {
                            return {
                                name: value,
                            }
                        }}
                        item="interests"
                        onSave={() => {
                            this.props.showAlert()
                        }}
                    />
                </Panel>
            )
        }
    }
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(InterestsPanel)

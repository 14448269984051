import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Actions from '../Actions/Actions'

import { diffArrays, getErrorFields, getErrorMessages } from '../../helpers'
import { translate, Translate } from 'react-i18nify'

import { diffProfileItems, getTopics } from '../../store/actions/user'

let map

class FormTopics extends Component {
    constructor(props) {
        super(props)

        this.state = {
            errors: [],
            items: [],
            all: [],
            filtered: [],
            manual: [],
            checked: [],
            add: false,
            filterValue: '',
            loading: false,
            fetching: true,
            roles: false,
        }

        this.isChecked = this.isChecked.bind(this)
        this.getClassNames = this.getClassNames.bind(this)
        this.onCheckboxChange = this.onCheckboxChange.bind(this)
        this.filter = this.filter.bind(this)
    }

    componentWillMount() {
        getTopics(
            this.props.auth,
            (response) => {
                const usedTopics = this.props.user.profile.topics.map((link) => link.topic_id)
                const unusedTopics = response.filter((item) => !usedTopics.includes(item.id))
                this.setState({
                    all: unusedTopics,
                    filtered: unusedTopics,
                    fetching: false,
                    checked:
                        this.props.topics !== undefined
                            ? this.props.topics.map((item) => {
                                  return item.id
                              })
                            : [],
                })
            },
            () => {},
        )
    }

    cancel() {
        this.props.onCancel()
    }

    isChecked(item) {
        let checked = this.state.checked

        return checked.indexOf(item.id) > -1
    }

    getClassNames(item) {
        let names = 'form__item'

        if (this.isChecked(item)) {
            names += ' form__item--selected'
        }

        return names
    }

    contains(list, item) {
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === item.id) {
                return true
            }
        }

        return false
    }

    onCheckboxChange(e, item) {
        if (e.target.checked) {
            this.check(item)
        } else {
            this.uncheck(item)
        }
    }

    check(item) {
        if (!this.isChecked(item)) {
            let checked = this.state.checked
            let items = this.state.items

            checked.push(item.id)

            this.setState({
                checked: checked,
            })
        }
    }

    uncheck(item) {
        if (this.isChecked(item)) {
            let checked = this.state.checked
            let items = this.state.items

            //Remove id of the item from the checked list.
            checked.splice(checked.indexOf(item.id), 1)

            this.setState({
                checked: checked,
            })
        }
    }

    save() {
        if (!this.state.loading) {
            this.setState({
                loading: true,
            })

            let todo = diffArrays(this.props.topics, this.state.checked, 'topic_id')
            this.props.dispatch(
                diffProfileItems(
                    'topics',
                    todo.toAdd,
                    todo.toRemove,
                    this.props.auth,
                    () => {
                        this.setState({ loading: false })
                        this.props.onSave()
                    },
                    () => {
                        this.error()
                    },
                ),
            )
        }
    }

    error(status, object) {
        console.log('Error saving topics', status)

        this.setState(
            {
                errors: [],
                fields: [],
                loading: false,
            },
            () => {
                if (object.hasOwnProperty('errors')) {
                    let messages = getErrorMessages(object.errors, map)
                    let fields = getErrorFields(object.errors)

                    this.addErrors(messages)
                    this.addErrorFields(fields)
                }
            },
        )
    }

    filter(e) {
        let value = e.target.value.toLowerCase()

        this.setState({
            filtered: this.state.all.filter((el) => {
                return el.name.toLowerCase().includes(value)
            }),
            filterValue: e.target.value,
        })
    }

    render() {
        const { className } = this.props

        return (
            <form
                className={this.props.className}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        //Enter pressed
                        this.save()
                    }
                }}
            >
                <div className="form__fieldset">
                    <div className="form__header">
                        <p>{translate('profile.topics_intro')}</p>
                    </div>
                    <hr />
                    <input
                        className="form__input--text"
                        type="text"
                        placeholder={translate('profile.filter_topics')}
                        onChange={(e) => this.filter(e)}
                        value={this.state.filterValue}
                        tabIndex="1"
                        autoFocus="autofocus"
                    />
                    <div className="form__items">
                        {this.state.fetching && (
                            <div className="actions min-height">
                                <div className="actions__action is-loading center_align" />
                            </div>
                        )}

                        {this.state.filtered &&
                            this.state.filtered.map((item, index) => {
                                return (
                                    <div
                                        className={this.getClassNames(item)}
                                        key={'competence-' + index}
                                    >
                                        <div className="form__checkbox">
                                            <input
                                                type="checkbox"
                                                name={item.id}
                                                id={item.id}
                                                className="form__checkbox__input"
                                                checked={this.isChecked(item)}
                                                onChange={(e) => this.onCheckboxChange(e, item)}
                                            />
                                            <label
                                                className="form__checkbox__label"
                                                htmlFor={item.id}
                                            >
                                                {item.name}
                                            </label>
                                            <p>{item.description}</p>
                                        </div>
                                    </div>
                                )
                            })}

                        {this.state.filtered.length === 0 && !this.state.fetching && (
                            <div className="actions text-center">
                                <div>{translate('profile.no_results')}</div>
                            </div>
                        )}
                    </div>
                    <hr />
                </div>

                <Actions>
                    <Link
                        className="actions__action--cancel"
                        onClick={() => this.cancel()}
                        tabIndex="2"
                    >
                        <Translate value="profile.cancel" />
                    </Link>
                    <Link
                        className={
                            'actions__action--save' + (this.state.loading ? ' is-loading' : '')
                        }
                        onClick={() => this.save()}
                        tabIndex="3"
                    >
                        <Translate value="profile.save" />
                    </Link>
                </Actions>
            </form>
        )
    }
}

FormTopics.propTypes = {
    className: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    edit: PropTypes.object,
}

function mapStateToProps(state) {
    const { auth, user } = state
    const { profile } = user
    const { topics } = profile

    return {
        auth,
        user,
        topics,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(FormTopics)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { translate } from 'react-i18nify'
import { BigTooltip } from '../Tooltip/BigTooltip'

export default class Tag extends Component {
    constructor(props) {
        super(props)
        this.state = { remove: false }
    }

    remove() {
        let pro
        if (this.props.doNotConfirm) {
            pro = true
        } else {
            pro = window.confirm(
                translate('profile.confirm_delete_item', { item: this.props.title }),
            )
        }
        if (pro) {
            this.setState({
                remove: true,
            })

            typeof this.props.onRemove === 'function' && this.props.onRemove()
        }
    }

    getClassNames() {
        let classes = 'tag'

        if (this.state.remove) {
            classes += ' removing'
        }

        return classes
    }

    render() {
        if (this.props.score && this.props.check) {
            if (parseFloat(this.props.score) > 0.5) {
                return (
                    <div className={this.getClassNames()}>
                        {this.props.check && (
                            <BigTooltip title="Extern toegewezen" position="top" arrow={true}>
                                <div className="tag__check icomoon-check" />
                            </BigTooltip>
                        )}
                        <div
                            className={
                                'tag__text' + (this.props.hideDelete ? ' tag__text__no_delete' : '')
                            }
                        >
                            {' '}
                            {this.props.children}{' '}
                        </div>
                    </div>
                )
            } else {
                return null
            }
        } else {
            return (
                <div className={this.getClassNames()}>
                    {this.props.check && (
                        <BigTooltip title="Extern toegewezen" position="top" arrow={true}>
                            <div className="tag__check icomoon-check" />
                        </BigTooltip>
                    )}
                    <div
                        className={
                            'tag__text' + (this.props.hideDelete ? ' tag__text__no_delete' : '')
                        }
                    >
                        {' '}
                        {this.props.children}{' '}
                    </div>

                    {!this.props.hideDelete && (
                        <Link
                            onClick={() => this.remove()}
                            className="tag__remove icons icon-close"
                        />
                    )}
                </div>
            )
        }
    }
}

Tag.propTypes = {
    onRemove: PropTypes.func,
    check: PropTypes.bool,
    showEdit: PropTypes.bool,
    hideDelete: PropTypes.bool,
    score: PropTypes.number,
    doNotConfirm: PropTypes.bool,
}

Tag.defaultProps = {
    check: false,
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { newCallApi } from '../../../store/actions/api'
import { translate, Translate } from 'react-i18nify'

import Panel from '../../Panel/Panel'
import Suggestion from '../../Suggestion/Suggestion'
import ModalForm from '../../Modal/ModalForm'
import TrajectoryCommentModal from './TrajectoryCommentModal'
import Pagination from '../../Pagination/Pagination'
import { PAGINATE_ITEM_NO } from '../../../constants/Constants'
import { BigTooltip } from '../../Tooltip/BigTooltip'

const LENGTH_OF_NOTE = 80

class TrajectoryNotes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            notes: [],
            expandedNotes: [],
            openComments: false,
            activeNote: {},
            activePage: 0,
        }
        this.onClose = this.onClose.bind(this)
        this.addComment = this.addComment.bind(this)
        this.toggleExpand = this.toggleExpand.bind(this)
    }

    componentDidMount() {
        this.getTrajectoryNotes()
    }

    getTrajectoryNotes() {
        const endpoint = this.props.config.API_TRAJECTORY_NOTES.replace(
            '{account_id}',
            this.props.user.profile.id,
        )

        if (this.props.showDetails) {
            this.setState({ loading: true })
            newCallApi('GET', endpoint).then((results) => {
                this.setState({ notes: results, loading: false })
            })
        } else {
            this.setState({ loading: false })
        }
    }

    getIcon(note) {
        if (this.state.expandedNotes.indexOf(note.id) >= 0) {
            return (
                <td className={'table__cell-hover'} onClick={() => this.toggleExpand(note)}>
                    <i className="icons icon-arrow-up">&nbsp;</i>
                </td>
            )
        } else {
            if (note.note.length > LENGTH_OF_NOTE) {
                return (
                    <td className={'table__cell-hover'} onClick={() => this.toggleExpand(note)}>
                        <i className="icons icon-arrow-down">&nbsp;</i>
                    </td>
                )
            } else {
                return <td />
            }
        }
    }

    onClose() {
        this.setState({ openComments: false })
    }

    addComment(comment) {
        let notes = this.state.notes.slice()
        let newNote = Object.assign({}, this.state.activeNote)
        newNote.comments.push(comment)
        newNote.comments.sort((n1, n2) => n1.created_at > n2.created_at)

        notes[notes.map((n) => n.id).indexOf(this.state.activeNote.id)] = newNote

        console.log(notes, newNote)
        this.setState({
            notes: notes,
            activeNote: newNote,
        })
    }

    toggleExpand(note) {
        let a = this.state.expandedNotes
        if (a.indexOf(note.id) >= 0) {
            a.splice(a.indexOf(note.id), 1)
        } else {
            a.push(note.id)
        }
        this.setState({
            expandedNotes: a,
        })
    }

    getTable() {
        const notes = this.state.notes
        const sliced = notes.slice(
            PAGINATE_ITEM_NO * this.state.activePage,
            PAGINATE_ITEM_NO * this.state.activePage + PAGINATE_ITEM_NO,
        )

        if (notes.length > 0) {
            return (
                <div className="table-repsonsive">
                    <table className="panel__table">
                        <thead>
                            <tr>
                                <th>{translate('profile.trajectory_notes.author')}</th>
                                <th>{translate('profile.trajectory_notes.contents')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sliced.map((note) => {
                                return (
                                    <tr key={note.id}>
                                        <td className="table__cell table__cell-sm">
                                            {note.author.name}
                                        </td>
                                        <td
                                            className={
                                                'table__cell table__cell-bg' +
                                                (note.note.length > LENGTH_OF_NOTE
                                                    ? ' table__cell-hover'
                                                    : '')
                                            }
                                            onClick={() =>
                                                note.note.length > LENGTH_OF_NOTE
                                                    ? this.toggleExpand(note)
                                                    : undefined
                                            }
                                        >
                                            <div
                                                className={
                                                    this.state.expandedNotes.indexOf(note.id) >= 0
                                                        ? 'truncated--off'
                                                        : 'truncated'
                                                }
                                            >
                                                {note.note}
                                            </div>
                                        </td>
                                        {this.getIcon(note)}
                                        <td
                                            className={'table__cell-hover'}
                                            onClick={() => {
                                                this.setState({
                                                    openComments: true,
                                                    activeNote: note,
                                                })
                                            }}
                                        >
                                            <BigTooltip
                                                title={
                                                    note.comments.length +
                                                    ' ' +
                                                    translate(
                                                        'profile.trajectory_notes.comment_info',
                                                    )
                                                }
                                                position="bottom"
                                                arrow={true}
                                            >
                                                <i className={'icons icon-speech'} />
                                            </BigTooltip>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <Pagination
                        pageCount={Math.ceil(notes.length / PAGINATE_ITEM_NO)}
                        onPageChange={(p) => this.setState({ activePage: p.selected })}
                    />
                </div>
            )
        } else {
            return (
                <Suggestion iconName="icon-book-open">
                    <Translate value="profile.trajectory_notes.no_notes" />
                </Suggestion>
            )
        }
    }

    render() {
        return (
            <Panel
                className="panel--multi__inner"
                headerText={translate('profile.trajectory_notes.title')}
                headerIcon="icon-note"
            >
                {this.state.loading ? <p className="is-loading--center--small" /> : this.getTable()}
                {this.state.openComments && (
                    <ModalForm
                        title={translate('profile.trajectory_notes.comments.title')}
                        show
                        onClose={this.onClose}
                        size={'x-large'}
                    >
                        <TrajectoryCommentModal
                            note={this.state.activeNote}
                            onClose={this.onClose}
                            addComment={this.addComment}
                        />
                    </ModalForm>
                )}
            </Panel>
        )
    }
}

TrajectoryNotes.propTypes = {
    profile: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
    }
}

export default connect(mapStateToProps)(TrajectoryNotes)

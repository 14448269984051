import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Button extends Component {
    render() {
        const { onClick, className, buttonText, buttonLink } = this.props
        return (
            <div className={this.props.className} onClick={onClick} style={this.props.style}>
                {this.props.children}
                {this.props.buttonText}
            </div>
        )
    }
}

Button.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    buttonLink: PropTypes.string,
    style: PropTypes.object,
}

Button.defaultProps = {
    style: {},
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container } from 'react-grid-system'
import { translate } from 'react-i18nify'
import Panel from '../components/Panel/Panel'
import ModalForm from '../components/Modal/ModalForm'
import FormPrivacy from '../components/Forms/FormPrivacy'
import { fetchProfile, getPrivacyOptions } from '../store/actions/user'
import ButtonPanel from '../components/Button/ButtonPanel'

class Privacy extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            allowEditPrivacy: false,
            privacyInfo: {
                ePortfolio: {
                    public: 0,
                    private: 0,
                },
                parent: {
                    public: 0,
                    private: 0,
                },
                external: {
                    public: 0,
                    private: 0,
                },
            },
        }
    }

    componentDidMount() {
        this.updateProfileInfo()
        this.props.dispatch(
            getPrivacyOptions(
                this.props.auth,
                (privacyOptions) => this.onPrivacyOptions(privacyOptions),
                () => {},
            ),
        )
    }

    updateProfileInfo() {
        this.setState({ loading: true })
        this.props.dispatch(
            fetchProfile(
                null,
                (status, error) => {
                    console.log('Got fetch profile error', status, error)
                },
                () => {
                    this.setState({ loading: false })
                },
            ),
        )
    }

    onPrivacyOptions(privacyOptions) {
        this.setState({
            privacyInfo: {
                ePortfolio: {
                    public: 0,
                    private: 0,
                },
                parent: {
                    public: 0,
                    private: 0,
                },
                external: {
                    public: 0,
                    private: 0,
                },
            },
        })
        const ePortfolioOptions = [
            'cities',
            'competences',
            'functions',
            'organizations',
            'study_level',
            'working_areas',
            'working_times',
        ]
        const parentOptions = [
            'zorgselect_ip',
            'zorgselect_account',
            'interaction_clicks',
            'interaction_pageviews',
            'interaction_scroll',
            'interaction_search_actions',
            'interaction_time',
        ]
        const externalOptions = this.props.config.whitelabel.privacy_external.map(
            (org) => 'external_' + org,
        )

        let privacyInfo = this.state.privacyInfo
        ePortfolioOptions.forEach((option) => {
            if (privacyOptions[option]) {
                privacyInfo.ePortfolio.public++
            } else {
                privacyInfo.ePortfolio.private++
            }
        })
        parentOptions.forEach((option) => {
            if (privacyOptions[option]) {
                privacyInfo.parent.public++
            } else {
                privacyInfo.parent.private++
            }
        })
        externalOptions.forEach((option) => {
            if (privacyOptions[option]) {
                privacyInfo.external.public++
            } else {
                privacyInfo.external.private++
            }
        })
        this.setState({ privacyOptions })
        this.setState({ privacyInfo })
    }

    getPrivacyPanel() {
        return (
            <Panel
                className="panel"
                headerText={translate('profile.privacy')}
                headerIcon="icon-lock"
                addButton={
                    <ButtonPanel
                        buttonText={translate('profile.edit')}
                        className="button--cta"
                        iconName="icon-pencil"
                        onClick={() => {
                            this.setState({
                                allowEditPrivacy: true,
                            })
                        }}
                    />
                }
            >
                <div className="data_block">
                    <h3>E-Portfolio</h3>
                    <h4>
                        {this.state.privacyInfo.ePortfolio.public}{' '}
                        {translate('profile.privacy_shared')}
                    </h4>
                    <h4>
                        {this.state.privacyInfo.ePortfolio.private}{' '}
                        {translate('profile.privacy_private')}
                    </h4>
                </div>

                <div className="data_block">
                    <h3>{this.props.config.whitelabel.parent_title}</h3>
                    <h4>
                        {this.state.privacyInfo.parent.public} {translate('profile.privacy_shared')}
                    </h4>
                    <h4>
                        {this.state.privacyInfo.parent.private}{' '}
                        {translate('profile.privacy_private')}
                    </h4>
                </div>
                {this.props.config.whitelabel.privacy_external &&
                    this.props.config.whitelabel.privacy_external.length > 0 && (
                        <div className="data_block">
                            <h3>{translate('profile.external_sources')}</h3>
                            <h4>
                                {this.state.privacyInfo.external.public}{' '}
                                {translate('profile.privacy_shared')}
                            </h4>
                            <h4>
                                {this.state.privacyInfo.external.private}{' '}
                                {translate('profile.privacy_private')}
                            </h4>
                        </div>
                    )}

                {this.state.allowEditPrivacy && this.getPrivacyModal(this.state.privacyOptions)}
            </Panel>
        )
    }

    getPrivacyModal(privacyOptions) {
        return (
            <ModalForm
                title={translate('profile.privacy')}
                className="modal"
                closeOnOuterClick={true}
                show={this.state.allowEditPrivacy}
                onClose={() => {
                    this.setState({
                        allowEditPrivacy: false,
                    })
                }}
            >
                <div className="text-left">
                    <FormPrivacy
                        privacyOptions={privacyOptions}
                        className="form--small_margin"
                        onCancel={() => {
                            this.setState({
                                allowEditPrivacy: false,
                            })
                        }}
                        onSave={() => {
                            this.setState({
                                allowEditPrivacy: false,
                            })
                            this.props.dispatch(
                                getPrivacyOptions(
                                    this.props.auth,
                                    (privacyOptions) => this.onPrivacyOptions(privacyOptions),
                                    () => {},
                                ),
                            )
                            this.showPrivacyAlert()
                        }}
                        dispatch={this.props.dispatch}
                    />
                </div>
            </ModalForm>
        )
    }

    render() {
        const { dispatch, auth } = this.props
        return (
            <div className="react-native-web">
                <Container>
                    <div id="PrivacyPanel">{this.getPrivacyPanel()}</div>
                </Container>
            </div>
        )
    }
}

Privacy.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

const select = (state) => state

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        config,
        user,
    }
}

export default connect(mapStateToProps)(Privacy)

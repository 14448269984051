import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { translate } from 'react-i18nify'
import ModalForm from '../../Modal/ModalForm'
import FormSchoolSubjects from '../../Forms/FormSchoolSubjects'

class SchoolSubjectsModal extends Component {
    constructor(props) {
        super(props)
    }

    onSuccess() {
        this.props.onSave()
    }

    cancel() {
        this.props.onCancel()
    }

    render() {
        return (
            <ModalForm
                title={translate('profile.school_subjects.title')}
                show
                onClose={() => {
                    this.cancel()
                }}
            >
                <FormSchoolSubjects
                    showActions={true}
                    darkMode={true}
                    onSave={() => this.onSuccess()}
                    onCancel={() => this.cancel()}
                />
            </ModalForm>
        )
    }
}

SchoolSubjectsModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    const { auth, user } = state

    return {
        auth,
        user,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(SchoolSubjectsModal)

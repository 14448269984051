import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18nify'
import { Hidden } from 'react-grid-system'
import { BigTooltip } from '../Tooltip/BigTooltip'

export default class ButtonPanel extends Component {
    render() {
        const { onClick, className, buttonText, showText, showIcon, iconName, svgIcon, svgPath } =
            this.props
        let iconClass = 'icons ' + iconName

        return (
            <BigTooltip title={buttonText} position="left" arrow={true}>
                <div
                    className={`${className} ${showText ? '' : 'panel__button--icon_only'}`}
                    onClick={onClick}
                >
                    {svgIcon ? (
                        <img src={svgPath} className={'svg-icon'} />
                    ) : (
                        showIcon && <i className={iconClass} />
                    )}
                    <Hidden xs sm>
                        {showText && (
                            <span className={showIcon ? 'button__text' : ''}>{buttonText}</span>
                        )}
                    </Hidden>
                </div>
            </BigTooltip>
        )
    }
}

ButtonPanel.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    showText: PropTypes.bool,
    showIcon: PropTypes.bool,
    iconName: PropTypes.string,
    svgIcon: PropTypes.bool,
    svgPath: PropTypes.string,
}

ButtonPanel.defaultProps = {
    buttonText: translate('profile.add_extra_info'),
    showText: true,
    showIcon: true,
    iconName: 'icon-plus',
}

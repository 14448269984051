import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import Alert from '../Alert/Alert'
import Button from '../Button/Button'
import { saveAs } from 'file-saver'
import Loading from '../Loading/Loading'
import { newCallApi } from '../../store/actions/api'
import { translate } from 'react-i18nify'
import { getDocumentsUrl } from '../../store/actions/user'
import Success from './Success'

import { getErrorMessages } from '../../helpers'

export default class FileUploader extends Component {
    constructor() {
        super()
        this.state = {
            deleting: 0,
            uploading: 0,
            errors: [],
            success: false,
        }
        this.dropzoneRef = React.createRef()
    }

    downloadFile(file) {
        let url = getDocumentsUrl(this.props.auth.id, file.id)
        newCallApi('GET', url, null, /* auth */ null, true, true, 'blob').then((blob) => {
            saveAs(blob, file.name)
        })
    }

    deleteFile(file) {
        // TODO: Modal instead of confirm
        let p = window.confirm('Are you sure you want to delete ' + file.name + '?')
        if (p) {
            if (this.props.onFileDeleted) {
                this.props.onFileDeleted(file)
            }
            this.setState({ deleting: 1 })

            let url = getDocumentsUrl(this.props.auth.id, file.id)
            newCallApi('DELETE', url, null, /* auth */ null, true, false).then(() => {
                if (this.props.onFileDeleted) {
                    this.props.onFileDeleted(file)
                }
            })
        }
    }

    getAllowedFileTypes() {
        let allowedDocumentTypes = Object.values(
            this.props.allowedTypes.allowed_document_types,
        ).filter((doc) => doc.code === this.props.type)
        if (allowedDocumentTypes.length > 0) return allowedDocumentTypes[0].allowed_file_types
        else return []
    }

    checkFileType(file) {
        // Checks if any of the types contains an allowed file extension
        return this.getAllowedFileTypes().includes(file.name.replace(/^.*\./, ''))
    }

    uploadFile(file) {
        var data = new FormData()
        if (this.checkFileType(file)) {
            data.append('file', file)
            data.append('code', this.props.type)
            this.setState({ uploading: 1 })

            let url = getDocumentsUrl(this.props.auth.id)
            newCallApi('POST', url, data, /* auth */ null, false).then(
                (response) => {
                    if (this.props.onUploaded) {
                        setTimeout(() => {
                            this.props.onUploaded(response)
                        }, 2000)
                    }

                    this.setState({ uploading: 0, success: true })
                },
                (errors) => {
                    let msgs = []
                    if (errors && errors.errors) {
                        msgs = getErrorMessages(errors.errors, {})
                    } else {
                        msgs.push(translate('errors.general'))
                    }

                    this.setState({ errors: msgs, uploading: false })
                },
            )
        } else {
            this.setState({ errors: [translate('errors.wrong_file_type')] })
        }
    }

    componentWillMount() {
        this.setState({ selectedFile: null })
        this.setState({ filesUploaded: [] })
        this.setState({ loading: 1 })
    }

    componentDidMount() {}

    onDrop(acceptedFiles, rejectedFiles) {
        if (acceptedFiles[0]) {
            this.uploadFile(acceptedFiles[0])
        } else {
            if (
                typeof rejectedFiles === 'object' &&
                typeof rejectedFiles[0] !== 'undefined' &&
                rejectedFiles[0].size > 10000000
            ) {
                this.setState({
                    errors: [...this.state.errors, translate('errors.size_too_large')],
                })
            } else {
                this.setState({ errors: [translate('errors.general')] })
            }
        }
    }

    render() {
        const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent,
        )

        return (
            <div>
                {this.state.deleting === 1 ? (
                    <Loading text="Deleting file..." />
                ) : this.state.uploading === 1 ? (
                    <Loading text={translate('profile.upload') + '...'} />
                ) : this.state.success ? (
                    <Success text="Bestand geupload!" />
                ) : (
                    <div className="dropzone">
                        {this.state.errors && this.state.errors.length > 0 && (
                            <Alert type="alert--error alert--simple">
                                <ul>
                                    {this.state.errors.map(function (value) {
                                        return <li key={value}>{value}</li>
                                    })}
                                </ul>
                            </Alert>
                        )}
                        <Dropzone
                            onDrop={this.onDrop.bind(this)}
                            maxSize={/* 10MB */ 10000000}
                            multiple={false}
                            style={{ display: !mobile ? '' : 'none' }}
                            ref={this.dropzoneRef}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <section className="dropzone__input">
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <span>
                                            {translate('profile.drag_drop_to_upload')}
                                            <br />({this.getAllowedFileTypes().toString()})
                                        </span>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                        <div className="dropzone__text" style={{ display: !mobile ? '' : 'none' }}>
                            <em>{translate('labels.or')}</em>
                        </div>
                        <div className="dropzone__text" style={{ display: mobile ? '' : 'none' }}>
                            <em>{translate('profile.allowed_file_types')}</em>
                            <br />
                            <em>({this.getAllowedFileTypes().toString()})</em>
                        </div>
                        <Button
                            onClick={() => {
                                if (this.dropzoneRef.current) {
                                    this.dropzoneRef.current.open()
                                }
                            }}
                            className="button--cta"
                            buttonText={translate('vacancies.upload')}
                        />
                    </div>
                )}
            </div>
        )
    }
}

FileUploader.propTypes = {
    auth: PropTypes.object.isRequired,
    onFileDeleted: PropTypes.func,
    type: PropTypes.string.isRequired,
    onUploaded: PropTypes.func,
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container, Row } from 'react-grid-system'

import { translate } from 'react-i18nify'
import { logoutUser, requestPasswordReset } from '../store/actions/auth'

import FormPasswordForgotten from '../components/Forms/FormPasswordForgotten'
import GridCol from '../components/GridCol/GridCol'
import GridColOffset from '../components/GridCol/GridColOffset'

import { isSignedIn } from '../helpers'

class PasswordForgotten extends Component {
    constructor(props) {
        super(props)

        this.state = { error: '', success: false, isFetching: false }

        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.success = this.success.bind(this)
        this.error = this.error.bind(this)

        if (isSignedIn(this.props.auth)) {
            this.props.dispatch(logoutUser(null, null, null, null))
        }
    }

    onFormSubmit(mail) {
        this.setState({
            error: '',
        })

        if (mail.length < 5 || !mail.includes('@') || !mail.includes('.')) {
            this.setState({
                error: translate('errors.email_not_valid'),
            })

            return
        }

        this.setState({
            isFetching: true,
        })

        requestPasswordReset(mail, this.success, this.error)
    }

    success() {
        this.setState({
            success: true,
            isFetching: false,
        })
    }

    error(response) {
        if (response.status == 400) {
            this.setState({
                success: true,
                isFetching: false,
            })
        } else {
            this.setState({
                success: false,
                isFetching: false,
                error: translate('errors.general'),
            })
        }
    }

    render() {
        return (
            <div className="react-native-web">
                <Container>
                    <Row>
                        <GridColOffset />
                        <GridCol>
                            <FormPasswordForgotten
                                className="form--bg"
                                onSubmit={this.onFormSubmit}
                                errorMessage={this.state.error}
                                success={this.state.success}
                                isFetching={this.state.isFetching}
                            />
                        </GridCol>
                    </Row>
                </Container>
            </div>
        )
    }
}

PasswordForgotten.propTypes = {
    dispatch: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
}

function mapStateToProps(state) {
    const { auth, config } = state
    const { isFetching, isAuthenticated, errorMessage } = auth

    return {
        isFetching,
        isAuthenticated,
        errorMessage,
        config,
        auth,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(PasswordForgotten)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'react-grid-system'
import FlexibleShiftsPanel from './FlexibleShiftsPanel'
import FlexappFunctionsPanel from './FlexappFunctionsPanel'

class FlexibleShiftsPage extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div>
                <div id="flexibleShiftsPanel">
                    <Row>
                        <Col xs={12} md={7}>
                            <div id="flexibleShiftsPanel">
                                <FlexibleShiftsPanel />
                            </div>
                        </Col>
                        <Col xs={12} md={5}>
                            <div id="flexappFunctionsPanel">
                                <FlexappFunctionsPanel
                                    getJobsGenerator={this.props.getJobsGenerator}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
        state,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(FlexibleShiftsPage)

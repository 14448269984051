import { connect } from 'react-redux'
import React, { Component } from 'react'
import Panel from '../../Panel/Panel'
import { translate, Translate } from 'react-i18nify'
import FormPanel from '../../Forms/FormPanel'
import Tag from '../../Tag/Tag'
import { diffProfileItems } from '../../../store/actions/user'
import Suggestion from '../../Suggestion/Suggestion'

class SkillsPanel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            allowEditCompetencies: false,
        }
    }

    render() {
        let { skills } = this.props.user.profile

        if (skills !== undefined && skills.length > 0) {
            return (
                <Panel
                    className={
                        skills.length > 20
                            ? 'panel--expand panel--multi__inner tags--mobile'
                            : 'panel--multi__inner'
                    }
                    headerText={translate('profile.skills')}
                    headerIcon="icon-star"
                >
                    <FormPanel
                        placeholderText={translate('profile.add_skills')}
                        iconName="icon-star"
                        build={(value) => {
                            return {
                                name: value,
                            }
                        }}
                        item="skills"
                        onSave={() => {
                            this.props.showAlert()
                        }}
                    />

                    <div className="panel__form">
                        <div className="panel__tags">
                            {skills.map((skill) => {
                                return (
                                    <Tag
                                        key={skill.id + '-' + skill.name}
                                        onRemove={() => {
                                            this.props.dispatch(
                                                diffProfileItems(
                                                    'skills',
                                                    [],
                                                    [skill.id],
                                                    this.props.auth,
                                                ),
                                            )
                                        }}
                                        showEdit={this.state.allowEditCompetencies}
                                        title={skill.name}
                                    >
                                        {skill.name}
                                    </Tag>
                                )
                            })}
                        </div>
                    </div>
                </Panel>
            )
        } else {
            return (
                <Panel
                    className="panel--suggestion panel--multi__inner "
                    headerText={translate('profile.skills')}
                    headerIcon="icon-star"
                >
                    <Suggestion iconName="icon-star">
                        <Translate value="profile.no_skills" />
                    </Suggestion>
                    <FormPanel
                        placeholderText={translate('profile.add_skills')}
                        iconName="icon-star"
                        build={(value) => {
                            return {
                                name: value,
                            }
                        }}
                        item="skills"
                        onSave={() => {
                            this.props.showAlert()
                        }}
                    />
                </Panel>
            )
        }
    }
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(SkillsPanel)

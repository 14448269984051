import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { newCallApi } from '../../store/actions/api'
import Actions from '../Actions/Actions'

import { getErrorFields, getErrorMessages } from '../../helpers'
import { translate, Translate } from 'react-i18nify'

let map

class FormPrivacy extends Component {
    constructor(props) {
        super(props)

        this.state = {
            errors: [],
            allowIxn: false,
            items: {
                account_id: 0,
                cities: false,
                competences: false,
                external_hrorganizer: false,
                functions: false,
                interaction_clicks: false,
                interaction_pageviews: false,
                interaction_scroll: false,
                interaction_search_actions: false,
                interaction_time: false,
                organizations: false,
                study_level: false,
                working_areas: false,
                working_times: false,
                zorgselect_account: false,
                zorgselect_ip: false,
            },
            all: [],
            filtered: [],
            manual: [],
            add: false,
            loading: false,
            fetching: true,
        }

        this.isChecked = this.isChecked.bind(this)
        this.getClassNames = this.getClassNames.bind(this)
        this.onCheckboxChange = this.onCheckboxChange.bind(this)
    }

    componentDidMount() {
        this.setState({ items: this.props.privacyOptions })
        setTimeout(() => {
            if (this.isChecked('zorgselect_ip') || this.isChecked('zorgselect_account')) {
                this.setState({ allowIxn: true })
            } else {
                this.setState({ allowIxn: false })
            }
        }, 1)
    }

    cancel() {
        this.props.onCancel()
    }

    isChecked(item) {
        return this.state.items[item] || false
    }

    getClassNames(item) {
        let names = 'form__item'

        if (this.isChecked(item)) {
            names += ' form__item--selected'
        }

        return names
    }

    onCheckboxChange(item) {
        if (!this.isChecked(item)) {
            this.check(item)
        } else {
            this.uncheck(item)
        }
        if (this.isChecked('zorgselect_ip') || this.isChecked('zorgselect_account')) {
            this.setState({ allowIxn: true })
        } else {
            this.setState({ allowIxn: false })
            this.uncheck('interaction_clicks')
            this.uncheck('interaction_pageviews')
            this.uncheck('interaction_scroll')
            this.uncheck('interaction_search_actions')
            this.uncheck('interaction_time')
        }
    }

    check(item) {
        if (!this.isChecked(item)) {
            const items = this.state.items
            items[item] = true
            this.setState({ items })
        }
    }

    uncheck(item) {
        if (this.isChecked(item)) {
            const items = this.state.items
            items[item] = false
            this.setState({ items })
        }
    }

    save() {
        if (!this.state.loading) {
            this.setState({ loading: true })
            newCallApi(
                'POST',
                this.props.config.API_PRIVACY.replace('{account}', this.props.auth.id),
                this.state.items,
                /* auth */ null,
            ).then((json) => {
                this.setState({ loading: false })
                if (typeof this.props.onSave === 'function') this.props.onSave()
            })
        }
    }

    error(status, object) {
        this.setState(
            {
                errors: [],
                fields: [],
                loading: false,
            },
            () => {
                if (object.hasOwnProperty('errors')) {
                    let messages = getErrorMessages(object.errors, map)
                    let fields = getErrorFields(object.errors)

                    this.addErrors(messages)
                    this.addErrorFields(fields)
                }
            },
        )
    }

    render() {
        const { className } = this.props

        return (
            <form
                className={this.props.className}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        //Enter pressed
                        this.save()
                    }
                }}
            >
                <div className="form__fieldset no-margin">
                    <div className="form__header">
                        <p>{translate('profile.privacy_info')}</p>
                    </div>

                    <h3 class="form__cta-text">E-Portfolio:</h3>
                    <div className="form__items privacy_modal">
                        <div className="form__checkbox">
                            <input
                                type="checkbox"
                                name="cities"
                                id="cities"
                                className="form__checkbox__input"
                                onChange={(e) => this.onCheckboxChange('cities')}
                                checked={this.isChecked('cities') ? 'checked' : ''}
                            />
                            <label className="form__checkbox__label" htmlFor="cities">
                                {translate('privacy.cities.title')}
                            </label>
                            <p>{translate('privacy.cities.description')}</p>
                        </div>
                    </div>
                    <div className="form__items privacy_modal">
                        <div className="form__checkbox">
                            <input
                                type="checkbox"
                                name="competences"
                                id="competences"
                                className="form__checkbox__input"
                                onChange={(e) => this.onCheckboxChange('competences')}
                                checked={this.isChecked('competences') ? 'checked' : ''}
                            />
                            <label className="form__checkbox__label" htmlFor="competences">
                                {translate('privacy.competences.title')}
                            </label>
                            <p>{translate('privacy.competences.description')}</p>
                        </div>
                    </div>
                    <div className="form__items privacy_modal">
                        <div className="form__checkbox">
                            <input
                                type="checkbox"
                                name="functions"
                                id="functions"
                                className="form__checkbox__input"
                                onChange={(e) => this.onCheckboxChange('functions')}
                                checked={this.isChecked('functions') ? 'checked' : ''}
                            />
                            <label className="form__checkbox__label" htmlFor="functions">
                                {translate('privacy.functions.title')}
                            </label>
                            <p>{translate('privacy.functions.description')}</p>
                        </div>
                    </div>
                    <div className="form__items privacy_modal">
                        <div className="form__checkbox">
                            <input
                                type="checkbox"
                                name="organizations"
                                id="organizations"
                                className="form__checkbox__input"
                                onChange={(e) => this.onCheckboxChange('organizations')}
                                checked={this.isChecked('organizations') ? 'checked' : ''}
                            />
                            <label className="form__checkbox__label" htmlFor="organizations">
                                {translate('privacy.organizations.title')}
                            </label>
                            <p>{translate('privacy.organizations.description')}</p>
                        </div>
                    </div>
                    <div className="form__items privacy_modal">
                        <div className="form__checkbox">
                            <input
                                type="checkbox"
                                name="study_level"
                                id="study_level"
                                className="form__checkbox__input"
                                onChange={(e) => this.onCheckboxChange('study_level')}
                                checked={this.isChecked('study_level') ? 'checked' : ''}
                            />
                            <label className="form__checkbox__label" htmlFor="study_level">
                                {translate('privacy.study_level.title')}
                            </label>
                            <p>{translate('privacy.study_level.description')}</p>
                        </div>
                    </div>
                    <div className="form__items privacy_modal">
                        <div className="form__checkbox">
                            <input
                                type="checkbox"
                                name="working_areas"
                                id="working_areas"
                                className="form__checkbox__input"
                                onChange={(e) => this.onCheckboxChange('working_areas')}
                                checked={this.isChecked('working_areas') ? 'checked' : ''}
                            />
                            <label className="form__checkbox__label" htmlFor="working_areas">
                                {translate('profile.experience_area')}
                            </label>
                            <p>{translate('privacy.experience_area.description')}</p>
                        </div>
                    </div>
                    <div className="form__items privacy_modal">
                        <div className="form__checkbox">
                            <input
                                type="checkbox"
                                name="working_times"
                                id="working_times"
                                className="form__checkbox__input"
                                onChange={(e) => this.onCheckboxChange('working_times')}
                                checked={this.isChecked('working_times') ? 'checked' : ''}
                            />
                            <label className="form__checkbox__label" htmlFor="working_times">
                                {translate('privacy.working_times.title')}
                            </label>
                            <p>{translate('privacy.working_times.description')}</p>
                        </div>
                    </div>

                    <h4>{this.props.config.whitelabel.parent_title}:</h4>

                    <div className="form__items privacy_modal">
                        <div className="form__checkbox">
                            <input
                                type="checkbox"
                                name="zorgselect_ip"
                                id="zorgselect_ip"
                                className="form__checkbox__input"
                                onChange={(e) => this.onCheckboxChange('zorgselect_ip')}
                                checked={this.isChecked('zorgselect_ip') ? 'checked' : ''}
                            />
                            <label className="form__checkbox__label" htmlFor="zorgselect_ip">
                                {translate('privacy.parent_ip.title')}
                            </label>
                            <p>{translate('privacy.parent_ip.description')}</p>
                        </div>
                    </div>
                    <div className="form__items privacy_modal">
                        <div className="form__checkbox">
                            <input
                                type="checkbox"
                                name="zorgselect_account"
                                id="zorgselect_account"
                                className="form__checkbox__input"
                                onChange={(e) => this.onCheckboxChange('zorgselect_account')}
                                checked={this.isChecked('zorgselect_account') ? 'checked' : ''}
                            />
                            <label className="form__checkbox__label" htmlFor="zorgselect_account">
                                {translate('privacy.parent_account.title')}
                            </label>
                            <p>{translate('privacy.parent_account.description')}</p>
                        </div>
                    </div>

                    <div style={!this.state.allowIxn ? { opacity: 0.4 } : {}}>
                        <p style={{ margin: '2rem 0' }}>
                            {translate('profile.interactions_desc')}:
                        </p>
                        <div className="form__items privacy_modal">
                            <div className="form__checkbox">
                                <input
                                    type="checkbox"
                                    name="interaction_clicks"
                                    id="interaction_clicks"
                                    className="form__checkbox__input"
                                    onChange={(e) => this.onCheckboxChange('interaction_clicks')}
                                    checked={this.isChecked('interaction_clicks') ? 'checked' : ''}
                                    disabled={this.state.allowIxn ? '' : 'disabled'}
                                />
                                <label
                                    className="form__checkbox__label"
                                    htmlFor="interaction_clicks"
                                >
                                    {translate('privacy.interaction_clicks.title')}
                                </label>
                                <p>{translate('privacy.interaction_clicks.description')}</p>
                            </div>
                        </div>
                        <div className="form__items privacy_modal">
                            <div className="form__checkbox">
                                <input
                                    type="checkbox"
                                    name="interaction_pageviews"
                                    id="interaction_pageviews"
                                    className="form__checkbox__input"
                                    onChange={(e) => this.onCheckboxChange('interaction_pageviews')}
                                    checked={
                                        this.isChecked('interaction_pageviews') ? 'checked' : ''
                                    }
                                    disabled={this.state.allowIxn ? '' : 'disabled'}
                                />
                                <label
                                    className="form__checkbox__label"
                                    htmlFor="interaction_pageviews"
                                >
                                    {translate('privacy.interaction_pageviews.title')}
                                </label>
                                <p>{translate('privacy.interaction_pageviews.description')}</p>
                            </div>
                        </div>
                        <div className="form__items privacy_modal">
                            <div className="form__checkbox">
                                <input
                                    type="checkbox"
                                    name="interaction_scroll"
                                    id="interaction_scroll"
                                    className="form__checkbox__input"
                                    onChange={(e) => this.onCheckboxChange('interaction_scroll')}
                                    checked={this.isChecked('interaction_scroll') ? 'checked' : ''}
                                    disabled={this.state.allowIxn ? '' : 'disabled'}
                                />
                                <label
                                    className="form__checkbox__label"
                                    htmlFor="interaction_scroll"
                                >
                                    {translate('privacy.interaction_scroll.title')}
                                </label>
                                <p>{translate('privacy.interaction_scroll.description')}</p>
                            </div>
                        </div>
                        <div className="form__items privacy_modal">
                            <div className="form__checkbox">
                                <input
                                    type="checkbox"
                                    name="interaction_search_actions"
                                    id="interaction_search_actions"
                                    className="form__checkbox__input"
                                    onChange={(e) =>
                                        this.onCheckboxChange('interaction_search_actions')
                                    }
                                    checked={
                                        this.isChecked('interaction_search_actions')
                                            ? 'checked'
                                            : ''
                                    }
                                    disabled={this.state.allowIxn ? '' : 'disabled'}
                                />
                                <label
                                    className="form__checkbox__label"
                                    htmlFor="interaction_search_actions"
                                >
                                    {translate('privacy.interaction_search_actions.title')}
                                </label>
                                <p>{translate('privacy.interaction_search_actions.description')}</p>
                            </div>
                        </div>
                        <div className="form__items privacy_modal">
                            <div className="form__checkbox">
                                <input
                                    type="checkbox"
                                    name="interaction_time"
                                    id="interaction_time"
                                    className="form__checkbox__input"
                                    onChange={(e) => this.onCheckboxChange('interaction_time')}
                                    checked={this.isChecked('interaction_time') ? 'checked' : ''}
                                    disabled={this.state.allowIxn ? '' : 'disabled'}
                                />
                                <label className="form__checkbox__label" htmlFor="interaction_time">
                                    {translate('privacy.interaction_time.title')}
                                </label>
                                <p>{translate('privacy.interaction_time.description')}</p>
                            </div>
                        </div>
                    </div>
                    {this.props.config.whitelabel.privacy_external &&
                        this.props.config.whitelabel.privacy_external.length > 0 && (
                            <span>
                                <h4>{translate('profile.external_sources')}:</h4>

                                {this.props.config.whitelabel.privacy_external.map((org) => {
                                    return (
                                        <div className="form__items privacy_modal">
                                            <div className="form__checkbox">
                                                <input
                                                    type="checkbox"
                                                    name={'external_' + org}
                                                    id={'external_' + org}
                                                    className="form__checkbox__input"
                                                    onChange={(e) =>
                                                        this.onCheckboxChange('external_' + org)
                                                    }
                                                    checked={
                                                        this.isChecked('external_' + org)
                                                            ? 'checked'
                                                            : ''
                                                    }
                                                />
                                                <label
                                                    className="form__checkbox__label"
                                                    htmlFor={'external_' + org}
                                                >
                                                    {translate(
                                                        `privacy.${'external_' + org}.title`,
                                                    )}
                                                </label>
                                                <p>
                                                    {translate(
                                                        `privacy.${'external_' + org}.description`,
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </span>
                        )}
                </div>
                <div className="spacer" />
                <Actions>
                    <Link
                        className="actions__action--cancel"
                        onClick={() => this.cancel()}
                        tabIndex="2"
                    >
                        <Translate value="profile.cancel" />
                    </Link>
                    <Link
                        className={
                            'actions__action--save' + (this.state.loading ? ' is-loading' : '')
                        }
                        onClick={() => this.save()}
                        tabIndex="3"
                    >
                        <Translate value="profile.save" />
                    </Link>
                </Actions>
            </form>
        )
    }
}

FormPrivacy.propTypes = {
    className: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    edit: PropTypes.object,
    privacyOptions: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    const { auth, user, config } = state
    const { profile } = user
    const { competences } = profile

    return {
        auth,
        config,
        user,
        competences,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(FormPrivacy)

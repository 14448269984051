import classNames from 'classnames'
import { useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react'
import Cropper from 'react-easy-crop'
import { translate, Translate } from 'react-i18nify'
import { Link } from 'react-router-dom'
import Actions from '../Actions/Actions'
import ModalForm from '../Modal/ModalForm'
import { Slider } from '../Slider/Slider'
import { fileToDataUrl, getCroppedImg } from './helpers'

import './ImgCropperModal.scss'

// access component api via ref
export const ImgCropperModal = forwardRef((_, ref) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(100)
    const [rotation, setRotation] = useState(0)
    const [loading, setLoading] = useState(false)

    const [isOpen, setOpen] = useState(false)
    const [fileSrc, setFileSrc] = useState(null)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const resolveRef = useRef(() => {})

    useImperativeHandle(ref, () => ({
        // opens the cropper module
        // returns a promise which may resolve to the edited file
        // or to a null value (meaning the file was not edited)
        open: async (file) => {
            const imageData = await fileToDataUrl(file)
            setFileSrc(imageData)
            setOpen(true)

            return new Promise((resolve) => {
                resolveRef.current = resolve
            })
        },
    }))

    const handleClose = () => {
        setOpen(false)
        resolveRef.current(null)
    }

    const handleCropComplete = (_croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    const handleSave = async () => {
        setLoading(true)
        const croppedFile = await getCroppedImg(fileSrc, croppedAreaPixels, rotation)
        resolveRef.current(croppedFile)
        setOpen(false)
    }

    useEffect(() => {
        setRotation(0)
        setZoom(100)
        setLoading(false)
    }, [isOpen])

    return (
        <>
            {isOpen && (
                <ModalForm
                    title={translate('profilePictureModal.title')}
                    size="x-large"
                    show
                    onClose={handleClose}
                >
                    <div className="cropper-container">
                        {/* because step in slider can't be 0.1 we need to scale he zoom with 100 */}
                        <Cropper
                            image={fileSrc}
                            crop={crop}
                            zoom={zoom / 100}
                            rotation={rotation}
                            aspect={1}
                            cropShape={'round'}
                            onCropChange={setCrop}
                            onCropComplete={handleCropComplete}
                            onZoomChange={(z) => setZoom(z * 100)}
                        />
                    </div>
                    <br />
                    <Slider
                        min={100}
                        max={300}
                        translation="profilePictureModal.zoom"
                        name="zoom"
                        value={zoom}
                        onChange={({ value }) => setZoom(value)}
                    />
                    <Slider
                        min={0}
                        max={270}
                        step={90}
                        translation="profilePictureModal.rotation"
                        name="rotation"
                        value={rotation}
                        onChange={({ value }) => setRotation(value)}
                    />

                    <Actions>
                        <Link
                            className="actions__action--cancel"
                            onClick={handleClose}
                            tabIndex="2"
                        >
                            <Translate value="profile.cancel" />
                        </Link>
                        <Link
                            className={classNames('actions__action--save', {
                                'is-loading': loading,
                            })}
                            onClick={handleSave}
                            tabIndex="5"
                        >
                            <Translate value="profilePictureModal.save" />
                        </Link>
                    </Actions>
                </ModalForm>
            )}
        </>
    )
})

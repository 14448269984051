import {
    SUGGESTION_FAILURE,
    SUGGESTION_NEXT,
    SUGGESTION_REQUEST,
    SUGGESTION_SUCCESS,
} from '../../constants/Constants'

import newCallApi from './api'
import { tokenUsed } from './auth'
import { store } from '../index'

const queryString = require('query-string')

/**
 * Fired at the start of suggestion fetching
 * @returns {{type, isFetching: boolean, error: string}}
 */
function requestSuggestions() {
    console.log('[ actions ]', 'setting isFetching to true')
    return {
        type: SUGGESTION_REQUEST,
        isFetching: true,
        error: '',
    }
}

function suggestionSuccess(suggestions, newArray = false) {
    return {
        type: SUGGESTION_SUCCESS,
        isFetching: false,
        suggestions: suggestions,
        clear: newArray,
    }
}

function suggestionError(error) {
    return {
        type: SUGGESTION_FAILURE,
        isFetching: false,
        error: error,
    }
}

export function fetchCompetenceSuggestions(auth, onSuccess, onError, exclude) {
    return fetchSuggestions(auth, onSuccess, onError, exclude, 50, -1, true, [
        'competence_question',
    ])
}

export function fetchSuggestions(
    auth,
    onSuccess,
    onError,
    exclude = [],
    limit = 100,
    suggestion_id = -1,
    clear = false,
    types = [],
    only = [],
) {
    return (dispatch) => {
        dispatch(requestSuggestions())

        var path = store.getState().config.API_SUGGESTIONS + '?limit=' + limit

        if (exclude.length > 0) {
            path += '&' + queryString.stringify({ exclude: exclude }, { arrayFormat: 'bracket' })
        }

        if (types.length > 0) {
            path += '&' + queryString.stringify({ type: types }, { arrayFormat: 'bracket' })
        }

        if (only.length > 0) {
            path += '&' + queryString.stringify({ only: only }, { arrayFormat: 'bracket' })
        }

        let newArray = false
        if (+suggestion_id !== -1) {
            path += '&suggestion_id=' + suggestion_id
        }

        if (clear) {
            newArray = true
        }

        return newCallApi('GET', path, null, auth).then(
            (response) => {
                //Success
                dispatch(tokenUsed())
                dispatch(suggestionSuccess(response, newArray))
                onSuccess(response)
            },
            (response) => {
                //Error
                dispatch(suggestionError(response.error))
                onError(response.status, response)
            },
        )
    }
}

export function nextCard(authState) {
    return {
        type: SUGGESTION_NEXT,
        auth: authState,
    }
}

export function setSuggestionResponse(success, error, suggestionId, confirmed = false, authState) {
    var path = store.getState().config.API_SUGGESTIONS + '/' + suggestionId

    var body = {
        confirmed: confirmed ? 1 : 0,
    }

    newCallApi('PUT', path, body, /* auth */ null, true, false).then(
        () => {
            success()
        },
        (response) => {
            error(response)
        },
    )
}

import React, { Component } from 'react'
import { Col, Hidden } from 'react-grid-system'

export default class GridColOffset extends Component {
    render() {
        return (
            <Hidden xs>
                <Col sm={12} md={1} xl={2}>
                    <span />
                </Col>
            </Hidden>
        )
    }
}

import React, { Component } from 'react'
import Panel from '../../Panel/Panel'
import { connect } from 'react-redux'
import { translate } from 'react-i18nify'
import AccountFieldsPanel from './AccountFieldsPanel'
import DocumentsPanel from './DocumentsPanel'
import TemplatesPanel from './TemplatesPanel'

class DocumentsPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            allowEditJobFunctions: false,
            editAccountFields: false,
            downloadBtnText: translate('profile.email_attachment'),
            viewdoc: null,
            uploaddoc: null,
        }
    }

    render() {
        return (
            <div>
                <Panel className="panel--multi">
                    <span className="separate_body" container_id={'documentsPanelFader'} />
                    <div id="documentsPanel">
                        <DocumentsPanel
                            showProfileExport={this.props.config.whitelabel.show_profile_export}
                        />
                    </div>

                    <div className="spacer-hr" />
                    <span className="separate_body" container_id={'templatesPanelFader'} />
                    <div id="templatesPanel">
                        <TemplatesPanel />
                    </div>

                    {this.updateProfile && (
                        <>
                            <div className="spacer-hr" />
                            <span className="separate_body" />
                            <div id="accountFieldsPanel">
                                <AccountFieldsPanel updateProfile={this.updateProfile} />
                            </div>
                        </>
                    )}
                </Panel>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
        state,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(DocumentsPage)

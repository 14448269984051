import { createStore } from 'redux'
import portfolioReducer from './reducers'
import enhancer from './enhancer'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, portfolioReducer)

let store = createStore(persistedReducer, enhancer)

let persistor = persistStore(store)

export { store, persistor }

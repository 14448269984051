import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row } from 'react-grid-system'

import { translate, Translate } from 'react-i18nify'
import { addClass, removeClass } from '../../helpers'

import Alert from '../Alert/Alert'
import GridCol from '../GridCol/GridCol'
import GridColOffset from '../GridCol/GridColOffset'
import history from '../../history'

export default class FormPasswordForgotten extends Component {
    constructor(props) {
        super(props)

        this.state = { email: '' }

        this.onEmailChange = this.onEmailChange.bind(this)
        this.submit = this.submit.bind(this)
    }

    onEmailChange(event) {
        this.setState({
            email: event.target.value,
        })

        if (event.target.value && event.target.value.length > 0) {
            addClass(event.target, 'has-value')
        } else {
            removeClass(event.target, 'has-value')
        }
    }

    submit(e) {
        e.preventDefault()
        this.props.onSubmit(this.state.email)
    }

    render() {
        const { className, errorMessage, onSubmit, success, isFetching } = this.props
        return (
            <form className={this.props.className} onSubmit={this.submit}>
                <Row>
                    <GridColOffset />
                    <GridCol>
                        <h1>
                            <Translate value="headers.forgot_password" />
                        </h1>

                        {!success && (
                            <p>
                                <Translate value="texts.forgot_password" />
                            </p>
                        )}

                        {errorMessage && errorMessage.length > 0 && (
                            <Alert alertText={errorMessage} type="alert--error" />
                        )}

                        {success && <Alert alertText={translate('texts.forgot_email_sent')} />}

                        {!success && (
                            <fieldset className="form__fieldset">
                                <div className="form__item">
                                    <i className="form__icon icons icon-envelope" />
                                    <input
                                        className="form__input--text"
                                        onChange={this.onEmailChange}
                                        type="email"
                                        id="email"
                                        autoFocus
                                        tabIndex="1"
                                    />
                                    <label className="form__label" htmlFor="email">
                                        <Translate value="labels.email" />
                                    </label>
                                </div>
                            </fieldset>
                        )}

                        {!success && (
                            <div className="form__button_container">
                                <button
                                    type="submit"
                                    className={'button--alt' + (isFetching ? ' is-loading' : '')}
                                    tabIndex="2"
                                >
                                    {translate('buttons.send_email')}
                                </button>

                                <button
                                    className="button"
                                    onClick={() => {
                                        history.goBack()
                                    }}
                                    tabIndex="3"
                                >
                                    {translate('buttons.back')}
                                </button>
                            </div>
                        )}
                    </GridCol>
                </Row>
            </form>
        )
    }
}

FormPasswordForgotten.propTypes = {
    className: PropTypes.string.isRequired,
    errorMessage: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    success: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool,
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { newCallApi } from '../../../store/actions/api'
import { translate, Translate } from 'react-i18nify'

import { Link } from 'react-router-dom'
import Actions from '../../Actions/Actions'
import moment from 'moment'

class TrajectoryCommentModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            comment: '',
        }
    }

    sendComment() {
        if (this.state.comment.length === 0) {
            return
        }
        this.setState({ loading: true })
        const endpoint = this.props.config.API_TRAJECTORY_NOTE_COMMENT.replace(
            '{note_id}',
            this.props.note.id,
        )

        newCallApi('POST', endpoint, { body: this.state.comment }).then((res) => {
            this.setState({ loading: false, comment: '' }, () => this.props.addComment(res))
        })
    }

    getSaveClasses() {
        let c = 'actions__action--save'
        if (this.state.loading) {
            c += ' is-loading'
        }
        return c
    }

    handleChange(value, key) {
        let obj = {}
        obj[key] = value.target.value

        this.setState(obj)
    }

    render() {
        return (
            <div>
                <div className={'text-newline'}>
                    {this.props.note.note}
                    <div>
                        <strong>
                            ~{this.props.note.author.name} -{' '}
                            {moment(this.props.note.date).format('DD MMMM YYYY')}
                        </strong>
                    </div>
                </div>
                <form
                    className="form"
                    onKeyDown={(e) => {
                        if (e.keyCode === 13 && !e.shiftKey) {
                            e.preventDefault()
                            this.sendComment()
                        }
                    }}
                >
                    <fieldset className="form__fieldset">
                        <div className="form__item">
                            <i className="form__icon icons icon-pencil" />
                            <textarea
                                className={
                                    'form__input--textarea--dynamic ' +
                                    (this.state.comment != null &&
                                    this.state.comment != '' &&
                                    this.state.comment.length > 0
                                        ? ' has-value'
                                        : '')
                                }
                                type="textarea"
                                id="comment"
                                value={this.state.comment}
                                tabIndex="1"
                                onInput={(value) => this.handleChange(value, 'comment')}
                                placeholder={translate(
                                    'profile.trajectory_notes.comments.placeholder',
                                )}
                            />
                        </div>
                    </fieldset>
                </form>
                <Actions>
                    <Link
                        className="actions__action--cancel"
                        onClick={() => this.props.onClose()}
                        tabIndex="160"
                    >
                        <Translate value="profile.cancel" />
                    </Link>
                    <Link className={this.getSaveClasses()} onClick={() => this.sendComment()}>
                        <Translate value="profile.trajectory_notes.comments.send" />
                    </Link>
                </Actions>
                <div className="spacer" />
                <div>
                    <table className="panel__table table__extra-margin">
                        <tbody>
                            {this.props.note.comments.map((comment) => (
                                <tr key={comment.id}>
                                    <td>{comment.created_at}</td>
                                    <td>{comment.author.name}</td>
                                    <td className={'text-newline'}>{comment.body}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

TrajectoryCommentModal.propTypes = {
    note: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
    }
}

export default connect(mapStateToProps)(TrajectoryCommentModal)

import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Alert extends Component {
    getClass() {
        return 'alert ' + this.props.type
    }

    render() {
        const { alertText, type } = this.props
        return (
            <div className={this.getClass()}>
                {alertText}
                {this.props.children}
            </div>
        )
    }
}

Alert.propTypes = {
    alertText: PropTypes.string,
    type: PropTypes.string.isRequired,
}

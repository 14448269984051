import React, { Component } from 'react'

export default class Success extends Component {
    render() {
        return (
            <div className="text-center">
                <div>
                    <svg
                        fill="#4abcac"
                        style={{ maxWidth: '125px', width: 100, height: 100 }}
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                    </svg>
                </div>
                <div>{this.props.text && <div>{this.props.text}</div>}</div>
            </div>
        )
    }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'simple-react-modal'
import { connect } from 'react-redux'

import { translate } from 'react-i18nify'

import { deleteAccount } from '../../store/actions/user'
import { logoutUser } from '../../store/actions/auth'

import Loading from '../Loading/Loading'

class ModalDelete extends Component {
    constructor(props) {
        super(props)

        let toShow = this.props.show != undefined ? this.props.show : false
        this.state = {
            show: toShow,
            confirmationCode: this.generate(),
            code: '',
            loading: false,
        }

        this.show = this.show.bind(this)
        this.close = this.close.bind(this)
    }

    generate() {
        let text = ''
        let possible = 'ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789'

        for (let i = 0; i < 8; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length))

        return text
    }

    delete() {
        if (this.state.code === this.state.confirmationCode) {
            //DELETE ACCOUNT
            this.setState({
                loading: true,
            })

            deleteAccount(this.props.auth).then(
                () => {
                    //Delete success. Logout and go to login page.
                    this.props.dispatch(logoutUser(this.props.auth))
                },
                (errors) => {
                    this.setState({
                        loading: false,
                    })
                },
            )
        } else {
            alert('Vul de bevestigingscode in om door te gaan.')
        }
    }

    setErrors(errors) {
        if (!errors) {
            errors = []
        }

        this.setState({
            errors: errors,
        })
    }

    show() {
        document.body.style.overflowY = 'hidden'
        this.setState({ show: true })
    }

    close() {
        document.body.style.overflowY = 'scroll'
        this.setState({ show: false })
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    render() {
        const { title, subTitle, hideClose } = this.props

        return (
            <div
                onKeyDown={(e) => {
                    //Esc
                    if (e.keyCode === 27) {
                        this.close()
                    }
                }}
            >
                <Modal
                    className="modal--delete"
                    containerClassName="modal__container"
                    closeOnOuterClick={true}
                    show={this.state.show}
                    onClose={this.close}
                >
                    <div className="modal__content">
                        <div className="modal__header">
                            <h2>
                                <i className="icons icon-exclamation" />
                                {title}
                            </h2>
                            <p>{subTitle}</p>
                        </div>

                        {this.state.loading && <Loading text="Account verwijderen..." />}

                        {!this.state.loading && (
                            <div className="modal__list">
                                <p>{translate('profile.delete_info_list')}</p>
                                <ul>
                                    {this.props.arrDelete.map((item, index) => {
                                        return <li key={'item-' + index}>{item}</li>
                                    })}
                                </ul>

                                <p className="text--lighter">
                                    {translate('profile.delete_confirm')}
                                </p>
                                <p>
                                    <i>{this.state.confirmationCode}</i>
                                </p>

                                <form className="form">
                                    <fieldset className="form__fieldset">
                                        <div className="form__item">
                                            <input
                                                className="form__input--text"
                                                type="text"
                                                id=""
                                                placeholder={translate(
                                                    'profile.confirm_delete_placeholder',
                                                )}
                                                value={this.state.code}
                                                onChange={(e) => {
                                                    this.setState({
                                                        code: e.target.value,
                                                    })
                                                }}
                                            />
                                        </div>
                                    </fieldset>
                                    <div className="actions">
                                        <button
                                            className="actions__action--cancel"
                                            onClick={this.close.bind(this)}
                                        >
                                            {translate('profile.cancel')}
                                        </button>

                                        {this.state.code === this.state.confirmationCode && (
                                            <button
                                                className="button--alert"
                                                onClick={this.delete.bind(this)}
                                            >
                                                {translate('profile.remove')}
                                            </button>
                                        )}
                                    </div>
                                </form>
                            </div>
                        )}
                    </div>
                </Modal>
            </div>
        )
    }
}

ModalDelete.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    hideClose: PropTypes.bool,
    onClose: PropTypes.func,
    show: PropTypes.bool.isRequired,
    arrDelete: PropTypes.array.isRequired,
}

function mapStateToProps(state) {
    const { auth } = state

    return {
        auth,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(ModalDelete)

import React, { Component } from 'react'
import { translate, Translate } from 'react-i18nify'
import { Hidden } from 'react-grid-system'
import Button from '../../Button/Button'
import FormDiscoveryMethod from '../../Forms/FormDiscoveryMethod'

export default class Discovery extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            save: undefined,
            cancel: undefined,
        }

        this.save = this.save.bind(this)
        this.setLoadingValue = this.setLoadingValue.bind(this)
        this.formRef = React.createRef()
    }

    save() {
        this.formRef.current.save()
    }

    setLoadingValue(value) {
        this.setState({ loading: value })
    }

    render() {
        return (
            <div className="card--simple">
                <div className="onboarding__content">
                    <h1 className="mixed-case">{translate('onboarding.step.discovery.title')}</h1>
                    <p></p>
                    <FormDiscoveryMethod
                        onCancel={this.props.prevStep}
                        onSave={this.props.nextStep}
                        setLoadingValue={this.setLoadingValue}
                        ref={this.formRef}
                        loading={this.state.loading}
                        className="form--bg"
                    />

                    <div className="card--simple__buttons">
                        <div>
                            <Button
                                className={
                                    'button--alt inline-block' +
                                    (this.state.loading ? ' is-loading' : '')
                                }
                                buttonText={translate('buttons.continue')}
                                onClick={() => {
                                    this.save()
                                }}
                            />
                        </div>
                        {!this.state.loading && (
                            <Hidden xs>
                                <button
                                    onClick={() => {
                                        this.props.prevStep()
                                    }}
                                    className="button--link inline-block"
                                >
                                    <i className="mdi mdi-arrow-left" />
                                    <Translate value="onboarding.button_back" />
                                </button>
                            </Hidden>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import { translate } from 'react-i18nify'
import { ToastContainer } from 'react-toastr'

import GridCol from '../components/GridCol/GridCol'
import GridColOffset from '../components/GridCol/GridColOffset'
import Page from '../components/Page/Page'

import { PATH_APPLICATIONS } from '../constants/Constants'
import ApplyJobOffer from './ApplyJobOffer'
import { getVacancyReactions } from '../store/actions/user'
import history from '../history'

class VacaturePage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            shouldShowProfileCta: false,
            showFeedbackModal: false,
            feedback: {},
            isFetching: false,
            job_offer: { id: parseInt(this.props.match.params.id) },
            showJobOffer: false,
            apply: false,
            reaction: undefined,
        }

        this.toastContainer = React.createRef()
    }

    componentDidMount() {
        // Fetch job offer details
        this.props.dispatch(
            getVacancyReactions(
                this.props.auth,
                (items) => {
                    this.findApplication(items)
                },
                () => {
                    this.setState({
                        reaction: null,
                    })
                },
            ),
        )
    }

    findApplication(items) {
        let sentVac = [],
            conceptVac = [],
            expiredVac = []

        let id = parseInt(this.props.match.params.id)

        items.map((item) => {
            if (!item.concept) {
                sentVac.push(item)
            } else if (item.concept && item.vacancy !== undefined && item.vacancy.state === 1) {
                conceptVac.push(item)
            } else if (item.concept && (item.vacancy === undefined || item.vacancy.state === 0)) {
                expiredVac.push(item)
            }
        })

        let item = items.find((element) => {
            return element.vacancy_id === id
        })

        if (item !== undefined) {
            this.setState({
                reaction: item,
            })
        } else {
            this.setState({
                reaction: null,
            })
        }
    }

    handleJobOfferClose(sent, saved) {
        if (sent) {
            this.showSuccessMessage(
                translate('vacancies.title_success'),
                translate('vacancies.message_success'),
            )

            setTimeout(() => {
                history.push('/' + PATH_APPLICATIONS)
            }, 3000)
        } else if (saved) {
            this.showSuccessMessage(
                translate('vacancies.title_saved'),
                translate('vacancies.message_saved'),
            )

            setTimeout(() => {
                history.push('/' + PATH_APPLICATIONS)
            }, 3000)
        } else {
            history.push('/' + PATH_APPLICATIONS)
        }
    }

    showSuccessMessage(title, message) {
        this.toastContainer.current.success(message, title, {
            timeOut: 10000,
            extendedTimeOut: 1000,
        })
    }

    render() {
        // injected by connect call
        const { dispatch } = this.props

        return (
            <div className="react-native-web">
                <Container>
                    <Row>
                        <GridColOffset />
                        <GridCol>
                            {this.state.reaction !== undefined && (
                                <Page>
                                    <div className="page--vacancy">
                                        {this.state.reaction !== null && (
                                            <ApplyJobOffer
                                                dispatch={this.props.dispatch}
                                                cancelable={false}
                                                job_offer={parseInt(this.props.match.params.id)}
                                                content={this.state.reaction.content}
                                                reaction_id={this.state.reaction.id}
                                                reaction={this.state.reaction}
                                                onClose={(sent, saved) => {
                                                    this.handleJobOfferClose(sent, saved)
                                                }}
                                            />
                                        )}

                                        {this.state.reaction === null && (
                                            <ApplyJobOffer
                                                dispatch={this.props.dispatch}
                                                cancelable={false}
                                                job_offer={parseInt(this.props.match.params.id)}
                                                onClose={(sent, saved) => {
                                                    this.handleJobOfferClose(sent, saved)
                                                }}
                                            />
                                        )}
                                    </div>
                                </Page>
                            )}
                        </GridCol>
                    </Row>
                </Container>

                <ToastContainer ref={this.toastContainer} className="toast-bottom-right" />
            </div>
        )
    }
}

VacaturePage.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    const { auth, user } = state
    const { documents } = user
    return {
        auth,
        documents,
        user,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(VacaturePage)

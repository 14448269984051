import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18nify'

import Button from '../Button/Button'

export default class Card extends Component {
    constructor(props) {
        super(props)

        this.showBack = this.showBack.bind(this)

        this.state = { showBack: false, allowStateChange: false }
    }

    showBack(show = true) {
        this.setState({
            showBack: show,
        })
    }

    getFrontChild() {
        if (this.props.children.constructor === Array) {
            //Multiple children
            return this.props.children[0]
        } else {
            return this.props.children
        }
    }

    getBackChild() {
        if (this.props.children.constructor !== Array) {
            return ''
        } else {
            return this.props.children[1]
        }
    }

    getMoreInfoButton() {
        if (this.props.children.constructor === Array && this.props.children.length == 2) {
            if (this.props.children[1].constructor === Object) {
                return (
                    <Button
                        className="button--small"
                        onClick={() => this.showBack(true)}
                        buttonText={translate('buttons.more_info')}
                        tabIndex="2"
                    />
                )
            } else {
                return (
                    <Button
                        className="button--small is-hidden"
                        onClick={() => this.showBack(true)}
                        buttonText={translate('buttons.more_info')}
                        tabIndex="2"
                    />
                )
            }
        } else {
            return (
                <Button
                    className="button--small is-hidden"
                    onClick={() => this.showBack(true)}
                    buttonText={translate('buttons.more_info')}
                    tabIndex="2"
                />
            )
        }
    }

    getNegativeButton() {
        if (this.props.isInfo) {
            return (
                <Button
                    className="button--icon icomoon-cross is-hidden"
                    onClick={this.props.onNegative}
                    tabIndex="1"
                />
            )
        } else {
            return (
                <Button
                    className="button--icon icomoon-cross"
                    onClick={this.props.onNegative}
                    tabIndex="1"
                />
            )
        }
    }

    getClass() {
        return this.state.showBack ? 'card--back' : this.props.className
    }

    render() {
        const { className, onPositive, onNegative, isInfo } = this.props

        return (
            <div className={this.getClass()}>
                {this.props.titleTop !== undefined && (
                    <div className="card__top">
                        <div className="card__top__content">{this.props.titleTop}</div>
                    </div>
                )}

                {!this.state.showBack && (
                    <div className="card__inner">
                        <div className="card__content">
                            <div className="card__content__inner">{this.getFrontChild()}</div>
                        </div>
                        <div className="card__footer">
                            {this.getNegativeButton()}

                            {this.getMoreInfoButton()}

                            {this.props.href ? (
                                <a
                                    href={this.props.href}
                                    target="_blank"
                                    onClick={this.props.onPositive}
                                    className="button--icon icomoon-check"
                                    tabIndex="2"
                                    rel="noreferrer"
                                />
                            ) : (
                                <Button
                                    className="button--icon icomoon-check"
                                    onClick={this.props.onPositive}
                                    tabIndex="2"
                                />
                            )}
                        </div>
                    </div>
                )}

                {this.state.showBack && (
                    <div className="card__fade">
                        <div className="card__inner">
                            <div className="card__content">{this.getBackChild()}</div>

                            <div className="card__footer">
                                <Button
                                    className="card__close button--cta inline-block"
                                    buttonText={translate('cards.back')}
                                    onClick={() => this.showBack(false)}
                                    tabIndex="1"
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

Card.propTypes = {
    className: PropTypes.string.isRequired,
    onPositive: PropTypes.func,
    onNegative: PropTypes.func,
    isInfo: PropTypes.bool,
    showTop: PropTypes.bool,
    titleTop: PropTypes.string,
    href: PropTypes.string,
}

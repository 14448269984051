import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { translate, Translate } from 'react-i18nify'

import { diffProfileItems } from '../../../store/actions/user'
import ButtonPanel from '../../Button/ButtonPanel'
import { Col, Row } from 'react-grid-system'
import Tag from '../../Tag/Tag'
import Panel from '../../Panel/Panel'
import SchoolSubjectsModal from './SchoolSubjectModal'
import Suggestion from '../../Suggestion/Suggestion'

class SchoolSubjectsPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            errors: [],
            addSubjects: false,
        }
    }

    removeSubject(id) {
        this.props.dispatch(
            diffProfileItems(
                'school_subjects',
                [],
                [id],
                this.props.auth,
                () => {
                    console.log('deleted account field')
                },
                () => {
                    console.error('failed to deleted')
                },
            ),
        )
    }

    render() {
        let school_subjects = this.props.user.profile.school_subjects
        if (school_subjects && school_subjects.length > 0) {
            return (
                <Panel
                    className={
                        school_subjects.length > 10
                            ? 'panel--expand panel--multi__inner tags'
                            : 'panel--multi__inner'
                    }
                    headerText={translate('profile.school_subjects.title')}
                    headerIcon="icon-graduation"
                    addButton={
                        <ButtonPanel
                            buttonText={translate('profile.add_new')}
                            className="button--cta"
                            onClick={() => {
                                this.setState({
                                    addSubjects: true,
                                })
                            }}
                        />
                    }
                >
                    <div className="panel__form">
                        <Row>
                            <Col sm={12}>
                                <div className="panel__tags">
                                    {school_subjects.map((subject) => {
                                        return (
                                            <Tag
                                                key={subject.id}
                                                onRemove={() => {
                                                    this.removeSubject(subject.id)
                                                }}
                                                title={subject.school_subject_name}
                                            >
                                                {subject.school_subject_name +
                                                    ' - ' +
                                                    subject.school_subject_level_name}
                                            </Tag>
                                        )
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </div>

                    {this.state.addSubjects && (
                        <SchoolSubjectsModal
                            school_subjects={school_subjects}
                            onSave={() => {
                                this.setState({ addSubjects: false })
                            }}
                            onCancel={() => {
                                this.setState({ addSubjects: false })
                            }}
                        />
                    )}
                </Panel>
            )
        } else {
            return (
                <Panel
                    className="panel--suggestion panel--multi__inner"
                    headerText={translate('profile.school_subjects.title')}
                    headerIcon="icon-badge"
                    addButton={
                        <ButtonPanel
                            buttonText={translate('profile.add_new')}
                            className="button--cta"
                            onClick={() => {
                                this.setState({
                                    addSubjects: true,
                                })
                            }}
                        />
                    }
                >
                    <Suggestion iconName="icon-badge">
                        <Translate value="profile.school_subjects.no_school_subjects" />
                    </Suggestion>

                    {this.state.addSubjects && (
                        <SchoolSubjectsModal
                            school_subjects={school_subjects}
                            onSave={() => {
                                this.setState({ addSubjects: false })
                            }}
                            onCancel={() => {
                                this.setState({ addSubjects: false })
                            }}
                        />
                    )}
                </Panel>
            )
        }
    }
}

SchoolSubjectsPanel.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    const { auth, user } = state

    return {
        auth,
        user,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(SchoolSubjectsPanel)

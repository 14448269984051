import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Container, Row } from 'react-grid-system'
import { translate, Translate } from 'react-i18nify'
import { addClass, removeClass } from '../../helpers'

import Alert from '../Alert/Alert'

export default class Form2FA extends Component {
    constructor(props) {
        super(props)

        this.handlesmsTokenChange = this.handlesmsTokenChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.state = { smsToken: '' }
    }

    handlesmsTokenChange(event) {
        this.setState({ smsToken: event.target.value })

        if (event.target.value && event.target.value.length > 0) {
            addClass(event.target, 'has-value')
        } else {
            removeClass(event.target, 'has-value')
        }
    }

    handleSubmit(event) {
        event.preventDefault()
        this.props.onSubmit(this.state.smsToken)
    }

    render() {
        const { className, onSubmit, errorMessage, isFetching } = this.props
        return (
            <form className={`form--login`} onSubmit={this.handleSubmit}>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={6} xl={6}>
                            <div className="info_block">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: translate('login.intro_text'),
                                    }}
                                />

                                <img className={'img-smiley-success'} />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} xl={6}>
                            <div className="form__content">
                                <h1>
                                    <Translate value="login.header" />
                                </h1>

                                <div>
                                    <p
                                        className={
                                            errorMessage && errorMessage.length > 0
                                                ? 'is-hidden--mobile'
                                                : ''
                                        }
                                    >
                                        <Translate value="login.header_text" />
                                    </p>
                                </div>

                                {errorMessage && errorMessage.length > 0 && (
                                    <Alert alertText={errorMessage} type="alert--error" />
                                )}

                                <fieldset className="form__fieldset">
                                    <div className="form__item">
                                        <i className="form__icon icons icon-envelope" />
                                        <input
                                            className="form__input--text"
                                            type="smsToken"
                                            id="smsToken"
                                            onChange={this.handlesmsTokenChange}
                                            tabIndex="1"
                                            autoFocus
                                        />
                                        <label className="form__label" htmlFor="smsToken">
                                            <Translate value="labels.smsToken" />
                                        </label>
                                    </div>
                                </fieldset>

                                <div className="form__button_container">
                                    <button
                                        type="submit"
                                        className={
                                            'button--alt' + (isFetching ? ' is-loading' : '')
                                        }
                                        tabIndex="3"
                                    >
                                        {translate('profile.verify')}
                                    </button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="info_block_xs">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: translate('login.intro_text'),
                                    }}
                                />

                                <img className={'img-smiley-success'} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </form>
        )
    }
}

Form2FA.propTypes = {
    className: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    isFetching: PropTypes.bool,
}

import React, { Component } from 'react'
import ModalForm from '../../Modal/ModalForm'
import { translate, Translate } from 'react-i18nify'
import FormDiplomas from '../../Forms/FormDiplomas'
import Panel from '../../Panel/Panel'
import ButtonPanel from '../../Button/ButtonPanel'
import Loading from '../../Loading/Loading'
import DiplomaTable from '../../DiplomaTable/DiplomaTable'
import Suggestion from '../../Suggestion/Suggestion'
import DiplomaSelfEval from '../../DiplomaSelfEval/DiplomaSelfEval'
import { connect } from 'react-redux'
import { fetchProfile } from '../../../store/actions/user'

class DiplomasPanel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            addDiploma: false,
            selectedWorkProcess: null,
            selectedDiploma: null,
        }
    }

    getDiplomasModal(diplomas = []) {
        return (
            <ModalForm
                title={translate('profile.add_diplomas')}
                show
                onClose={() => {
                    this.setState({
                        addDiploma: false,
                    })
                }}
            >
                <FormDiplomas
                    userId={this.props.user.profile.id}
                    diplomas={diplomas}
                    className="form"
                    onSaveEnd={() => this.setState({ loading: false })}
                    onSaveStart={() => this.setState({ loading: true })}
                    onSave={() => {
                        this.setState({
                            addDiploma: false,
                        })
                        this.props.dispatch(
                            fetchProfile(
                                this.props.auth,
                                () => {},
                                () => {},
                            ),
                        )
                        this.props.showAlert()
                    }}
                    onCancel={() => {
                        this.setState({
                            addDiploma: false,
                        })
                    }}
                    dispatch={this.props.dispatch}
                />
            </ModalForm>
        )
    }

    getSelfEvalModal() {
        return (
            <div id="DiplomaSelfEval">
                <ModalForm
                    title={this.state.selectedDiploma.title}
                    show
                    onClose={() => {
                        this.setState({
                            selectedWorkProcess: null,
                            selectedDiploma: null,
                        })
                    }}
                    size={'large'}
                >
                    <DiplomaSelfEval
                        workProcess={this.state.selectedWorkProcess}
                        diploma={this.state.selectedDiploma}
                        user={this.props.user}
                        onUpdated={() => {
                            this.setState({
                                selectedWorkProcess: null,
                            })
                            this.props.updateProfile()
                        }}
                        onCancel={() => {
                            this.setState({
                                selectedWorkProcess: null,
                            })
                        }}
                    />
                </ModalForm>
            </div>
        )
    }

    render() {
        let { diplomas, evaluations } = this.props.user.profile

        if (diplomas != null && diplomas.length > 0) {
            return (
                <Panel
                    className="panel--multi__inner"
                    headerText={translate('profile.diplomas')}
                    headerIcon="icon-notebook"
                    addButton={
                        <ButtonPanel
                            buttonText={translate('profile.add_new')}
                            className="button--cta"
                            onClick={() => {
                                this.setState({ addDiploma: true })
                            }}
                        />
                    }
                >
                    <p>
                        <Translate value="profile.control_feedback_status" />
                    </p>
                    {this.state.loading ? (
                        <Loading />
                    ) : (
                        <DiplomaTable
                            onUpdated={() => this.props.updateProfile()}
                            onSelected={(workProcess, diploma) =>
                                this.setState({
                                    selectedWorkProcess: workProcess,
                                    selectedDiploma: diploma,
                                })
                            }
                            selectedWorkProcess={this.state.selectedWorkProcess}
                            diplomas={diplomas}
                            evaluations={evaluations}
                        />
                    )}
                    {this.state.addDiploma && this.getDiplomasModal(diplomas)}
                    {!!this.state.selectedWorkProcess && this.getSelfEvalModal(diplomas)}
                </Panel>
            )
        } else {
            return (
                <Panel
                    className="panel--suggestion panel--multi__inner"
                    headerText={translate('profile.diplomas')}
                    headerIcon="icon-notebook"
                    addButton={
                        <ButtonPanel
                            buttonText={translate('profile.add_new')}
                            className="button--cta"
                            onClick={() => {
                                this.setState({ addDiploma: true })
                            }}
                        />
                    }
                >
                    <Suggestion iconName="icon-notebook">
                        <Translate value="profile.no_diplomas" />
                    </Suggestion>

                    {this.state.addDiploma && this.getDiplomasModal()}
                </Panel>
            )
        }
    }
}

function mapStateToProps(state) {
    const { auth, config, user } = state

    return {
        auth,
        config,
        user,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(DiplomasPanel)

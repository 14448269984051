import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { newCallApi } from '../store/actions/api'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'react-grid-system'
import callingCountries from 'country-telephone-data'
import { Link } from 'react-router-dom'
import { translate, Translate } from 'react-i18nify'
import { addClass, removeClass } from '../helpers'
import Actions from '../components/Actions/Actions'
import Alert from '../components/Alert/Alert'
import Loading from '../components/Loading/Loading'
import Panel from '../components/Panel/Panel'
import { fetchProfile } from '../store/actions/user'
import history from '../history'
import { PATH_PROFILE } from '../constants/Constants'
import {
    ALL_REQUIREMENTS,
    checkPasswordRequirements,
    PasswordRequirements,
} from '../components/PasswordRequirements/PasswordRequirements'

class Security extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            loadingPassword: false,
            country_code: 31,
            phone_number: '',
            twoFactorSent: false,
            twoFactorErrors: null,
            current_password: '',
            new_password: '',
            repeat_password: '',
            twoFactorToken: '',
        }
    }

    componentDidMount() {
        this.updateProfileInfo()
    }

    updateProfileInfo() {
        this.setState({ loading: true, loadingPassword: true })
        this.props.dispatch(
            fetchProfile(
                null,
                (status, error) => {
                    console.log('Got fetch profile error', status, error)
                },
                () => {
                    this.setState({ loading: false, loadingPassword: false })
                },
            ),
        )
    }

    save() {
        this.setState({ loadingPassword: true, passwordChanged: 0 })

        if (this.state.current_password.length > 0) {
            if (
                !checkPasswordRequirements(
                    this.state.current_password,
                    this.state.new_password,
                    this.state.repeat_password,
                    ALL_REQUIREMENTS,
                )
            ) {
                this.setState({
                    passwordErrors: translate('errors.password_requirements_not_met'),
                    passwordChanged: 0,
                })
                this.setState({ loadingPassword: false })
            } else {
                newCallApi('POST', this.props.config.API_CHANGE_PASSWORD, {
                    old_password: this.state.current_password,
                    password: this.state.new_password,
                    password_confirmation: this.state.repeat_password,
                })
                    .then((json) => {
                        this.setState({ loadingPassword: false })
                        this.setState({
                            passwordChanged: 1,
                            passwordErrors: null,
                            current_password: '',
                            new_password: '',
                            repeat_password: '',
                        })
                    })
                    .catch((json) => {
                        this.setState({
                            current_password: '',
                            new_password: '',
                            repeat_password: '',
                        })
                        if (json && json.errors) {
                            this.setState({
                                passwordErrors: json.errors[0].message,
                                passwordChanged: 0,
                            })
                        }
                        this.setState({ loadingPassword: false })
                    })
            }
        } else {
            this.setState({ passwordErrors: translate('errors.empty_field'), passwordChanged: 0 })
            this.setState({ loadingPassword: false })
        }
    }

    onSuccess() {
        this.updateProfileInfo()
        this.setState({
            country_code: 31,
            passwordErrors: '',
            phone_number: '',
            current_password: '',
            twoFactorToken: '',
            passwordChanged: 0,
            new_password: '',
            repeat_password: '',
            loading: false,
            loadingPassword: false,
            twoFactorSent: false,
        })
    }

    getSaveClasses(password = false) {
        let c = 'actions__action--save'
        if (password) {
            if (this.state.loadingPassword) {
                c += ' is-loading'
            }
        } else {
            if (this.state.loading) {
                c += ' is-loading'
            }
        }
        return c
    }

    getClassNames(item) {
        if (item != null && item != '' && item.length > 0) {
            return 'form__input--text has-value'
        } else {
            return 'form__input--text'
        }
    }

    handleChange(value, key, changeClass = true) {
        let obj = {}
        obj[key] = value.target.value
        this.setState(obj)
        if (changeClass) {
            if (value.target.value.length > 0) {
                addClass(value.currentTarget, 'has-value')
            } else {
                removeClass(value.currentTarget, 'has-value')
            }
        }
    }

    cancel2Fa() {
        this.setState({ twoFactorSent: false })
    }

    verify2Fa() {
        this.setState({ loading: true })
        newCallApi('POST', this.props.config.API_ENABLE_2FA, {
            token: this.state.twoFactorToken,
        })
            .then(() => {
                this.onSuccess()
            })
            .catch((json) => {
                if (json && json.errors) {
                    this.setState({ twoFactorSent: true, twoFactorErrors: json.errors })
                }
                this.setState({ loading: false })
            })
    }

    setupTwoFactorAuthentication() {
        this.setState({ loading: true })
        newCallApi('POST', this.props.config.API_CONFIGURE_2FA, {
            cellphone: this.state.phone_number,
            country_code: this.state.country_code,
        })
            .then(() => {
                this.setState({ twoFactorSent: true, twoFactorErrors: [] })
            })
            .catch((json) => {
                if (json && json.errors) {
                    this.setState({ twoFactorErrors: json.errors })
                }
            })
            .then(() => this.setState({ loading: false }))
    }

    removeTwoFactorAuthentication() {
        this.setState({ loading: true })
        newCallApi('DELETE', this.props.config.API_2FA)
            .then(() => {
                this.onSuccess()
            })
            .catch((json) => {
                if (json && json.errors) {
                    this.setState({ twoFactorErrors: json.errors })
                }
                this.setState({ loading: false })
            })
    }

    render() {
        const { dispatch } = this.props
        return (
            <div className="react-native-web">
                <Container>
                    <Row>
                        <Col sm={12} md={6} xl={6}>
                            <Panel
                                className="panel--security"
                                headerText={translate('buttons.change_password')}
                                headerIcon="icon-lock"
                            >
                                <form
                                    className="form"
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                        return false
                                    }}
                                >
                                    {this.state.loadingPassword && <Loading />}

                                    {!this.state.loadingPassword && (
                                        <div>
                                            {this.state.passwordErrors && (
                                                <Alert type="alert--error alert--simple">
                                                    <div>{this.state.passwordErrors}</div>
                                                </Alert>
                                            )}
                                            <fieldset
                                                onKeyDown={(e) => {
                                                    if (e.keyCode === 13) {
                                                        this.save()
                                                    }
                                                }}
                                                className="form__fieldset--single"
                                            >
                                                <div className="form__item">
                                                    <i className="form__icon icons icon-key" />
                                                    <input
                                                        className={this.getClassNames(
                                                            this.state.current_password,
                                                        )}
                                                        type="password"
                                                        autoComplete="current-password"
                                                        id="current_password"
                                                        value={this.state.current_password}
                                                        onChange={(value) =>
                                                            this.handleChange(
                                                                value,
                                                                'current_password',
                                                            )
                                                        }
                                                    />
                                                    <label
                                                        className="form__label"
                                                        htmlFor="current_password"
                                                    >
                                                        <Translate value="profile.current_password" />
                                                    </label>
                                                </div>
                                                <div className="form__item">
                                                    <input
                                                        className={this.getClassNames(
                                                            this.state.new_password,
                                                        )}
                                                        type="password"
                                                        autoComplete="new-password"
                                                        id="new_password"
                                                        value={this.state.new_password}
                                                        onChange={(value) =>
                                                            this.handleChange(value, 'new_password')
                                                        }
                                                    />
                                                    <label
                                                        className="form__label"
                                                        htmlFor="new_password"
                                                    >
                                                        <Translate value="profile.new_password" />
                                                    </label>
                                                </div>
                                                <div className="form__item">
                                                    <input
                                                        className={this.getClassNames(
                                                            this.state.repeat_password,
                                                        )}
                                                        type="password"
                                                        autoComplete="new-password"
                                                        id="repeat_password"
                                                        value={this.state.repeat_password}
                                                        onChange={(value) =>
                                                            this.handleChange(
                                                                value,
                                                                'repeat_password',
                                                            )
                                                        }
                                                    />
                                                    <label
                                                        className="form__label"
                                                        htmlFor="repeat_password"
                                                    >
                                                        <Translate value="profile.repeat_password" />
                                                    </label>
                                                </div>

                                                <PasswordRequirements
                                                    current_password={this.state.current_password}
                                                    new_password={this.state.new_password}
                                                    repeat_password={this.state.repeat_password}
                                                />

                                                <div className="form__buttons">
                                                    <Link
                                                        className="button--cta "
                                                        onClick={() => this.save()}
                                                    >
                                                        <Translate value="profile.save" />
                                                    </Link>
                                                </div>
                                            </fieldset>
                                        </div>
                                    )}
                                </form>
                            </Panel>
                        </Col>
                        <Col sm={12} md={6} xl={6}>
                            <Panel
                                className="panel--security"
                                headerText={translate('buttons.2fa')}
                                headerIcon="icon-lock"
                            >
                                <form
                                    className="form"
                                    onSubmit={(e) => {
                                        e.preventDefault()
                                        return false
                                    }}
                                >
                                    {this.state.loading && <Loading />}

                                    {!this.state.loading && (
                                        <div>
                                            {this.state.twoFactorSent ? (
                                                <div>
                                                    {this.state.twoFactorErrors && (
                                                        <Alert type="alert--error alert--simple">
                                                            <ul>
                                                                {this.state.twoFactorErrors.map(
                                                                    (error, index) => {
                                                                        return (
                                                                            <li
                                                                                key={
                                                                                    'error_' + index
                                                                                }
                                                                            >
                                                                                {error.message}
                                                                            </li>
                                                                        )
                                                                    },
                                                                )}
                                                            </ul>
                                                        </Alert>
                                                    )}

                                                    <fieldset
                                                        onKeyDown={(e) => {
                                                            if (e.keyCode === 13) {
                                                                this.verify2Fa()
                                                            }
                                                        }}
                                                        className="form__fieldset"
                                                    >
                                                        <h2>
                                                            <Translate value="buttons.2fa_verify" />
                                                        </h2>
                                                        <p>
                                                            <Translate value="profile.2fa_message" />
                                                        </p>
                                                        <div className="form__item">
                                                            <i className="form__icon icons icon-key" />
                                                            <input
                                                                className={this.getClassNames(
                                                                    this.state.twoFactorToken,
                                                                )}
                                                                type="tel"
                                                                id="twoFactorToken"
                                                                value={this.state.twoFactorToken}
                                                                onChange={(value) =>
                                                                    this.handleChange(
                                                                        value,
                                                                        'twoFactorToken',
                                                                    )
                                                                }
                                                                autoFocus={true}
                                                            />
                                                            <label
                                                                className="form__label"
                                                                htmlFor="twoFactorToken"
                                                            >
                                                                <Translate value="profile.twoFactorToken" />
                                                            </label>
                                                        </div>
                                                    </fieldset>

                                                    <Actions>
                                                        <Link
                                                            className="actions__action--cancel"
                                                            onClick={() => this.cancel2Fa()}
                                                        >
                                                            <Translate value="profile.cancel" />
                                                        </Link>
                                                        <Link
                                                            className={this.getSaveClasses()}
                                                            onClick={() => this.verify2Fa()}
                                                        >
                                                            <Translate value="profile.verify" />
                                                        </Link>
                                                    </Actions>
                                                </div>
                                            ) : (
                                                <div>
                                                    {this.props.user.profile['2fa_enabled'] ? (
                                                        <div>
                                                            <fieldset className="form__fieldset">
                                                                <p className="form--intro">
                                                                    <Translate value="profile.2fa_enabled" />
                                                                </p>
                                                                <div className="actions">
                                                                    <Link
                                                                        className="actions__action--cancel"
                                                                        onClick={() =>
                                                                            this.removeTwoFactorAuthentication()
                                                                        }
                                                                    >
                                                                        <Translate value="profile.remove_2fa" />
                                                                    </Link>
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <fieldset
                                                                onKeyDown={(e) => {
                                                                    if (e.keyCode === 13) {
                                                                        this.setupTwoFactorAuthentication()
                                                                    }
                                                                }}
                                                                className="form__fieldset--single"
                                                            >
                                                                <div className="form__item">
                                                                    <i className="form__icon icons icon-globe" />
                                                                    <div className="form__select">
                                                                        <select
                                                                            value={
                                                                                this.state
                                                                                    .country_code ||
                                                                                ''
                                                                            }
                                                                            className="form__input--select"
                                                                            onChange={(value) =>
                                                                                this.handleChange(
                                                                                    value,
                                                                                    'country_code',
                                                                                    false,
                                                                                )
                                                                            }
                                                                        >
                                                                            {callingCountries.allCountries.map(
                                                                                (element) => {
                                                                                    return (
                                                                                        <option
                                                                                            key={
                                                                                                'country_' +
                                                                                                element.dialCode +
                                                                                                element.iso2
                                                                                            }
                                                                                            value={
                                                                                                element.dialCode
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                element.name
                                                                                            }{' '}
                                                                                            (+
                                                                                            {
                                                                                                element.dialCode
                                                                                            }
                                                                                            )
                                                                                        </option>
                                                                                    )
                                                                                },
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                    <label
                                                                        className="form__label"
                                                                        htmlFor="country_code"
                                                                    >
                                                                        <Translate value="profile.country_code" />
                                                                    </label>
                                                                </div>
                                                                <div className="form__item">
                                                                    <i className="form__icon icons icon-call-end" />
                                                                    <input
                                                                        className={this.getClassNames(
                                                                            this.state.phone_number,
                                                                        )}
                                                                        type="tel"
                                                                        id="phone_number"
                                                                        value={
                                                                            this.state.phone_number
                                                                        }
                                                                        onChange={(value) =>
                                                                            this.handleChange(
                                                                                value,
                                                                                'phone_number',
                                                                            )
                                                                        }
                                                                    />
                                                                    <label
                                                                        className="form__label"
                                                                        htmlFor="phone_number"
                                                                    >
                                                                        <Translate value="profile.phone_number" />
                                                                    </label>
                                                                </div>

                                                                {this.state.twoFactorErrors && (
                                                                    <Alert type="alert--error alert--simple">
                                                                        <ul>
                                                                            {this.state.twoFactorErrors.map(
                                                                                (error, index) => {
                                                                                    return (
                                                                                        <li
                                                                                            key={
                                                                                                'error_' +
                                                                                                index
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                error.message
                                                                                            }
                                                                                        </li>
                                                                                    )
                                                                                },
                                                                            )}
                                                                        </ul>
                                                                    </Alert>
                                                                )}

                                                                <div className="form__buttons">
                                                                    <Link
                                                                        className="button--cta"
                                                                        onClick={() =>
                                                                            this.setupTwoFactorAuthentication()
                                                                        }
                                                                    >
                                                                        <Translate value="profile.setup_2fa" />
                                                                    </Link>
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </form>
                            </Panel>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

Security.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        config,
        user,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(Security)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { connect } from 'react-redux'

import Actions from '../Actions/Actions'

import { addClass, formatDate, getErrorFields, getErrorMessages, removeClass } from '../../helpers'
import { translate, Translate } from 'react-i18nify'

import { editProfileItem } from '../../store/actions/user'

import Alert from '../Alert/Alert'
import 'react-dates/lib/css/_datepicker.css'
import SingleDatePicker from 'react-dates'
import newCallApi from '../../store/actions/api'

let map

var moment = require('moment')

class FormAccountFields extends Component {
    constructor(props) {
        super(props)

        let vals = {}
        for (let i = 0; i < (this.props.user.account_field_names || []).length; i++) {
            vals[this.props.user.account_field_names[i].code] = ''
        }
        let fieldValues = this.props.user.profile.account_fields || []
        for (let i = 0; i < fieldValues.length; i++) {
            vals[fieldValues[i].code] = fieldValues[i].value
        }

        let form_profile_extra_info =
            props.config && props.config.whitelabel.form_profile_extra_info
        if (form_profile_extra_info && form_profile_extra_info.length > 0) {
            form_profile_extra_info.map((field) => {
                delete vals[field]
                fieldValues = fieldValues.filter((val) => val.code !== field)
            })
        }

        this.state = {
            error: '',
            loading: false,
            errors: [],
            fields: [],
            account_fields: fieldValues,
            vals: vals,
        }
    }

    componentWillMount() {}

    handleChange(value, key, changeClass = true) {
        let currentVals = this.state.vals
        let currentValue = null
        if (value.target && value.target.value) {
            currentValue = value.target.value
        }
        currentVals[key] = currentValue

        if (!currentValue) {
            if (value.target.checked) {
                currentVals[key] = true
            } else {
                currentVals[key] = ''
            }
        }

        this.setState({ vals: currentVals })

        if (changeClass) {
            if (currentValue && currentValue.toString().length > 0) {
                addClass(value.currentTarget, 'has-value')
            } else {
                removeClass(value.currentTarget, 'has-value')
            }
        }
    }

    handleDateChange(value, key) {
        let currentVals = this.state.vals

        if (value && typeof value.format === 'function') {
            currentVals[key] = value.format('YYYY-MM-DD')
        } else if (!value) {
            // If you cleared the input, send an empty string
            currentVals[key] = value
        }

        this.setState({ vals: currentVals })
    }

    handleCheck(value, key, changeClass = true) {
        let currentVals = this.state.vals

        currentVals[key] = value.target.checked

        this.setState({ vals: currentVals })

        if (changeClass) {
            if (value.target.value.toString().length > 0) {
                addClass(value.currentTarget, 'has-value')
            } else {
                removeClass(value.currentTarget, 'has-value')
            }
        }
    }

    getClassNames(item) {
        if (item != null && item != '' && item.toString().length > 0) {
            return 'form__input--text has-value'
        } else {
            return 'form__input--text'
        }
    }

    save() {
        this.setState({
            loading: true,
            errors: [],
        })
        let update = []
        for (let i = 0; i < Object.keys(this.state.vals).length; i++) {
            update.push({
                code: Object.keys(this.state.vals)[i],
                value: this.state.vals[Object.keys(this.state.vals)[i]],
            })
        }
        const complete = () =>
            this.props.dispatch(
                editProfileItem(
                    'fields',
                    {
                        account_fields: update,
                    },
                    this.props.auth,
                    () => this.onSuccess(),
                    (status, error) => this.onError(status, error),
                ),
            )
        const bigNumbers = update.filter((item) => item.code === 'big_number')
        if (bigNumbers.length && !!bigNumbers[0].value) {
            newCallApi('POST', this.props.config.API_BIG_CHECK, {
                big: bigNumbers[0].value,
            })
                .then((bigInfo) => {
                    if (bigInfo.result) {
                        complete()
                    } else {
                        this.onError(404, { status: 404, errors: [{ message: bigInfo.message }] })
                        this.setState({ loading: false })
                        const vals = this.state.vals
                        vals['big_number'] = ''
                        this.setState({ vals })
                    }
                })
                .catch((error) => {
                    this.onError(error.status, error)
                    this.setState({ loading: false })
                    const vals = this.state.vals
                    vals['big_number'] = ''
                    this.setState({ vals })
                })
        } else {
            complete()
        }
    }

    onError(status, object) {
        let map = {}

        this.setState(
            {
                errors: [],
                fields: [],
                loading: false,
            },
            () => {
                if (object.hasOwnProperty('errors')) {
                    let messages = getErrorMessages(object.errors, map)
                    let fields = getErrorFields(object.errors)

                    this.addErrors(messages)
                    this.addErrorFields(fields)
                }
            },
        )
    }

    addErrors(errors) {
        this.setState({
            errors: errors,
        })
    }

    addErrorFields(fields) {
        this.setState({
            fields: fields,
        })
    }

    getClassNames(item, field) {
        let c = 'form__input--text'

        if (item != null && item != '' && item.toString().length > 0) {
            c += ' has-value'
        }

        if (this.state.fields.indexOf(field) != -1) {
            c += ' error'
        }

        return c
    }

    checkValue(val) {
        if (val == null || val == undefined || val.length < 1) {
            return null
        } else {
            return val
        }
    }

    onSuccess() {
        this.state = { errors: [], fields: [], vals: [], error: '', loading: false }
        this.props.onSave()
    }

    cancel() {
        this.state = { errors: [], fields: [], vals: [], error: '', loading: false }
        this.props.onCancel()
    }

    getSaveClasses() {
        let c = 'actions__action--save'

        if (this.state.loading) {
            c += ' is-loading'
        }

        return c
    }

    render() {
        const { className, onCancel, onSave, dispatch } = this.props

        return (
            <form
                className={this.props.className}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        //Enter pressed
                        this.save()
                    }
                }}
            >
                <fieldset className="form__fieldset">
                    {this.state.errors && this.state.errors.toString().length > 0 && (
                        <Alert type="alert--error alert--simple">
                            <ul>
                                {this.state.errors.map(function (value) {
                                    return <li key={value}>{value}</li>
                                })}
                            </ul>
                        </Alert>
                    )}

                    <div>
                        {this.state.account_fields.map((item) => {
                            switch (item.type) {
                                case 'int':
                                    return (
                                        <div className="form__item" key={item.id}>
                                            {/*<i className="form__icon icons icon-info" />*/}
                                            <input
                                                value={this.state.vals[item.code]}
                                                onChange={(value) =>
                                                    this.handleChange(value, item.code)
                                                }
                                                className={this.getClassNames(
                                                    this.state.vals[item.code],
                                                    item.code,
                                                )}
                                                type="number"
                                                id={item.code}
                                            />
                                            <label className="form__label" htmlFor={item.code}>
                                                {item.name}
                                            </label>
                                        </div>
                                    )
                                    break
                                case 'boolean':
                                    return (
                                        <div className="form__item--checkbox" key={item.id}>
                                            <div className="form__checkbox" key={item.id}>
                                                <input
                                                    onChange={(value) =>
                                                        this.handleCheck(value, item.code)
                                                    }
                                                    className="form__checkbox__input"
                                                    type="checkbox"
                                                    id={item.code}
                                                    checked={
                                                        this.state.vals[item.code] == true
                                                            ? 'checked'
                                                            : ''
                                                    }
                                                />
                                                <label
                                                    className="form__checkbox__label"
                                                    htmlFor={item.code}
                                                >
                                                    {item.name}
                                                </label>
                                            </div>
                                        </div>
                                    )
                                    break
                                case 'datetime':
                                case 'date':
                                    return (
                                        <div className="form__item--datepicker" key={item.id}>
                                            <i className="form__icon icons icon-calendar" />
                                            <label
                                                className="form__checkbox__label"
                                                htmlFor={item.code}
                                            >
                                                {item.name}
                                            </label>
                                            <SingleDatePicker
                                                displayFormat="DD-MM-YYYY"
                                                date={
                                                    !this.state.vals[item.code] ||
                                                    this.state.vals[item.code] == '0000-00-00' ||
                                                    formatDate(this.state.vals[item.code]) ==
                                                        'NaN-NaN-NaN'
                                                        ? null
                                                        : moment(this.state.vals[item.code])
                                                }
                                                onDateChange={(date) =>
                                                    this.handleDateChange(date, item.code)
                                                }
                                                className={this.getClassNames(
                                                    this.state.vals[item.code],
                                                    'date_of_birth',
                                                )}
                                                tabIndex={13}
                                                disabledKeyboardNavigation
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                locale="nl"
                                                placeholderText={translate('profile.datepicker')}
                                            />
                                        </div>
                                    )
                                    break
                                default:
                                    return (
                                        <div className="form__item" key={item.id}>
                                            {/*<i className="form__icon icons icon-info" />*/}
                                            <input
                                                value={this.state.vals[item.code]}
                                                onChange={(value) =>
                                                    this.handleChange(value, item.code)
                                                }
                                                className={this.getClassNames(
                                                    this.state.vals[item.code],
                                                    item.code,
                                                )}
                                                type="text"
                                                id={item.code}
                                            />
                                            <label className="form__label" htmlFor={item.code}>
                                                {item.name}
                                            </label>
                                        </div>
                                    )
                                    break
                            }
                        })}
                    </div>
                </fieldset>

                <Actions>
                    <Link
                        className="actions__action--cancel"
                        onClick={() => this.cancel()}
                        tabIndex="14"
                    >
                        <Translate value="profile.cancel" />
                    </Link>
                    <Link
                        className={this.getSaveClasses()}
                        onClick={() => this.save()}
                        tabIndex="15"
                    >
                        <Translate value="profile.save" />
                    </Link>
                </Actions>
            </form>
        )
    }
}

FormAccountFields.propTypes = {
    className: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(FormAccountFields)

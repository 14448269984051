import React, { Component } from 'react'
import Panel from '../../Panel/Panel'
import { connect } from 'react-redux'
import { translate } from 'react-i18nify'
import ButtonPanel from '../../Button/ButtonPanel'

class OpenBadgesPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
        }
    }

    render() {
        return (
            <div>
                <Panel className="panel--multi">
                    <Panel
                        headerText={translate('profile.open_badges.title')}
                        className="panel--multi__inner"
                        headerIcon="icon-badge"
                    >
                        <iframe
                            width="100%"
                            height="500px"
                            frameBorder="none"
                            src={
                                this.props.config.whitelabel.open_badges_iframe_url +
                                this.props.user.profile.open_badges_id
                            }
                        />
                        <div className={'spacer'} />
                    </Panel>
                </Panel>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth, config, user } = state

    return {
        auth,
        config,
        user,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(OpenBadgesPage)

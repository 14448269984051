import React from 'react'
import PropTypes from 'prop-types'
import { translate, Translate } from 'react-i18nify'
import Modal from '../Modal/Modal'
import { Radar } from 'react-chartjs-2'
import { formatDiploma } from '../../helpers'

function Graph(props) {
    const getGraphData = () => {
        const diploma = props.diploma
        const types = ['student', 'teacher', 'mentor', 'coach', 'recipient', 'other_student']
        const GRAPH_COLORS = {
            student: 'rgba(115,92,197,1)',
            teacher: 'rgba(74,171,226,1)',
            mentor: 'rgba(3,78,162,1)',
            coach: 'rgba(74,172,188,1)',
            recipient: 'rgba(255,84,39,1)',
            other_student: 'rgba(247,183,50,1)',
        }
        const workProcesses = formatDiploma(diploma).workProcesses

        const diplomaEvaluations = props.evaluations.filter(
            (evaluation) => evaluation.diploma_id === diploma.diploma_id,
        )
        let evaluationsByType = {}
        types.forEach((type) => {
            evaluationsByType[type] = diplomaEvaluations.filter(
                (evaluation) => evaluation.evaluator_role.name === type && !evaluation.pending,
            )
        })

        let dataPointsByType = {}
        Object.keys(evaluationsByType).forEach((key) => (dataPointsByType[key] = []))
        Object.keys(evaluationsByType).forEach((key) =>
            workProcesses.forEach((process) => {
                let evaluations = evaluationsByType[key].filter((evaluation) => {
                    return evaluation.work_process_id === process.work_process_id
                })
                dataPointsByType[key].push(
                    evaluations.reduce((a, b) => a + (b.score || 0), 0) /
                        Math.max(evaluations.length, 1),
                )
            }),
        )
        let data = {
            labels: workProcesses.map((process, index) => index + 1),
            datasets: [],
        }
        Object.keys(evaluationsByType).forEach((type) => {
            data.datasets.push({
                label: translate(`diplomas.${type !== 'student' ? type : 'my_evaluation'}`),
                backgroundColor: GRAPH_COLORS[type].replace(',1)', ',0.2)'),
                borderColor: GRAPH_COLORS[type],
                pointBackgroundColor: GRAPH_COLORS[type],
                pointBorderColor: GRAPH_COLORS[type],
                pointHoverBackgroundColor: GRAPH_COLORS[type],
                pointHoverBorderColor: GRAPH_COLORS[type],
                data: dataPointsByType[type],
            })
        })

        return {
            workProcesses: workProcesses,
            diplomaEvaluation: diplomaEvaluations,
            evaluationsByType: evaluationsByType,
            dataPointsByType: dataPointsByType,
            data: data,
        }
    }

    const graphData = getGraphData()

    return (
        <Modal
            hideClose={false}
            show={true}
            title={translate('diplomas.evaluations')}
            onClose={props.onClose}
        >
            <div className="graph__container">
                <div className="legend-help-text">
                    <Translate value="diplomas.legend_help" />
                </div>
                <Radar
                    data={graphData.data}
                    width={1000}
                    height={1000}
                    options={{
                        maintainAspectRatio: true,
                        legend: { position: 'top' },
                        tooltips: { enabled: false },
                        layout: { padding: 4 },
                        scale: {
                            ticks: {
                                max: 3,
                                min: 0,
                                stepSize: 1,
                                callback: function (value, index, values) {
                                    if (value === 1) return translate('diplomas.starter')
                                    if (value === 2) return translate('diplomas.advanced')
                                    if (value === 3) return translate('diplomas.professional')
                                },
                            },
                        },
                    }}
                />
                <table className="panel__table--bordered table">
                    <thead>
                        <tr>
                            <th className="table_cell--index">&nbsp;</th>
                            <th className="text-left">
                                <Translate value="labels.work_process" />
                            </th>
                        </tr>
                    </thead>
                    {graphData.workProcesses.map((process, index) => {
                        return (
                            <tbody key={index + 1}>
                                <tr>
                                    <td>{index + 1}</td>
                                    <td className="text-left">{process.title}</td>
                                </tr>
                            </tbody>
                        )
                    })}
                </table>
            </div>
        </Modal>
    )
}

Graph.propTypes = {
    diploma: PropTypes.object,
    evaluations: PropTypes.array,
    onClose: PropTypes.func,
}

export default Graph

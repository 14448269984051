import React, { Component } from 'react'
import Panel from '../../Panel/Panel'
import { connect } from 'react-redux'
import CompetencesPanel from './CompetencesPanel'
import RolesPanel from './RolesPanel'
import SkillsPanel from './SkillsPanel'
import InterestsPanel from './InterestsPanel'
import TopicsPanel from './TopicsPanel'
import LanguagesPanel from './LanguagesPanel'
import SchoolSubjectsPanel from './SchoolSubjectPanel'

class SkillsPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
        }
    }

    render() {
        return (
            <div>
                <div id="competenciesPanel">
                    <Panel className="panel--multi">
                        {this.props.config.whitelabel.show_school_subjects && (
                            <span>
                                <SchoolSubjectsPanel />
                                <div className="spacer-hr" />
                            </span>
                        )}

                        <RolesPanel showAlert={this.props.showAlert} />
                        <div className="spacer-hr" />

                        <CompetencesPanel showAlert={this.props.showAlert} />
                        <div className="spacer-hr" />

                        <SkillsPanel showAlert={this.props.showAlert} />
                        <div className="spacer-hr" />
                        <InterestsPanel showAlert={this.props.showAlert} />
                        <div className="spacer-hr" />
                        {this.props.config.whitelabel.show_topics && (
                            <span>
                                <TopicsPanel showAlert={this.props.showAlert} />
                                <div className="spacer-hr" />
                            </span>
                        )}
                        <LanguagesPanel showAlert={this.props.showAlert} />
                    </Panel>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth, config } = state

    return {
        auth,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(SkillsPage)

import ReactSimpleRange from 'react-simple-range'
import { Translate } from 'react-i18nify'

export const Slider = ({ translation, ...props }) => {
    return (
        <div className="form__item form__range">
            <ReactSimpleRange
                label={true}
                sliderColor="rgba(0, 23, 48, 0.1)"
                trackColor="#4aabe2"
                thumbColor="#4aabe2"
                tabIndex="2"
                {...props}
            />

            <label className="form__label">
                <Translate value={translation} />
            </label>
        </div>
    )
}

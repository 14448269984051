import React, { Component } from 'react'
import { saveAs } from 'file-saver'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { newCallApi } from '../../../store/actions/api'
import { translate, Translate } from 'react-i18nify'
import Panel from '../../Panel/Panel'
import Suggestion from '../../Suggestion/Suggestion'
import Loading from '../../Loading/Loading'
import Success from '../../FileLister/Success'
import Actions from '../../Actions/Actions'
import ModalWindow from '../../Modal/Modal'
import { ToastContainer } from 'react-toastr'

class TrajectoryDocumentsPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fetchingApprovedDocuments: false,
            fetchingDocument: false,
            successfullyDownloadedFile: false,
            documents: [],
        }

        this.toastContainer = React.createRef()
    }

    componentDidMount() {
        this.getTrajectoryDocuments()
    }

    getTrajectoryDocuments() {
        const endpoint = this.props.config.API_TRAJECTORY_CLIENT_VISIBLE_DOCUMENTS.replace(
            '{account_id}',
            this.props.user.profile.id,
        )

        this.setState({ fetchingApprovedDocuments: true })
        newCallApi('GET', endpoint)
            .then((results) => {
                this.setState({ documents: results, loading: false })
            })
            .finally(() => {
                this.setState({ fetchingApprovedDocuments: false })
            })
    }

    showErrorMessage(title, message) {
        this.toastContainer.current.error(message, title, {
            timeOut: 10000,
            extendedTimeOut: 1000,
        })
    }

    async downloadApprovedDocument(document) {
        const url = this.props.config.API_TRAJECTORY_VERSION_FILE_DOWNLOAD.replace(
            '{trajectory_id}',
            document.trajectory_id,
        )
            .replace('{file_id}', document.file_id)
            .replace('{version_id}', document.id)

        this.setState(
            {
                fetchingDocument: true,
            },
            () => {
                newCallApi('GET', url, null, /* auth */ null, true, true, 'blob')
                    .then((blob) => {
                        this.setState(
                            {
                                successfullyDownloadedFile: true,
                                fetchingDocument: false,
                            },
                            () => {
                                setTimeout(() => {
                                    this.setState({
                                        successfullyDownloadedFile: false,
                                        downloaddoc: undefined,
                                    })
                                }, 5000)
                            },
                        )
                        saveAs(blob, document.name)
                    })
                    .catch((error) => {
                        this.setState({
                            downloaddoc: undefined,
                            successfullyDownloadedFile: false,
                        })
                        this.showErrorMessage('', translate('errors.download_approved_document'))
                    })
            },
        )
    }

    getDownloadModal() {
        return (
            <div>
                <ModalWindow
                    show={!!this.state.downloaddoc}
                    title={translate('profile.approved_documents')}
                    onClose={() =>
                        this.setState({
                            downloaddoc: undefined,
                        })
                    }
                >
                    <div>
                        {this.state.fetchingDocument && (
                            <Loading text={translate('profile.fetching_file')} />
                        )}
                        {this.state.successfullyDownloadedFile && (
                            <Success text={translate('profile.downloading_file')} />
                        )}
                    </div>
                </ModalWindow>
            </div>
        )
    }

    getDocumentApprovalState(document) {
        if (document.client_approval && document.client_approval.requested) {
            if (document.client_approval.approved === 1) {
                return translate('profile.document_approved')
            }
            if (document.client_approval.approved === 0) {
                return translate('profile.document_rejected')
            } else {
                return translate('profile.document_pending')
            }
        } else {
            return translate('profile.document_informative')
        }
    }

    render() {
        const approved_documents = this.state.documents

        if (approved_documents.length > 0) {
            return (
                <div>
                    <Panel
                        className="panel--multi__inner"
                        headerText={translate('profile.trajectory_documents')}
                        headerIcon="icon-cloud-download"
                    >
                        <table className="panel__table">
                            <tbody>
                                {approved_documents.map((downloadDocument) => (
                                    <tr key={downloadDocument.id}>
                                        <td>
                                            {downloadDocument.name} (
                                            {this.getDocumentApprovalState(downloadDocument)})
                                        </td>
                                        <td>
                                            <Actions>
                                                <Link
                                                    className="actions__action--download"
                                                    onClick={() => {
                                                        this.setState({
                                                            downloaddoc: downloadDocument.name,
                                                        })
                                                        this.downloadApprovedDocument(
                                                            downloadDocument,
                                                        )
                                                    }}
                                                >
                                                    {translate('profile.download')}
                                                </Link>
                                            </Actions>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Panel>
                    {this.getDownloadModal()}
                    <ToastContainer ref={this.toastContainer} className="toast-bottom-right" />
                </div>
            )
        } else {
            return (
                <div>
                    <Panel
                        className="panel--multi__inner"
                        headerText={translate('profile.trajectory_documents')}
                        headerIcon="icon-cloud-download"
                    ></Panel>
                    {this.state.fetchingApprovedDocuments ? (
                        <p className="is-loading--center--small" />
                    ) : (
                        <Suggestion iconName="icon-book-open">
                            <Translate value="profile.no_approved_documents" />
                        </Suggestion>
                    )}
                    <ToastContainer ref={this.toastContainer} className="toast-bottom-right" />
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(TrajectoryDocumentsPanel)

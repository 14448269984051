import ReactGA from 'react-ga4'

function sendToAnalytics({ id, name, value }) {
    ReactGA.ga('send', 'event', {
        eventCategory: 'Web Vitals',
        eventAction: name,
        eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
        eventLabel: id, // id unique to current page load
        nonInteraction: true, // avoids affecting bounce rate
    })
}

const reportWebVitals = () => {
    let func = sendToAnalytics
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
        getCLS(func)
        getFID(func)
        getFCP(func)
        getLCP(func)
        getTTFB(func)
    })
}

export default reportWebVitals

let LocalStorage

// https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/localstorage.js
function localStorageAvailable() {
    const test = 'test'
    try {
        window.sessionStorage.setItem(test, test)
        window.sessionStorage.removeItem(test)
        return true
    } catch (e) {
        return false
    }
}

if (localStorageAvailable() && typeof Storage != 'undefined' && window.localStorage !== null) {
    LocalStorage = window.sessionStorage
} else {
    window.dataLayer = window.dataLayer || {}
    window.dataLayer.storage = {}
    LocalStorage = {
        setItem: function (id, val) {
            // localStorage supports objects, so it's better to stringify and parse when creating and reading
            return (window.dataLayer.storage[id] = JSON.stringify(val))
        },
        getItem: function (id) {
            return window.dataLayer.storage.hasOwnProperty(id)
                ? JSON.parse(window.dataLayer.storage[id])
                : undefined
        },
        removeItem: function (id) {
            return delete window.dataLayer.storage[id]
        },
        clear: function () {
            return (window.dataLayer.storage = {})
        },
    }
}

export default LocalStorage

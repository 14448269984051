import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { newCallApi } from '../../../store/actions/api'
import { translate, Translate } from 'react-i18nify'

import Panel from '../../Panel/Panel'
import Actions from '../../Actions/Actions'
import { Link } from 'react-router-dom'
import { PAGINATE_ITEM_NO, TESTABLE_TYPE_EELLOO } from '../../../constants/Constants'
import Suggestion from '../../Suggestion/Suggestion'
import Pagination from '../../Pagination/Pagination'

/**
 * Converts a base64 data string to a binary blob, usable for manal downloads
 * @see https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
 * @param {string} b64Data
 * @param contentType
 * @param sliceSize
 * @returns {Blob}
 */
const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: contentType })
}

function saveFile(data, filename, mime) {
    var blob = new Blob([data], { type: mime || 'application/octet-stream' })

    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        window.navigator.msSaveBlob(blob, filename)
    } else {
        var blobURL = window.URL.createObjectURL(blob)
        var tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', filename)

        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank')
        }

        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        window.URL.revokeObjectURL(blobURL)
    }
}

class EellooTests extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            eelloo_tests: [],
            sharing: [],
            activePage: 0,
        }
    }

    componentDidMount() {
        this.getTestResults()
    }

    getTestResults() {
        if (this.props.showDetails) {
            // this.setState({loading: true});
            newCallApi(
                'GET',
                this.props.config.API_EELLOO_TESTS.replace('{account}', this.props.user.profile.id),
            )
                .then((results) => {
                    this.setState({ eelloo_tests: results })
                })
                .catch(() => {})
                .finally(() => this.setState({ loading: false }))
        } else {
            this.setState({
                loading: false,
                eelloo_tests: this.props.profile.tests.filter(
                    (t) => t.testable_type === TESTABLE_TYPE_EELLOO,
                ),
            })
        }
    }

    goToTestSso(accountTestId) {
        newCallApi(
            'GET',
            this.props.config.API_EELLOO_TEST_SSO.replace(
                '{account}',
                this.props.user.profile.id,
            ).replace('{accountTest}', accountTestId),
        )
            .then((results) => {
                window.open(results, '_blank')
            })
            .catch(() => {})
    }

    downloadTestResults(accountTestId) {
        newCallApi(
            'GET',
            this.props.config.API_DOWNLOAD_EELLOO_TEST.replace('{accountTest}', accountTestId),
        )
            .then((results) => {
                const bytes = b64toBlob(results.archive)
                saveFile(bytes, 'eelloo.zip', 'application/octet-stream')
            })
            .catch((error) => {
                console.log('Got err', error)
            })
    }

    shareTestResults(accountTest) {
        let newSharing = this.state.sharing
        newSharing.push(accountTest.id)
        this.setState({ sharing: newSharing })
        newCallApi(
            'PATCH',
            this.props.config.API_SHARE_EELLOO_TEST.replace('{accountTestId}', accountTest.id),
            {
                share: !accountTest.share,
            },
        )
            .then(() => {})
            .catch((error) => {
                console.log('Got err', error)
            })
            .finally(() => {
                newSharing = this.state.sharing.filter((s) => s !== accountTest.id)
                this.setState({ sharing: newSharing })
                this.getTestResults()
            })
    }

    getTable() {
        const test_results = this.state.eelloo_tests
        const sliced = test_results.slice(
            PAGINATE_ITEM_NO * this.state.activePage,
            PAGINATE_ITEM_NO * this.state.activePage + PAGINATE_ITEM_NO,
        )

        if (test_results.length > 0) {
            return (
                <div className="table-repsonsive">
                    <table className="panel__table">
                        <thead>
                            <tr>
                                <th>{translate('eelloo.test')}</th>
                                <th>{translate('eelloo.request_date')}</th>
                                {this.props.showDetails && <th>{translate('eelloo.completed')}</th>}
                                {this.props.showDetails && (
                                    <th style={{ textAlign: 'center' }}>
                                        {translate('eelloo.share_with_advisor')}
                                    </th>
                                )}
                                <th style={{ textAlign: 'center' }}>
                                    {this.props.showDetails && (
                                        <Translate value="profile.goto_test" />
                                    )}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {sliced.map((result) => {
                                return (
                                    <tr key={result.id}>
                                        <td>
                                            {result.testable.service
                                                ? result.testable.service.name
                                                : result.testable.template.name}
                                        </td>
                                        <td>
                                            {result.created_at
                                                ? result.created_at.split(' ')[0]
                                                : ''}
                                        </td>
                                        {this.props.showDetails && (
                                            <td>
                                                {result.status ? (
                                                    <i className="icons mdi mdi-check-circle-outline" />
                                                ) : (
                                                    '-'
                                                )}
                                            </td>
                                        )}
                                        {this.props.showDetails && (
                                            <td className={'center'}>
                                                {!!result.status &&
                                                    (result.testable.reports.length > 0 ? (
                                                        <Actions>
                                                            <Link
                                                                onClick={() => {
                                                                    this.shareTestResults(result)
                                                                }}
                                                                className={
                                                                    this.state.sharing.includes(
                                                                        result.id,
                                                                    )
                                                                        ? 'is-loading'
                                                                        : 'actions__action--share'
                                                                }
                                                            >
                                                                <Translate
                                                                    value={
                                                                        !!result.share
                                                                            ? 'profile.stop_sharing'
                                                                            : 'profile.sharing'
                                                                    }
                                                                />
                                                            </Link>
                                                        </Actions>
                                                    ) : (
                                                        <Translate
                                                            value={'profile.not_shareable'}
                                                        />
                                                    ))}
                                            </td>
                                        )}
                                        <td style={{ textAlign: 'right' }}>
                                            {!!result.status && result.testable.reports.length > 0 && (
                                                <Actions>
                                                    <Link
                                                        onClick={() => {
                                                            this.downloadTestResults(result.id)
                                                        }}
                                                        className="actions__action--download"
                                                    >
                                                        {this.props.showDetails && (
                                                            <Translate value="profile.download" />
                                                        )}
                                                    </Link>
                                                </Actions>
                                            )}
                                            {!result.status && !!result.testable.url && (
                                                <Actions>
                                                    <Link
                                                        onClick={() => {
                                                            this.goToTestSso(result.id)
                                                        }}
                                                        className="actions__action--view"
                                                    />
                                                </Actions>
                                            )}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <Pagination
                        pageCount={Math.ceil(this.state.eelloo_tests.length / PAGINATE_ITEM_NO)}
                        onPageChange={(p) => this.setState({ activePage: p.selected })}
                    />
                </div>
            )
        } else {
            return (
                <Suggestion iconName="icon-book-open">
                    <Translate value="eelloo.no_tests" />
                </Suggestion>
            )
        }
    }

    render() {
        return (
            <Panel
                className="panel--multi__inner"
                headerText={translate('profile.eelloo_tests')}
                headerIcon="icon-graduation"
            >
                {this.state.loading ? <p className="is-loading--center--small" /> : this.getTable()}
            </Panel>
        )
    }
}

EellooTests.propTypes = {
    profile: PropTypes.object.isRequired,
    showDetails: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
    }
}

export default connect(mapStateToProps)(EellooTests)

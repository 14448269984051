import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '../Button/Button'
import { translate, Translate } from 'react-i18nify'
import Modal from '../Modal/Modal'
import { Link } from 'react-router-dom'
import Actions from '../Actions/Actions'
import { signOutFromEvent, signUpForEvent } from '../../store/actions/user'

const getTimeString = (start, end, hours) => {
    const startDate = new Date(start)
    const endDate = new Date(end)

    let result
    if (end === null) {
        result = getDateString(startDate)
    } else {
        result = [
            translate('profile.events.from_date'),
            getDateString(startDate),
            translate('profile.events.to_date'),
            getDateString(endDate),
        ].join(' ')
    }

    if (hours !== '') {
        result += ' | ' + hours
    }
    return result
}

function getDateString(date) {
    const MONTHS = [
        translate('profile.january'),
        translate('profile.february'),
        translate('profile.march'),
        translate('profile.april'),
        translate('profile.may'),
        translate('profile.june'),
        translate('profile.july'),
        translate('profile.august'),
        translate('profile.september'),
        translate('profile.october'),
        translate('profile.november'),
        translate('profile.december'),
    ]

    const DAYS = [
        translate('profile.events.day_of_the_week.Sunday'),
        translate('profile.events.day_of_the_week.Monday'),
        translate('profile.events.day_of_the_week.Tuesday'),
        translate('profile.events.day_of_the_week.Wednesday'),
        translate('profile.events.day_of_the_week.Thursday'),
        translate('profile.events.day_of_the_week.Friday'),
        translate('profile.events.day_of_the_week.Saturday'),
    ]

    return [
        DAYS[date.getDay()] + ',',
        date.getDate(),
        MONTHS[date.getMonth()],
        date.getFullYear(),
    ].join(' ')
}

export default class Event extends Component {
    constructor(props) {
        super(props)
        this.state = {
            learnMoreOpen: false,
            confirmSignOut: false,
            mounted: true,
            modalLoading: false,
            loading: false,
        }
    }

    componentWillUnmount() {
        this.setState({ mounted: false })
    }

    getLearnMoreModal() {
        const { name, item_start, item_end, item_time_text, content } = this.props.event

        return (
            <Modal
                modalClass="modal--form"
                title=""
                hideTitle
                show
                onClose={() => this.setState({ learnMoreOpen: false })}
            >
                <h2 className="green">{translate('profile.events.learn_more')}</h2>
                <div className="modal__title">{name}</div>
                <div className="modal__time">
                    {getTimeString(item_start, item_end, item_time_text)}
                </div>
                <div className="modal__description" dangerouslySetInnerHTML={{ __html: content }} />
                <Actions>
                    <Link
                        className="actions__action--cancel"
                        onClick={() => this.setState({ learnMoreOpen: false })}
                    >
                        <Translate value="profile.cancel" />
                    </Link>
                    {this.props.registered ? (
                        <span>
                            {new Date(item_end) > new Date() && (
                                <Link
                                    className={
                                        'actions__action--save--red' +
                                        (this.state.modalLoading ? ' is-loading' : '')
                                    }
                                    onClick={() => {
                                        this.setState({ modalLoading: true })
                                        this.signOut(
                                            () => {
                                                this.setState({
                                                    learnMoreOpen: false,
                                                    modalLoading: false,
                                                })
                                            },
                                            () => {
                                                this.setState({
                                                    learnMoreOpen: false,
                                                    modalLoading: false,
                                                })
                                            },
                                        )
                                    }}
                                >
                                    <Translate value="profile.events.sign_out" />
                                </Link>
                            )}
                        </span>
                    ) : (
                        <Link
                            className={
                                'actions__action--save' +
                                (this.state.modalLoading ? ' is-loading' : '')
                            }
                            onClick={() => {
                                this.setState({ modalLoading: true })
                                this.signUp(
                                    () => {
                                        this.setState({ learnMoreOpen: false, modalLoading: false })
                                    },
                                    () => {
                                        this.setState({ learnMoreOpen: false, modalLoading: false })
                                    },
                                )
                            }}
                        >
                            <Translate value="profile.events.sign_up" />
                        </Link>
                    )}
                </Actions>
            </Modal>
        )
    }

    getConfirmSignOutModal() {
        const { name, item_start, item_end, item_time_text } = this.props.event

        return (
            <Modal
                modalClass="modal--form"
                title=""
                hideTitle
                show
                onClose={() => this.setState({ confirmSignOut: false })}
            >
                <h2 className="red">{translate('profile.events.confirm')}</h2>
                <div className="modal__subtitle">{translate('profile.events.are_you_sure')}</div>
                <div className="modal__title">{name}</div>
                <div className="modal__time">
                    {getTimeString(item_start, item_end, item_time_text)}
                </div>
                <Actions>
                    <Link
                        className="actions__action--cancel"
                        onClick={() => this.setState({ confirmSignOut: false })}
                    >
                        <Translate value="profile.cancel" />
                    </Link>
                    <Link
                        className={
                            'actions__action--save--red' +
                            (this.state.modalLoading ? ' is-loading' : '')
                        }
                        onClick={() => {
                            this.setState({ modalLoading: true })
                            this.signOut(
                                () => {
                                    this.setState({ learnMoreOpen: false, modalLoading: false })
                                },
                                () => {
                                    this.setState({ learnMoreOpen: false, modalLoading: false })
                                },
                            )
                        }}
                    >
                        <Translate value="profile.events.sign_out" />
                    </Link>
                </Actions>
            </Modal>
        )
    }

    signUp = (success = () => {}, error = () => {}) => {
        this.props.dispatch(signUpForEvent(this.props.event, this.props.auth, success, error))
    }

    signOut = (success = () => {}, error = () => {}) => {
        this.props.dispatch(signOutFromEvent(this.props.event.id, this.props.auth, success, error))
    }

    render() {
        const { name, introduction, item_start, item_end, item_time_text } = this.props.event

        return (
            <div className="event">
                <div className="time">{getTimeString(item_start, item_end, item_time_text)}</div>
                <div className="title">{name}</div>
                <div className="description">{introduction}</div>
                <div className="button-box">
                    <div className="flex-spacer" />
                    <Button
                        className="button--cta--adjusted"
                        onClick={() => this.setState({ learnMoreOpen: true })}
                        buttonText={translate('profile.events.learn_more')}
                    />
                    {new Date(this.props.event.item_start) >= new Date().setHours(0, 0, 0, 0) &&
                        (this.props.registered ? (
                            <Button
                                className="button--cancel--outlined"
                                onClick={() => this.setState({ confirmSignOut: true })}
                                buttonText={translate('profile.events.sign_out')}
                            />
                        ) : (
                            <Button
                                className={
                                    'button--cta--outlined' +
                                    (this.state.loading ? ' is-loading' : '')
                                }
                                onClick={() => {
                                    this.setState({ loading: true })
                                    this.signUp(
                                        () => {
                                            this.setState({ loading: false })
                                        },
                                        () => {
                                            this.setState({ loading: false })
                                        },
                                    )
                                }}
                                buttonText={translate('profile.events.sign_up')}
                            />
                        ))}
                </div>
                {this.state.learnMoreOpen && this.getLearnMoreModal(this.props.event)}
                {this.state.confirmSignOut && this.getConfirmSignOutModal(this.props.event)}
            </div>
        )
    }
}

Event.propTypes = {
    event: PropTypes.shape({
        id: PropTypes.number.isRequired,
        item_start: PropTypes.string.isRequired,
    }).isRequired,
    registered: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'simple-react-modal'

export default class ModalWindow extends Component {
    constructor(props) {
        super(props)

        let show = false
        if (this.props.show) {
            show = true
        }

        this.state = { show: show }

        this.show = this.show.bind(this)
        this.close = this.close.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.show !== this.props.show) {
            this.setState({ show: this.props.show })
        }
    }
    show() {
        document.body.style.overflowY = 'hidden'
        this.setState({ show: true })
    }

    close() {
        document.body.style.overflowY = 'scroll'
        this.setState({ show: false })
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    componentDidMount() {
        if (this.button !== undefined) {
            this.button.focus()
        }
    }

    render() {
        const { title, hideClose, hideTitle, size, modalClass } = this.props

        return (
            <div>
                <Modal
                    className={modalClass ? modalClass : 'modal'}
                    containerClassName={
                        'modal__container' +
                        (size === 'large' ? ' modal__container___large' : '') +
                        (size === 'x-large' ? ' modal__container__x-large' : '') +
                        (this.props.padding ? '' : ' no__padding')
                    }
                    closeOnOuterClick={false}
                    show={this.state.show}
                    onClose={this.close}
                >
                    <div className="modal__content">
                        {!hideTitle && <h2>{title}</h2>}
                        {this.props.children}
                    </div>

                    {!hideClose && (
                        <a className="modal__close" onClick={this.close.bind(this)}>
                            ×
                        </a>
                    )}
                </Modal>
            </div>
        )
    }
}

ModalWindow.propTypes = {
    title: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    hideClose: PropTypes.bool,
    hideTitle: PropTypes.bool,
    onClose: PropTypes.func,
    size: PropTypes.string,
    show: PropTypes.bool,
    padding: PropTypes.bool,
    modalClass: PropTypes.string,
}

ModalWindow.defaultProps = {
    padding: true,
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '../Button/Button'
import Loading from '../Loading/Loading'
import { Col, Row } from 'react-grid-system'
import { translate } from 'react-i18nify'
import AlertBox from '../Alert/AlertBox'

export default class InlineFileUploader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            errors: [],
            filesUploaded: [],
        }

        this.imageUpload = React.createRef()
    }

    uploadFile(evt) {
        this.setState({ errors: [] })
        const { fileSize } = this.props
        let file = evt.target.files[0]

        const sizeKb = file.size / 1024
        if (fileSize && sizeKb > fileSize) {
            this.setState({
                errors: [translate('errors.size_error', { size: fileSize })],
            })
            this.imageUpload.current.value = ''
            this.uploaded(null)
            return
        }
        this.uploaded(file)
    }

    uploaded(file) {
        this.setState(
            {
                file: file,
            },
            () => {
                this.props.onFileSelected(file)
            },
        )
    }

    getFileName() {
        return this.state.file ? this.state.file.name : 'Geen bestand geselecteerd'
    }

    render() {
        return (
            <div>
                {this.state.loading ? (
                    <Loading text={translate('profile.upload') + '...'} />
                ) : (
                    <div className="file_uploader">
                        <Row>
                            <Col xs={12} sm={6} md={4}>
                                <Button
                                    onClick={() => {
                                        this.imageUpload.current.click()
                                    }}
                                    className="button--cta"
                                    buttonText={translate('vacancies.upload')}
                                />
                            </Col>
                            <Col xs={12} sm={6} md={8}>
                                <input
                                    type="file"
                                    id="imageUpload"
                                    ref={this.imageUpload}
                                    name="imageUpload"
                                    style={{ display: 'none' }}
                                    onChange={(evt) => {
                                        this.uploadFile(evt)
                                    }}
                                    accept={this.props.accept}
                                />

                                <span className="file_input__label">{this.getFileName()}</span>
                            </Col>
                        </Row>

                        {this.state.errors.length > 0 && (
                            <AlertBox messages={this.state.errors} type="error" />
                        )}
                    </div>
                )}
            </div>
        )
    }
}

InlineFileUploader.propTypes = {
    auth: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    accept: PropTypes.string,
    fileSize: PropTypes.number, // KB
    onFileSelected: PropTypes.func,
}

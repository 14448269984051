import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { translate, Translate } from 'react-i18nify'
import { connect } from 'react-redux'
import { editProfileItem } from '../../store/actions/user'
import { addClass, getErrorFields, getErrorMessages, removeClass } from '../../helpers'

import Actions from '../Actions/Actions'

class FormQuote extends Component {
    constructor(props) {
        super(props)

        let quote = this.props.user.profile.quote_text
        if (quote == null || quote == undefined || quote.length == 0) {
            quote = ''
        }

        this.state = { quote: quote, loading: false }
    }

    handleChange(value, key, changeClass = true) {
        let obj = {}
        obj[key] = value.target.value

        this.setState(obj)

        if (changeClass) {
            if (value.target.value.length > 0) {
                addClass(value.currentTarget, 'has-value')
            } else {
                removeClass(value.currentTarget, 'has-value')
            }
        }
    }

    getClassNames(item) {
        if (item != null && item != '' && item.length > 0) {
            return 'form__input--text has-value'
        } else {
            return 'form__input--text'
        }
    }

    save() {
        this.setState({
            loading: true,
        })

        let quote = this.state.quote

        let update = {
            quote_text: quote,
        }

        this.props.dispatch(
            editProfileItem(
                'general',
                update,
                this.props.auth,
                () => this.onSuccess(),
                (status, error) => this.onError(status, error),
            ),
        )
    }

    cancel() {
        this.setState({
            loading: false,
        })

        this.props.onCancel()
    }

    onSuccess() {
        this.setState({
            loading: false,
        })

        this.props.onSave()
    }

    onError(status, object) {
        let map = {}

        this.setState({
            loading: false,
        })

        this.setState(
            {
                errors: [],
                fields: [],
                loading: false,
            },
            () => {
                if (object.hasOwnProperty('errors')) {
                    let messages = getErrorMessages(object.errors, map)
                    var fields = getErrorFields(object.errors)

                    this.addErrors(messages)
                    this.addErrorFields(fields)
                }
            },
        )
    }

    getSaveClasses() {
        let c = 'actions__action--save'

        if (this.state.loading) {
            c += ' is-loading'
        }

        return c
    }

    render() {
        const { className } = this.props
        return (
            <form className={this.props.className}>
                <fieldset className="form__fieldset">
                    <div className="form__item">
                        <i className="form__icon icons icon-user-female" />
                        <textarea
                            className="form__input--text has-value"
                            type="text"
                            id="quote"
                            onChange={(value) => this.handleChange(value, 'quote', false)}
                            placeholder={translate('profile.quote_placeholder')}
                            tabIndex="1"
                            autoFocus
                            value={this.state.quote}
                        />
                    </div>
                </fieldset>
                <Actions>
                    <Link
                        className="actions__action--cancel"
                        onClick={() => this.cancel()}
                        tabIndex="2"
                    >
                        <Translate value="profile.cancel" />
                    </Link>
                    <Link
                        className={this.getSaveClasses()}
                        onClick={() => this.save()}
                        tabIndex="3"
                    >
                        <Translate value="profile.save" />
                    </Link>
                </Actions>
            </form>
        )
    }
}

FormQuote.propTypes = {
    className: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    const { auth, user } = state

    return {
        auth,
        user,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(FormQuote)

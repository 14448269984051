import React, { Component } from 'react'
import Panel from '../../Panel/Panel'
import { connect } from 'react-redux'
import EducationsPanel from './EducationsPanel'
import CoursesPanel from './CoursesPanel'
import DiplomasPanel from './DiplomasPanel'

class EducationsPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedDiploma: null,
        }
    }

    render() {
        const { auth, user } = this.props

        return (
            <div>
                <div id="courseEducationPanel">
                    <Panel className="panel--multi">
                        {user.profile.account_types &&
                            user.profile.account_types.some((type) => type.code == 'student') && (
                                <div>
                                    <div id="diplomasPanel">
                                        <DiplomasPanel
                                            updateProfile={this.props.updateProfile}
                                            showAlert={this.props.showAlert}
                                        />
                                    </div>
                                </div>
                            )}
                        <EducationsPanel showAlert={this.props.showAlert} />
                        <div className="spacer-hr" />
                        <CoursesPanel showAlert={this.props.showAlert} />
                    </Panel>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
        state,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(EducationsPage)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, dispatch } from 'react-redux'
import { newCallApi } from '../../store/actions/api'
import { translate, Translate } from 'react-i18nify'

import Modal from '../Modal/Modal'
import Button from '../Button/Button'
import Loading from '../Loading/Loading'

class DiplomaSelfEval extends Component {
    constructor(props) {
        super(props)
        let desc = {
            description: this.props.workProcess.description,
            result_starter: translate('evaluation_descriptions.starter'),
            result_advanced: translate('evaluation_descriptions.advanced'),
            result_expert: translate('evaluation_descriptions.professional'),
        }
        this.state = {
            score: 0,
            activeModal: null,
            comments: '',
            loading: false,
            selfEvaluation: false,
            descriptions: desc,
        }
    }
    componentDidMount() {
        this.getDetails()
    }
    componentDidUpdate(prevProps) {
        if (this.props.workProcess.work_process_id !== prevProps.workProcess.work_process_id) {
            this.getDetails()
        }
    }

    handleOptionChange(event) {
        this.setState({
            score: Number(event.target.value),
        })
    }

    mapScoreToTitle(score) {
        if (score === 1) return 'starter'
        if (score === 2) return 'advanced'
        if (score === 3) return 'professional'
    }

    overrideWPResult(score) {
        return this.state.descriptions['result_' + this.mapScoreToTitle(score)]
    }

    getDetails() {
        this.setState({ loading: true })
        newCallApi(
            'GET',
            this.props.config.API_EVALUATIONS.replace('{account}', this.props.user.profile.id)
                .replace('{diploma}', this.props.diploma.diploma_id)
                .replace('{work_process}', this.props.workProcess.work_process_id),
        )
            .then((evaluations) => {
                let selfEvaluation
                evaluations.forEach((evaluation) => {
                    if (
                        evaluation.is_own &&
                        evaluation.diploma_id === this.props.diploma.diploma_id &&
                        evaluation.work_process_id === this.props.workProcess.work_process_id
                    )
                        selfEvaluation = evaluation
                })
                this.setState({
                    descriptions: { ...this.state.descriptions, ...this.props.workProcess },
                })

                if (!selfEvaluation) throw new Error('evaluation-not-found')
                return selfEvaluation
            })
            .then((selfEvaluation) =>
                this.setState(
                    {
                        selfEvaluation,
                        comments: selfEvaluation.comments,
                        score: selfEvaluation.score,
                    },
                    () => {
                        if (!this.props.diploma.school_specific) {
                            this.getDescriptions()
                        }
                    },
                ),
            )
            .catch((error) => {
                console.log('Got err', error)
                if (typeof this.props.onUpdated === 'function') {
                    this.props.onUpdated()
                }
            })
            .then(() => this.setState({ loading: false }))
    }
    getDescriptions() {
        newCallApi(
            'GET',
            this.props.config.API_EVALUATION_DESCRIPTION.replace(
                '{account}',
                this.props.user.profile.id,
            )
                .replace('{diploma}', this.props.diploma.diploma_id)
                .replace('{work_process}', this.props.workProcess.work_process_id)
                .replace('{evaluation}', this.state.selfEvaluation.id),
        ).then((res) => {
            Object.keys(res).map((key) => {
                if (res[key] == null || res[key].length === 0) {
                    delete res[key]
                }
            })
            this.setState({
                descriptions: { ...this.state.descriptions, ...res },
            })
        })
    }
    save() {
        this.setState({ loading: true })

        newCallApi(
            'PUT',
            this.props.config.API_EVALUATION.replace('{account}', this.props.user.profile.id)
                .replace('{diploma}', this.props.diploma.diploma_id)
                .replace('{work_process}', this.props.workProcess.work_process_id)
                .replace('{evaluation}', this.state.selfEvaluation.id),
            {
                score: this.state.score === 0 ? 1 : this.state.score,
                comments: this.state.comments,
            },
        )
            .then(() => {})
            .catch((error) => {})
            .then(() => {
                this.setState({ loading: false })
                if (typeof this.props.onUpdated === 'function') {
                    this.props.onUpdated()
                }
            })
    }
    render() {
        return this.state.loading ? (
            <Loading text={translate('texts.hint_loading')} />
        ) : (
            <div className="diploma-self">
                {!!this.state.activeModal && (
                    <Modal
                        show={true}
                        onClose={() => this.setState({ activeModal: null })}
                        title={translate(
                            `diplomas.${this.mapScoreToTitle(this.state.activeModal)}`,
                        )}
                    >
                        <p className="line-breaks">
                            {this.overrideWPResult(this.state.activeModal)}
                        </p>
                    </Modal>
                )}

                <div className="diploma-self-block">
                    <h2>
                        <span>{this.props.workProcess.title}</span>
                    </h2>
                    <p dangerouslySetInnerHTML={{ __html: this.state.descriptions.description }} />
                    <h3>
                        <Translate value="diplomas.status" />
                    </h3>
                    <p>
                        <Translate value="diplomas.pending_evaluation" />
                    </p>
                </div>

                <div className="diploma-self-block">
                    <h3>
                        <Translate value="diplomas.evaluate" />
                    </h3>
                    <div className="score_board">
                        <div className="score_board__col">
                            <div className="score_board__item">
                                <input
                                    type="radio"
                                    value={1}
                                    id="score_1"
                                    checked={this.state.score === 1}
                                    onChange={(e) => this.handleOptionChange(e)}
                                />
                                <label htmlFor="score_1">
                                    <i
                                        className={[
                                            this.state.score === 1
                                                ? 'mdi mdi-star'
                                                : 'mdi mdi-star-outline',
                                        ]}
                                    />
                                    <span className="score_board__label_text">
                                        <Translate value="diplomas.starter" />
                                    </span>
                                </label>
                            </div>
                            <span className="score_board__info_btn">
                                <i
                                    className="icons icon-question"
                                    onClick={() => this.setState({ activeModal: 1 })}
                                />
                            </span>
                        </div>
                        <div className="score_board__col">
                            <div className="score_board__item">
                                <input
                                    type="radio"
                                    value={2}
                                    id="score_2"
                                    checked={this.state.score === 2}
                                    onChange={(e) => this.handleOptionChange(e)}
                                />
                                <label htmlFor="score_2">
                                    <i
                                        className={[
                                            this.state.score === 2
                                                ? 'mdi mdi-star'
                                                : 'mdi mdi-star-outline',
                                        ]}
                                    />
                                    <i
                                        className={[
                                            this.state.score === 2
                                                ? 'mdi mdi-star'
                                                : 'mdi mdi-star-outline',
                                        ]}
                                    />
                                    <span className="score_board__label_text">
                                        <Translate value="diplomas.advanced" />
                                    </span>
                                </label>
                            </div>
                            <span className="score_board__info_btn">
                                <i
                                    className="icons icon-question"
                                    onClick={() => this.setState({ activeModal: 2 })}
                                />
                            </span>
                        </div>
                        <div className="score_board__col">
                            <div className="score_board__item">
                                <input
                                    type="radio"
                                    value={3}
                                    id="score_3"
                                    checked={this.state.score === 3}
                                    onChange={(e) => this.handleOptionChange(e)}
                                />
                                <label htmlFor="score_3">
                                    <i
                                        className={[
                                            this.state.score === 3
                                                ? 'mdi mdi-star'
                                                : 'mdi mdi-star-outline',
                                        ]}
                                    />
                                    <i
                                        className={[
                                            this.state.score === 3
                                                ? 'mdi mdi-star'
                                                : 'mdi mdi-star-outline',
                                        ]}
                                    />
                                    <i
                                        className={[
                                            this.state.score === 3
                                                ? 'mdi mdi-star'
                                                : 'mdi mdi-star-outline',
                                        ]}
                                    />
                                    <span className="score_board__label_text">
                                        <Translate value="diplomas.professional" />
                                    </span>
                                </label>
                            </div>
                            <span className="score_board__info_btn">
                                <i
                                    className="icons icon-question"
                                    onClick={() => this.setState({ activeModal: 3 })}
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="diploma-self-block">
                    <h3>
                        <Translate value="diplomas.remarks" />
                    </h3>
                    <textarea
                        defaultValue={this.state.comments}
                        onInput={(event) => this.setState({ comments: event.target.value })}
                        className="diploma-textarea"
                    />
                </div>
                <Button
                    className="button--cta"
                    onClick={() => this.save()}
                    buttonText={translate('buttons.save')}
                    tabIndex="2"
                />
                <Button
                    className="button--cancel"
                    onClick={() =>
                        typeof this.props.onCancel === 'function' && this.props.onCancel()
                    }
                    buttonText={translate('buttons.cancel')}
                />
            </div>
        )
    }
}

DiplomaSelfEval.propTypes = {
    workProcess: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    diploma: PropTypes.object.isRequired,
    onUpdated: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
    }
}
export default connect(mapStateToProps)(DiplomaSelfEval)

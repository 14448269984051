import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Translate } from 'react-i18nify'

export default class Form extends Component {
    render() {
        const { className } = this.props
        return (
            <form className={this.props.className}>
                <h1>Form header</h1>
                <p>Korte uitleg</p>

                <fieldset className="form__fieldset">
                    <div className="form__item">
                        <i className="form__icon icons icon-envelope" />
                        <input className="form__input--text" type="email" id="email" />
                        <label className="form__label" htmlFor="email">
                            <Translate value="form.email" />
                        </label>
                    </div>
                    <div className="form__item">
                        <i className="form__icon icons icon-lock" />
                        <input className="form__input--text" type="password" id="password" />
                        <label className="form__label" htmlFor="password">
                            <Translate value="form.password" />
                        </label>
                    </div>
                    <div className="form__item">
                        <i className="form__icon icons icon-location-pin" />
                        <div className="form__select">
                            <select className="form__input--select">
                                <option>Kies locatie...</option>
                                <option>Keuze 1</option>
                                <option>Keuze 2</option>
                            </select>
                        </div>
                        <label className="form__label">Locatie:</label>
                    </div>
                    <div className="form__item--radio">
                        <div className="form__radio">
                            <input
                                type="radio"
                                name="radio_styled"
                                id="radio_styled1"
                                className="form__radio__input"
                                value="man"
                                defaultChecked={true}
                            />
                            <label className="form__radio__label" htmlFor="radio_styled1">
                                Man
                            </label>
                        </div>
                        <div className="form__radio">
                            <input
                                type="radio"
                                name="radio_styled"
                                id="radio_styled2"
                                className="form__radio__input"
                                value="woman"
                            />
                            <label className="form__radio__label" htmlFor="radio_styled2">
                                Vrouw
                            </label>
                        </div>
                        <div className="form__radio">
                            <input
                                type="radio"
                                name="radio_styled"
                                id="radio_styled3"
                                className="form__radio__input"
                                value="different"
                            />
                            <label className="form__radio__label" htmlFor="radio_styled3">
                                Anders
                            </label>
                        </div>
                    </div>

                    <div className="form__item--checkbox">
                        <div className="form__checkbox">
                            <input
                                type="checkbox"
                                name="cb_styled"
                                id="checkbox_styled"
                                className="form__checkbox__input"
                            />
                            <label className="form__checkbox__label" htmlFor="checkbox_styled">
                                Dit is een checkbox
                            </label>
                        </div>
                    </div>
                    <div className="form__item--checkbox">
                        <div className="form__checkbox form__gc">
                            <input
                                type="checkbox"
                                name="cb_styled"
                                id="cb_gc"
                                className="form__checkbox__input"
                            />
                            <label className="form__checkbox__label" htmlFor="cb_gc">
                                Ik ga akkoord met de <br />
                                <Link to="register-step2">gebruikersvoorwaarden</Link>
                            </label>
                        </div>
                    </div>
                </fieldset>
            </form>
        )
    }
}

Form.propTypes = {
    className: PropTypes.string.isRequired,
}

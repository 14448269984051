import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Actions from '../Actions/Actions'
import { translate, Translate } from 'react-i18nify'

import { addClass, getErrorFields, getErrorMessages, removeClass } from '../../helpers'

import { diffProfileItems, editProfileItem } from '../../store/actions/user'

import 'react-dates/lib/css/_datepicker.css'
import Alert from '../Alert/Alert'
import Datepicker from '../Datepicker/Datepicker'

var moment = require('moment')

var map

export default class FormWorkExperience extends Component {
    constructor(props) {
        super(props)

        if (this.props.edit != undefined) {
            let job = this.props.edit

            let end = null
            if (job.end_date != null && job.end_date.length > 0) {
                end = moment(job.end_date)
            }

            this.state = {
                func: job.function_title,
                location: job.company_name,
                city: job.city,
                start: moment(job.start_date),
                end: end,
                area: job.job_working_area_id,
                edit: true,
                id: job.id,
                errors: [],
                fields: [],
                current: job.end_date == null || job.end_date.length < 1,
            }
        } else {
            this.state = {
                current: false,
                id: -1,
                func: '',
                location: '',
                area: 0,
                start: moment(),
                end: moment(),
                city: '',
                country: '',
                error: '',
                edit: false,
                loading: false,
                errors: [],
                fields: [],
            }
        }
    }

    componentDidMount() {
        if (!this.state.edit && this.props.areas && this.props.areas.length > 0) {
            this.setState({
                area: this.props.areas[0].id,
            })
        }
    }

    handleChange(value, key, changeClass = true) {
        let obj = {}
        obj[key] = value.target.value

        this.setState(obj)

        if (key == 'end' && value.target.value.length > 0) {
            this.setState({
                current: false,
            })
        }

        if (changeClass) {
            if (value.target.value.length > 0) {
                addClass(value.currentTarget, 'has-value')
            } else {
                removeClass(value.currentTarget, 'has-value')
            }
        }
    }

    handleStartDate(date) {
        this.setState({
            start: date,
        })
    }

    handleEndDate(date) {
        this.setState({
            end: date,
        })
    }

    getDateClassNames(item, field) {
        let c = 'form__input--date has-value'

        if (this.state.fields.indexOf(field) != -1) {
            c += ' error'
        }

        return c
    }

    getClassNames(item, field) {
        let c = 'form__input--text'

        if (item != null && item != '' && item.length > 0) {
            c += ' has-value'
        }

        if (this.state.fields.indexOf(field) != -1) {
            c += ' error'
        }

        return c
    }

    cancel() {
        this.state = {
            id: -1,
            func: '',
            location: '',
            area: 0,
            start: '',
            end: '',
            city: '',
            country: '',
            error: '',
            edit: false,
            loading: false,
            errors: [],
            fields: [],
        }
        this.props.onCancel()
    }

    save() {
        let func = this.state.func,
            location = this.state.location,
            start = this.state.start,
            end = this.state.end,
            city = this.state.city,
            area = this.state.area,
            curr = this.state.current
        //
        //if (func.length < 1 || location.length < 1 || start.length < 1 || end.length < 1) {
        //    this.setState({
        //        error: translate('errors.empty_field')
        //    });
        //
        //    return;
        //}

        if (end && typeof end.format === 'function') {
            end = end.format('YYYY-MM-DD')
        }

        if (start && typeof start.format === 'function') {
            start = start.format('YYYY-MM-DD')
        }

        let current = 0
        if (new Date(end).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0) || curr) {
            current = 1
        }

        let jobFunction = {
            function_title: func,
            company_name: location,
            city: city,
            start_date: start,
            end_date: end,
            current_position: current,
            job_working_area_id: area,
        }

        if (this.state.id != -1) {
            jobFunction.id = this.state.id
        }

        this.setState({
            loading: true,
        })

        if (this.state.edit) {
            this.props.dispatch(
                editProfileItem(
                    'job_functions',
                    jobFunction,
                    this.props.auth,
                    () => this.onSuccess(),
                    (status, error) => this.onError(status, error),
                ),
            )
        } else {
            this.props.dispatch(
                diffProfileItems(
                    'job_functions',
                    [jobFunction],
                    [],
                    this.props.auth,
                    () => {
                        this.onSuccess()
                    },
                    (s, o) => {
                        this.onError(s, o)
                    },
                ),
            )
        }
    }

    onError(status, object) {
        map = {
            company_name: translate('profile.experience_location'),
            city: translate('profile.experience_city'),
            function_title: translate('profile.experience_function'),
            job_working_area_id: translate('profile.experience_area'),
            start_date: translate('profile.experience_from'),
            end_date: translate('profile.experience_until'),
        }

        this.setState(
            {
                errors: [],
                fields: [],
                loading: false,
            },
            () => {
                if (object.hasOwnProperty('errors')) {
                    let messages = getErrorMessages(object.errors, map)
                    var fields = getErrorFields(object.errors)

                    this.addErrors(messages)
                    this.addErrorFields(fields)
                }
            },
        )
    }

    addErrors(errors) {
        this.setState({
            errors: errors,
        })
    }

    addErrorFields(fields) {
        this.setState({
            fields: fields,
        })
    }

    onSuccess() {
        this.state = {
            id: -1,
            func: '',
            location: '',
            area: 0,
            start: '',
            end: '',
            city: '',
            country: '',
            error: '',
            edit: false,
            loading: false,
            errors: [],
            fields: [],
        }
        this.props.onSave()
    }

    getSaveClasses() {
        let c = 'actions__action--save'

        if (this.state.loading) {
            c += ' is-loading'
        }

        return c
    }

    currentChange(val) {
        if (val.target.checked == 1) {
            this.setState({
                end: null,
                current: true,
            })
        } else {
            this.setState({
                current: false,
                end: moment(),
            })
        }
    }

    render() {
        const { areas } = this.props

        return (
            <form
                className={this.props.className}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        //Enter pressed
                        this.save()
                    }
                }}
            >
                <fieldset className="form__fieldset">
                    {this.state.errors && this.state.errors.length > 0 && (
                        <Alert type="alert--error alert--simple">
                            <ul>
                                {this.state.errors.map(function (value) {
                                    return <li key={value}>{value}</li>
                                })}
                            </ul>
                        </Alert>
                    )}

                    <div className="form__item">
                        <i className="form__icon icons icon-settings" />
                        <input
                            className={this.getClassNames(this.state.func, 'function_title')}
                            type="text"
                            id="experience_function"
                            value={this.state.func}
                            onChange={(value) => this.handleChange(value, 'func')}
                            tabIndex="1"
                            autoFocus
                        />
                        <label className="form__label" htmlFor="experience_function">
                            <Translate value="profile.experience_function" />
                        </label>
                    </div>
                    <div className="form__item">
                        <i className="form__icon icons icon-briefcase" />
                        <input
                            className={this.getClassNames(this.state.location, 'company_name')}
                            type="text"
                            id="experience_location"
                            value={this.state.location}
                            onChange={(value) => this.handleChange(value, 'location')}
                            tabIndex="2"
                        />
                        <label className="form__label" htmlFor="experience_location">
                            <Translate value="profile.experience_location" />
                        </label>
                    </div>
                    <div className="form__item">
                        <i className="form__icon icons icon-location-pin" />
                        <input
                            className={this.getClassNames(this.state.city, 'city')}
                            type="text"
                            id="city"
                            value={this.state.city}
                            onChange={(value) => this.handleChange(value, 'city')}
                            tabIndex="2"
                        />
                        <label className="form__label" htmlFor="city">
                            <Translate value="labels.city" />
                        </label>
                    </div>
                    <div className="form__item">
                        <i className="form__icon icons icon-direction" />
                        <div className="form__select">
                            <select
                                className="form__input--select"
                                onChange={(value) => this.handleChange(value, 'area', false)}
                                tabIndex="3"
                            >
                                {areas.map((item) => {
                                    if (item.id == this.state.area) {
                                        return (
                                            <option key={item.id} value={item.id} selected>
                                                {item.name}
                                            </option>
                                        )
                                    } else {
                                        return (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        )
                                    }
                                })}
                            </select>
                        </div>
                        <label className="form__label" htmlFor="experience_location">
                            <Translate value={'profile.experience_area'} />
                        </label>
                    </div>
                    <div className="form__item">
                        <i className="form__icon icons icon-calendar" />
                        <Datepicker
                            date={this.state.start}
                            onDateChange={(date) => this.handleStartDate(date)}
                            classNames={this.getDateClassNames(this.state.start, 'start_date')}
                            id={'experience_from'}
                        />
                        <label className="form__label" htmlFor="experience_from">
                            <Translate value="profile.experience_from" />
                        </label>
                    </div>
                    <div className="form__item">
                        <Datepicker
                            date={this.state.end}
                            onDateChange={(date) => this.handleEndDate(date)}
                            classNames={this.getDateClassNames(this.state.start, 'end_date')}
                            id={'experience_until'}
                        />
                        <label className="form__label" htmlFor="experience_until">
                            <Translate value="profile.experience_until" />
                        </label>
                    </div>

                    <div className="form__item--checkbox">
                        <div className="form__checkbox form__gc">
                            <input
                                onChange={(val) => this.currentChange(val)}
                                type="checkbox"
                                name="cb_styled"
                                id="cb_styled"
                                className="form__checkbox__input"
                                tabIndex="6"
                                checked={this.state.current == true ? 'checked' : false}
                            />
                            <label className="form__checkbox__label" htmlFor="cb_styled">
                                <Translate value="profile.current" />
                                <br />
                            </label>
                        </div>
                    </div>
                </fieldset>
                <Actions>
                    <Link
                        className="actions__action--cancel"
                        onClick={() => this.cancel()}
                        tabIndex="6"
                    >
                        <Translate value="profile.cancel" />
                    </Link>
                    <Link
                        className={this.getSaveClasses()}
                        onClick={() => this.save()}
                        tabIndex="7"
                    >
                        <Translate value="profile.save" />
                    </Link>
                </Actions>
            </form>
        )
    }
}

FormWorkExperience.propTypes = {
    className: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    areas: PropTypes.array.isRequired,
    auth: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    edit: PropTypes.object,
}

import React, { Component } from 'react'
import { Col } from 'react-grid-system'

export default class GridCol extends Component {
    render() {
        return (
            <Col xs={12} sm={12} md={10} xl={8}>
                <span />
                {this.props.children}
            </Col>
        )
    }
}

import React, { Component } from 'react'
import Panel from '../../Panel/Panel'
import { getTimeString, isCollapsible } from '../helpers'
import { translate, Translate } from 'react-i18nify'
import ButtonPanel from '../../Button/ButtonPanel'
import ListItem from '../../ListItem/ListItem'
import Suggestion from '../../Suggestion/Suggestion'
import ModalForm from '../../Modal/ModalForm'
import FormCourses from '../../Forms/FormCourses'
import { connect } from 'react-redux'

class CoursesPanel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            addCourse: false,
            editCourse: undefined,
            allowEditEducation: false,
        }
    }

    getCoursesModal(edit = false, course = {}) {
        if (edit) {
            return (
                <ModalForm
                    title={translate('profile.change_courses')}
                    show
                    onClose={() => {
                        this.setState({
                            editCourse: undefined,
                        })
                    }}
                >
                    <FormCourses
                        className="form"
                        onCancel={() => {
                            this.setState({
                                editCourse: undefined,
                            })
                        }}
                        onSave={() => {
                            this.setState({
                                editCourse: undefined,
                            })
                            this.props.showAlert()
                        }}
                        dispatch={this.props.dispatch}
                        edit={course}
                    />
                </ModalForm>
            )
        } else {
            return (
                <ModalForm
                    title={translate('profile.add_courses')}
                    show
                    onClose={() => {
                        this.setState({
                            addCourse: false,
                        })
                    }}
                >
                    <FormCourses
                        className="form"
                        onSave={() => {
                            this.setState({
                                addCourse: false,
                            })
                            this.props.showAlert()
                        }}
                        onCancel={() => {
                            this.setState({
                                addCourse: false,
                            })
                        }}
                        dispatch={this.props.dispatch}
                    />
                </ModalForm>
            )
        }
    }

    render() {
        let { courses } = this.props.user.profile

        if (courses != null && courses.length > 0) {
            return (
                <Panel
                    className={
                        isCollapsible(courses.length)
                            ? 'panel--expand panel--multi__inner'
                            : 'panel--multi__inner'
                    }
                    headerText={translate('profile.courses')}
                    headerIcon="icon-book-open"
                    addButton={
                        <ButtonPanel
                            buttonText={translate('profile.add_new')}
                            className="button--cta"
                            onClick={() => {
                                this.setState({
                                    addCourse: true,
                                })
                            }}
                        />
                    }
                >
                    <div className="panel__list">
                        {courses.map((course) => {
                            return (
                                <ListItem
                                    key={course.id + '-' + course.name}
                                    onEdit={() => {
                                        this.setState({
                                            editCourse: course,
                                        })
                                    }}
                                    onChange={() => {
                                        this.props.showAlert()
                                    }}
                                    dispatch={this.props.dispatch}
                                    auth={this.props.auth}
                                    type="courses"
                                    item={course}
                                    showEdit={this.state.allowEditEducation}
                                    title={course.name}
                                >
                                    <p>
                                        {getTimeString(course.achieved, course.expiration_date, 0)}
                                    </p>
                                    <h3>
                                        <span>{course.name}</span>
                                    </h3>
                                    {/* Not happy with this, but React.Fragment wasn't introduced until React 16.2 */}
                                    {course.expiration_date ? (
                                        <p>
                                            <Translate value="profile.graduated_at" />:{' '}
                                            {getTimeString(course.achieved)}
                                            <br />
                                            <Translate value="profile.expires_on" />:{' '}
                                            {getTimeString(course.expiration_date)}
                                        </p>
                                    ) : (
                                        <p>
                                            <Translate value="profile.graduated_at" />:{' '}
                                            {getTimeString(course.achieved)}
                                        </p>
                                    )}
                                </ListItem>
                            )
                        })}
                    </div>

                    {this.state.editCourse !== undefined &&
                        this.getCoursesModal(true, this.state.editCourse)}

                    {this.state.addCourse && this.getCoursesModal()}
                </Panel>
            )
        } else {
            return (
                <Panel
                    className="panel--suggestion panel--multi__inner"
                    headerText={translate('profile.courses')}
                    headerIcon="icon-book-open"
                    addButton={
                        <ButtonPanel
                            buttonText={translate('profile.add_new')}
                            className="button--cta"
                            onClick={() => {
                                this.setState({
                                    addCourse: true,
                                })
                            }}
                        />
                    }
                >
                    <Suggestion iconName="icon-book-open">
                        <Translate value="profile.no_courses" />
                    </Suggestion>

                    {this.state.addCourse && this.getCoursesModal()}
                </Panel>
            )
        }
    }
}

function mapStateToProps(state) {
    const { auth, config, user } = state

    return {
        auth,
        config,
        user,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(CoursesPanel)

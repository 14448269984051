import { PAGINATE_ITEM_NO } from '../../../constants/Constants'
import Panel from '../../Panel/Panel'
import { translate, Translate } from 'react-i18nify'
import ButtonPanel from '../../Button/ButtonPanel'
import ListItem from '../../ListItem/ListItem'
import { getTimeString } from '../helpers'
import Pagination from '../../Pagination/Pagination'
import Suggestion from '../../Suggestion/Suggestion'
import ModalForm from '../../Modal/ModalForm'
import FormWorkExperience from '../../Forms/FormWorkExperience'
import { connect } from 'react-redux'
import React, { Component } from 'react'

class JobExperiencePanel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            editJob: undefined,
            addJob: false,
            jobExperiencesPage: 0,
            allowEditJobExperiences: false,
        }
    }

    getJobExperienceModal(edit = false, job = {}) {
        if (edit) {
            return (
                <ModalForm
                    title={translate('profile.change_experience')}
                    show
                    onClose={() => {
                        this.setState({
                            editJob: undefined,
                        })
                    }}
                >
                    <FormWorkExperience
                        areas={this.props.user.working_areas}
                        onCancel={() => {
                            this.setState({
                                editJob: undefined,
                            })
                        }}
                        onSave={() => {
                            this.setState({
                                editJob: undefined,
                            })
                            this.props.showAlert()
                        }}
                        className="form"
                        dispatch={this.props.dispatch}
                        auth={this.props.auth}
                        user={this.props.user}
                        edit={job}
                        config={this.props.config}
                    />
                </ModalForm>
            )
        } else {
            return (
                <ModalForm
                    title={translate('profile.add_experience')}
                    show
                    onClose={() => {
                        this.setState({
                            addJob: false,
                        })
                    }}
                >
                    <FormWorkExperience
                        areas={this.props.user.working_areas}
                        onCancel={() => {
                            this.setState({
                                addJob: false,
                            })
                        }}
                        onSave={() => {
                            this.setState({
                                addJob: false,
                            })
                            this.props.showAlert()
                        }}
                        className="form"
                        dispatch={this.props.dispatch}
                        auth={this.props.auth}
                        user={this.props.user}
                    />
                </ModalForm>
            )
        }
    }

    render() {
        let experiences = this.props.user.profile.job_functions
        if (experiences !== undefined && experiences.length > 0) {
            const sliced = experiences.slice(
                PAGINATE_ITEM_NO * this.state.jobExperiencesPage,
                PAGINATE_ITEM_NO * this.state.jobExperiencesPage + PAGINATE_ITEM_NO,
            )

            return (
                <Panel
                    className={'panel--multi__inner'}
                    headerText={translate('profile.experience')}
                    headerIcon="icon-settings"
                    addButton={
                        <ButtonPanel
                            buttonText={translate('profile.add_new')}
                            className="button--cta"
                            onClick={() => {
                                this.setState({
                                    addJob: true,
                                })
                            }}
                        />
                    }
                >
                    <div className="panel__list">
                        {sliced.map((item) => {
                            return (
                                <ListItem
                                    key={item.id + '-' + item.function_title}
                                    onEdit={() => {
                                        this.setState({
                                            editJob: item,
                                        })
                                    }}
                                    onChange={() => {
                                        this.props.showAlert()
                                    }}
                                    dispatch={this.props.dispatch}
                                    auth={this.props.auth}
                                    type="job_functions"
                                    item={item}
                                    showEdit={this.state.allowEditJobExperiences}
                                    title={item.function_title}
                                >
                                    <p>
                                        {getTimeString(
                                            item.start_date,
                                            item.end_date,
                                            item.current_position,
                                        )}
                                    </p>
                                    <h3>
                                        <span>{item.function_title}</span>
                                        <br />
                                        {item.company_name}
                                    </h3>
                                    <p>{item.city}</p>
                                </ListItem>
                            )
                        })}
                        <Pagination
                            pageCount={Math.ceil(experiences.length / PAGINATE_ITEM_NO)}
                            onPageChange={(p) => this.setState({ jobExperiencesPage: p.selected })}
                        />
                    </div>

                    {this.state.editJob !== undefined &&
                        this.getJobExperienceModal(true, this.state.editJob)}

                    {this.state.addJob && this.getJobExperienceModal()}
                </Panel>
            )
        } else {
            return (
                <Panel
                    className="panel--suggestion panel--multi__inner"
                    headerText={translate('profile.experience')}
                    headerIcon="icon-settings"
                    addButton={
                        <ButtonPanel
                            buttonText={translate('profile.add_new')}
                            className="button--cta"
                            onClick={() => {
                                this.setState({
                                    addJob: true,
                                })
                            }}
                        />
                    }
                >
                    <Suggestion iconName="icon-settings">
                        <Translate value="profile.no_experience" />
                    </Suggestion>

                    {this.state.addJob && this.getJobExperienceModal()}
                </Panel>
            )
        }
    }
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(JobExperiencePanel)

import React, { Component } from 'react'
import Panel from '../../Panel/Panel'
import { translate, Translate } from 'react-i18nify'
import ButtonPanel from '../../Button/ButtonPanel'
import { Col, Row } from 'react-grid-system'
import Tag from '../../Tag/Tag'
import { diffProfileItems } from '../../../store/actions/user'
import Suggestion from '../../Suggestion/Suggestion'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { RolesGenerator } from './RolesGenerator'

class RolesPanel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            addRole: false,
            confirmCardsRedirect: false,
        }
    }

    render() {
        let { roles } = this.props.user.profile

        const panelClassname = classNames('panel--multi__inner', {
            'panel--expand tags': roles && roles.length > 20,
            'panel--suggestion': roles && !roles.length,
        })

        return (
            <Panel
                className={panelClassname}
                headerText={translate('profile.roles')}
                headerIcon="icon-people"
                addButton={
                    <ButtonPanel
                        buttonText={translate('profile.add_new')}
                        className="button--cta"
                        onClick={() => {
                            this.setState({
                                addRole: true,
                            })
                        }}
                    />
                }
            >
                {roles && roles.length > 0 ? (
                    <div className="panel__form">
                        <Row>
                            <Col sm={12}>
                                <div className="panel__tags">
                                    {roles.map((item) => {
                                        return (
                                            <Tag
                                                key={item.id}
                                                score={1}
                                                onRemove={() => {
                                                    this.props.dispatch(
                                                        diffProfileItems(
                                                            'competence_roles',
                                                            [],
                                                            [item.id],
                                                            this.props.auth,
                                                        ),
                                                    )
                                                }}
                                                showEdit={false}
                                                title={item.name}
                                            >
                                                {item.name}
                                            </Tag>
                                        )
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <Suggestion iconName="icon-people">
                        <Translate value="profile.no_roles" />
                    </Suggestion>
                )}

                {this.state.addRole && (
                    <RolesGenerator
                        handleClose={() => {
                            this.setState({
                                addRole: false,
                            })
                        }}
                        handleSave={() => {
                            this.setState({
                                addRole: false,
                            })
                            this.props.showAlert()
                        }}
                    />
                )}
            </Panel>
        )
    }
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(RolesPanel)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { saveAs } from 'file-saver'
import Loading from '../Loading/Loading'
import newCallApi from '../../store/actions/api'
import { translate, Translate } from 'react-i18nify'
import { getDocumentsUrl } from '../../store/actions/user'
import Success from './Success'

export default class FileLister extends Component {
    constructor() {
        super()
        this.state = {
            fetching: false,
            loading: true,
            success: false,
            filesUploaded: [],
            selectedFile: null,
        }
    }

    downloadFile(file) {
        let url = getDocumentsUrl(this.props.userId || this.props.auth.id, file.id)
        this.setState(
            {
                fetching: true,
            },
            () => {
                newCallApi('GET', url, null, /* auth */ null, true, true, 'blob').then((blob) => {
                    this.setState(
                        {
                            success: true,
                            fetching: false,
                        },
                        () => {
                            setTimeout(() => {
                                this.setState({
                                    success: false,
                                })
                            }, 2500)
                        },
                    )

                    saveAs(blob, file.name)
                })
            },
        )
    }

    selectFile(file) {
        if (this.props.onFileSelected) {
            this.props.onFileSelected(file)
        }
    }

    deleteFile(file) {
        // TODO: Modal instead of confirm
        let p = window.confirm('Are you sure you want to delete ' + file.name + '?')
        if (p) {
            this.setState({ deleting: true })
            let url = getDocumentsUrl(this.props.userId || this.props.auth.id, file.id)
            newCallApi('DELETE', url, null, /* auth */ null, false, false)
                .then(() => {
                    this.refreshUploadedList()
                    if (this.props.onFileDeleted) {
                        this.props.onFileDeleted(file)
                    }
                })
                .catch(() => {})
        }
    }

    refreshUploadedList() {
        if (this.props.auth) {
            let url = getDocumentsUrl(this.props.userId || this.props.auth.id)
            newCallApi('GET', url + '?code=' + this.props.type, null, /* auth */ null).then(
                (json) => {
                    this.setState({ filesUploaded: json })
                    this.setState({ loading: false })
                    this.setState({ deleting: false })
                },
                () => {},
            )
        }
    }

    componentWillMount() {
        if (this.props.files) {
            this.setState({ filesUploaded: this.props.files, loading: false })
        } else {
            this.refreshUploadedList()
        }
    }

    componentDidMount() {}

    render() {
        return (
            <div className="filelister">
                {this.state.fetching ? (
                    <Loading text={translate('profile.fetching_file')} />
                ) : this.state.deleting ? (
                    <Loading text={translate('profile.deleting_file')} />
                ) : this.state.uploading ? (
                    <Loading text={translate('profile.upload') + '...'} />
                ) : this.state.success ? (
                    <Success text={translate('profile.downloading_file')} />
                ) : (
                    <div>
                        {this.state.loading ? (
                            <Loading text={translate('profile.loading_files')} />
                        ) : this.state.filesUploaded.length === 0 ? (
                            <p>{translate('vacancies.no_files')}</p>
                        ) : (
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <h4>
                                                <Translate value="profile.file_name" />
                                            </h4>
                                        </th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.filesUploaded.map((file) => (
                                        <tr key={file.id} className="hover--darkerclass">
                                            <td>
                                                <i className={'icons icon-doc'} />
                                                <span>{file.name}</span>
                                            </td>
                                            <td>
                                                <div className="actions">
                                                    {this.props.onFileSelected && (
                                                        <Link
                                                            className="actions__action--delete"
                                                            onClick={this.selectFile.bind(
                                                                this,
                                                                file,
                                                            )}
                                                        >
                                                            <Translate value="profile.select" />
                                                        </Link>
                                                    )}
                                                    {!this.props.hideDelete && (
                                                        <Link
                                                            className="actions__action--delete"
                                                            onClick={this.deleteFile.bind(
                                                                this,
                                                                file,
                                                            )}
                                                        >
                                                            <Translate value="profile.remove" />
                                                        </Link>
                                                    )}
                                                    <Link
                                                        className="actions__action--download"
                                                        onClick={this.downloadFile.bind(this, file)}
                                                    >
                                                        <Translate value="profile.download" />
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                )}
            </div>
        )
    }
}

FileLister.propTypes = {
    auth: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    onFileDeleted: PropTypes.func,
    type: PropTypes.string,
    hideDelete: PropTypes.bool,
    files: PropTypes.array,
    userId: PropTypes.number,
    onFileSelected: PropTypes.func,
}

import React, { useState } from 'react'
import { translate } from 'react-i18nify'

import classNames from 'classnames'
import { searchCities } from '../../store/actions/jobOffers'
import AsyncSelect from 'react-select/async'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

const getCitySuggestions = (auth) => (input) =>
    searchCities(auth, input).then((resp) =>
        resp.map((c) => ({ ...c, value: c.id, label: c.name })),
    )

export const CitySelector = ({ controller, className = 'select--alt', ...props }) => {
    const { error, value, onChange } = controller
    const [query, setQuery] = useState(null)
    const auth = useSelector((state) => state.auth)
    const search = getCitySuggestions(auth)

    return (
        <AsyncSelect
            name="city"
            className={classNames(className, {
                error: error,
                'has-value': value,
            })}
            classNamePrefix="select"
            loadOptions={(loadQuery) => {
                setQuery(loadQuery)
                return search(loadQuery)
            }}
            onMenuOpen={() => {
                setQuery(null)
                onChange(null)
            }}
            value={value}
            isSearchable={true}
            clearable={true}
            onChange={(s) => onChange(s)}
            loadingMessage={() => translate('texts.hint_loading')}
            noOptionsMessage={() =>
                !query
                    ? translate('onboarding.typeYourCity')
                    : translate('onboarding.noCitiesFound')
            }
            placeholder={translate('onboarding.cityPlaceholder')}
            {...props}
        />
    )
}

const controllerShape = {
    value: PropTypes.any,
    error: PropTypes.any,
    onChange: PropTypes.func.isRequired,
}

CitySelector.propTypes = {
    controller: PropTypes.shape(controllerShape).isRequired,
    className: PropTypes.string,
}

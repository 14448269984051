import Panel from '../../Panel/Panel'
import { translate, Translate } from 'react-i18nify'
import Actions from '../../Actions/Actions'
import { Link } from 'react-router-dom'
import { Col, Row, Hidden } from 'react-grid-system'
import Button from '../../Button/Button'
import { exportPortfolioEmail, fetchDocumentsList } from '../../../store/actions/user'
import React, { Component } from 'react'
import ModalWindow from '../../Modal/Modal'
import FileUploader from '../../FileUploader/FileUploader'
import FileLister from '../../FileLister/FileLister'
import newCallApi from '../../../store/actions/api'
import { saveAs } from 'file-saver'
import { connect } from 'react-redux'

class DocumentsPanel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            downloadBtnText: translate('profile.email_attachment'),
        }
    }

    exportPortfolioDownload() {
        if (!!new Blob()) {
            newCallApi(
                'GET',
                this.props.config.API_DOWNLOAD_EXPORT,
                null,
                /* auth */ null,
                true,
                true,
                'blob',
            ).then(function (blob) {
                document && saveAs(blob)
            })
        } else {
            alert('Your browser does not support downloading files.')
        }
    }

    getDocumentsModals(files_to_upload) {
        const fileSelectCallBack = () => {
            this.setState({ uploaddoc: undefined })
            this.props.dispatch(fetchDocumentsList(this.props.auth))
        }

        return (
            <div>
                {this.state.uploaddoc && (
                    <ModalWindow
                        show={true}
                        title={translate('vacancies.select_a_file')}
                        onClose={() => {
                            this.setState({
                                uploaddoc: undefined,
                            })
                        }}
                    >
                        <FileUploader
                            onUploaded={fileSelectCallBack}
                            allowedTypes={files_to_upload}
                            type={this.state.uploaddoc}
                            auth={this.props.auth}
                        />
                    </ModalWindow>
                )}

                {this.state.viewdoc && (
                    <ModalWindow
                        show={true}
                        title={this.state.viewdoc.name}
                        onClose={() => {
                            this.setState({
                                viewdoc: undefined,
                            })
                        }}
                    >
                        <FileLister
                            dispatch={this.props.dispatch}
                            type={this.state.viewdoc.code}
                            auth={this.props.auth}
                            onFileDeleted={fileSelectCallBack}
                        />
                    </ModalWindow>
                )}
            </div>
        )
    }

    render() {
        let files_to_upload = this.props.user.file_upload_types
        let showProfileExport = this.props.showProfileExport

        return (
            <div>
                {files_to_upload && typeof files_to_upload !== 'undefined' && (
                    <div>
                        {files_to_upload.allowed_document_types &&
                            files_to_upload.allowed_document_types.length > 0 && (
                                <div>
                                    <Panel
                                        className="panel--multi__inner"
                                        headerText={translate('profile.upload_files')}
                                        headerIcon="icon-cloud-upload"
                                    >
                                        <table className="panel__table">
                                            <tbody>
                                                {files_to_upload.allowed_document_types.map(
                                                    (uploadDocument) => {
                                                        return (
                                                            <tr
                                                                key={
                                                                    uploadDocument.id +
                                                                    '-' +
                                                                    uploadDocument.name
                                                                }
                                                            >
                                                                <td>{uploadDocument.name}</td>
                                                                <td className="table__cell--icon">
                                                                    {uploadDocument.count &&
                                                                    uploadDocument.count !== 0
                                                                        ? uploadDocument.count + ' '
                                                                        : translate('common.none') +
                                                                          ' '}
                                                                    {translate(
                                                                        'profile.uploaded_files',
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <Actions>
                                                                        {!!(
                                                                            uploadDocument.count &&
                                                                            parseInt(
                                                                                uploadDocument.count,
                                                                            ) > 0
                                                                        ) && (
                                                                            <Link
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        viewdoc:
                                                                                            uploadDocument,
                                                                                    })
                                                                                }}
                                                                                className="actions__action--view"
                                                                            >
                                                                                {translate(
                                                                                    'buttons.view',
                                                                                )}
                                                                            </Link>
                                                                        )}
                                                                        <Link
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    uploaddoc:
                                                                                        uploadDocument.code,
                                                                                })
                                                                            }}
                                                                            className="actions__action--upload"
                                                                        >
                                                                            {translate(
                                                                                'buttons.upload',
                                                                            )}
                                                                        </Link>
                                                                    </Actions>
                                                                </td>
                                                            </tr>
                                                        )
                                                    },
                                                )}
                                            </tbody>
                                        </table>
                                    </Panel>
                                </div>
                            )}

                        {(!files_to_upload ||
                            !files_to_upload.allowed_document_types ||
                            files_to_upload.allowed_document_types.length <= 0) && (
                            <Panel
                                className="panel--multi__inner"
                                headerText={translate('profile.upload_files')}
                                headerIcon="icon-cloud-upload"
                            >
                                <p>
                                    <Translate value="profile.no_uploading" />
                                </p>
                            </Panel>
                        )}
                    </div>
                )}

                {showProfileExport && (
                    <>
                        <div className="spacer-hr" />

                        <Panel
                            className="panel--multi__inner"
                            headerText={translate('profile.export')}
                            headerIcon="icon-cloud-download"
                        >
                            <p>{translate('profile.export_message')}</p>

                            <Row>
                                <Col md={6} lg={5}>
                                    <Button
                                        buttonText={translate('profile.download_zip')}
                                        className="button--cta"
                                        onClick={() => {
                                            this.props.dispatch(() => {
                                                this.exportPortfolioDownload()
                                            })
                                        }}
                                    />
                                </Col>
                                <Hidden xs sm md>
                                    <Col lg={2} />
                                </Hidden>
                                <Col md={6} lg={5}>
                                    <Button
                                        buttonText={this.state.downloadBtnText}
                                        className="button--cta"
                                        onClick={() => {
                                            this.setState({
                                                downloadBtnText: translate('profile.emailing'),
                                            })
                                            this.props.dispatch(() => {
                                                exportPortfolioEmail(this.props.auth).then(() => {
                                                    this.setState({
                                                        downloadBtnText:
                                                            translate('profile.emailed'),
                                                    })
                                                    setTimeout(() => {
                                                        this.setState({
                                                            downloadBtnText: translate(
                                                                'profile.email_attachment',
                                                            ),
                                                        })
                                                    }, 2000)
                                                })
                                            })
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Panel>
                        <div className="spacer" />
                    </>
                )}

                {this.getDocumentsModals(this.props.user.file_upload_types)}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth, config, user } = state

    return {
        auth,
        config,
        user,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(DocumentsPanel)

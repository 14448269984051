import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import { translate } from 'react-i18nify'

import Button from '../components/Button/Button'
import GridCol from '../components/GridCol/GridCol'
import GridColOffset from '../components/GridCol/GridColOffset'
import Page from '../components/Page/Page'
import history from '../history'

class PrivacyAndTerms extends Component {
    render() {
        // injected by connect call
        const { dispatch } = this.props

        return (
            <div className="react-native-web">
                <Container>
                    <Row>
                        <GridColOffset />
                        <GridCol>
                            <Page>
                                <h1>Privacy & Voorwaarden</h1>
                                <p>
                                    <strong>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Donec posuere ligula ut consectetur eleifend. Ut placerat
                                        egestas dolor, ac bibendum dolor facilisis quis. Maecenas
                                        lacinia urna a elit ornare consectetur. Curabitur porta
                                        augue vel volutpat suscipit. Nam vel lectus et velit mollis
                                        volutpat vitae vitae turpis. Cras euismod dignissim
                                        pharetra. Praesent egestas magna et tortor eleifend
                                        hendrerit. Aenean eget molestie ante. Cras non magna libero.
                                        Integer quis quam ultrices, finibus nunc vulputate,
                                        fermentum odio.
                                    </strong>
                                </p>
                                <h2>Subheader</h2>
                                <p>
                                    Sed rutrum efficitur nisi a tincidunt. Aliquam augue turpis,
                                    lacinia at dignissim vel, luctus in lacus. Pellentesque sit amet
                                    metus non eros ultricies luctus in eu elit. Curabitur nec congue
                                    nulla. Curabitur et nisi maximus, ultrices mi dignissim, aliquet
                                    metus. Fusce commodo dui sed lacus pharetra, ac mattis tortor
                                    placerat. Maecenas sed lectus hendrerit quam fermentum
                                    consectetur sit amet ut tortor. Sed vitae interdum magna, in
                                    tempus odio. Pellentesque tincidunt libero eros, id fringilla
                                    justo vestibulum eu. Ut placerat dolor a facilisis semper.
                                    Aliquam porttitor mi quis elementum rhoncus. In luctus blandit
                                    condimentum. Nulla facilisi.
                                </p>
                                <h2>Subheader</h2>
                                <p>
                                    Nulla consectetur a arcu quis scelerisque. Etiam magna ligula,
                                    placerat vitae finibus eget, hendrerit quis risus. Aenean eget
                                    mauris lobortis, congue magna id, pulvinar lacus. Phasellus
                                    congue gravida elit, sit amet semper purus cursus rhoncus. Sed
                                    faucibus aliquam eros eu tempor. Fusce et gravida tortor. Ut
                                    lacinia tellus sit amet magna lacinia, vel sodales nisi luctus.
                                    Sed euismod sollicitudin lacus vel efficitur. Integer sit amet
                                    posuere augue, quis molestie purus. Aenean augue augue, gravida
                                    eget est eu, fermentum porta erat. Nulla ex neque, luctus vel
                                    purus quis, faucibus porta velit. Nulla elementum mi a turpis
                                    semper luctus. Sed eget ultricies mi, et pulvinar nunc. Mauris
                                    viverra laoreet enim, ut hendrerit enim efficitur eget.
                                    Curabitur ac velit sed nulla tempus pretium.
                                </p>
                                <h2>Subheader</h2>
                                <p>
                                    Phasellus a vehicula augue. Curabitur nec metus mattis,
                                    consectetur elit sit amet, vehicula leo. Curabitur diam elit,
                                    ullamcorper nec nunc nec, sollicitudin iaculis lorem. Ut id quam
                                    ipsum. Quisque quis posuere justo, in pretium est. In eleifend,
                                    purus sed varius lacinia, dolor sem ultrices felis, nec volutpat
                                    augue nisl sit amet augue. Duis non ante sem. Phasellus suscipit
                                    commodo tortor in commodo. Duis justo nibh, posuere vel
                                    efficitur ut, pharetra in lectus. Ut cursus vel nisl faucibus
                                    pretium. Etiam sit amet metus et justo iaculis volutpat. Vivamus
                                    dictum nec sapien eu fermentum. Fusce aliquam iaculis magna,
                                    euismod porttitor elit scelerisque efficitur. Cum sociis natoque
                                    penatibus et magnis dis parturient montes, nascetur ridiculus
                                    mus. In hac habitasse platea dictumst. Donec consequat turpis et
                                    hendrerit laoreet.
                                </p>
                                <p>
                                    Sed massa purus, tincidunt eget ultrices vitae, placerat ut dui.
                                    Nam luctus quam nec diam pretium pretium. Ut quis sollicitudin
                                    dui. Ut vel hendrerit turpis, id vehicula velit. Maecenas
                                    consequat mauris at nibh porttitor sodales. Quisque id commodo
                                    turpis. Vestibulum tristique turpis volutpat nulla tristique
                                    condimentum. Sed auctor porta odio. Nullam ac bibendum mauris,
                                    sit amet condimentum urna. Vivamus non vestibulum turpis.
                                    Aliquam erat volutpat. Sed porttitor magna fringilla efficitur
                                    pharetra. Pellentesque a sem sed nulla fermentum porttitor.
                                    Maecenas vel dui non urna aliquet ullamcorper. Morbi egestas,
                                    urna a interdum varius, enim mauris vulputate turpis, sed
                                    iaculis nibh mi tempor urna. Sed ultrices, ipsum sit amet
                                    laoreet placerat, augue neque dignissim ex, condimentum ultrices
                                    diam tortor a eros.
                                </p>
                                <Button
                                    buttonText={translate('buttons.previous_page')}
                                    className="button--alt inline-block"
                                    onClick={history.goBack}
                                />
                            </Page>
                        </GridCol>
                    </Row>
                </Container>
            </div>
        )
    }
}

PrivacyAndTerms.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

const select = (state) => state

// Wrap the component to inject dispatch and state into it
export default connect(select)(PrivacyAndTerms)

import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class CheckboxList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            expanded: false,
            categoryChecked: false,
            objectBool: false,
            defChecked: false,
            firstChange: true,
            checkedNew: [],
            disabled: this.props.arr ? this.props.arr.length === 0 : true,
            personalData: [
                'name',
                'date_of_birth',
                'place_of_birth',
                'email',
                'address',
                'phone_number',
                'mobile_number',
            ],
            personalObjects: [],
        }
    }

    componentDidMount() {
        if (this.props.edit) {
            let checkedNew = this.state.checkedNew
            if (this.props.checked !== undefined) {
                this.props.checked.forEach((item) => {
                    if (item) {
                        if (!this.state.objectBool) {
                            checkedNew.push(item)
                        } else {
                            checkedNew.push(item)
                        }
                    }
                }, this)

                this.setState({
                    checkedNew: checkedNew,
                })
            }
        }

        this.setState({
            expanded: this.props.expanded,
        })

        if (Object.getPrototypeOf(this.props.arr) === Object.prototype) {
            this.setState(
                {
                    objectBool: true,
                },
                () => {
                    this.autoCheck()
                },
            )

            let arrObjects = Object.getOwnPropertyNames(this.props.arr)
            {
                arrObjects.map((item) => {
                    if (this.state.personalData.indexOf(item) > -1) {
                        if (
                            this.state.personalObjects.indexOf(item) === -1 &&
                            this.props.arr[item] !== null &&
                            this.props.arr[item] !== undefined
                        ) {
                            this.state.personalObjects.push(item)
                        }
                    }
                }, this)
            }
        } else {
            this.setState(
                {
                    objectBool: false,
                },
                () => {
                    this.autoCheck()
                },
            )
        }

        if (this.props.defChecked !== undefined) {
            this.setState({
                defChecked: true,
                expanded: true,
            })
        }
    }

    createAddressLine() {
        if (!this.props.arr.postal_code && !this.props.arr.city_name) {
            return `${this.props.arr.address || ''} ${this.props.arr.address_number || ''}${
                this.props.arr.address_number_suffix || ''
            }`
        }

        return `${this.props.arr.address || ''} ${this.props.arr.address_number || ''}${
            this.props.arr.address_number_suffix || ''
        }, ${this.props.arr.postal_code || ''} ${this.props.arr.city_name || ''}`
    }

    autoCheck() {
        if (this.state.defChecked) {
            let checkedNew = this.state.checkedNew
            if (this.state.objectBool) {
                this.state.personalObjects.map(function (item) {
                    checkedNew.push(this.props.arr[item.id])
                    this.setState({
                        checkedNew: checkedNew,
                    })
                }, this)
            } else {
                this.props.arr.map(function (item) {
                    checkedNew.push(this.props.arr[item])
                    this.setState(
                        {
                            checkedNew: checkedNew,
                        },
                        () => {
                            this.props.onAutoCheck(checkedNew)
                        },
                    )
                }, this)
            }
        }
    }

    getStudyName(study) {
        let other = study.study_name_other
        let name
        if (other == null || other == undefined || other.length == 0) {
            let studies = this.props.studies
            let study_id = study.study_id
            for (var i = 0; i < studies.length; i++) {
                let s = studies[i]

                if (s.id == study_id) {
                    name = s.name
                    break
                }
            }
        } else {
            name = other
        }
        return name
    }

    onCheckboxChange(checked, item) {
        if (!this.isChecked(item)) {
            this.check(item)
        } else if (this.isChecked(item)) {
            this.uncheck(item)
        }
        this.props.onCheckChange(this.state.checkedNew)
    }

    onCheckboxChangeMain(arr) {
        if (this.props.checked.length > 0 && this.state.firstChange) {
            this.setState(
                {
                    categoryChecked: true,
                    firstChange: false,
                },
                () => {
                    this.checkMain(arr)
                },
            )
        } else {
            this.checkMain(arr)
        }
    }

    checkMain(arr) {
        let checkedNew = this.state.checkedNew

        if (!this.state.objectBool && !this.state.defChecked) {
            // if user checks a category, all items within are automatically checked.
            if (!this.state.categoryChecked) {
                arr.map(function (item, index) {
                    if (!this.isChecked(item)) {
                        this.check(item)
                    }
                }, this)
                this.setState({
                    categoryChecked: true,
                })
            }

            // if user unchecks a category, all items within are automatically unchecked.
            else {
                {
                    arr.map(function (item) {
                        if (this.isChecked(item)) {
                            this.uncheck(item)
                        }
                    }, this)
                }
                this.setState({
                    categoryChecked: false,
                })
            }
        } else if (this.state.objectBool && !this.state.defChecked) {
            if (!this.state.categoryChecked) {
                this.state.personalObjects.map(function (item, index) {
                    if (!this.isChecked(this.props.arr[item])) {
                        this.check(this.props.arr[item])
                    }
                }, this)
                this.setState({
                    categoryChecked: true,
                })
            } else {
                {
                    this.state.personalObjects.map(function (item) {
                        if (this.isChecked(this.props.arr[item])) {
                            this.uncheck(this.props.arr[item])
                        }
                    }, this)
                }
                this.setState({
                    categoryChecked: false,
                })
            }
        }
        this.props.onToggleAll(checkedNew)
    }

    isChecked(item) {
        let checkedNew = this.state.checkedNew || [] // array with all checked item id's
        let propsChecked = this.props.checked || []
        if (this.state.defChecked) {
            return true
        } else if (!this.state.objectBool) {
            return checkedNew.indexOf(item.id) > -1 || propsChecked.indexOf(item.id) > -1 // return whether or not the item is in the "checked" array
        } else {
            return checkedNew.indexOf(item) > -1 || propsChecked.indexOf(item) > -1 // return whether or not the item is in the "checked" array
        }
    }

    getKeyByValue(object, value) {
        return Object.keys(object).find((key) => object[key] === value)
    }

    check(item) {
        let checkedNew = this.state.checkedNew // array with all checked item id's

        if (!this.state.objectBool) {
            checkedNew.push(item.id)
            this.setState({
                checkedNew: checkedNew,
            })
        } else {
            checkedNew.push(item)
            this.setState({
                checkedNew: checkedNew,
            })
        }

        if (this.state.checkedNew.length === 1) {
            this.setState({
                categoryChecked: true,
            })
        }
    }

    uncheck(item) {
        let checkedNew = this.state.checkedNew
        let key = this.getKeyByValue(this.props.arr, item)

        if (!this.state.objectBool && !this.state.defChecked) {
            checkedNew.splice(checkedNew.indexOf(item.id), 1)
            this.setState({
                checkedNew: checkedNew,
            })
        } else if (!this.state.defChecked) {
            checkedNew.splice(checkedNew.indexOf(item), 1)
            this.setState({
                checkedNew: checkedNew,
            })
        }

        if (this.state.checkedNew.length === 0) {
            this.setState({
                categoryChecked: false,
            })
        }
    }

    toggleTable() {
        if (this.state.expanded) {
            this.setState({
                expanded: false,
            })
        } else {
            this.setState({
                expanded: true,
            })
        }
    }

    render() {
        const { itemName, title, itemText, itemSubText } = this.props
        return (
            <li
                className={
                    (this.state.expanded ? 'expanded' : '') +
                    ' ' +
                    (this.state.disabled ? 'disabled' : '')
                }
            >
                <div className="checkbox_list__clickable" onClick={() => this.toggleTable()}>
                    <div className="form__checkbox">
                        <input
                            type="checkbox"
                            className="form__checkbox__input"
                            name={itemName}
                            id={itemName}
                            disabled={this.state.disabled}
                            onChange={(checked) => {
                                this.onCheckboxChangeMain(this.props.arr)
                            }}
                            checked={
                                this.state.categoryChecked ||
                                this.state.defChecked ||
                                (this.props.checked !== undefined && this.props.checked.length > 0)
                            }
                        />
                        <label
                            className={
                                'form__checkbox__label' +
                                ' ' +
                                (this.state.defChecked ? 'checked_box' : '')
                            }
                            htmlFor={itemName}
                        />
                    </div>
                    <div className="checkbox_list__title">
                        {title}
                        <i
                            className={
                                'checkbox_list__fold_icon icons' +
                                ' ' +
                                (this.state.expanded ? ' icon-arrow-up' : 'icon-arrow-down')
                            }
                        />
                    </div>
                </div>

                {this.state.expanded && !this.state.objectBool && (
                    <ul className="checkbox_list__sub">
                        {this.props.arr.map(function (item, index) {
                            let text = itemText.replace(/['"]+/g, '')
                            let subText = itemSubText.replace(/['"]+/g, '')
                            return (
                                <li
                                    key={'item_' + item.id}
                                    onClick={(checked) => {
                                        this.onCheckboxChange(checked, item)
                                    }}
                                >
                                    <div className="form__checkbox ">
                                        <input
                                            type="checkbox"
                                            className="form__checkbox__input"
                                            name={itemName + index}
                                            id={itemName + index}
                                            onChange={(checked) => {
                                                this.onCheckboxChange(checked, item)
                                            }}
                                            checked={this.isChecked(item) || this.state.defChecked}
                                        />
                                        <label
                                            className="form__checkbox__label"
                                            htmlFor={itemName + index}
                                        >
                                            {' '}
                                        </label>
                                    </div>
                                    {itemName !== 'education' && (
                                        <span className="checkbox_list__text">
                                            {item[text]}
                                            <br />
                                            <i>{item[subText]}</i>
                                        </span>
                                    )}
                                    {itemName === 'education' && (
                                        <span className="checkbox_list__text">
                                            {this.getStudyName(item)}
                                            <br />
                                            <i>{item[subText]}</i>
                                        </span>
                                    )}
                                </li>
                            )
                        }, this)}
                    </ul>
                )}

                {this.state.expanded && this.state.objectBool && (
                    <ul className="checkbox_list__sub">
                        {this.state.personalObjects.map(function (item, index) {
                            return (
                                <li key={'item_' + this.props.arr[item]}>
                                    <div className="form__checkbox">
                                        <input
                                            type="checkbox"
                                            className="form__checkbox__input"
                                            name={'personal' + index}
                                            id={'personal' + index}
                                            onChange={(checked) => {
                                                this.onCheckboxChange(checked, this.props.arr[item])
                                            }}
                                            checked={
                                                this.isChecked(this.props.arr[item]) ||
                                                this.state.defChecked
                                            }
                                        />
                                        <label
                                            className="form__checkbox__label"
                                            htmlFor={'personal' + index}
                                        >
                                            {' '}
                                        </label>
                                    </div>
                                    {item !== 'address' && (
                                        <span className="checkbox_list__text">
                                            {this.props.arr[item]}
                                        </span>
                                    )}

                                    {item === 'address' && (
                                        <span className="checkbox_list__text">
                                            {this.createAddressLine()}
                                        </span>
                                    )}
                                </li>
                            )
                        }, this)}
                    </ul>
                )}
            </li>
        )
    }
}

CheckboxList.propTypes = {
    itemName: PropTypes.string,
    defChecked: PropTypes.bool,
    arr: PropTypes.any.isRequired,
    checked: PropTypes.array,
    title: PropTypes.string,
    itemText: PropTypes.string,
    itemSubText: PropTypes.string,
    onCheckChange: PropTypes.func,
    onToggleAll: PropTypes.func,
    onAutoCheck: PropTypes.func,
    edit: PropTypes.bool,
    studies: PropTypes.array,
    expanded: PropTypes.bool,
}

CheckboxList.defaultProps = {
    expanded: false,
}

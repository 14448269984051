import React, { Component } from 'react'

export default class Quote extends Component {
    render() {
        return (
            <h3 className="quote">
                <span className="quote__quotation_mark">&ldquo;</span>
                {this.props.children}
                <span className="quote__quotation_mark">&rdquo;</span>
            </h3>
        )
    }
}

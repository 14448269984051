import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Actions from '../Actions/Actions'
import { connect } from 'react-redux'
import { translate, Translate } from 'react-i18nify'
import { addClass, getErrorFields, getErrorMessages, removeClass } from '../../helpers'
import Alert from '../Alert/Alert'
import moment from 'moment'
import 'react-dates/lib/css/_datepicker.css'
import { saveAs } from 'file-saver'
import newCallApi from '../../store/actions/api'
import Datepicker from '../Datepicker/Datepicker'

let map

class FormApplications extends Component {
    constructor(props) {
        super(props)

        this.state = {
            vacancy: '',
            company: '',
            dateApplied: moment(),
            attachment: '',
            result: 'in_progress',
            files: [],
            loading: false,
            errors: [],
            editing: false,
        }
    }

    componentDidMount() {
        if (this.props.editItem) {
            this.setState({
                vacancy: this.props.editItem.vacancy,
                result: this.props.editItem.result,
                company: this.props.editItem.company,
                dateApplied: moment(this.props.editItem.date),
                files: this.props.editItem.files,
                editing: true,
            })
        }
    }

    handleChange(value, key, changeClass = true) {
        let obj = {}
        try {
            obj[key] = key === 'attachment' ? value.target.files[0] : value.target.value
        } catch (error) {
            obj[key] = value.target.value
        }

        this.setState(obj)

        if (changeClass) {
            if (value.target.value.length > 0) {
                addClass(value.currentTarget, 'has-value')
            } else {
                removeClass(value.currentTarget, 'has-value')
            }
        }
    }

    cancel() {
        this.state = {
            vacancy: '',
            company: '',
            result: 'in_progress',
            dateApplied: '',
            files: [],
            loading: false,
            attachment: '',
            errors: [],
            editing: false,
        }

        this.props.onCancel()
    }

    refresh() {
        if (!this.state.editing || !this.props.editItem) return
        this.setState({ loading: true })
        newCallApi(
            'GET',
            this.props.config.API_APPLICATION.replace('{account}', this.props.userId).replace(
                '{application_id}',
                this.props.editItem.id,
            ),
        )
            .then((info) => {
                this.setState({
                    vacancy: info.vacancy,
                    company: info.company,
                    dateApplied: moment(info.date),
                    files: info.files,
                })
            })
            .catch(() => {})
            .then(() => this.setState({ loading: false }))
    }

    deleteFile(file) {
        const p = window.confirm(translate('profile.confirm_delete_item', { item: file.name }))
        if (!p) return
        newCallApi(
            'DELETE',
            this.props.config.API_APPLICATION_FILES.replace('{account}', this.props.userId).replace(
                '{application_id}',
                this.props.editItem.id,
            ) +
                '/' +
                file.id,
        )
            .then(() => {})
            .catch(() => {})
            .then(() => this.refresh())
    }

    downloadFile(file) {
        newCallApi(
            'GET',
            this.props.config.API_APPLICATION_FILES.replace('{account}', this.props.userId).replace(
                '{application_id}',
                this.props.editItem.id,
            ) +
                '/' +
                file.id,
            null,
            /* auth */ null,
            true,
            true,
            'blob',
        ).then((blob) => {
            saveAs(blob, file.name)
        })
    }

    save() {
        this.setState({ errors: [] })
        this.setState({ loading: true })
        if (typeof this.props.onSaveStart === 'function') this.props.onSaveStart()
        const formData = new FormData()
        formData.append('date', this.state.dateApplied.format('YYYY-MM-DD'))
        formData.append('vacancy', this.state.vacancy)
        formData.append('company', this.state.company)
        formData.append('result', this.state.result)
        if (this.state.attachment) {
            formData.append('files[0]', this.state.attachment, this.state.attachment.name)
        }
        newCallApi(
            this.state.editing ? 'PATCH' : 'POST',
            (this.state.editing
                ? this.props.config.API_APPLICATION
                : this.props.config.API_APPLICATIONS
            )
                .replace('{account}', this.props.userId)
                .replace('{application_id}', this.props.editItem && this.props.editItem.id),
            formData,
            null,
            false,
        )
            .then((a) => console.log('Got this', a))
            .then(() => this.onSuccess())
            .catch((error) => {
                console.log('Got error', error)
                this.onError(error.status, error)
            })
            .then(() => this.setState({ loading: false }))
    }

    onError(status, object) {
        if (typeof this.props.onSaveEnd === 'function') this.props.onSaveEnd()
        this.setState(
            {
                errors: [],
                loading: false,
            },
            () => {
                if (object.hasOwnProperty('errors')) {
                    let messages = getErrorMessages(object.errors, map)
                    let fields = getErrorFields(object.errors)

                    this.addErrors(messages)
                    this.addErrorFields(fields)
                }
            },
        )
    }

    addErrors(errors) {
        this.setState({
            errors: errors,
        })
    }

    addErrorFields(fields) {
        this.setState({
            fields: fields,
        })
    }

    getClassNames(item, field, select = false) {
        let c = select ? 'form__input--select' : 'form__input--text'

        if (item != null && item != '' && item.length > 0) {
            c += ' has-value'
        }

        return c
    }

    onSuccess() {
        this.state = {
            vacancy: '',
            loading: false,
            errors: [],
        }
        this.props.onSave()
    }

    getSaveClasses() {
        let c = 'actions__action--save'

        if (this.state.loading) {
            c += ' is-loading'
        }

        return c
    }

    render() {
        const { className } = this.props

        return (
            <form
                className={this.props.className}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        //Enter pressed
                        e.preventDefault()
                        this.save()
                    }
                }}
            >
                <fieldset className="form__fieldset">
                    {this.state.errors && this.state.errors.length > 0 && (
                        <Alert type="alert--error alert--simple">
                            <ul>
                                {this.state.errors.map(function (value) {
                                    return <li key={value}>{value}</li>
                                })}
                            </ul>
                        </Alert>
                    )}

                    <div className="panel--applications form">
                        <div className="form__item">
                            <i className="form__icon icons icon-calendar" />
                            <Datepicker
                                date={this.state.dateApplied}
                                onDateChange={(date) => this.setState({ dateApplied: date })}
                                classNames={this.getClassNames(
                                    this.state.dateApplied,
                                    'dateApplied',
                                )}
                                id={'dateApplied'}
                            />
                            <label className="form__label" htmlFor="dateApplied">
                                <Translate value="labels.date" />
                            </label>
                        </div>
                        <div className="form__item">
                            <i className="form__icon icons icon-docs" />
                            <input
                                className={this.getClassNames(this.state.vacancy, 'vacancy')}
                                type="text"
                                id="vacancy"
                                value={this.state.vacancy}
                                onChange={(e) => this.handleChange(e, 'vacancy')}
                            />
                            <label className="form__label" htmlFor="vacancy">
                                <Translate value="labels.vacancy" />
                            </label>
                        </div>
                        <div className="form__item">
                            <i className="form__icon icons icon-briefcase" />
                            <input
                                className={this.getClassNames(this.state.company, 'company')}
                                type="text"
                                id="company"
                                value={this.state.company}
                                onChange={(e) => this.handleChange(e, 'company')}
                            />
                            <label className="form__label" htmlFor="company">
                                <Translate value="labels.company" />
                            </label>
                        </div>
                        <div className="form__item">
                            <i className="form__icon icons icon-flag" />
                            <div className="form__select">
                                <select
                                    className={this.getClassNames(
                                        this.state.result,
                                        'result',
                                        true,
                                    )}
                                    id="result"
                                    value={this.state.result}
                                    onChange={(e) => this.handleChange(e, 'result')}
                                >
                                    <option value="in_progress">
                                        {translate('labels.in_progress')}
                                    </option>
                                    <option value="rejected">{translate('labels.rejected')}</option>
                                    <option value="applied">{translate('labels.applied')}</option>
                                    <option value="interview">
                                        {translate('labels.interview')}
                                    </option>
                                </select>
                            </div>
                            <label className="form__label" htmlFor="result">
                                <Translate value="labels.result" />
                            </label>
                        </div>
                        <h3>
                            <Translate value="labels.attachment" />
                        </h3>
                        {!!this.state.files.length && (
                            <table className="panel__table">
                                <tbody>
                                    {this.state.files.map((file, index) => {
                                        return (
                                            <tr key={`f_${index}_${file.id}`}>
                                                <td>
                                                    <Link
                                                        className="actions__action--delete"
                                                        onClick={() => this.downloadFile(file)}
                                                    >
                                                        {file.name}
                                                    </Link>
                                                </td>
                                                <td>
                                                    <Link
                                                        className="actions__action--delete"
                                                        onClick={() => this.deleteFile(file)}
                                                    >
                                                        <Translate value="profile.remove" />
                                                    </Link>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        )}
                        <div className="form__item">
                            <i className="form__icon icons icon-envelope" />
                            <input
                                className={
                                    this.getClassNames(this.state.attachment, 'attachment') +
                                    ' hidden'
                                }
                                type="file"
                                id="attachment"
                                onChange={(e) => this.handleChange(e, 'attachment')}
                            />
                            <label
                                htmlFor={'attachment'}
                                className={'button--cta button--cta--adjusted'}
                            >
                                {translate('vacancies.upload')}
                            </label>
                            {this.state.attachment && (
                                <div className={'margin-top word-wrap'}>
                                    {this.state.attachment.name}
                                </div>
                            )}
                        </div>
                    </div>
                </fieldset>
                <Actions>
                    <Link
                        className="actions__action--cancel"
                        onClick={() => this.cancel()}
                        tabIndex="4"
                    >
                        <Translate value="profile.cancel" />
                    </Link>
                    <Link
                        className={this.getSaveClasses()}
                        onClick={() => this.save()}
                        tabIndex="5"
                    >
                        <Translate value="profile.save" />
                    </Link>
                </Actions>
            </form>
        )
    }
}

FormApplications.propTypes = {
    className: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    editItem: PropTypes.object,
    userId: PropTypes.number.isRequired,
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(FormApplications)

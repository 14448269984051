import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Container, Hidden, Row, Visible } from 'react-grid-system'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import HorizontalMenu from '../HorizontalMenu/HorizontalMenu'

import { PATH_SUGGESTIONS } from '../../constants/Constants'

class Header extends Component {
    render() {
        return (
            <div className="header">
                <Container>
                    <Row>
                        <Col x={12}>
                            {this.props.state.auth != null &&
                                this.props.state.auth.isAuthenticated && (
                                    <Visible xs sm md>
                                        <div
                                            className={`hamburger_icon_container pull-left ${
                                                this.props.navigationDrawerOpen ? 'change' : ''
                                            }`}
                                            onClick={this.props.toggleNavigationDrawer}
                                        >
                                            <div className="bar1" />
                                            <div className="bar2" />
                                            <div className="bar3" />
                                        </div>
                                    </Visible>
                                )}

                            <Link
                                className="header__logo"
                                to={'/' + this.props.state.config.whitelabel.home_page}
                            >
                                <span id="header__logo" />
                            </Link>

                            <Hidden xs sm md>
                                {this.props.state.auth != null &&
                                    this.props.state.auth.isAuthenticated && <HorizontalMenu />}
                            </Hidden>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

Header.propTypes = {
    state: PropTypes.object,
    navigationDrawerOpen: PropTypes.bool,
    toggleNavigationDrawer: PropTypes.func,
    studentLogo: PropTypes.bool,
}

function mapStateToProps(state) {
    return {
        state: state,
    }
}

// export default Header;
// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(Header)

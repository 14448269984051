import React, { useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { translate, Translate } from 'react-i18nify'
import PropTypes from 'prop-types'

import { OnboardStepFormItemContext } from '../../components/OnboardStepSchemaContext'
import { OnboardStepFormItem } from '../../components/OnboardStepFormItem'
import { Col, Hidden, Row } from 'react-grid-system'
import { CityFormikSelector } from '../../components/CityAsyncSelect'
import AlertBox from '../../../Alert/AlertBox'
import Button from '../../../Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { editProfileItem } from '../../../../store/actions/user'
import { PersonalInfoSchema } from './constants'
import classNames from 'classnames'

const PersonalInfo = ({ cache, prevStep, nextStep, auth, saveCache }) => {
    const [loading, setLoading] = useState(false)
    const profile = useSelector((state) => state.user.profile)
    const dispatch = useDispatch()
    const saveProfileInfo = (values, actions) => {
        setLoading(true)
        const { city } = values
        dispatch(
            editProfileItem(
                'general',
                {
                    ...values,
                    city_id: city.value,
                    city_name: city.label,
                },
                auth,
                () => {
                    setLoading(false)
                    saveCache(values)
                    nextStep()
                },
                (status, error) => {
                    setLoading(false)
                    error.errors.map(({ field, message }) => {
                        actions.setFieldError(field, message)
                    })
                },
            ),
        )
    }

    const prefilledCity = profile.city_id
        ? {
              value: profile.city_id,
              label: profile.city_name,
          }
        : {}

    const initialValues = {
        city: prefilledCity,
        mobile_number: profile?.mobile_number,
        ...cache['personal_info'],
    }

    const saveToCache = (values) => {
        saveCache('personal_info', values)
    }
    return (
        <OnboardStepFormItemContext.Provider value={{ schema: PersonalInfoSchema }}>
            <Formik
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={initialValues}
                validationSchema={PersonalInfoSchema}
                onSubmit={saveProfileInfo}
            >
                {(formik) => {
                    const { values, errors } = formik
                    return (
                        <div className="card--simple">
                            <div className="onboarding__content">
                                <h1 className={'mixed-case'}>
                                    {translate('onboarding.alt1.step6.title')}
                                </h1>
                                <Form className="form">
                                    <Row>
                                        <Col xs={12}>
                                            <OnboardStepFormItem fieldName="city">
                                                <CityFormikSelector />
                                            </OnboardStepFormItem>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <OnboardStepFormItem fieldName="mobile_number">
                                                <label
                                                    className={'form__label'}
                                                    htmlFor={'mobile_number'}
                                                >
                                                    {translate('labels.mobile_number')}
                                                </label>
                                                <Field name={'mobile_number'}>
                                                    {({ field, form }) => (
                                                        <input
                                                            type={'text'}
                                                            id={'mobile_number'}
                                                            name={'mobile_number'}
                                                            className={classNames(
                                                                'form__input--text',
                                                                {
                                                                    error: !!errors.mobile_number,
                                                                },
                                                            )}
                                                            value={field.value}
                                                            onChange={form.handleChange}
                                                        />
                                                    )}
                                                </Field>
                                            </OnboardStepFormItem>
                                        </Col>
                                    </Row>
                                </Form>
                                {Object.keys(errors).length > 0 && (
                                    <AlertBox
                                        messages={[
                                            Array.from(
                                                new Set(
                                                    Object.values(errors).map((error) =>
                                                        translate(
                                                            error,
                                                            {},
                                                            { returnKeyOnError: true },
                                                        ),
                                                    ),
                                                ),
                                            ),
                                        ]}
                                        type={'error'}
                                    />
                                )}

                                <div className="card--simple__buttons">
                                    <div>
                                        <Button
                                            className={
                                                'button--alt inline-block' +
                                                (loading ? ' is-loading' : '')
                                            }
                                            buttonText={translate('buttons.continue')}
                                            onClick={!loading && formik.handleSubmit}
                                        />
                                    </div>
                                    <Hidden xs>
                                        <button
                                            onClick={
                                                !loading &&
                                                (() => {
                                                    saveToCache(values)
                                                    prevStep()
                                                })
                                            }
                                            className="inline-block button--link"
                                        >
                                            <i className="mdi mdi-arrow-left" />
                                            <Translate value="onboarding.button_back" />
                                        </button>
                                    </Hidden>
                                </div>
                            </div>
                        </div>
                    )
                }}
            </Formik>
        </OnboardStepFormItemContext.Provider>
    )
}

export default PersonalInfo

PersonalInfo.propTypes = {
    cache: PropTypes.object.isRequired,
    nextStep: PropTypes.func.isRequired,
    prevStep: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    saveCache: PropTypes.func.isRequired,
}

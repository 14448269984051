import { newCallApi } from './store/actions/api'

import { FIELD_PLACEHOLDER, SET_PROFILE_PICTURE, FLEXAPP_ROLES } from './constants/Constants'
import { translate } from 'react-i18nify'
import { store } from './store'
import { hideFlexappNotification, showFlexappNotification } from './store/actions/user'

export function addClass(el, className) {
    if (el.classList) {
        el.classList.add(className)
    } else {
        el.className += ' ' + className
    }
}

export function removeClass(el, className) {
    if (el.classList) {
        el.classList.remove(className)
    } else {
        el.className = el.className.replace(
            new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
            ' ',
        )
    }
}

export function getFieldValue(data, input, field) {
    console.log(data)
    console.log(input)
    console.log(field)

    if (data != undefined && Object.keys(data).length > 0) {
        if (data.hasOwnProperty(field) && data[field] != null && data[field] != undefined) {
            console.log('Has property and stuff')
            //addClass(input, "has-value");
            return data[field]
        } else {
            console.log('Has no property and stuff')

            //removeClass(input, "has-value");
            return ''
        }
    } else {
        console.log('stuff is null')

        //removeClass(input, "has-value");
        return ''
    }
}

export function createErrorMessage(msg, key, map) {
    if (!msg) {
        return ''
    }

    if (!msg.includes(FIELD_PLACEHOLDER)) {
        return msg
    }

    if (!map.hasOwnProperty(key)) {
        console.log('The field name has no specified label')
        return msg
    }

    return msg.replace(FIELD_PLACEHOLDER, map[key])
}

export function getErrorMessages(errors, map) {
    let errorArray = []

    if (errors && errors.length > 0) {
        //There are errors
        for (var i = 0; i < errors.length; i++) {
            let error = errors[i]
            let field = 'some_field'
            if (error.hasOwnProperty('field') && error.field.length > 0) {
                field = error.field
            }

            errorArray.push(createErrorMessage(error.message, field, map))
        }
    }

    console.log('[ Errors ]', errorArray)

    return errorArray
}

export function getErrorFields(errors) {
    let fieldArray = []

    if (errors.length > 0) {
        for (let i = 0; i < errors.length; i++) {
            let error = errors[i]

            if (
                error.hasOwnProperty('field') &&
                error.field.length > 0 &&
                fieldArray.indexOf(error.field) < 0
            ) {
                fieldArray.push(error.field)
            }
        }
    }

    return fieldArray
}

export function isTokenValid(exp) {
    if (exp === undefined || exp === null) {
        return false
    }
    let curr = Math.round(new Date().getTime() / 1000)
    return curr < exp
}

let months = [
    translate('profile.january'),
    translate('profile.february'),
    translate('profile.march'),
    translate('profile.april'),
    translate('profile.may'),
    translate('profile.june'),
    translate('profile.july'),
    translate('profile.august'),
    translate('profile.september'),
    translate('profile.october'),
    translate('profile.november'),
    translate('profile.december'),
]

export function formatDate(date) {
    let d = new Date(date)

    //Add 1 to the month to account for a 0 index array
    return (
        formatDateNumber(d.getDate()) +
        '-' +
        formatDateNumber(d.getMonth() + 1) +
        '-' +
        formatYear(d.getFullYear())
    )
}

export function formatDateNumber(number) {
    if (number < 10) {
        return '0' + number
    } else {
        return number
    }
}

export function formatYear(year) {
    if (year < 10) {
        return '000' + year
    } else if (year < 100) {
        return '00' + year
    } else if (year < 1000) {
        return '0' + year
    } else {
        return year
    }
}

export function getProfilePicture(auth, profile, app = false) {
    return (dispatch) => {
        if (auth.isAuthenticated && profile && profile.has_profile_picture) {
            newCallApi(
                'GET',
                store.getState().config.API_PROFILE + `/${auth.id}/profilepicture`,
                null,
                /* auth */ null,
                false,
                true,
                'blob',
            ).then(
                (res) => {
                    let urlCreator = window.URL || window.webkitURL
                    let url = urlCreator.createObjectURL(res)
                    dispatch({
                        type: SET_PROFILE_PICTURE,
                        url,
                    })
                },
                (err) => {
                    dispatch({
                        type: SET_PROFILE_PICTURE,
                        url: getFallbackProfilePicture(app),
                    })
                },
            )
        } else {
            //TODO return default image
            dispatch({
                type: SET_PROFILE_PICTURE,
                url: getFallbackProfilePicture(app),
            })
        }
    }
}

export function getPublicProfilePicture(profile) {
    return new Promise((resolve, reject) => {
        if (profile) {
            newCallApi(
                'GET',
                store.getState().config.API_PROFILE + `/${profile.id}/profilepicture`,
                null,
                /* auth */ null,
                false,
                true,
                'blob',
                true,
            )
                .then(
                    (res) => {
                        let urlCreator = window.URL || window.webkitURL
                        let url = urlCreator.createObjectURL(res)
                        resolve(url)
                    },
                    () => {
                        resolve(getFallbackProfilePicture())
                    },
                )
                .catch(() => {
                    resolve(getFallbackProfilePicture())
                })
        } else {
            resolve(getFallbackProfilePicture())
        }
    })
}

function getFallbackProfilePicture(app) {
    let imagename = 'avatar-placeholder.png'

    if (app) {
        return imagename
    } else {
        return '/images/' + imagename
    }
}

export function formatDiploma(d) {
    d.workProcesses = d.coreTasks.map((ct) => ct.workProcesses).flat()

    return d
}

export function sortVacancyReactions(items) {
    let results = []
    let expired = []

    for (let i = 0; i < items.length; i++) {
        let item = items[i]
        if (item.vacancy !== null && item.vacancy.state === 0) {
            expired.push(item)
        } else {
            results.push(item)
        }
    }

    results.sort((item, item2) => {
        if (item.concept && !item2.concept) {
            return -1
        } else if (!item.concept && item2.concept) {
            return 1
        }

        return 0
    })

    return results.concat(expired)
}

export function diffArrays(a1, a2, key) {
    let currentlyAssigned = a1
    let newlyChecked = a2
    let toRemove = []
    let toAdd = []

    for (let i = 0; i < currentlyAssigned.length; i++) {
        if (newlyChecked.indexOf(currentlyAssigned[i][key]) === -1) {
            toRemove.push(currentlyAssigned[i].id)
        }
    }

    for (let i = 0; i < newlyChecked.length; i++) {
        let item = currentlyAssigned.find((add) => {
            return add[key] === newlyChecked[i]
        })

        if (item === undefined) {
            toAdd.push({ [key]: newlyChecked[i] })
        }
    }

    return {
        toAdd,
        toRemove,
    }
}

export function compareArrays(arr1, arr2) {
    // If the length of the arrays varies, the arrays can never be identical
    if (arr1.length !== arr2.length) {
        return false
    }

    for (let i = 0; i < arr1.length; i++) {
        let keys1 = Object.keys(arr1[i])
        let keys2 = Object.keys(arr2[i])

        // if the length of the objects vary, the arrays are not identical
        if (keys1.length !== keys2.length) {
            return false
        }

        for (let j = 0; j < keys1.length; j++) {
            //check if one array has a key that the other does not
            // (Although the length is the same, the arrays can still miss different keys)
            if (!arr1[i].hasOwnProperty(keys2[j]) || !arr2[i].hasOwnProperty(keys1[j])) {
                return false
            }

            if (arr1[i][keys1[j]] !== arr2[i][keys1[j]]) {
                return false
            }
        }
    }

    return true
}

export function sanitize(text) {
    if (!text) {
        return ''
    }

    // this.state.job.content.replace(/h1/g, 'h3'));
    return text
        .replace(/<p>&nbsp;<\/p>/g, '')
        .replace(/<h2>&nbsp;<\/h2>/g, '')
        .replace(/<h3>&nbsp;<\/h3>/g, '')
}

export function isSignedIn(auth) {
    return !!(auth !== null && auth !== undefined && auth.isAuthenticated && isTokenValid(auth.exp))
}

export function checkThemeColorChange(whitelabel) {
    if (whitelabel) {
        changeThemeColors(whitelabel)
        changeFaviconAndTitle(whitelabel)
    }
}

export function changeFaviconAndTitle(whitelabel) {
    let link = document.querySelector("link[rel*='icon']")
    link.href = whitelabel.favicon

    let title = document.querySelector('title')
    title.innerText = whitelabel.title
}

export function changeThemeColors(whitelabel) {
    const root = document.querySelector(':root')

    if (whitelabel.theme) {
        Object.keys(whitelabel.theme).map((cssVar) => {
            root.style.setProperty('--' + cssVar, whitelabel.theme[cssVar])
        })
    }
}

export function redirectToFlexapp(e, profile, config, dispatch, auth) {
    e.preventDefault()
    const isFlexappActivated = profile.account_roles.some(({ name }) =>
        FLEXAPP_ROLES.includes(name),
    )
    if (isFlexappActivated) {
        dispatch(hideFlexappNotification())
        const { url } = config.whitelabel.flexapp
        newCallApi('POST', config.API_SSO, {}, auth).then((res) => {
            window.open(url + 'sign-in/' + window.btoa(res.token))
        })
    } else {
        dispatch(showFlexappNotification())
        setTimeout(() => {
            dispatch(hideFlexappNotification())
        }, 10000)
    }
}

export function checkForEmptyFields(state) {
    let empty = []
    if (state.requiredFields && state.requiredFields.length > 0) {
        state.requiredFields.map((field) => {
            if (
                !(
                    state[field] &&
                    ((state[field] + '').length > 0 ||
                        (state[field] instanceof Object &&
                            (state[field].value || state[field]._isAMomentObject)))
                )
            ) {
                empty.push(field)
            }
        })
    }
    return empty
}

export function isRequired(key, state) {
    return state.requiredFields && state.requiredFields.includes(key) ? ' required' : ''
}

export const isRequiredField = (validationSchema, name) =>
    !!validationSchema.describe().fields[name].tests.find(({ name }) => name === 'required')

export const processNrForInput = (nr) => {
    const stringToNr = Number.parseInt(nr, 10)
    if (Number.isNaN(stringToNr)) {
        return ''
    }
    return stringToNr
}

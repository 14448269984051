import Panel from '../../Panel/Panel'
import { translate, Translate } from 'react-i18nify'
import ButtonPanel from '../../Button/ButtonPanel'
import Suggestion from '../../Suggestion/Suggestion'
import { formatDate } from '../../../helpers'
import ListItem from '../../ListItem/ListItem'
import ModalForm from '../../Modal/ModalForm'
import FormAccountFields from '../../Forms/FormAccountFields'
import React, { Component } from 'react'
import { connect } from 'react-redux'

class AccountFieldsPanel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            editAccountFields: false,
            allowEditJobFunctions: false,
        }
    }

    render() {
        let user = this.props.user

        let fieldValues = user.profile.account_fields
        let fieldElements = {}
        let isEmpty = true
        if (fieldValues) {
            for (let i = 0; i < fieldValues.length; i++) {
                fieldElements[fieldValues[i].code] = fieldValues[i].value
            }
        }
        ;(user.account_field_names || []).map(function (item) {
            if (fieldElements[item.code]) isEmpty = false
        })

        let form_profile_extra_info =
            this.props.config && this.props.config.whitelabel.form_profile_extra_info
        if (form_profile_extra_info && form_profile_extra_info.length > 0 && fieldValues) {
            form_profile_extra_info.map((field) => {
                delete fieldElements[field]
                fieldValues = fieldValues.filter((val) => val.code !== field)
            })
        }

        if (
            user.account_field_names &&
            user.account_field_names.length > 0 &&
            fieldValues &&
            fieldValues.length > 0
        ) {
            return (
                <Panel
                    className="panel--multi__inner"
                    headerText={translate('profile.extra_info')}
                    headerIcon="icon-info"
                    addButton={
                        <ButtonPanel
                            buttonText={
                                this.state.allowEditJobFunctions
                                    ? translate('profile.stop_edit')
                                    : translate('profile.edit')
                            }
                            className="button--cta"
                            iconName="actions__action--edit"
                            onClick={() => {
                                this.setState({
                                    editAccountFields: true,
                                })
                            }}
                        />
                    }
                >
                    {fieldValues.length === 0 ? (
                        <Suggestion iconName="icon-settings">
                            <Translate value="profile.no_extra_info" />
                        </Suggestion>
                    ) : (
                        <div className="panel__list">
                            {(fieldValues.sort((a, b) => (a.name > b.name ? 1 : -1)) || []).map(
                                (item) => {
                                    let value = !(item.type === 'date' || item.type === 'datetime')
                                        ? item.value
                                        : formatDate(item.value)
                                    if (value === '0000-00-00') {
                                        value = null
                                    }
                                    return (
                                        <ListItem
                                            key={item.id + '-' + item.name}
                                            hideEdit={true}
                                            hideDelete={true}
                                            dispatch={this.props.dispatch}
                                            auth={this.props.auth}
                                            type="job_functions"
                                            item={item}
                                            title={item.name}
                                        >
                                            <h3>{item.name}</h3>
                                            <p>
                                                {item.type === 'boolean' ? (
                                                    fieldElements[item.code] ? (
                                                        <span>✓</span>
                                                    ) : (
                                                        <span>✕</span>
                                                    )
                                                ) : (
                                                    value
                                                )}
                                            </p>
                                        </ListItem>
                                    )
                                },
                            )}
                        </div>
                    )}
                    {this.state.editAccountFields && (
                        <ModalForm
                            title={translate('profile.extra_info')}
                            show
                            onClose={() => {
                                this.setState({
                                    editAccountFields: false,
                                })
                            }}
                        >
                            <FormAccountFields
                                className="form"
                                onSave={() => {
                                    this.setState({
                                        editAccountFields: false,
                                    })
                                }}
                                onCancel={() => {
                                    this.setState({
                                        editAccountFields: false,
                                    })
                                }}
                            />
                        </ModalForm>
                    )}
                </Panel>
            )
        } else {
            return <span />
        }
    }
}

function mapStateToProps(state) {
    const { auth, config, user } = state

    return {
        auth,
        config,
        user,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(AccountFieldsPanel)

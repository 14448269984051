import React, { Component } from 'react'
import Panel from '../../Panel/Panel'
import { translate, Translate } from 'react-i18nify'
import ButtonPanel from '../../Button/ButtonPanel'
import { Col, Row } from 'react-grid-system'
import Tag from '../../Tag/Tag'
import { diffProfileItems } from '../../../store/actions/user'
import Suggestion from '../../Suggestion/Suggestion'
import { connect } from 'react-redux'
import ModalForm from '../../Modal/ModalForm'
import FormCompetences from '../../Forms/FormCompetences'
import RoleGenerator from '../../Forms/RoleGenerator'
import Actions from '../../Actions/Actions'
import { Link } from 'react-router-dom'
import { PATH_SUGGESTIONS } from '../../../constants/Constants'
import history from '../../../history'
import { RolesGenerator } from './RolesGenerator'

class CompetencesPanel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            addCompetences: false,
            allowEditCompetences: false,
            showRoles: false,
            confirmCardsRedirect: false,
        }
    }

    getSuggestionsRedirect() {
        return (
            <ModalForm
                title={translate('profile.title_cards_redirect')}
                className="modal"
                closeOnOuterClick={true}
                show
                onClose={() =>
                    this.setState({
                        confirmCardsRedirect: false,
                    })
                }
            >
                <p>{translate('profile.confirm_cards_redirect')}</p>

                <Actions>
                    <Link
                        className="actions__action green pull-right action--no-padding"
                        onClick={() => {
                            this.setState({
                                confirmCardsRedirect: false,
                                addCompetences: true,
                            })
                        }}
                    >
                        {translate('profile.button_cards_redirect')}
                    </Link>
                </Actions>
            </ModalForm>
        )
    }

    getCompetenceModal() {
        return (
            <ModalForm
                title={translate('headers.competences')}
                show
                onClose={() => {
                    this.setState({
                        addCompetences: false,
                    })
                }}
            >
                <FormCompetences
                    className="form--small_margin"
                    onCancel={() => {
                        this.setState({
                            addCompetences: false,
                        })
                    }}
                    onSave={() => {
                        this.setState({
                            addCompetences: false,
                        })
                        this.props.showAlert()
                    }}
                    dispatch={this.props.dispatch}
                    showRoles={() => {
                        this.setState({
                            showRoles: true,
                        })
                    }}
                />
            </ModalForm>
        )
    }

    hasRoles() {
        let roles = this.props.user.profile.roles
        return roles !== undefined && roles.length > 0
    }

    getClassname(competences) {
        if (competences && competences.length > 20) {
            return 'panel--expand panel--multi__inner tags'
        } else if (competences && competences.length === 0) {
            return 'panel--multi__inner panel--suggestion'
        } else {
            return 'panel--multi__inner'
        }
    }

    render() {
        let { competences } = this.props.user.profile

        const { show_suggestion_page } = this.props.config.whitelabel

        return (
            <Panel
                className={this.getClassname(competences)}
                headerText={translate('profile.competences')}
                headerIcon="icon-badge"
                addButton={
                    <ButtonPanel
                        buttonText={translate('profile.add_new')}
                        className="button--cta"
                        onClick={() => {
                            this.setState({
                                addCompetences: true,
                            })
                        }}
                    />
                }
            >
                {competences && competences[0] !== null && competences.length > 0 ? (
                    <div className="panel__form">
                        <Row>
                            <Col sm={12}>
                                <div className="panel__tags">
                                    {competences.map((competence) => {
                                        return (
                                            <Tag
                                                key={competence.id + '-' + competence.name}
                                                check={competence.external}
                                                score={competence.score ? competence.score : 1}
                                                onRemove={() => {
                                                    this.props.dispatch(
                                                        diffProfileItems(
                                                            'competences',
                                                            [],
                                                            [competence.id],
                                                            this.props.auth,
                                                        ),
                                                    )
                                                }}
                                                showEdit={this.state.allowEditCompetences}
                                                title={competence.name}
                                            >
                                                {competence.name}
                                            </Tag>
                                        )
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <Suggestion iconName="icon-badge">
                        <Translate value="profile.no_competences" />
                    </Suggestion>
                )}

                {((this.state.addCompetences && !this.hasRoles()) || this.state.showRoles) && (
                    <RolesGenerator
                        handleClose={() => {
                            this.setState({
                                addCompetences: false,
                                showRoles: false,
                            })
                        }}
                        handleSave={() => {
                            this.setState({
                                addCompetences: !show_suggestion_page,
                                showRoles: false,
                                confirmCardsRedirect: show_suggestion_page,
                            })
                            this.props.showAlert()
                        }}
                    />
                )}

                {this.state.confirmCardsRedirect && this.getSuggestionsRedirect()}

                {this.state.addCompetences && this.hasRoles() && this.getCompetenceModal()}
            </Panel>
        )
    }
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(CompetencesPanel)

import React, { Component } from 'react'

import { connect } from 'react-redux'
import { Col, Container, Row } from 'react-grid-system'
import { Link } from 'react-router-dom'
import { translate } from 'react-i18nify'

import Alert from '../components/Alert/Alert'
import Actions from '../components/Actions/Actions'
import GridColOffset from '../components/GridCol/GridColOffset'
import GridCol from '../components/GridCol/GridCol'
import RoadmapItem from '../components/RoadmapItem/RoadmapItem'
import { newCallApi } from '../store/actions/api'

class Roadmap extends Component {
    constructor(props) {
        super(props)

        let email = ''
        this.state = { email: email, loading: false, done: false, errors: [] }
    }

    handleChange(value, key, changeClass = true) {
        let obj = {}
        obj[key] = value.target.value

        this.setState(obj)
    }

    subscribe() {
        this.setState({ loading: true })
        setTimeout(() => {
            newCallApi('POST', this.props.config.API_SUBSCRIPTION, {
                email: this.state.email,
            })
                .then((r) => {
                    this.setState({ done: true, errors: [] })
                })
                .catch((e) => {
                    this.setState({ errors: e.errors })
                })
                .then(() => {
                    this.setState({ loading: false, email: '' })
                })
        }, 200)
    }

    render() {
        return (
            <Container>
                <Row style={{ marginBottom: '5rem' }}>
                    <GridColOffset />
                    <GridCol>
                        <h2 className="text-center purple">{translate('roadmap.more_coming')}</h2>
                        <p className="text-center">{translate('roadmap.info')}</p>

                        <RoadmapItem
                            day=""
                            month="Juni"
                            year="2018"
                            className="authorization"
                            title="Autorisatie"
                        >
                            <ul>
                                <li>Gebruikers van E-Portfolio kunnen gegevens opvragen/muteren</li>
                            </ul>
                        </RoadmapItem>

                        <RoadmapItem
                            day=""
                            month="Juli"
                            year="2018"
                            className="authorization"
                            title="Autorisatie"
                        >
                            <ul>
                                <li>Werkgevers kunnen zelf autoriseren</li>
                            </ul>
                        </RoadmapItem>

                        <RoadmapItem
                            day=""
                            month="Augustus"
                            year="2019"
                            className="tests"
                            title="Testen"
                        >
                            <ul>
                                <li>Integreren van de module inzetbaarheid (MoDi)</li>
                            </ul>
                        </RoadmapItem>

                        <RoadmapItem
                            day=""
                            month="September"
                            year="2019"
                            className="jobs"
                            title="Stages / Klussen"
                        >
                            <ul>
                                <li>Integratie van de kwalificatiedossiers</li>
                            </ul>
                        </RoadmapItem>

                        <RoadmapItem
                            day=""
                            month="September"
                            year="2019"
                            className="reintegration"
                            title="Re-integratie"
                        >
                            <ul>
                                <li>Het Cliëntvolgsysteem</li>
                            </ul>
                        </RoadmapItem>

                        <RoadmapItem
                            day=""
                            month="Oktober"
                            year="2019"
                            className="tests"
                            title="Testen"
                        >
                            <ul>
                                <li>AMN</li>
                            </ul>
                        </RoadmapItem>

                        <RoadmapItem
                            day=""
                            month="November"
                            year="2019"
                            className="jobs"
                            title="Stages / Klussen"
                        >
                            <ul>
                                <li>Stages en overzicht leerbedrijven</li>
                            </ul>
                        </RoadmapItem>

                        <RoadmapItem
                            day=""
                            month="November"
                            year="2019"
                            className="tests"
                            title="Testen"
                        >
                            <ul>
                                <li>Eelloo</li>
                            </ul>
                        </RoadmapItem>

                        <RoadmapItem
                            day=""
                            month="December"
                            year="2019"
                            className="connections"
                            title="Koppelingen"
                        >
                            <ul>
                                <li>Relatie beheer systeem</li>
                            </ul>
                        </RoadmapItem>
                        {/*---------------------------*/}
                    </GridCol>
                </Row>
                <Row>
                    <GridColOffset />
                    <GridCol>
                        <Row>
                            <Col xs={0} sm={0} md={6} xl={7}>
                                {' '}
                            </Col>
                            <Col xs={12} sm={12} md={6} xl={5}>
                                <form
                                    className="form roadmap__form"
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            this.subscribe()
                                            e.preventDefault()
                                            return false
                                        }
                                    }}
                                >
                                    <fieldset className="form__fieldset">
                                        <p className="purple">{translate('roadmap.updates')}</p>

                                        {this.state.errors.length > 0 && (
                                            <Alert type="alert--error">
                                                {this.state.errors.map(function (value, index) {
                                                    return <li key={index}>{value.message}</li>
                                                })}
                                            </Alert>
                                        )}

                                        {this.state.done ? (
                                            <div>
                                                <h2 className="text-center purple">✓</h2>
                                            </div>
                                        ) : (
                                            <div>
                                                <p className="subtext">
                                                    {translate('roadmap.email')}
                                                </p>
                                                <div className="form__item">
                                                    <i className="form__icon icons icon-envelope" />
                                                    <input
                                                        className="form__input--text has-value"
                                                        type="text"
                                                        id="postal_code"
                                                        value={this.state.email}
                                                        onChange={(value) =>
                                                            this.handleChange(value, 'email')
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </fieldset>
                                    {this.state.done ? (
                                        ''
                                    ) : (
                                        <Actions>
                                            <Link
                                                onClick={() => {
                                                    this.subscribe()
                                                }}
                                                className={
                                                    'actions__action green pull-right action--no-padding' +
                                                    (this.state.loading ? ' is-loading' : '')
                                                }
                                            >
                                                {translate('profile.save')}
                                            </Link>
                                        </Actions>
                                    )}
                                </form>
                            </Col>
                        </Row>
                    </GridCol>
                </Row>
            </Container>
        )
    }
}

Roadmap.propTypes = {}

function mapStateToProps(state) {
    const { auth, user, config } = state
    const { documents } = user
    return {
        auth,
        config,
        documents,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(Roadmap)

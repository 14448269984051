import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Col, Row } from 'react-grid-system'

import { connect } from 'react-redux'

import Actions from '../Actions/Actions'

import {
    addClass,
    getErrorFields,
    getErrorMessages,
    getProfilePicture,
    processNrForInput,
    removeClass,
} from '../../helpers'
import { translate, Translate } from 'react-i18nify'

import {
    editProfileItem,
    fetchProfile,
    getResumes,
    removeProfilePicture,
} from '../../store/actions/user'

import Alert from '../Alert/Alert'
import 'react-dates/lib/css/_datepicker.css'
import newCallApi from '../../store/actions/api'
import Loading from '../Loading/Loading'
import Button from '../Button/Button'
import Datepicker from '../Datepicker/Datepicker'
import { BigTooltip } from '../Tooltip/BigTooltip'
import { CitySelector } from '../CitySelector'
import { MAX_SIZE_PROFILE_PICTURE } from '../../constants/Constants'

let map

var moment = require('moment')

class FormFullProfile extends Component {
    constructor(props) {
        super(props)

        let d = this.props.data
        let func = ''
        if (d.tagline_text != null) {
            func = d.tagline_text
        }

        let h_f = d.account_fields.find((af) => af.code === 'hours_from')
        let h_t = d.account_fields.find((af) => af.code === 'hours_till')
        this.state = {
            initials: d.initials,
            first: d.first_name,
            last: d.last_name,
            email: d.email,
            address: d.address != null ? d.address : '',
            nr: d.address_number != null ? d.address_number : '',
            suffix: d.address_number_suffix != null ? d.address_number_suffix : '',
            postal: d.postal_code != null ? d.postal_code : '',
            city_name: d.city_name != null ? d.city_name : '',
            city_id: d.city_id || '',
            phone: d.phone_number != null ? d.phone_number : '',
            mobile: d.mobile_number != null ? d.mobile_number : '',
            birth: d.date_of_birth != null ? moment(d.date_of_birth) : null,
            birthCity: d.place_of_birth != null ? d.place_of_birth : '',
            error: '',
            loading: false,
            errors: [],
            fields: [],
            uploadingImage: false,
            removingImage: false,
            first_name: d.first_name,
            last_name: d.last_name,
            func: func,
            country_code: '31',
            phone_number: '',
            account_types: null,
            type: d.type,
            organisation: d.organisation_id,
            resumes: [],
            default_resume: d.default_account_resume_id || -1,
            image: null,
            flexapp_work_as_freelancer: d.flexapp_work_as_freelancer || false,
            vacancy_suggestions_email: d.email_settings.vacancy_suggestions,
            wants_project_vacancies: d.wants_project_vacancies,
            hours_from: h_f ? h_f.value + '' : null,
            hours_till: h_t ? h_t.value + '' : null,
            sameEmployer: false,
            birthdayFocused: false,
        }
        this.imgCropperRef = React.createRef()
        this.imageUpload = React.createRef()
    }

    componentDidMount() {
        newCallApi('GET', this.props.config.ACCOUNT_TYPES, null, /* auth */ null)
            .then((json) => {
                this.setState({
                    account_types: json,
                })

                getResumes(this.props.auth)
                    .then((resp) => {
                        resp.unshift({
                            id: -1,
                            name: 'Niet zichtbaar voor werkgevers',
                            description: null,
                        })

                        this.setState({
                            resumes: resp,
                            loading: false,
                        })
                    })
                    .catch((err) => {
                        this.setState({
                            loading: false,
                        })
                    })
            })
            .catch(() => {})
        this.setState({ sameEmployer: this.checkSameEmployer() })
    }

    handleDateChange(date) {
        this.setState({
            birth: date,
        })
    }

    getDateClassNames(item, field) {
        let c = 'form__input--date has-value'

        if (this.state.fields.indexOf(field) != -1) {
            c += ' error'
        }

        return c
    }

    handleChange(value, key, changeClass = true) {
        let obj = {}
        obj[key] = value.target.value

        this.setState(obj)

        if (changeClass) {
            if (value.target.value.length > 0) {
                addClass(value.currentTarget, 'has-value')
            } else {
                removeClass(value.currentTarget, 'has-value')
            }
        }
    }
    getClassNames(item, field) {
        let c = 'form__input--text'

        if (item !== null && item !== '' && ('' + item).length > 0) {
            c += ' has-value'
        }

        if (this.state.fields.indexOf(field) !== -1) {
            c += ' error'
        }

        return c
    }

    loading(loading = true) {
        this.setState({
            loading: loading,
        })
    }

    save() {
        this.loading()
        const { hours_from, hours_till } = this.state
        if (!this.state.city_id) {
            this.addErrors([translate('errors.empty_field')])
            this.addErrorFields(['city'])
            this.loading(false)
            return
        }
        if (
            this.props.config.whitelabel.form_profile_extra_info.includes('hours_from') &&
            (+hours_from > +hours_till || +hours_till > 60 || +hours_from < 1)
        ) {
            this.addErrors([translate('errors.hours_per_week_not_valid')])
            this.addErrorFields(['hours_from', 'hours_till'])
            this.loading(false)
            return
        }

        let initials = this.state.initials,
            first = this.state.first,
            last = this.state.last,
            email = this.state.email,
            address = this.state.address,
            nr = this.state.nr,
            suffix = this.state.suffix,
            postal = this.state.postal,
            city_name = this.state.city_name,
            city_id = this.state.city_id,
            phone = this.state.phone,
            mobile = this.state.mobile,
            birth = this.state.birth,
            birthPlace = this.state.birthCity,
            func = this.state.func,
            flexapp_work_as_freelancer = this.state.flexapp_work_as_freelancer,
            org = this.state.organisation

        if (!/[^\s@]+@[^\s@]+\.[^\s@]+/.test(this.state.email)) {
            this.addErrors([translate('errors.email_not_valid')])
            this.addErrorFields(['email'])
            this.loading(false)
            return
        }

        if (birth != null && birth != undefined) {
            birth = birth.format('YYYY-MM-DD')
        }

        let update = {
            initials: initials,
            first_name: first,
            last_name: last,
            email: email,
            address: this.checkValue(address),
            address_number: this.checkValue(nr),
            address_number_suffix: this.checkValue(suffix),
            postal_code: this.checkValue(postal),
            city_name: this.checkValue(city_name),
            city_id: this.checkValue(city_id),
            phone_number: this.checkValue(phone),
            mobile_number: this.checkValue(mobile),
            date_of_birth: birth == '0000-00-00' ? null : birth,
            place_of_birth: this.checkValue(birthPlace),
            tagline_text: func,
            organisation_id: org,
            default_account_resume_id: this.state.default_resume,
            wants_project_vacancies: this.state.wants_project_vacancies,
            flexapp_work_as_freelancer: flexapp_work_as_freelancer,
        }

        this.setState({
            loading: true,
        })

        let failed = false

        let promises = []
        promises.push(
            this.props.dispatch(
                editProfileItem(
                    'general',
                    update,
                    this.props.auth,
                    () => {},
                    (status, error) => {
                        failed = true
                        this.onError(status, error)
                    },
                    false,
                ),
            ),
        )

        let emailSettings = {
            vacancy_suggestions: this.state.vacancy_suggestions_email,
        }
        let path = this.props.config.API_EMAIL_SETTINGS.replace('{account}', this.props.auth.id)

        promises.push(newCallApi('POST', path, emailSettings, null))

        let fields = this.props.config.whitelabel.form_profile_extra_info
        let fields_update = []
        for (let field of fields) {
            fields_update.push({
                code: field,
                value: this.state[field],
            })
        }
        promises.push(
            this.props.dispatch(
                editProfileItem(
                    'fields',
                    {
                        account_fields: fields_update,
                    },
                    this.props.auth,
                    () => {},
                    (status, error) => {
                        failed = true
                        this.onError(status, error)
                    },
                ),
            ),
        )

        Promise.all(promises).then(() => {
            !failed && this.onSuccess()
        })
    }

    onError(status, object) {
        map = {
            initials: translate('labels.initials'),
            first_name: translate('labels.first_name'),
            last_name: translate('labels.last_name'),
            address: translate('labels.address'),
            address_number: translate('labels.address_number'),
            address_number_suffix: translate('labels.address_number_suffix'),
            postal_code: translate('labels.postal_code'),
            city_name: translate('labels.city_name'),
            phone_number: translate('labels.phone_number'),
            mobile_number: translate('labels.mobile_number'),
            date_of_birth: translate('labels.date_of_birth'),
            place_of_birth: translate('labels.place_of_birth'),
        }

        this.setState(
            {
                errors: [],
                fields: [],
                loading: false,
            },
            () => {
                if (object.hasOwnProperty('errors')) {
                    let messages = getErrorMessages(object.errors, map)
                    let fields = getErrorFields(object.errors)

                    this.addErrors(messages)
                    this.addErrorFields(fields)
                }
            },
        )
    }

    addErrors(errors) {
        this.setState({
            errors: errors,
        })
    }

    addErrorFields(fields) {
        this.setState({
            fields: fields,
        })
    }

    checkValue(val) {
        if (val == null || val == undefined || val.length < 1) {
            return null
        } else {
            return val
        }
    }

    onSuccess() {
        this.setState({
            errors: [],
            fields: [],
            initials: '',
            first: '',
            last: '',
            email: '',
            address: '',
            nr: '',
            suffix: '',
            postal: '',
            city_name: '',
            city_id: '',
            phone: '',
            mobile: '',
            birth: '',
            birthCity: '',
            error: '',
            loading: false,
            country_code: '',
            phone_number: '',
            first_name: '',
            last_name: '',
            func: '',
            flexapp_work_as_freelancer: false,
            hours_from: 0,
            hours_till: 0,
        })
        this.props.onSave()
    }

    cancel() {
        this.setState({
            errors: [],
            fields: [],
            initials: '',
            first: '',
            last: '',
            email: '',
            address: '',
            nr: '',
            suffix: '',
            postal: '',
            city_name: '',
            city_id: '',
            phone: '',
            mobile: '',
            birth: '',
            birthCity: '',
            error: '',
            loading: false,
            country_code: '',
            phone_number: '',
            first_name: '',
            last_name: '',
            func: '',
            flexapp_work_as_freelancer: false,
            hours_from: 0,
            hours_till: 0,
        })
        this.loading(false)
        this.props.onCancel()
    }

    getSaveClasses() {
        let c = 'actions__action--save'

        if (this.state.loading) {
            c += ' is-loading'
        }

        return c
    }

    getIconClasses() {
        return 'form__icon icons icon-user'
    }

    async uploadFile(evt) {
        let file = evt.target.files[0]
        if (!file) {
            return
        }

        if (file.size > MAX_SIZE_PROFILE_PICTURE) {
            this.addErrors([translate('errors.photo_5mb_size')])
            return
        }

        this.setState({
            uploadingImage: true,
            errors: [],
        })

        const processedFile = await this.props.imgCropperRef.current.open(file)
        if (processedFile instanceof File) {
            file = processedFile
        } else {
            this.setState({ uploadingImage: false })
            return
        }

        // Check if uploaded file is an image
        let data = new FormData()
        data.append('profile_picture', file)
        data.append('code', this.props.type)

        let url = this.props.config.API_PICTURE.replace('{account}', this.props.auth.id)
        newCallApi('POST', url, data, /* auth */ null, false).then(
            (response) => {
                if (this.props.onUploaded) {
                    this.props.onUploaded(response)
                }

                this.props.dispatch(
                    fetchProfile(
                        this.props.auth,
                        () => {
                            this.setState({ uploadingImage: false })
                            this.props.dispatch(
                                getProfilePicture(this.props.auth, this.props.user.profile, false),
                            )
                        },
                        () => {
                            this.setState({ uploadingImage: false })
                            this.props.dispatch(
                                getProfilePicture(this.props.auth, this.props.user.profile, false),
                            )
                        },
                    ),
                )
            },
            (errors) => {
                let msgs = []
                if (errors && errors.errors) {
                    msgs = getErrorMessages(errors.errors, {})
                } else {
                    msgs = [translate('errors.general')]
                }

                this.setState({
                    errors: msgs,
                    uploadingImage: false,
                })
            },
        )
    }

    removeFile() {
        this.setState({
            removingImage: true,
        })

        removeProfilePicture(
            this.props.auth,
            () => {
                this.setState({
                    removingImage: false,
                })

                this.props.dispatch(
                    fetchProfile(this.props.auth, (status, err) => {
                        console.log('Something went wrong fetching the profile after image upload')
                    }),
                )
            },
            (status, response) => {
                console.log(response)
            },
        )
    }

    onCitySelected(value) {
        console.log('City selected', value)
        if (!value) {
            return this.setState({ city_name: null, city_id: null })
        }
        this.setState({ city_name: value.label, city_id: value.value })
    }

    checkSameEmployer() {
        const flexOrgs = this.props.data.flex_organisations
        const org = this.props.data.organisation_id
        console.log('employer data', flexOrgs, org)
        return flexOrgs && flexOrgs.length === 1 && flexOrgs[0].organisation_id === org
    }

    render() {
        const { user } = this.props
        const { hours_till, hours_from } = this.state
        return (
            <>
                <form
                    className={this.props.className}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            //Enter pressed
                            this.save()
                        }
                    }}
                >
                    {this.state.loading && <Loading />}

                    {!this.state.loading && (
                        <div>
                            <Row>
                                <Col md={6}>
                                    <div className="form__image">
                                        <div className="form__profile_img">
                                            <div className="form__profile_img__wrapper">
                                                <img src={user.profile_picture} alt="profile" />
                                            </div>
                                            <Button
                                                className={
                                                    this.state.uploadingImage
                                                        ? 'button--cta is-loading'
                                                        : 'button--cta'
                                                }
                                                buttonText="Upload andere foto"
                                                onClick={() => {
                                                    this.imageUpload.current.click()
                                                }}
                                            />

                                            <input
                                                type="file"
                                                id="imageUpload"
                                                accept="image/*"
                                                ref={this.imageUpload}
                                                name="imageUpload"
                                                style={{ display: 'none' }}
                                                onChange={(evt) => {
                                                    this.uploadFile(evt)
                                                    this.imageUpload.current.value = null
                                                }}
                                            />

                                            {user.profile.has_profile_picture && (
                                                <Button
                                                    className={
                                                        this.state.removingImage
                                                            ? 'button--link grey is-loading'
                                                            : 'button--link grey'
                                                    }
                                                    buttonText={translate('profile.remove_photo')}
                                                    onClick={() => {
                                                        this.removeFile()
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </Col>

                                <Col md={6}>
                                    <fieldset className="form__fieldset">
                                        {this.state.errors && this.state.errors.length > 0 && (
                                            <Alert type="alert--error alert--simple">
                                                <ul>
                                                    {this.state.errors.map(function (value) {
                                                        return <li key={value}>{value}</li>
                                                    })}
                                                </ul>
                                            </Alert>
                                        )}

                                        <div className="form__item">
                                            <i className={this.getIconClasses()} />
                                            <input
                                                className={this.getClassNames(
                                                    this.state.first,
                                                    'first_name',
                                                )}
                                                type="text"
                                                id="first_name"
                                                value={this.state.first}
                                                onChange={(value) =>
                                                    this.handleChange(value, 'first')
                                                }
                                                tabIndex="10"
                                            />
                                            <label className="form__label" htmlFor="first_name">
                                                <Translate value="labels.first_name" />
                                            </label>
                                        </div>
                                        <div className="form__item">
                                            <input
                                                className={this.getClassNames(
                                                    this.state.initials,
                                                    'initials',
                                                )}
                                                type="text"
                                                id="initials"
                                                value={this.state.initials}
                                                onChange={(value) =>
                                                    this.handleChange(value, 'initials')
                                                }
                                                tabIndex="20"
                                            />
                                            <label className="form__label" htmlFor="initials">
                                                <Translate value="labels.initials" />
                                            </label>
                                        </div>
                                        <div className="form__item">
                                            <input
                                                className={this.getClassNames(
                                                    this.state.last,
                                                    'last_name',
                                                )}
                                                type="text"
                                                id="last_name"
                                                value={this.state.last}
                                                onChange={(value) =>
                                                    this.handleChange(value, 'last')
                                                }
                                                tabIndex="30"
                                            />
                                            <label className="form__label" htmlFor="last_name">
                                                <Translate value="labels.last_name" />
                                            </label>
                                        </div>

                                        <div className="form__item">
                                            <input
                                                className={this.getClassNames(
                                                    this.state.email,
                                                    'email',
                                                )}
                                                type="text"
                                                id="email"
                                                value={this.state.email}
                                                onChange={(value) =>
                                                    this.handleChange(value, 'email')
                                                }
                                                tabIndex="40"
                                            />
                                            <label className="form__label" htmlFor="email">
                                                <Translate value="labels.email" />
                                            </label>
                                        </div>
                                    </fieldset>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <fieldset className="form__fieldset">
                                        <h3>
                                            <Translate value="profile.personal_information" />
                                        </h3>
                                        <div className="form__item">
                                            <i className="form__icon icons icon-location-pin" />
                                            <input
                                                className={this.getClassNames(
                                                    this.state.address,
                                                    'address',
                                                )}
                                                type="text"
                                                id="address"
                                                value={this.state.address}
                                                onChange={(value) =>
                                                    this.handleChange(value, 'address')
                                                }
                                                tabIndex="70"
                                            />
                                            <label className="form__label" htmlFor="address">
                                                <Translate value="labels.street" />
                                            </label>
                                        </div>

                                        <Row>
                                            <Col xs={4} className={'form__item--address'}>
                                                <div className="form__item">
                                                    <input
                                                        className={this.getClassNames(
                                                            this.state.nr,
                                                            'address_number',
                                                        )}
                                                        type="number"
                                                        id="house_number"
                                                        value={this.state.nr}
                                                        onChange={(value) =>
                                                            this.handleChange(value, 'nr')
                                                        }
                                                        tabIndex="80"
                                                    />
                                                    <label
                                                        className="form__label"
                                                        htmlFor="house_number"
                                                    >
                                                        <Translate value="labels.housenumber" />
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xs={4} className={'form__item--address'}>
                                                <div className="form__item">
                                                    <input
                                                        className={this.getClassNames(
                                                            this.state.suffix,
                                                            'address_number_suffix',
                                                        )}
                                                        type="text"
                                                        id="suffix"
                                                        value={this.state.suffix}
                                                        onChange={(value) =>
                                                            this.handleChange(value, 'suffix')
                                                        }
                                                        tabIndex="90"
                                                    />
                                                    <label className="form__label" htmlFor="suffix">
                                                        <Translate value="labels.suffix" />
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xs={4} className={'form__item--address'}>
                                                <div className="form__item">
                                                    <input
                                                        className={this.getClassNames(
                                                            this.state.postal,
                                                            'postal_code',
                                                        )}
                                                        type="text"
                                                        maxLength={6}
                                                        id="postal_code"
                                                        value={this.state.postal}
                                                        onChange={(value) =>
                                                            this.handleChange(value, 'postal')
                                                        }
                                                        tabIndex="100"
                                                    />
                                                    <label
                                                        className="form__label"
                                                        htmlFor="postal_code"
                                                    >
                                                        <Translate value="labels.zipcode" />
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <div className="form__item">
                                                    <CitySelector
                                                        id="city_name"
                                                        className="select--alt-black"
                                                        controller={{
                                                            error: this.state.fields.includes(
                                                                'city',
                                                            ),
                                                            value: {
                                                                value: this.state.city_id,
                                                                label: this.state.city_name,
                                                            },
                                                            onChange:
                                                                this.onCitySelected.bind(this),
                                                        }}
                                                    />
                                                    <label
                                                        className="form__label"
                                                        htmlFor="city_name"
                                                    >
                                                        <Translate value="labels.city" />
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="form__item">
                                            <i className="form__icon icons icon-phone" />
                                            <input
                                                className={this.getClassNames(
                                                    this.state.phone,
                                                    'phone_number',
                                                )}
                                                type="tel"
                                                id="phonenumber"
                                                value={this.state.phone}
                                                onChange={(value) =>
                                                    this.handleChange(value, 'phone')
                                                }
                                                tabIndex="120"
                                            />
                                            <label className="form__label" htmlFor="phonenumber">
                                                <Translate value="labels.phonenumber" />
                                            </label>
                                        </div>
                                        <div className="form__item">
                                            <input
                                                className={this.getClassNames(
                                                    this.state.mobile,
                                                    'mobile_number',
                                                )}
                                                type="tel"
                                                id="phonenumber_alt"
                                                value={this.state.mobile}
                                                onChange={(value) =>
                                                    this.handleChange(value, 'mobile')
                                                }
                                                tabIndex="130"
                                            />
                                            <label
                                                className="form__label"
                                                htmlFor="phonenumber_alt"
                                            >
                                                <Translate value="labels.mobilenumber" />
                                            </label>
                                        </div>
                                        <div className="form__item--datepicker">
                                            <i className="form__icon icons icon-calendar" />
                                            <Datepicker
                                                date={this.state.birth}
                                                onDateChange={(date) => this.handleDateChange(date)}
                                                classNames={this.getDateClassNames(
                                                    this.state.birth,
                                                    'date_of_birth',
                                                )}
                                                id={'birthday'}
                                            />
                                            <label className="form__label" htmlFor="birthday">
                                                <Translate value="labels.birthday" />
                                            </label>
                                        </div>
                                        <div className="form__item">
                                            <input
                                                className={this.getClassNames(
                                                    this.state.birthCity,
                                                    'place_of_birth',
                                                )}
                                                type="text"
                                                id="birthplace"
                                                value={this.state.birthCity}
                                                onChange={(value) =>
                                                    this.handleChange(value, 'birthCity')
                                                }
                                                tabIndex="150"
                                            />
                                            <label className="form__label" htmlFor="birthplace">
                                                <Translate value="labels.birthplace" />
                                            </label>
                                        </div>
                                    </fieldset>
                                </Col>

                                <Col md={6}>
                                    <fieldset className="form__fieldset">
                                        <h3>
                                            <Translate value="profile.profile_settings" />
                                        </h3>
                                        <div className="form__item">
                                            <i className="form__icon icons icon-settings" />
                                            <input
                                                className={this.getClassNames(this.state.func)}
                                                type="text"
                                                id="function"
                                                value={this.state.func}
                                                onChange={(value) =>
                                                    this.handleChange(value, 'func')
                                                }
                                                tabIndex="3"
                                            />
                                            <label className="form__label" htmlFor="function">
                                                <Translate value="profile.function" />
                                            </label>
                                        </div>
                                        <div className="form__item">
                                            <div className="form__select">
                                                <select
                                                    value={this.state.organisation || ''}
                                                    className="form__input--select"
                                                    onChange={(value) =>
                                                        this.handleChange(
                                                            value,
                                                            'organisation',
                                                            false,
                                                        )
                                                    }
                                                    tabIndex="3"
                                                >
                                                    {user.organizations !== undefined &&
                                                        user.organizations.map(function (item) {
                                                            return (
                                                                <option
                                                                    key={item.id}
                                                                    value={item.id}
                                                                >
                                                                    {item.name}
                                                                </option>
                                                            )
                                                        })}
                                                </select>
                                                {this.props.data.account_types.some(
                                                    (type) =>
                                                        type.code === 'planner' ||
                                                        type.code === 'planner-administrator',
                                                ) &&
                                                    this.state.organisation !=
                                                        this.props.data.organisation_id && (
                                                        <div className="form__alert">
                                                            <Translate value="profile.organisation_warning" />
                                                        </div>
                                                    )}
                                            </div>
                                            <label
                                                className="form__label"
                                                htmlFor="experience_location"
                                            >
                                                <Translate value="profile.employer_internal_vacancies" />
                                            </label>
                                        </div>
                                        <div className="form__item">
                                            <i className="form__icon icons icon-doc" />
                                            <div className="form__select">
                                                <select
                                                    value={this.state.default_resume || ''}
                                                    className="form__input--select"
                                                    onChange={(value) =>
                                                        this.handleChange(
                                                            value,
                                                            'default_resume',
                                                            false,
                                                        )
                                                    }
                                                    tabIndex="3"
                                                >
                                                    {this.state.resumes &&
                                                        this.state.resumes.map(function (item) {
                                                            return (
                                                                <option
                                                                    key={item.id}
                                                                    value={item.id}
                                                                >
                                                                    {item.name +
                                                                        (item.description
                                                                            ? ` ( ${item.description} )`
                                                                            : '')}
                                                                </option>
                                                            )
                                                        })}
                                                </select>
                                            </div>
                                            <label
                                                className="form__label"
                                                htmlFor="experience_location"
                                            >
                                                <BigTooltip
                                                    title={translate('profile.account_resume_info')}
                                                    className={'tooltip-modal'}
                                                    position="top"
                                                    arrow={true}
                                                >
                                                    <i
                                                        className={
                                                            'mdi mdi-information-outline tooltip-modal'
                                                        }
                                                    />
                                                </BigTooltip>
                                                <Translate value="profile.default_account_resume" />
                                            </label>
                                        </div>
                                        <div className="form__item--checkbox">
                                            <div className="form__checkbox">
                                                <input
                                                    type="checkbox"
                                                    id="wants_project_vacancies"
                                                    className={'form__checkbox__input'}
                                                    name="wants_project_vacancies"
                                                    checked={
                                                        this.state.wants_project_vacancies
                                                            ? 'checked'
                                                            : ''
                                                    }
                                                    onChange={(e) => {
                                                        this.setState({
                                                            wants_project_vacancies:
                                                                e.target.checked,
                                                        })
                                                    }}
                                                />
                                                <label
                                                    className={'form__checkbox__label'}
                                                    htmlFor="wants_project_vacancies"
                                                >
                                                    <Translate value="profile.wants_project_vacancies" />
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form__item--checkbox">
                                            <div className="form__checkbox">
                                                <input
                                                    type="checkbox"
                                                    id="vacancy_suggestions_email"
                                                    className={'form__checkbox__input'}
                                                    name="vacancy_suggestions_email"
                                                    checked={
                                                        this.state.vacancy_suggestions_email
                                                            ? 'checked'
                                                            : ''
                                                    }
                                                    onChange={(e) => {
                                                        this.setState({
                                                            vacancy_suggestions_email:
                                                                e.target.checked,
                                                        })
                                                    }}
                                                />
                                                <label
                                                    className={'form__checkbox__label'}
                                                    htmlFor="vacancy_suggestions_email"
                                                >
                                                    <Translate value="profile.vacancy_suggestions_email" />
                                                </label>
                                            </div>
                                        </div>
                                        {this.props.config.whitelabel.form_profile_extra_info.some(
                                            (info) => info.includes('hours_'),
                                        ) && (
                                            <div>
                                                <div className="form__item">
                                                    <Translate value="profile.hours_per_week" />
                                                </div>
                                                <Row>
                                                    <Col xs={6}>
                                                        <div className="form__item">
                                                            <input
                                                                type="text"
                                                                pattern="[0-9]*"
                                                                id="hours_from"
                                                                className={this.getClassNames(
                                                                    hours_from,
                                                                    'hours_from',
                                                                )}
                                                                name="hours_from"
                                                                value={hours_from}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        hours_from:
                                                                            processNrForInput(
                                                                                e.target.value,
                                                                            ),
                                                                    })
                                                                }}
                                                            />
                                                            <label
                                                                className="form__label"
                                                                htmlFor="hours_from"
                                                            >
                                                                <Translate value="profile.hours_from" />
                                                            </label>
                                                        </div>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <div className="form__item">
                                                            <input
                                                                type="text"
                                                                pattern="[0-9]*"
                                                                id="hours_till"
                                                                className={this.getClassNames(
                                                                    hours_till,
                                                                    'hours_till',
                                                                )}
                                                                name="hours_till"
                                                                value={hours_till}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        hours_till:
                                                                            processNrForInput(
                                                                                e.target.value,
                                                                            ),
                                                                    })
                                                                }}
                                                            />
                                                            <label
                                                                className={'form__label'}
                                                                htmlFor="hours_till"
                                                            >
                                                                <Translate value="profile.hours_till" />
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    </fieldset>
                                </Col>
                            </Row>
                        </div>
                    )}
                    <Actions>
                        <Link
                            className="actions__action--cancel"
                            onClick={() => this.cancel()}
                            tabIndex="160"
                        >
                            <Translate value="profile.cancel" />
                        </Link>
                        <Link
                            className={this.getSaveClasses()}
                            onClick={() => this.save()}
                            tabIndex="170"
                        >
                            <Translate value="profile.save" />
                        </Link>
                    </Actions>
                </form>
            </>
        )
    }
}

FormFullProfile.propTypes = {
    className: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(FormFullProfile)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container, Row } from 'react-grid-system'
import { translate } from 'react-i18nify'
import { resetPassword } from '../store/actions/auth'

import { PATH_LOGIN } from '../constants/Constants'
import { getErrorFields, getErrorMessages } from '../helpers'
import FormPasswordReset from '../components/Forms/FormPasswordReset'
import GridCol from '../components/GridCol/GridCol'
import GridColOffset from '../components/GridCol/GridColOffset'
import history from '../history'

var map, backToUrl

class PasswordReset extends Component {
    constructor(props) {
        super(props)

        this.state = { error: '', success: false, isFetching: false, errors: [], fields: [] }

        if (window.location.href.includes('?redirect=')) {
            backToUrl = window.location.href.split('?redirect=')[1]
        }

        this.onSubmit = this.onSubmit.bind(this)

        map = {
            password: translate('labels.password'),
            repeat_password: translate('labels.repeat_password'),
        }
    }

    onSubmit(email, password, passwordAgain) {
        this.setState({
            isFetching: true,
            errorFields: [],
            errorArray: [],
            errors: [],
        })

        resetPassword(
            this.props.match.params.token,
            email,
            password,
            passwordAgain,
            () => this.onSuccess(),
            (status, error) => this.onError(status, error),
        )
    }

    onSuccess() {
        this.setState({
            isFetching: false,
            success: true,
        })
        setTimeout(function () {
            if (backToUrl) {
                window.location.href = backToUrl
            } else {
                history.push('/' + PATH_LOGIN)
            }
        }, 2000)
    }

    onError(status, object) {
        map = {
            email: 'E-mailadres',
            password: 'Wachtwoord',
            repeat_password: 'Herhaal wachtwoord',
        }

        switch (status) {
            case 400:
            case 404:
            case 422:
                this.setState(
                    {
                        errors: [],
                        fields: [],
                        isFetching: false,
                    },
                    () => {
                        if (object.hasOwnProperty('errors')) {
                            let messages = getErrorMessages(object.errors, map)
                            var fields = getErrorFields(object.errors)

                            this.addErrors(messages)
                            this.addErrorFields(fields)
                        }
                    },
                )
                break
            default:
                this.addErrors([translate('errors.general')])
        }
    }

    addErrors(errors) {
        this.setState({
            errors: errors,
        })
    }

    addErrorFields(fields) {
        this.setState({
            fields: fields,
        })
    }

    getClassNames(item, field) {
        let c = 'form__input--text'

        if (item != null && item != '' && item.length > 0) {
            c += ' has-value'
        }

        if (this.state.fields.indexOf(field) != -1) {
            c += ' error'
        }

        return c
    }

    addError(error) {
        var errors = this.state.errorArray
        errors.push(error)

        this.setState({
            errors: errors,
        })
    }

    addErrorField(field) {
        var fields = this.state.errorFields

        fields.push(field)

        this.setState({
            fields: fields,
        })
    }

    render() {
        return (
            <div className="react-native-web">
                <Container>
                    <Row>
                        <GridColOffset />
                        <GridCol>
                            <FormPasswordReset
                                className="form--bg white"
                                errorMessages={this.state.errors}
                                errorFields={this.state.fields}
                                onSubmit={this.onSubmit}
                                success={this.state.success}
                                isFetching={this.state.isFetching}
                            />
                        </GridCol>
                    </Row>
                </Container>
            </div>
        )
    }
}

PasswordReset.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

function select(state) {
    return {
        state: state,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(select)(PasswordReset)

import React from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'react-i18nify'

const MINIMUM_CHARACTER_COUNT = 'minimum_character_count'
const LOWERCASE_LETTER = 'lowercase_letter'
const UPPERCASE_LETTER = 'uppercase_letter'
const NUMBER = 'number'
const SPECIAL_CHARACTER = 'special_character'
const MATCH = 'match'
const NOT_OLD_PASSWORD = 'not_old_password'
const ALL_REQUIREMENTS = 'all_requirements'

const checkPasswordRequirements = (
    current_password,
    new_password,
    repeat_password,
    requirement,
) => {
    const CONDITION_MET = 'condition_met'
    let re
    switch (requirement) {
        case MINIMUM_CHARACTER_COUNT:
            return new_password.length >= 8 ? CONDITION_MET : ''
        case LOWERCASE_LETTER:
            re = new RegExp('(?=.*[a-z])')
            return re.test(new_password) ? CONDITION_MET : ''
        case UPPERCASE_LETTER:
            re = new RegExp('(?=.*[A-Z])')
            return re.test(new_password) ? CONDITION_MET : ''
        case NUMBER:
            re = new RegExp('(?=.*[0-9])')
            return re.test(new_password) ? CONDITION_MET : ''
        case SPECIAL_CHARACTER:
            re = new RegExp('(?=.*[-+_!@#$%^&*.,?])')
            return re.test(new_password) ? CONDITION_MET : ''
        case MATCH:
            return new_password === repeat_password && new_password !== '' ? CONDITION_MET : ''
        case NOT_OLD_PASSWORD:
            return new_password !== current_password &&
                new_password !== '' &&
                current_password !== ''
                ? CONDITION_MET
                : ''
        case ALL_REQUIREMENTS:
            re = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-+_!@#$%^&*.,?])')
            return (
                re.test(new_password) &&
                new_password.length >= 8 &&
                new_password === repeat_password &&
                new_password !== current_password &&
                current_password !== ''
            )
        default:
            return ''
    }
}

function PasswordRequirements(props) {
    const { current_password, new_password, repeat_password } = props

    return (
        <div className="password_requirements">
            <div>
                <Translate value="password.requirements" />
            </div>
            <ul
                className={checkPasswordRequirements(
                    current_password,
                    new_password,
                    repeat_password,
                    ALL_REQUIREMENTS,
                )}
            >
                <li
                    className={checkPasswordRequirements(
                        current_password,
                        new_password,
                        repeat_password,
                        MINIMUM_CHARACTER_COUNT,
                    )}
                >
                    <Translate value="password.minimum_characters" />
                </li>
                <li
                    className={checkPasswordRequirements(
                        current_password,
                        new_password,
                        repeat_password,
                        LOWERCASE_LETTER,
                    )}
                >
                    <Translate value="password.lowercase_letter" />
                </li>
                <li
                    className={checkPasswordRequirements(
                        current_password,
                        new_password,
                        repeat_password,
                        UPPERCASE_LETTER,
                    )}
                >
                    <Translate value="password.uppercase_letter" />
                </li>
                <li
                    className={checkPasswordRequirements(
                        current_password,
                        new_password,
                        repeat_password,
                        NUMBER,
                    )}
                >
                    <Translate value="password.number" />
                </li>
                <li
                    className={checkPasswordRequirements(
                        current_password,
                        new_password,
                        repeat_password,
                        SPECIAL_CHARACTER,
                    )}
                >
                    <Translate value="password.special_character" />
                </li>
                <li
                    className={checkPasswordRequirements(
                        current_password,
                        new_password,
                        repeat_password,
                        MATCH,
                    )}
                >
                    <Translate value="password.match" />
                </li>
                <li
                    className={checkPasswordRequirements(
                        current_password,
                        new_password,
                        repeat_password,
                        NOT_OLD_PASSWORD,
                    )}
                >
                    <Translate value="password.not_old_password" />
                </li>
            </ul>
        </div>
    )
}

PasswordRequirements.propTypes = {
    current_password: PropTypes.string,
    new_password: PropTypes.string,
    repeat_password: PropTypes.string,
}

export { PasswordRequirements, ALL_REQUIREMENTS, checkPasswordRequirements }

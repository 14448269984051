import { connect } from 'react-redux'
import React, { Component } from 'react'
import Panel from '../../Panel/Panel'
import { translate, Translate } from 'react-i18nify'
import ButtonPanel from '../../Button/ButtonPanel'
import { Col, Row } from 'react-grid-system'
import Tag from '../../Tag/Tag'
import { diffProfileItems } from '../../../store/actions/user'
import Suggestion from '../../Suggestion/Suggestion'
import ModalForm from '../../Modal/ModalForm'
import FormTopics from '../../Forms/FormTopics'

class TopicsPanel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            addTopics: false,
        }
    }

    getTopicModal() {
        return (
            <ModalForm
                title={translate('headers.topics')}
                show
                onClose={() => {
                    this.setState({
                        addTopics: false,
                    })
                }}
            >
                <FormTopics
                    className="form--small_margin"
                    onCancel={() => {
                        this.setState({
                            addTopics: false,
                        })
                    }}
                    onSave={() => {
                        this.setState({
                            addTopics: false,
                        })
                        this.props.showAlert()
                    }}
                    dispatch={this.props.dispatch}
                />
            </ModalForm>
        )
    }

    render() {
        let { topics } = this.props.user.profile

        if (topics !== undefined && topics[0] !== null && topics.length > 0) {
            return (
                <Panel
                    className={
                        topics.length > 20
                            ? 'panel--expand panel--multi__inner tags'
                            : 'panel--multi__inner'
                    }
                    headerText={translate('profile.topics')}
                    headerIcon="icon-like"
                    addButton={
                        <ButtonPanel
                            buttonText={translate('profile.add_new')}
                            className="button--cta"
                            onClick={() => {
                                this.setState({
                                    addTopics: true,
                                })
                            }}
                        />
                    }
                >
                    <div className="panel__form">
                        <Row>
                            <Col sm={12}>
                                <div className="panel__tags">
                                    {topics.map((topic) => {
                                        return (
                                            <Tag
                                                key={topic.id + '-' + topic.name}
                                                onRemove={() => {
                                                    this.props.dispatch(
                                                        diffProfileItems(
                                                            'topics',
                                                            [],
                                                            [topic.id],
                                                            this.props.auth,
                                                        ),
                                                    )
                                                }}
                                                title={topic.name}
                                            >
                                                {topic.name}
                                            </Tag>
                                        )
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </div>

                    {this.state.addTopics && this.getTopicModal()}
                </Panel>
            )
        } else {
            return (
                <Panel
                    className="panel--suggestion panel--multi__inner"
                    headerText={translate('profile.topics')}
                    headerIcon="icon-like"
                    addButton={
                        <ButtonPanel
                            buttonText={translate('profile.add_new')}
                            className="button--cta"
                            onClick={() => {
                                this.setState({
                                    addTopics: true,
                                })
                            }}
                        />
                    }
                >
                    <Suggestion iconName="icon-like">
                        <Translate value="profile.no_topics" />
                    </Suggestion>

                    {this.state.addTopics && this.getTopicModal()}
                </Panel>
            )
        }
    }
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(TopicsPanel)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-grid-system'
import { translate } from 'react-i18nify'

export default class RoadmapItem extends Component {
    constructor(props) {
        super(props)

        this.state = { expanded: false }
    }

    render() {
        return (
            <div className={'roadmap-item ' + this.props.className}>
                <Row>
                    <Col xs={12} sm={0} md={4} xl={4}>
                        <div className="roadmap-item__content left">
                            <div className="content__title">
                                <h3>{this.props.title}</h3>
                            </div>
                            {this.props.children}
                            <p>
                                {translate('roadmap.expected_date')} {this.props.day}{' '}
                                {this.props.month} {this.props.year}.{' '}
                            </p>
                        </div>
                    </Col>

                    <Col xs={12} sm={4} md={4} xl={4}>
                        <div className="roadmap-item__date">
                            <h3>{this.props.month}</h3>
                            <p>{this.props.year}</p>
                        </div>
                    </Col>

                    <Col xs={12} sm={7} md={4} xl={4}>
                        <div className="roadmap-item__content right">
                            <div className="content__title">
                                <h3>{this.props.title}</h3>
                            </div>
                            {this.props.children}
                            <p>
                                {translate('roadmap.expected_date')} {this.props.day}{' '}
                                {this.props.month} {this.props.year}.{' '}
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

RoadmapItem.propTypes = {
    className: PropTypes.string.isRequired,
    day: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { translate, Translate } from 'react-i18nify'
import { diffProfileItems } from '../../store/actions/user'

import Actions from '../Actions/Actions'

export default class ListItem extends Component {
    constructor(props) {
        super(props)

        this.state = { loading: false }
    }

    remove() {
        this.setState(
            {
                loading: true,
            },
            function () {
                if (this.props.resume) {
                    this.props.onRemove()
                    if (typeof this.props.onChange === 'function') this.props.onChange()
                } else {
                    var pro = window.confirm(
                        translate('profile.confirm_delete_item', { item: this.props.title }),
                    )
                    if (pro) {
                        this.props.dispatch(
                            diffProfileItems(
                                this.props.type,
                                [],
                                [this.props.item.id],
                                this.props.auth,
                                () => {
                                    this.success()
                                },
                                (status, errors) => {
                                    console.log(status, errors)
                                },
                            ),
                        )
                        if (typeof this.props.onChange === 'function') this.props.onChange()
                    } else {
                        this.setState({
                            loading: false,
                        })
                    }
                }
            },
        )
    }

    success() {
        if (typeof this.props.onChange === 'function') this.props.onChange()
        this.setState({
            loading: false,
        })
    }

    getClassNames() {
        let c = 'actions__action--delete'

        if (this.state.loading) {
            c += ' is-loading'
        }

        return c
    }

    getPositiveText() {
        return this.props.positiveText ? (
            this.props.positiveText
        ) : (
            <Translate value="profile.edit" />
        )
    }

    render() {
        const { positiveText } = this.props
        return (
            <div className={this.props.className + '  list_item'}>
                {!this.props.hideEdit && this.props.href && (
                    <a
                        target="_blank"
                        href={this.props.href}
                        className="list_item__content"
                        rel="noreferrer"
                    >
                        {this.props.children}
                    </a>
                )}
                {!this.props.href && (
                    <Link
                        onClick={() => {
                            if (!this.props.hideDownload) {
                                typeof this.props.onDownload === 'function' &&
                                    this.props.onDownload()
                            } else if (!this.props.hideEdit) {
                                typeof this.props.onEdit === 'function' && this.props.onEdit()
                            }
                        }}
                        className="list_item__content"
                    >
                        {this.props.children}
                    </Link>
                )}
                {this.props.banner && <div className="list_item__banner">{this.props.banner}</div>}

                <Actions>
                    {!this.props.hideDownload && (
                        <Link
                            onClick={() => {
                                typeof this.props.onDownload === 'function' &&
                                    this.props.onDownload()
                            }}
                            className="actions__action--download"
                        >
                            <Translate value="profile.emrex.download_diploma" />
                        </Link>
                    )}
                    {!this.props.hideEdit && this.props.href && (
                        <a
                            target="_blank"
                            href={this.props.href}
                            className={this.props.actionType}
                            rel="noreferrer"
                        >
                            {this.props.positiveText
                                ? this.props.positiveText
                                : translate('profile.edit')}
                        </a>
                    )}
                    {!this.props.hideEdit && !this.props.href && (
                        <Link
                            onClick={() => {
                                typeof this.props.onEdit === 'function' && this.props.onEdit()
                            }}
                            className={this.props.actionType}
                        >
                            {this.props.positiveText
                                ? this.props.positiveText
                                : translate('profile.edit')}
                        </Link>
                    )}
                    {!this.props.hideDelete && (
                        <Link onClick={() => this.remove()} className={this.getClassNames()}>
                            <Translate value="profile.remove" />
                        </Link>
                    )}
                </Actions>
            </div>
        )
    }
}

ListItem.propTypes = {
    onEdit: PropTypes.func,
    onRemove: PropTypes.func,
    href: PropTypes.string,
    item: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    auth: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    resume: PropTypes.bool,
    onChange: PropTypes.func,
    onDownload: PropTypes.func,
    hideEdit: PropTypes.bool,
    hideDelete: PropTypes.bool,
    hideDownload: PropTypes.bool,
    title: PropTypes.string,
    positiveText: PropTypes.string,
    showEdit: PropTypes.bool,
    actionType: PropTypes.string,
}

ListItem.defaultProps = {
    showEdit: true,
    hideDownload: true,
    actionType: 'actions__action--edit',
}

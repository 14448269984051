import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import { translate } from 'react-i18nify'
import { diffProfileItems } from '../../store/actions/user'
import { getErrorMessages } from '../../helpers'

import Alert from '../Alert/Alert'
import Button from '../Button/Button'

let map

class FormPanel extends Component {
    constructor(props) {
        super(props)

        this.state = { value: '', loading: false, errors: [] }

        this.save = this.save.bind(this)
    }

    onSuccess() {
        this.setState({
            loading: false,
        })
        this.props.onSave()
    }

    save(event) {
        if ((event && event.key === 'Enter') || event.target.classList.contains('button--cta')) {
            event.preventDefault()
        } else {
            return
        }

        if (this.state.value !== undefined && this.state.value.length > 0) {
            this.setState({
                value: '',
                loading: true,
                errors: [],
            })

            let item = this.props.build(this.state.value)

            this.props.dispatch(
                diffProfileItems(
                    this.props.item,
                    [item],
                    [],
                    this.props.auth,
                    () => {
                        this.onSuccess()
                    },
                    (s, o) => {
                        this.onError(s, o)
                    },
                ),
            )
        }
    }

    onError(status, object) {
        map = {
            name: translate('profile.name'),
        }

        switch (status) {
            case 400:
            case 404:
                this.setState(
                    {
                        errors: [],
                        loading: false,
                    },
                    function () {
                        if (object.hasOwnProperty('errors')) {
                            let messages = getErrorMessages(object.errors, map)

                            this.addErrors(messages)
                        }
                    },
                )
                break
        }
    }

    addErrors(errors) {
        this.setState({
            errors: errors,
        })
    }

    render() {
        const { placeholderText, iconName } = this.props

        let iconClass = 'form--panel__input__icon icons ' + iconName

        return (
            <form className="form--panel">
                <Row>
                    {this.state.errors && this.state.errors.length > 0 && (
                        <Alert type="alert--error alert--simple">
                            <ul>
                                {this.state.errors.map(function (value) {
                                    return <li key={value}>{value}</li>
                                })}
                            </ul>
                        </Alert>
                    )}

                    <Col md={7} lg={9}>
                        <div className="form--panel__input">
                            <i className={iconClass} />
                            <div className="form--panel__input__container">
                                <input
                                    onKeyPress={this.save}
                                    type="text"
                                    value={this.state.value}
                                    placeholder={placeholderText}
                                    onChange={(event) => {
                                        this.setState({
                                            value: event.target.value,
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={5} lg={3}>
                        <Button
                            className={
                                'button--cta' +
                                (this.state.loading
                                    ? ' is-loading'
                                    : '' +
                                      (this.state.value !== undefined && this.state.value.length > 0
                                          ? ''
                                          : ' disabled'))
                            }
                            buttonText={translate('profile.add_new')}
                            onClick={this.save}
                        />
                    </Col>
                </Row>
            </form>
        )
    }
}

FormPanel.propTypes = {
    dispatch: PropTypes.func.isRequired,
    placeholderText: PropTypes.string.isRequired,
    iconName: PropTypes.string.isRequired,
    build: PropTypes.func.isRequired,
    item: PropTypes.string.isRequired,
    onSave: PropTypes.func,
}

function mapStateToProps(state) {
    const { auth, user } = state

    return {
        auth,
        user,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(FormPanel)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Container, Hidden, Row } from 'react-grid-system'
import { connect } from 'react-redux'
import { translate, Translate } from 'react-i18nify'
import { Link, withRouter } from 'react-router-dom'
import Dropdown, { DropdownContent, DropdownTrigger } from 'react-simple-dropdown'
import { ToastContainer } from 'react-toastr'

import Actions from '../components/Actions/Actions'
import Button from '../components/Button/Button'
import FormFullProfile from '../components/Forms/FormFullProfile'
import FormQuote from '../components/Forms/FormQuote'
import ModalForm from '../components/Modal/ModalForm'
import ModalDelete from '../components/Modal/ModalDelete'
import Panel from '../components/Panel/Panel'
import Quote from '../components/Quote/Quote'
import {
    editProfileItem,
    fetchAccountFields,
    fetchDocumentsList,
    fetchProfile,
} from '../store/actions/user'
import { logoutUser } from '../store/actions/auth'
import { isSignedIn } from '../helpers'

import LocalStorage from '../components/LocalStorage'
import EducationsPage from '../components/Profile/Education/EducationsPage'
import DocumentsPage from '../components/Profile/Documents/DocumentsPage'
import SkillsPage from '../components/Profile/Skills/SkillsPage'
import WorkExperiencePage from '../components/Profile/WorkExperience/WorkExperiencePage'
import FlexibleShiftsPage from '../components/Profile/FlexibleShifts/FlexibleShiftsPage'
import EventsPage from '../components/Profile/Events/EventsPage'
import JobsGenerator from '../components/Forms/JobsGenerator'
import ReferencePossibleJobsBlock from '../components/Profile/ReferencePossibleJobsBlock'
import OpenBadgesPage from '../components/Profile/OpenBadges/OpenBadgesPage'
import ReintegrationPage from '../components/Profile/Reintegration/ReintegrationPage'
import { ImgCropperModal } from '../components/ImgCropperModal/ImgCropperModal'

const REINTEGRATION = 'reintegration'
const EDUCATION = 'education'
const WORKING_EXPERIENCE = 'working_experience'
const SKILLS = 'skills'
const ACHIEVEMENTS = 'achievements'
const DOCUMENTS = 'documents'
const FLEXIBLE_SHIFTS = 'flexible_shifts'
const EVENTS = 'events'
const OPEN_BADGES = 'open_badges'

const PAGE_ICON = {
    [REINTEGRATION]: 'icon-people',
    [EDUCATION]: 'icon-graduation',
    [WORKING_EXPERIENCE]: 'icon-briefcase',
    [SKILLS]: 'icon-equalizer',
    [ACHIEVEMENTS]: 'icon-trophy',
    [DOCUMENTS]: 'icon-docs',
    [FLEXIBLE_SHIFTS]: 'icon-rocket',
    [EVENTS]: 'icon-calendar',
    [OPEN_BADGES]: 'icon-badge',
}

const NCP_OK = 'NCP_OK'
const NCP_CANCEL = 'NCP_CANCEL'

class Profile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            editPersonal: false,
            editQuote: false,
            confirmCardsRedirect: false,
            alerts: 0,
            privacyAlerts: 0,
            profile_picture: null,
            deleteAccount: false,
            pages: [],
            page: LocalStorage.getItem('profilePage'),
        }

        this.showAlert = this.showAlert.bind(this)
        this.updateProfile = this.updateProfile.bind(this)
        this.getJobsGenerator = this.getJobsGenerator.bind(this)

        this.toastContainer = React.createRef()
        this.pagesDropdown = React.createRef()
        this.imgCropperRef = React.createRef()
    }

    componentDidMount() {
        this.setState({ pages: this.props.config.whitelabel.profile_pages.slice() })
        if (this.props.config.whitelabel.flexapp.used && this.props.user.profile.account_types) {
            if (
                this.props.user.profile.account_types.some(
                    (type) => type.code == 'corona' || type.code == 'flexapp-employee',
                )
            ) {
                this.state.pages.push(FLEXIBLE_SHIFTS)
            } else if (
                this.props.user.profile.account_types.some((type) => type.code == 'reintegrant')
            ) {
                this.state.pages.unshift(REINTEGRATION)
            }
        }

        if (
            this.props.config.whitelabel.open_badges_iframe_url &&
            this.props.user.profile.open_badges_id
        ) {
            this.state.pages.push(OPEN_BADGES)
        }

        if (this.props.location.search) {
            let query = new URLSearchParams(this.props.location.search)

            let page = LocalStorage.getItem('profilePage') || this.state.pages[0]
            if (query.get('invite_diploma_share')) {
                page = EDUCATION
                this.setState({ emrexModal: true })
                // Remove parameters from url to prevent showing toast on reload
                window.history.replaceState(
                    {},
                    '',
                    window.location.origin + window.location.pathname,
                )
            } else if (query.get('from_emrex')) {
                page = EDUCATION
                if (query.get('response') === NCP_OK) {
                    this.showFeedbackModal(
                        translate('profile.emrex.success'),
                        translate('profile.emrex.studies_added'),
                    )
                } else if (query.get('response') === NCP_CANCEL) {
                    this.showFeedbackModal(
                        translate('profile.emrex.cancelled'),
                        translate('profile.emrex.studies_cancelled'),
                    )
                } else {
                    this.showFeedbackModal(
                        translate('profile.emrex.failed'),
                        translate('profile.emrex.something_went_wrong'),
                    )
                }
                // Remove parameters from url to prevent showing toast on reload
                window.history.replaceState(
                    {},
                    '',
                    window.location.origin + window.location.pathname,
                )
            }

            if (query.get('page') && this.state.pages.includes(query.get('page'))) {
                page = query.get('page')
            }

            this.savePage(page)
            this.setState({ page: page })
        }
        if (!isSignedIn(this.props.auth)) {
            this.props.dispatch(logoutUser(this.props.auth, null))
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // Corona employes and flex employees are able to see shift organisations
        let pages = this.state.pages
        if (
            this.props.user.profile.account_types &&
            this.props.user.profile.account_types.some(
                (type) => type.code == 'corona' || type.code == 'flexapp-employee',
            ) &&
            !pages.includes(FLEXIBLE_SHIFTS) &&
            this.props.config.whitelabel.flexapp.used
        ) {
            pages.push(FLEXIBLE_SHIFTS)
        }
        if (
            this.props.user.profile.account_types &&
            !pages.includes(REINTEGRATION) &&
            this.props.user.profile.account_types.some((type) => type.code == 'reintegrant')
        ) {
            pages.unshift(REINTEGRATION)
        }

        if (
            this.props.config.whitelabel.open_badges_iframe_url &&
            this.props.user.profile.open_badges_id &&
            !pages.includes(OPEN_BADGES)
        ) {
            pages.push(OPEN_BADGES)
        }

        if (JSON.stringify(pages) !== JSON.stringify(prevState.pages)) {
            this.setState({ pages })
        }

        let page = LocalStorage.getItem('profilePage')
            ? LocalStorage.getItem('profilePage')
            : this.state.pages[0]

        if (this.props.location.search) {
            let queryPage = new URLSearchParams(this.props.location.search).get('page')
            if (queryPage && this.state.pages.includes(queryPage)) {
                page = queryPage
            }
        }

        this.savePage(page)
        if (this.state.page !== page) {
            this.setState({ page: page })
        }
    }

    savePage(page) {
        LocalStorage.setItem('profilePage', page)
    }

    handlePageChange(event) {
        this.pagesDropdown.current.setState({ active: false })
        this.setState({ page: event })
        LocalStorage.setItem('profilePage', event)
    }

    updateProfile(callback) {
        this.props.dispatch(
            fetchProfile(
                this.props.auth,
                (status, obj) => {
                    this.setState({ loading: false })
                    this.onFetchError(status, obj)
                },
                () => {
                    this.setState({ loading: false })
                    this.props.dispatch(
                        fetchAccountFields(this.props.user.profile.account_types, this.props.auth),
                    )
                    if (typeof callback === 'function') callback()
                },
            ),
        )
    }

    onFetchError(status, errors) {}

    showAlert() {
        this.state.alerts = this.state.alerts + 1
        if (this.state.alerts < 4) {
            this.showFeedbackModal(
                translate('profile.edit_profile_note'),
                translate('profile.edit_profile_explanation'),
            )
        } else {
            setTimeout(() => {
                this.state.alerts = 0
            }, 50000)
        }
    }

    showPrivacyAlert() {
        if (this.state.privacyAlerts < 4) {
            this.showFeedbackModal(
                translate('profile.edit_profile_note'),
                translate('profile.edit_privacy_explanation'),
            )
        } else {
            setTimeout(() => {
                this.state.privacyAlerts = 0
            }, 50000)
        }
    }

    showFeedbackModal(title, message) {
        this.toastContainer.current.info(message, title, {
            timeOut: 10000,
            extendedTimeOut: 1000,
        })
    }

    getQuote() {
        let quote = this.props.user.profile.quote_text
        if (quote == null || quote.length < 1) {
            return translate('profile.quote_unavailable')
        } else {
            return quote
        }
    }

    removeQuote() {
        this.setState({
            removingQuote: true,
        })

        let update = {
            quote_text: '',
        }

        this.props.dispatch(
            editProfileItem(
                'general',
                update,
                this.props.auth,
                () => this.onQuoteSuccess(),
                (status, error) => this.onQuoteError(status, error),
            ),
        )
    }

    onQuoteSuccess() {
        this.setState({
            removingQuote: false,
        })
    }

    onQuoteError() {
        this.setState({
            removingQuote: false,
        })
    }

    getJobsGenerator(key, close) {
        return (
            <ModalForm
                title={
                    key === 'generateJob'
                        ? translate('profile.job_interest')
                        : translate('profile.flexapp_function_interest')
                }
                show
                onClose={() => close()}
            >
                <JobsGenerator
                    className="form--small_margin"
                    onCancel={() => close()}
                    onSave={() => {
                        this.showAlert()
                        close()
                    }}
                    flexapp={key === 'addFlexappFunction'}
                    dispatch={this.props.dispatch}
                />
            </ModalForm>
        )
    }

    getTagline(tagline) {
        if (tagline === undefined || tagline === null || tagline.length === 0) {
            return ''
        }
        return tagline
    }

    render() {
        const { auth, user } = this.props

        return (
            <div className="react-native-web">
                <ToastContainer ref={this.toastContainer} className="toast-bottom-right" />

                <Container>
                    {user !== undefined && Object.keys(user).length > 0 && (
                        <div>
                            <Row className="panel--flex">
                                <Col lg={7}>
                                    <Panel className="panel--bg">
                                        <div className="panel__profile_img">
                                            <div className="panel__profile_img__img">
                                                <img src={user.profile_picture} alt="" />
                                            </div>
                                        </div>
                                        <div className="panel__intro">
                                            <div className="panel__intro__text">
                                                <h2>{user.profile.name}</h2>
                                                {(user.profile.tagline_text ||
                                                    user.profile.city_name) && (
                                                    <p>
                                                        {user.profile.tagline_text &&
                                                            this.getTagline(
                                                                user.profile.tagline_text,
                                                            )}
                                                        {user.profile.city_name && (
                                                            <span>{user.profile.city_name}</span>
                                                        )}
                                                    </p>
                                                )}
                                            </div>

                                            <Actions>
                                                <Link
                                                    to="#"
                                                    onClick={() => {
                                                        this.setState({
                                                            editPersonal: true,
                                                        })
                                                    }}
                                                    className="actions__action--edit stay-same pull-right"
                                                >
                                                    <Translate value="profile.edit_profile" />
                                                </Link>
                                            </Actions>
                                        </div>

                                        {this.state.editPersonal && (
                                            <ModalForm
                                                title={translate('menu.profile')}
                                                show
                                                onClose={() => {
                                                    this.setState({
                                                        editPersonal: false,
                                                    })
                                                }}
                                                size={'x-large'}
                                            >
                                                <FormFullProfile
                                                    className="form"
                                                    imgCropperRef={this.imgCropperRef}
                                                    data={user.profile}
                                                    onSave={() => {
                                                        this.props.dispatch(
                                                            fetchDocumentsList(this.props.auth),
                                                        )
                                                        this.setState({
                                                            editPersonal: false,
                                                        })
                                                    }}
                                                    onCancel={() => {
                                                        this.setState({
                                                            editPersonal: false,
                                                        })
                                                    }}
                                                />
                                            </ModalForm>
                                        )}
                                    </Panel>
                                </Col>
                                <ImgCropperModal ref={this.imgCropperRef} />

                                <Col lg={5}>
                                    <Panel className="panel--quote">
                                        <Quote>{this.getQuote()}</Quote>
                                        <Actions>
                                            <Link
                                                to="#"
                                                onClick={() => {
                                                    this.removeQuote()
                                                }}
                                                className={
                                                    'actions__action--delete' +
                                                    (this.state.removingQuote ? ' is-loading' : '')
                                                }
                                            >
                                                <Translate value="profile.remove" />
                                            </Link>

                                            <Link
                                                to="#"
                                                onClick={() => {
                                                    this.setState({
                                                        editQuote: true,
                                                    })
                                                }}
                                                className="actions__action--edit"
                                            >
                                                <Translate value="profile.edit" />
                                            </Link>
                                        </Actions>

                                        {this.state.editQuote && (
                                            <ModalForm
                                                title="Quote"
                                                show
                                                onClose={() => {
                                                    this.setState({
                                                        editQuote: false,
                                                    })
                                                }}
                                            >
                                                <FormQuote
                                                    className="form"
                                                    onSave={() => {
                                                        this.setState({
                                                            editQuote: false,
                                                        })
                                                    }}
                                                    onCancel={() => {
                                                        this.setState({
                                                            editQuote: false,
                                                        })
                                                    }}
                                                />
                                            </ModalForm>
                                        )}
                                    </Panel>
                                </Col>
                            </Row>

                            <Dropdown ref={this.pagesDropdown} className="dropdown--large">
                                <DropdownTrigger>
                                    <i
                                        className={`dropdown__trigger__icon icons ${
                                            PAGE_ICON[this.state.page]
                                        }`}
                                    />
                                    <Translate value={'pages.' + this.state.page} />
                                    <i className="dropdown__icon_down icons icon-arrow-down" />
                                </DropdownTrigger>
                                <DropdownContent>
                                    <ul>
                                        {this.state.pages.map(function (item) {
                                            return (
                                                <li
                                                    key={item}
                                                    className={
                                                        this.state.page === item ? 'is-active' : ''
                                                    }
                                                >
                                                    <a onClick={() => this.handlePageChange(item)}>
                                                        <i className={`icons ${PAGE_ICON[item]}`} />
                                                        <Translate value={'pages.' + item} />
                                                    </a>
                                                </li>
                                            )
                                        }, this)}
                                    </ul>
                                </DropdownContent>
                            </Dropdown>

                            {this.state.page === REINTEGRATION && (
                                <ReintegrationPage showAlert={this.showAlert} />
                            )}

                            {this.state.page === EDUCATION && (
                                <EducationsPage
                                    showAlert={this.showAlert}
                                    updateProfile={this.updateProfile}
                                />
                            )}

                            {this.state.page === WORKING_EXPERIENCE && (
                                <WorkExperiencePage
                                    showAlert={this.showAlert}
                                    updateApplication={this.updateApplication}
                                />
                            )}

                            {this.state.page === SKILLS && (
                                <SkillsPage showAlert={this.showAlert} />
                            )}

                            {this.state.page === DOCUMENTS && (
                                <DocumentsPage showAlert={this.showAlert} />
                            )}

                            {this.state.page === FLEXIBLE_SHIFTS && (
                                <FlexibleShiftsPage
                                    getJobsGenerator={this.getJobsGenerator}
                                    showAlert={this.showAlert}
                                />
                            )}

                            {this.state.page === EVENTS && (
                                <EventsPage showAlert={this.showAlert} />
                            )}

                            {this.state.page === OPEN_BADGES && <OpenBadgesPage />}

                            <ReferencePossibleJobsBlock
                                getJobsGenerator={this.getJobsGenerator}
                                showAlert={this.showAlert}
                            />

                            <Row>
                                <Hidden xs sm>
                                    <Col md={3}>
                                        <span />
                                    </Col>
                                </Hidden>
                                <Col xs={12} md={6}>
                                    <Button
                                        className="button--danger"
                                        buttonText={translate('profile.delete_profile')}
                                        onClick={() => {
                                            this.setState({
                                                deleteAccount: true,
                                            })
                                        }}
                                    />
                                </Col>
                            </Row>

                            <div className="spacer-button" />
                        </div>
                    )}
                </Container>

                {this.state.deleteAccount && !user.profile.has_trajectories && (
                    <ModalDelete
                        title={translate('profile.delete_profile')}
                        subTitle={translate('profile.delete_info')}
                        arrDelete={[
                            translate('profile.delete_item_export'),
                            translate('profile.delete_item_personal'),
                            translate('profile.delete_item_files'),
                            translate('profile.delete_item_reactions'),
                            translate('profile.delete_item_signin'),
                            translate('profile.delete_item_profile_picture'),
                        ]}
                        show
                        onClose={() => {
                            this.setState({
                                deleteAccount: false,
                            })
                        }}
                    />
                )}

                {this.state.deleteAccount && user.profile.has_trajectories && (
                    <ModalForm
                        title={translate('profile.cannot_delete_profile_title')}
                        show
                        className="modal"
                        onClose={() => {
                            this.setState({
                                deleteAccount: false,
                            })
                        }}
                    >
                        <p>{translate('profile.cannot_delete_profile')}</p>
                        <Actions>
                            <Link
                                className="actions__action--save"
                                onClick={() => {
                                    this.setState({
                                        deleteAccount: false,
                                    })
                                }}
                            >
                                Ok
                            </Link>
                        </Actions>
                    </ModalForm>
                )}
            </div>
        )
    }
}

Profile.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        config,
        user,
        state,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(withRouter(Profile))
export { EDUCATION, WORKING_EXPERIENCE, SKILLS, DOCUMENTS }

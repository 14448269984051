import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-grid-system'
import Alert from '../Alert/Alert'

import { translate, Translate } from 'react-i18nify'

import newCallApi from '../../store/actions/api'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { PATH_LOGIN } from '../../constants/Constants'

class FormRegisterStep1 extends Component {
    constructor(props) {
        super(props)

        this.accountTypeChange = this.accountTypeChange.bind(this)
        this.firstNameChange = this.firstNameChange.bind(this)
        this.lastNameChange = this.lastNameChange.bind(this)
        this.submit = this.submit.bind(this)

        this.state = {
            first_name: '',
            last_name: '',
            account_type_id: 1,
            account_types: null,
        }
    }

    componentDidMount() {
        newCallApi('GET', this.props.config.ACCOUNT_TYPES).then((json) => {
            this.setState({
                account_types: json,
            })
            if (this.state.account_types && this.props.account_type_code) {
                let account_types = this.state.account_types.filter(
                    (acc) => acc.code === this.props.account_type_code,
                )
                if (account_types.length === 1) {
                    this.setState({ account_type_id: String(account_types[0].id) })
                }
            }
        })

        if (this.props.state.firstname && this.props.state.firstname.length > 0) {
            this.setState({
                first_name: this.props.state.firstname,
            })
        }

        if (this.props.state.lastname && this.props.state.lastname.length > 0) {
            this.setState({
                last_name: this.props.state.lastname,
            })
        }
    }

    accountTypeChange(event) {
        this.setState({
            account_type_id: event.target.value,
        })
    }

    firstNameChange(event) {
        this.setState({
            first_name: event.target.value,
        })
    }

    lastNameChange(event) {
        this.setState({
            last_name: event.target.value,
        })
    }

    submit(e) {
        e.preventDefault()

        this.props.onSubmit(this.state.first_name, this.state.last_name, this.state.account_type_id)
    }

    getClassName(ref) {
        let classes = 'form__input--text'

        if (this.props.errorFields.indexOf(ref) > -1) {
            classes += ' error'
        }

        if (this.state[ref] && this.state[ref].length > 0) {
            classes += ' has-value'
        }

        return classes
    }

    render() {
        const { className, onSubmit, errorMessage, errorFields, state } = this.props
        return (
            <form className={this.props.className} onSubmit={this.submit}>
                <Link to={'/' + PATH_LOGIN} className="back_to_login">
                    <i className={'icons icon-arrow-left'} />
                    <Translate value="buttons.to_login" />
                </Link>
                <Row>
                    <Col xs={12} sm={1} md={2} lg={2}>
                        <span />
                    </Col>
                    <Col xs={12} sm={10} md={8} lg={8}>
                        <h1>
                            <Translate value="register.create_account" />
                        </h1>

                        <p
                            className={
                                errorMessage && errorMessage.length > 0 ? 'is-hidden--mobile' : ''
                            }
                        >
                            <Translate value="register.create_an_account" />
                            <br />
                            <Translate value={'register.account_validity'} />
                        </p>

                        {errorMessage && errorMessage.length > 0 && (
                            <Alert alertText={errorMessage} type="alert--error" />
                        )}

                        <fieldset className="form__fieldset">
                            <div className="form__item">
                                <i className="form__icon icons icon-user" />
                                <input
                                    onChange={this.firstNameChange}
                                    className={this.getClassName('first_name')}
                                    type="firstName"
                                    id="first_name"
                                    value={this.state.first_name}
                                    autoFocus
                                    tabIndex="1"
                                />
                                <label className="form__label" htmlFor="first_name">
                                    <Translate value="labels.first_name" />
                                </label>
                            </div>
                            <div className="form__item">
                                <input
                                    onChange={this.lastNameChange}
                                    className={this.getClassName('last_name')}
                                    type="lastName"
                                    id="last_name"
                                    value={this.state.last_name}
                                    tabIndex="2"
                                />
                                <label className="form__label" htmlFor="last_name">
                                    <Translate value="labels.last_name" />
                                </label>
                            </div>
                        </fieldset>

                        <div className="form__button_container">
                            <button
                                type="submit"
                                className="button--alt"
                                onClick={this.submit}
                                tabIndex="5"
                            >
                                {translate('buttons.continue')}
                            </button>
                        </div>
                    </Col>
                </Row>
            </form>
        )
    }
}

FormRegisterStep1.propTypes = {
    className: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    errorFields: PropTypes.array,
    state: PropTypes.object,
}

function mapStateToProps(state) {
    const { config } = state

    return {
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(FormRegisterStep1)

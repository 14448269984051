import newCallApi from './api'
import { store } from '../index'

export function fetchJobOffer(id, auth, onSuccess, onError) {
    var path = store.getState().config.API_VACANCIES + '/' + id
    return newCallApi('GET', path, null, /* auth */ null).then(
        (response) => {
            onSuccess(response)
        },
        (response) => {
            onError(response.status, response)
        },
    )
}

export function findVacancies(auth, city_id = -1, radius = 10) {
    let path = store.getState().config.API_VACANCIES + `?city_id=${city_id}&radius=${radius}`

    return new Promise((resolve, reject) => {
        newCallApi('GET', path, null, /* auth */ null).then(
            (object) => {
                resolve(object)
            },
            (response) => {
                reject({ status: response.status, response: response })
            },
        )
    })
}

export function searchCities(auth, query) {
    let path = store.getState().config.API_CITIES + (query !== '' ? '?search=' + query : '')

    return new Promise((resolve, reject) => {
        newCallApi('GET', path, null, /* auth */ null).then(
            (object) => {
                resolve(object)
            },
            (response) => {
                reject({ status: response.status, response: response })
            },
        )
    })
}

import React, { Component } from 'react'
import Sidebar from 'react-sidebar'
import ReactGA from 'react-ga4'

import { connect } from 'react-redux'

import Header from './components/Header/Header'
import Intro from './components/Intro/Intro'
import Footer from './components/Footer/Footer'
import LocalStorage from './components/LocalStorage'
import {
    checkGoBackTo,
    checkZorgLogin,
    loginSSO,
    logoutUser,
    refreshToken,
    saveFirstLogin,
    setGoBackTo,
} from './store/actions/auth'
import {
    PATH_ABOUT,
    PATH_ACTIVATE,
    PATH_FORGOT_PASSWORD,
    PATH_LOGIN,
    PATH_REGISTER,
    PATH_RESET_PASSWORD,
    PATH_ROADMAP,
} from './constants/Constants'
import { withRouter } from 'react-router-dom'

import {
    fetchAccountFields,
    fetchDocumentsList,
    fetchEventInterests,
    fetchFlexappOrganizations,
    fetchOrganizations,
    fetchProfile,
    fetchSchools,
    fetchStudies,
    fetchStudyLevels,
    fetchWorkingAreas,
} from './store/actions/user'
import { checkSublabel, fetchConfig } from './store/actions/config'
import { isSignedIn } from './helpers'

import moment from 'moment'
import PropTypes from 'prop-types'
import Raven from 'raven-js'
import NavigationDrawer from './components/NavigationDrawer/NavigationDrawer'
import TopNotification from './components/TopNotification/TopNotification'
import Onboarding from './components/Onboarding/Onboarding'
import { translate } from 'react-i18nify'
import Loading from './components/Loading/Loading'
import routes from './Routes'
import history from './history'
import reportWebVitals from './reportWebVitals'

if (process.env.REACT_APP_SENTRY_URL) {
    Raven.config(process.env.REACT_APP_SENTRY_URL).install()
}

class App extends Component {
    constructor(props) {
        super(props)
        moment.updateLocale('nl', {
            months: [
                'Januari',
                'Februari',
                'Maart',
                'April',
                'Mei',
                'Juni',
                'Juli',
                'Augustus',
                'September',
                'Oktober',
                'November',
                'December',
            ],
        })
        this.state = {
            refreshing: true,
            navigationDrawerOpen: false,
        }
        this.toggleNavigationDrawer = this.toggleNavigationDrawer.bind(this)
        this.onFetchError = this.onFetchError.bind(this)
    }

    toggleNavigationDrawer() {
        this.setState({ navigationDrawerOpen: !this.state.navigationDrawerOpen })
    }

    componentDidMount() {
        this.props.dispatch(setGoBackTo())
        this.props.dispatch(fetchConfig()).then((x) => this.onConfigLoaded())
    }

    onConfigLoaded() {
        if (this.props.config.whitelabel.ga_property_id) {
            ReactGA.initialize(this.props.config.whitelabel.ga_property_id)
            reportWebVitals()
        }

        //If signed in, refresh the token to make sure we have a valid one
        if (this.props.auth && this.props.auth.isAuthenticated && this.shouldRefresh()) {
            this.setState({
                refreshing: true,
            })
            this.props.dispatch(
                refreshToken(
                    this.props.auth,
                    () => {
                        this.onRefreshSuccess()
                    },
                    (response) => this.onRefreshError(response),
                ),
            )
        } else if (!this.props.auth || !this.props.auth.isAuthenticated) {
            this.setState({
                refreshing: true,
            })
            // If not signed in, check if Zorgselect token exists
            this.props.dispatch(
                checkZorgLogin(
                    (object) => {
                        this.onZorgSessionSuccess(object)
                    },
                    (response) => this.onZorgSessionError(),
                    () => {
                        this.setState({
                            refreshing: false,
                        })
                    },
                ),
            )
        } else {
            this.setState({
                refreshing: false,
            })

            // if we are on forgot password page don't fetch user info
            const {
                location: { pathname },
            } = this.props
            if (pathname.slice(1) !== PATH_FORGOT_PASSWORD) {
                this.fetch()
            }
        }
        if (this.props.user.profile.account_types) {
            this.props.dispatch(
                checkSublabel(this.props.config.whitelabel, this.props.user.profile.account_types),
            )
        } else {
            this.props.dispatch(checkSublabel(this.props.config.whitelabel, []))
        }
    }

    onZorgSessionSuccess(object) {
        if (
            this.props.auth &&
            this.props.auth.isAuthenticated &&
            this.props.state.routing.locationBeforeTransitions.pathname.includes(PATH_LOGIN)
        ) {
            this.setState({ refreshing: false })
            this.props.dispatch(checkGoBackTo())
        }

        if (!LocalStorage.getItem('hasRefreshed')) {
            LocalStorage.setItem('hasRefreshed', '1')
            window.reload(true)
        }
        this.setState({ refreshing: false })
    }

    onZorgSessionError() {
        this.setState({ refreshing: false })
        if (this.shouldRedirect(this.props.state.routing.locationBeforeTransitions.pathname)) {
            this.setState({ refreshing: false })
            history.push('/' + PATH_LOGIN)

            if (
                !isSignedIn(this.props.auth) &&
                this.shouldRedirect(this.props.state.routing.locationBeforeTransitions.pathname)
            ) {
                this.props.dispatch(logoutUser(this.props.auth))
            }
        }
    }

    shouldRefresh() {
        let d = new Date()
        let current = d.getTime()

        let exp = this.props.auth.exp * 1000
        let iat = this.props.auth.iat * 1000

        let life = exp - iat

        return current > exp - life / 2
    }

    shouldRedirect(pathname) {
        if (
            pathname.includes(PATH_FORGOT_PASSWORD) ||
            pathname.includes(PATH_RESET_PASSWORD) ||
            pathname.includes(PATH_REGISTER) ||
            pathname.includes(PATH_LOGIN) ||
            pathname.includes(PATH_ACTIVATE) ||
            pathname.includes(PATH_ABOUT) ||
            pathname.includes(PATH_ROADMAP)
        ) {
            return false
        }
        return true
    }

    onRefreshSuccess() {
        this.setState({
            refreshing: false,
        })

        this.fetch()
    }

    onRefreshError(response) {
        this.props.dispatch(
            checkZorgLogin(
                (object) => {
                    this.onZorgSessionSuccess(object)
                },
                (response) => this.onZorgSessionError(),
                () => {
                    this.setState({
                        refreshing: false,
                    })
                    this.props.dispatch(logoutUser(this.props.auth, null))
                },
            ),
        )
    }

    fetch() {
        this.props.dispatch(
            fetchProfile(
                this.props.auth,
                (status, obj) => {
                    this.onFetchError(status, obj)
                    this.setState({
                        refreshing: false,
                    })
                },
                () => {
                    this.props.dispatch(
                        fetchAccountFields(this.props.user.profile.account_types, this.props.auth),
                    )
                    if (
                        this.props.user.profile.account_types
                            .map((a) => a.code)
                            .indexOf('student') >= 0
                    ) {
                        this.props.dispatch(fetchSchools(this.props.auth))
                    }
                    this.setState({
                        refreshing: false,
                    })
                },
            ),
        )
        this.props.dispatch(fetchWorkingAreas(this.props.auth, this.onFetchError))
        this.props.dispatch(fetchStudies(this.props.auth, this.onFetchError))
        this.props.dispatch(fetchDocumentsList(this.props.auth, () => {}))
        this.props.dispatch(fetchOrganizations(this.props.auth, () => {}, this.onFetchError))
        this.props.dispatch(fetchStudyLevels(this.props.auth, this.onFetchError))
        if (this.props.config.whitelabel.flexapp.used) {
            this.props.dispatch(
                fetchFlexappOrganizations(this.props.auth, () => {}, this.onFetchError),
            )
        }
        if (this.props.config.whitelabel.profile_pages.includes('events')) {
            this.props.dispatch(fetchEventInterests(this.props.auth, () => {}, this.onFetchError))
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location !== prevProps.location) {
            ReactGA.send({
                hitType: 'pageview',
                page: window.location.pathname + window.location.search,
            })
        }
        if (
            this.props.auth &&
            this.props.auth.isAuthenticated &&
            (!prevProps.auth || !prevProps.auth.isAuthenticated)
        ) {
            this.fetch()
        }
    }

    onFetchError(obj) {
        if (obj.status === 403) {
            this.props.dispatch(logoutUser(this.props.auth, null, null, true))
        }
    }

    render() {
        const { auth, config, user } = this.props

        if (user.profile.account_types) {
            this.props.dispatch(checkSublabel(config.whitelabel, user.profile.account_types))
        }
        // Ask for extra information if user has chosen to be part of flexapp/corona
        let missingData =
            config.whitelabel?.flexapp?.used &&
            user.profile &&
            user.profile.missing_flexapp_data &&
            user.profile?.account_types?.some(
                (type) => type.code === 'flexapp-employee' || type.code === 'corona',
            ) &&
            auth.onboarding_done

        return !Object.keys(user.profile).length && auth.isAuthenticated ? (
            <Loading text={translate('general.loading')} />
        ) : (
            <div className="wrapper">
                <Sidebar
                    sidebar={<NavigationDrawer hide={this.toggleNavigationDrawer} />}
                    open={this.state.navigationDrawerOpen}
                    onSetOpen={this.toggleNavigationDrawer}
                    styles={{ sidebar: { background: 'white', position: 'fixed' } }}
                >
                    <div />
                </Sidebar>
                <div className="content">
                    <Header
                        navigationDrawerOpen={this.state.navigationDrawerOpen}
                        toggleNavigationDrawer={this.toggleNavigationDrawer}
                        studentLogo={
                            this.props.match.params &&
                            this.props.match.params.account_type === 'student'
                        }
                    />
                    <Intro auth={auth} config={config} />
                    {auth.isAuthenticated && (user.showFlexappNotification || missingData) && (
                        <TopNotification
                            auth={auth}
                            user={user}
                            missingData={missingData}
                            dispatch={this.props.dispatch}
                        />
                    )}
                    {!this.state.refreshing && routes}

                    {!this.state.refreshing &&
                        auth.isAuthenticated &&
                        !user.profile.onboarding_done && <Onboarding />}
                </div>
                <Footer />
            </div>
        )
    }
}

App.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        state,
        user,
        config,
    }
}

export default connect(mapStateToProps)(withRouter(App))

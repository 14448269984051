import React, { Component } from 'react'
import { translate } from 'react-i18nify'
import Button from '../Button/Button'
import {
    fetchProfile,
    filledInFlexappData,
    hideFlexappNotification,
    updateProfileItem,
} from '../../store/actions/user'
import { Col, Container, Row } from 'react-grid-system'

export default class TopNotification extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showAwaitMsg: false,
            showPendingMsg:
                !props.missingData &&
                props.user.profile.account_types.some((at) => at.code === 'flexapp-employee'),
        }
        this.check = this.check.bind(this)
    }

    check() {
        this.props.dispatch(
            updateProfileItem('general', { onboarding_done: false }, this.props.auth),
        )
        this.props.dispatch(hideFlexappNotification())
    }

    render() {
        return (
            <div className="top_notification">
                <Container>
                    <span className="top_notification__text">
                        {this.state.showPendingMsg
                            ? translate('notification.pending_acceptance')
                            : this.state.showAwaitMsg
                            ? translate('notification.wait_for_activation')
                            : this.props.missingData
                            ? translate('notification.onboarding_not_complete')
                            : translate('notification.suggest_flexapp')}
                    </span>
                    {this.props.missingData ? (
                        <Row>
                            <Col xs={12} sm={2} md={3} />
                            <Col xs={12} sm={8} md={6}>
                                <Button
                                    className={'button--alt'}
                                    onClick={() => {
                                        this.props.dispatch(filledInFlexappData())
                                    }}
                                >
                                    <div>
                                        <i className="icons icon-info" />
                                        {translate('notification.show_onboarding')}
                                    </div>
                                </Button>
                            </Col>
                        </Row>
                    ) : (
                        !this.state.showAwaitMsg &&
                        !this.state.showPendingMsg && (
                            <Row>
                                <Col xs={12} sm={12} md={2} lg={3} />
                                <Col xs={12} sm={6} md={4} lg={3}>
                                    <Button
                                        className={'button--alt'}
                                        onClick={() => {
                                            this.setState({ showAwaitMsg: true })
                                            setTimeout(this.check, 5000)
                                            this.props.dispatch(
                                                updateProfileItem(
                                                    'account_types',
                                                    {
                                                        account_types: [
                                                            11,
                                                            ...this.props.user.profile.account_types.map(
                                                                (a) => a.id,
                                                            ),
                                                        ],
                                                    },
                                                    this.props.auth,
                                                    () => {
                                                        this.setState({
                                                            loading: false,
                                                        })
                                                        this.props.dispatch(
                                                            fetchProfile(this.props.auth, null),
                                                        )
                                                    },
                                                    (status, errors) => this.error(status, errors), // get errors
                                                ),
                                            )
                                        }}
                                    >
                                        <div>
                                            <i className="icons icon-check" />
                                            {translate('notification.button_yes')}
                                        </div>
                                    </Button>
                                </Col>
                                <Col xs={12} sm={6} md={4} lg={3}>
                                    <Button
                                        className={'button--danger'}
                                        onClick={() => {
                                            this.props.dispatch(
                                                updateProfileItem(
                                                    'flexapp_invitation_rejected',
                                                    {
                                                        flexapp_invitation_rejected: true,
                                                    },
                                                    this.props.auth,
                                                    () => {
                                                        this.setState({
                                                            loading: false,
                                                        })
                                                        this.props.dispatch(
                                                            fetchProfile(
                                                                this.props.auth,
                                                                (status, errs) => {},
                                                            ),
                                                        )
                                                    },
                                                    (status, errors) => this.error(status, errors), // get errors
                                                ),
                                            )
                                            this.props.dispatch(hideFlexappNotification())
                                        }}
                                    >
                                        <div>
                                            <i className="icons icon-close" />
                                            {translate('notification.button_no')}
                                        </div>
                                    </Button>
                                </Col>
                            </Row>
                        )
                    )}
                </Container>
            </div>
        )
    }
}

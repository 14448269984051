import {
    SUGGESTION_FAILURE,
    SUGGESTION_NEXT,
    SUGGESTION_REQUEST,
    SUGGESTION_SUCCESS,
} from '../../constants/Constants'

var newCardsThreshold = 30

/**
 * Reducer to change the application state for all auth state changes
 * @param state
 * @param action
 */
export function suggestions(
    state = {
        isFetching: false,
        suggestions: [],
        error: '',
        card: null,
        index: 0,
        lastCallIndex: 0,
        outOfSuggestions: false,
        shouldFetch: false,
        answeredSuggestions: [],
    },
    action,
) {
    switch (action.type) {
        case SUGGESTION_REQUEST:
            let callIndex = 0

            if (state.suggestions.length > 0) {
                callIndex = state.index
            }

            return Object.assign({}, state, {
                isFetching: true,
                error: '',
                lastCallIndex: callIndex,
                shouldFetch: false,
            })
        case SUGGESTION_SUCCESS:
            let suggestions = []
            let card = {}
            let cardIndex = 0

            if (state.suggestions.length > 0 && !action.clear) {
                suggestions = state.suggestions.concat(action.suggestions)
                let newSuggestions = suggestions.filter((card) => {
                    return state.answeredSuggestions.indexOf(card.id) < 0
                })

                console.log(
                    'set new suggestions',
                    newSuggestions,
                    suggestions,
                    state.answeredSuggestions,
                )
                suggestions = newSuggestions
                //
                cardIndex = state.index
                card = suggestions[0]
            } else {
                suggestions = action.suggestions
                cardIndex = 0
                card = suggestions[cardIndex]
            }

            return Object.assign({}, state, {
                isFetching: false,
                error: '',
                suggestions: suggestions,
                card: card,
                index: cardIndex,
            })
        case SUGGESTION_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error,
            })
        case SUGGESTION_NEXT:
            let shouldFetchNewSuggestions = false
            let diff = state.index - state.lastCallIndex
            if (diff > newCardsThreshold) {
                shouldFetchNewSuggestions = true
            }

            let answered = state.answeredSuggestions
            answered.push(state.card.id)

            if (state.index + 1 < state.suggestions.length) {
                //There are more suggestions
                return Object.assign({}, state, {
                    card: state.suggestions[state.index + 1],
                    index: state.index + 1,
                    shouldFetch: shouldFetchNewSuggestions,
                    answeredSuggestions: answered,
                })
            } else {
                return Object.assign({}, state, {
                    outOfSuggestions: true,
                    card: {},
                    index: state.index + 1,
                    shouldFetch: shouldFetchNewSuggestions,
                    answeredSuggestions: answered,
                })
            }
        default:
            return state
    }
}

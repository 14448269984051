import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Button from '../Button/Button'

import Actions from '../Actions/Actions'

import { diffArrays, getErrorFields, getErrorMessages } from '../../helpers'
import { Translate } from 'react-i18nify'

import { diffProfileItems, getRoles } from '../../store/actions/user'

import Alert from '../Alert/Alert'

let map

class RoleGenerator extends Component {
    constructor(props) {
        super(props)

        this.state = {
            errors: [],
            items: [],
            all: [],
            filtered: [],
            manual: [],
            checked: [],
            add: true,
            filterValue: '',
            loading: false,
            fetching: true,
        }

        this.isChecked = this.isChecked.bind(this)
        this.getClassNames = this.getClassNames.bind(this)
        this.onCheckboxChange = this.onCheckboxChange.bind(this)
        this.filter = this.filter.bind(this)
    }

    getInitialManual() {
        let saved = this.props.roles || []
        let suggested = this.state.items || []

        let manual = []
        for (let i = 0; i < saved.length; i++) {
            if (
                suggested.find((item) => {
                    return item.id === saved[i].id
                }) === undefined
            ) {
                manual.push(saved[i])
            }
        }

        return manual
    }

    componentWillMount() {
        getRoles(
            this.props.auth,
            (response) => {
                this.setState({
                    all: response,
                    filtered: response,
                    fetching: false,
                    checked:
                        this.props.roles !== undefined
                            ? this.props.roles.map((item) => {
                                  return item.id
                              })
                            : [],
                    manual: this.getInitialManual(),
                })
            },
            () => {},
        )

        //TODO fetch suggested roles.
    }

    cancel() {
        this.props.onCancel()
    }

    save() {
        if (!this.state.loading) {
            this.setState({
                loading: true,
            })

            let todo = diffArrays(this.props.roles, this.state.checked, 'id')
            this.props.dispatch(
                diffProfileItems(
                    'competence_roles',
                    todo.toAdd,
                    todo.toRemove,
                    this.props.auth,
                    () => {
                        this.setState({ loading: false })
                        this.props.onSave()
                    },
                    () => {
                        this.error()
                    },
                ),
            )
        }
    }

    error(status, object) {
        this.setState(
            {
                errors: [],
                fields: [],
                loading: false,
            },
            () => {
                if (object.hasOwnProperty('errors')) {
                    let messages = getErrorMessages(object.errors, map)
                    let fields = getErrorFields(object.errors)

                    this.addErrors(messages)
                    this.addErrorFields(fields)
                }
            },
        )
    }

    isChecked(item) {
        let checked = this.state.checked

        return checked.indexOf(item.id) > -1
    }

    getClassNames(item) {
        let names = 'form__item'

        if (this.isChecked(item)) {
            names += ' form__item--selected'
        }

        return names
    }

    contains(list, item) {
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === item.id) {
                return true
            }
        }

        return false
    }

    onCheckboxChange(e, item) {
        if (e.target.checked) {
            this.check(item)
        } else {
            this.uncheck(item)
        }
    }

    check(item) {
        if (!this.isChecked(item)) {
            let checked = this.state.checked
            let items = this.state.items

            checked.push(item.id)

            let manual = this.state.manual
            if (!this.contains(items, item) && !this.contains(manual, item)) {
                manual.push(item)
            }

            this.setState({
                checked: checked,
                manual: manual,
            })
        }
    }

    uncheck(item) {
        if (this.isChecked(item)) {
            let checked = this.state.checked
            let items = this.state.items

            //Remove id of the item from the checked list.
            checked.splice(checked.indexOf(item.id), 1)

            //If item is not a suggested role, remove it from the manually added roles.
            let manual = this.state.manual
            if (!this.contains(items, item) && this.contains(manual, item)) {
                manual.splice(manual.indexOf(item), 1)
            }

            this.setState({
                checked: checked,
                manual: manual,
            })
        }
    }

    filter(e) {
        let value = e.target.value.toLowerCase()

        this.setState({
            filtered: this.state.all.filter((el) => {
                return (
                    el.name.toLowerCase().includes(value) ||
                    el.description.toLowerCase().includes(value)
                )
            }),
            filterValue: e.target.value,
        })
    }

    render() {
        const { className } = this.props

        return (
            <form
                className={this.props.className}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        //Enter pressed
                        this.save()
                    }
                }}
            >
                {/* Show the suggested roles for the user along with any manually selected roles from the full list */}
                {!this.state.add && (
                    <fieldset className="form__fieldset">
                        {this.state.errors && this.state.errors.length > 0 && (
                            <Alert type="alert--error alert--simple">
                                <ul>
                                    {this.state.errors.map(function (value) {
                                        return <li key={value}>{value}</li>
                                    })}
                                </ul>
                            </Alert>
                        )}

                        <div className="form__header">
                            <p>
                                Selecteer hieronder de rollen die je bij jou vindt passen. Op basis
                                van deze selectie zullen cards getoond worden met competenties.
                            </p>
                            <br />
                            <p>
                                We hebben alvast een aantal rollen ingevuld op basis van jouw
                                werkervaring.
                            </p>
                        </div>

                        <hr />
                        <div className="form__items">
                            {this.state.fetching && (
                                <div className="actions min-height">
                                    <div className="actions__action is-loading center_align" />
                                </div>
                            )}

                            {this.state.items.map((item, index) => {
                                return (
                                    <div
                                        className={this.getClassNames(item)}
                                        key={'competence-' + index}
                                    >
                                        <div className="form__checkbox">
                                            <input
                                                type="checkbox"
                                                name={item.id}
                                                id={item.id}
                                                className="form__checkbox__input"
                                                checked={this.isChecked(item)}
                                                onChange={(e) => this.onCheckboxChange(e, item)}
                                            />
                                            <label
                                                className="form__checkbox__label"
                                                htmlFor={item.id}
                                            >
                                                {item.name}
                                            </label>
                                            <p>{item.description}</p>
                                        </div>
                                    </div>
                                )
                            })}

                            {this.state.manual.map((item, index) => {
                                return (
                                    <div
                                        className={this.getClassNames(item)}
                                        key={'competence-' + index}
                                    >
                                        <div className="form__checkbox">
                                            <input
                                                type="checkbox"
                                                name={item.id}
                                                id={item.id}
                                                className="form__checkbox__input"
                                                checked={this.isChecked(item)}
                                                onChange={(e) => this.onCheckboxChange(e, item)}
                                            />
                                            <label
                                                className="form__checkbox__label"
                                                htmlFor={item.id}
                                            >
                                                {item.name}
                                            </label>
                                            <p>{item.description}</p>
                                        </div>
                                    </div>
                                )
                            })}

                            {this.state.manual.length === 0 && !this.state.fetching && (
                                <div className="actions text-center">
                                    <div>Geen resultaten</div>
                                </div>
                            )}
                        </div>
                        <hr />

                        <Button
                            buttonText="Alle rollen bekijken"
                            className="button--cta"
                            onClick={() => {
                                this.setState({
                                    add: true,
                                })
                            }}
                        />
                    </fieldset>
                )}

                {/* Show the full list of roles */}
                {this.state.add && (
                    <fieldset className="form__fieldset">
                        <div className="form__header">
                            <p>
                                Selecteer hieronder de rollen die je bij jou vindt passen. Op basis
                                van deze selectie zullen suggesties getoond worden met competenties.
                            </p>
                        </div>
                        <hr />
                        <input
                            className="form__input--text"
                            type="text"
                            placeholder="Filter rollen..."
                            onChange={(e) => this.filter(e)}
                            value={this.state.filterValue}
                        />
                        <div className="form__items">
                            {this.state.fetching && (
                                <div className="actions min-height">
                                    <div className="actions__action is-loading center_align" />
                                </div>
                            )}

                            {this.state.filtered.map((item, index) => {
                                return (
                                    <div
                                        className={this.getClassNames(item)}
                                        key={'competence-' + index}
                                    >
                                        <div className="form__checkbox">
                                            <input
                                                type="checkbox"
                                                name={item.id}
                                                id={item.id}
                                                className="form__checkbox__input"
                                                checked={this.isChecked(item)}
                                                onChange={(e) => this.onCheckboxChange(e, item)}
                                            />
                                            <label
                                                className="form__checkbox__label"
                                                htmlFor={item.id}
                                            >
                                                {item.name}
                                            </label>
                                            <p>{item.description}</p>
                                        </div>
                                    </div>
                                )
                            })}

                            {this.state.filtered.length === 0 && (
                                <div className="actions text-center">
                                    <div>Geen resultaten</div>
                                </div>
                            )}
                        </div>
                        <hr />

                        {/*<Button*/}
                        {/*buttonText="Terug"*/}
                        {/*className="button--cta"*/}
                        {/*onClick={() => {*/}
                        {/*this.setState({*/}
                        {/*add: false,*/}
                        {/*filterValue: '',*/}
                        {/*filtered: this.state.all*/}
                        {/*});*/}
                        {/*}}*/}
                        {/*/>*/}
                    </fieldset>
                )}

                <Actions>
                    <Link
                        className="actions__action--cancel"
                        onClick={() => this.cancel()}
                        tabIndex="5"
                    >
                        <Translate value="profile.cancel" />
                    </Link>
                    <Link
                        className={
                            'actions__action--save' + (this.state.loading ? ' is-loading' : '')
                        }
                        onClick={() => this.save()}
                        tabIndex="6"
                    >
                        <Translate value="profile.save" />
                    </Link>
                </Actions>
            </form>
        )
    }
}

RoleGenerator.propTypes = {
    className: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    edit: PropTypes.object,
}

function mapStateToProps(state) {
    const { auth, user } = state
    const { profile } = user
    const { roles } = profile

    return {
        auth,
        user,
        roles,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(RoleGenerator)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'react-grid-system'
import ModalForm from '../Modal/ModalForm'
import { translate, Translate } from 'react-i18nify'
import { diffProfileItems } from '../../store/actions/user'
import Panel from '../Panel/Panel'
import ButtonPanel from '../Button/ButtonPanel'
import ListItem from '../ListItem/ListItem'
import Suggestion from '../Suggestion/Suggestion'
import FormReferences from '../Forms/FormReferences'
import Tag from '../Tag/Tag'
import { isCollapsible } from './helpers'

class ReferencePossibleJobsBlock extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,

            generateJob: false,

            allowEditReferences: false,
            addRef: false,
            editRef: undefined,
        }
    }

    getReferencesPanel(refs) {
        if (refs !== undefined && refs.length > 0) {
            return (
                <Panel
                    className={isCollapsible(refs.length) ? 'panel--expand' : 'panel'}
                    headerText={translate('profile.references')}
                    headerIcon="icon-user"
                    addButton={
                        <ButtonPanel
                            buttonText={translate('profile.add_new')}
                            className="button--cta"
                            showText={false}
                            onClick={() => {
                                this.setState({
                                    addRef: true,
                                })
                            }}
                        />
                    }
                >
                    <div className="panel__list text-justify">
                        {refs.map((ref) => {
                            return (
                                <ListItem
                                    className="list_item--narrow"
                                    key={ref.id + '-' + ref.name}
                                    onEdit={() => {
                                        this.setState({
                                            editRef: ref,
                                        })
                                    }}
                                    onChange={() => {
                                        this.props.showAlert()
                                    }}
                                    dispatch={this.props.dispatch}
                                    auth={this.props.auth}
                                    type="references"
                                    item={ref}
                                    showEdit={this.state.allowEditReferences}
                                    title={ref.name}
                                >
                                    <h3>
                                        <span>{ref.name}</span>
                                    </h3>
                                    <p>
                                        <span>{ref.company}</span>
                                    </p>
                                    {ref.phone_number && <p>{ref.phone_number}</p>}
                                    {ref.description && <p>{ref.description}</p>}
                                </ListItem>
                            )
                        })}
                    </div>

                    {this.state.editRef !== undefined &&
                        this.getReferencesModal(true, this.state.editRef)}

                    {this.state.addRef && this.getReferencesModal()}
                </Panel>
            )
        } else {
            return (
                <Panel
                    className="panel--suggestion"
                    headerText={translate('profile.references')}
                    headerIcon="icon-user"
                    addButton={
                        <ButtonPanel
                            buttonText={translate('profile.add_new')}
                            className="button--cta"
                            showText={false}
                            onClick={() => {
                                this.setState({
                                    addRef: true,
                                })
                            }}
                        />
                    }
                >
                    <Suggestion iconName="icon-user">
                        <Translate value="profile.no_references" />
                    </Suggestion>

                    {this.state.addRef && this.getReferencesModal()}
                </Panel>
            )
        }
    }

    getReferencesModal(edit = false, ref = {}) {
        if (edit) {
            return (
                <ModalForm
                    title={translate('profile.change_references')}
                    show
                    onClose={() => {
                        this.setState({
                            editRef: undefined,
                        })
                    }}
                >
                    <FormReferences
                        className="form"
                        onCancel={() => {
                            this.setState({
                                editRef: undefined,
                            })
                        }}
                        onSave={() => {
                            this.setState({
                                editRef: undefined,
                            })
                            this.props.showAlert()
                        }}
                        dispatch={this.props.dispatch}
                        edit={ref}
                    />
                </ModalForm>
            )
        } else {
            return (
                <ModalForm
                    title={translate('profile.add_references')}
                    show
                    onClose={() => {
                        this.setState({
                            addRef: false,
                        })
                    }}
                >
                    <FormReferences
                        className="form"
                        onCancel={() => {
                            this.setState({
                                addRef: false,
                            })
                        }}
                        onSave={() => {
                            this.setState({
                                addRef: false,
                            })
                            this.props.showAlert()
                        }}
                        dispatch={this.props.dispatch}
                    />
                </ModalForm>
            )
        }
    }

    getJobsPanel(job_interests) {
        if (job_interests !== undefined && job_interests.length > 0) {
            return (
                <Panel
                    className={job_interests.length > 5 ? 'panel--expand' : 'panel'}
                    headerText={translate('profile.job_interest')}
                    headerIcon="icon-direction"
                    addButton={
                        <ButtonPanel
                            buttonText={translate('profile.add_new')}
                            className="button--cta"
                            showText={false}
                            onClick={() => {
                                this.setState({
                                    generateJob: true,
                                })
                            }}
                        />
                    }
                >
                    <div className="panel__form">
                        <div className="panel__tags">
                            {this.state.generateJob &&
                                this.props.getJobsGenerator('generateJob', () => {
                                    this.setState({ generateJob: false })
                                })}

                            {job_interests.map((interest) => {
                                return (
                                    <Tag
                                        key={interest.id + '-' + interest.name}
                                        onRemove={() => {
                                            this.props.dispatch(
                                                diffProfileItems(
                                                    'job_interests',
                                                    [],
                                                    [interest.id],
                                                    this.props.auth,
                                                ),
                                            )
                                        }}
                                        showEdit={this.state.allowEditJobInterests}
                                        title={interest.name}
                                    >
                                        {interest.name}
                                    </Tag>
                                )
                            })}
                        </div>
                    </div>
                </Panel>
            )
        } else {
            return (
                <Panel
                    className="panel--suggestion"
                    headerText={translate('profile.job_interest')}
                    headerIcon="icon-briefcase"
                    addButton={
                        <ButtonPanel
                            buttonText={translate('profile.add_new')}
                            className="button--cta"
                            showText={false}
                            onClick={() => {
                                this.setState({
                                    generateJob: true,
                                })
                            }}
                        />
                    }
                >
                    {this.state.generateJob &&
                        this.props.getJobsGenerator('generateJob', () => {
                            this.setState({ generateJob: false })
                        })}

                    <Suggestion iconName="icon-briefcase">
                        <Translate value="profile.no_job_interest" />
                    </Suggestion>
                </Panel>
            )
        }
    }

    render() {
        const { auth, user } = this.props

        return (
            <Row>
                <Col xs={12} md={7}>
                    <div id="ReferencesPanel">
                        {this.getReferencesPanel(user.profile.references)}
                    </div>
                </Col>
                <Col xs={12} md={5}>
                    <div id="JobsPanel">{this.getJobsPanel(user.profile.job_interests)}</div>
                </Col>
            </Row>
        )
    }
}

function mapStateToProps(state) {
    const { auth, user } = state

    return {
        auth,
        user,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(ReferencePossibleJobsBlock)

import React, { Component } from 'react'
import slugify from 'slugify'
import { Link } from 'react-router-dom'
import { PATH_PUBLIC_PROFILE } from '../../constants/Constants'
import PropTypes from 'prop-types'
import { getPublicProfilePicture } from '../../helpers'

export default class ListProfile extends Component {
    constructor(props) {
        super(props)
        this.state = { profile_picture: '' }
    }

    componentDidMount() {
        getPublicProfilePicture(this.props.user).then((profile_picture) =>
            this.setState({ profile_picture }),
        )
    }

    render() {
        return typeof this.props.onClick === 'function' ? (
            <a onClick={() => this.props.onClick()} className="list_profile">
                <div className="list_profile__image_container">
                    <img
                        className="list_profile__image"
                        src={this.state.profile_picture || '/images/avatar-placeholder.png'}
                    />
                </div>

                <div className="list_profile__info">
                    <div className="list_profile__info_inside">
                        {!!this.props.user.name && (
                            <div className="list_profile__info__name">{this.props.user.name}</div>
                        )}
                        {!!this.props.user.email && (
                            <div className="list_profile__info__email">{this.props.user.email}</div>
                        )}
                    </div>
                </div>
            </a>
        ) : (
            <Link
                to={
                    '/' +
                    PATH_PUBLIC_PROFILE.replace(
                        ':username',
                        `${slugify(this.props.user.name, { lower: true })}-${this.props.user.id}`,
                    )
                }
                className="list_profile"
            >
                <div className="list_profile__image_container">
                    <img
                        className="list_profile__image"
                        src={this.state.profile_picture || '/images/avatar-placeholder.png'}
                    />
                </div>
                <div className="list_profile__info">
                    <div className="list_profile__info_inside">
                        {!!this.props.user.name && (
                            <div className="list_profile__info__name">{this.props.user.name}</div>
                        )}
                        {!!this.props.user.email && (
                            <div className="list_profile__info__email">{this.props.user.email}</div>
                        )}
                    </div>
                </div>
            </Link>
        )
    }
}

ListProfile.propTypes = {
    user: PropTypes.object.isRequired,
    onClick: PropTypes.func,
}

ListProfile.defaultProps = {}

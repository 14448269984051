import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Container, Row } from 'react-grid-system'
import { Link } from 'react-router-dom'
import { translate, Translate } from 'react-i18nify'
import { addClass, removeClass } from '../../helpers'

import Alert from '../Alert/Alert'

import { PATH_FORGOT_PASSWORD, PATH_REGISTER } from '../../constants/Constants'

export default class FormLogin extends Component {
    constructor(props) {
        super(props)

        this.handleEmailChange = this.handleEmailChange.bind(this)
        this.handlePasswordChange = this.handlePasswordChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.toggleShowPassword = this.toggleShowPassword.bind(this)
        this.state = { email: '', password: '', showPassword: false }
    }

    handleEmailChange(event) {
        this.setState({ email: event.target.value })

        if (event.target.value && event.target.value.length > 0) {
            addClass(event.target, 'has-value')
        } else {
            removeClass(event.target, 'has-value')
        }
    }

    handlePasswordChange(event) {
        this.setState({ password: event.target.value })

        if (event.target.value && event.target.value.length > 0) {
            addClass(event.target, 'has-value')
        } else {
            removeClass(event.target, 'has-value')
        }
    }

    toggleShowPassword() {
        this.setState({ showPassword: !this.state.showPassword })
    }

    handleSubmit(event) {
        event.preventDefault()
        this.props.onSubmit(this.state.email, this.state.password)
    }

    render() {
        const { className, onSubmit, errorMessage, isFetching } = this.props
        return (
            <form className={`form--login`} onSubmit={this.handleSubmit}>
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={6} xl={6}>
                            <div className="info_block">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: translate('login.intro_text'),
                                    }}
                                />

                                <img className={'img-smiley-success'} />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} xl={6}>
                            <div className="form__content">
                                <h1>
                                    <Translate value="login.header" />
                                </h1>

                                <div>
                                    <p
                                        className={
                                            errorMessage && errorMessage.length > 0
                                                ? 'is-hidden--mobile'
                                                : ''
                                        }
                                    >
                                        <Translate value="login.header_text" />
                                    </p>
                                </div>

                                {errorMessage && errorMessage.length > 0 && (
                                    <Alert alertText={errorMessage} type="alert--error" />
                                )}

                                <fieldset className="form__fieldset">
                                    <div className="form__item">
                                        <i className="form__icon icons icon-envelope" />
                                        <input
                                            className="form__input--text"
                                            type="email"
                                            id="email"
                                            onChange={this.handleEmailChange}
                                            tabIndex="1"
                                            autoFocus
                                        />
                                        <label className="form__label" htmlFor="email">
                                            <Translate value="labels.email" />
                                        </label>
                                    </div>
                                    <div className="form__item">
                                        <i className="form__icon icons icon-lock" />
                                        <span
                                            className={`mdi form__password_icon ${
                                                this.state.showPassword ? 'mdi-eye' : 'mdi-eye-off'
                                            }`}
                                            onClick={this.toggleShowPassword}
                                        />
                                        <input
                                            className="form__input--text"
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            id="password"
                                            onChange={this.handlePasswordChange}
                                            tabIndex="2"
                                        />
                                        <label className="form__label" htmlFor="password">
                                            <Translate value="labels.password" />
                                        </label>
                                    </div>
                                </fieldset>

                                <div className="form__button_container">
                                    <button
                                        type="submit"
                                        className={
                                            'button--alt' + (isFetching ? ' is-loading' : '')
                                        }
                                        tabIndex="3"
                                    >
                                        {translate('buttons.login')}
                                    </button>
                                    <Link className="button" to={PATH_REGISTER} tabIndex="4">
                                        <Translate value="buttons.create_account" />
                                    </Link>
                                </div>

                                <div className="form__forgot-password">
                                    <p>
                                        <Translate value="buttons.forgot_password" />
                                    </p>
                                    <Link
                                        className="button--link"
                                        to={PATH_FORGOT_PASSWORD}
                                        tabIndex="5"
                                    >
                                        <Translate value="buttons.click_here" />
                                    </Link>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="info_block_xs">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: translate('login.intro_text'),
                                    }}
                                />

                                <img className={'img-smiley-success'} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </form>
        )
    }
}

FormLogin.propTypes = {
    className: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    isFetching: PropTypes.bool,
}

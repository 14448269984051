import {
    CONFIG_ERROR,
    CONFIG_RECEIVED,
    CONFIG_REQUESTED,
    CONFIG_SUBLABEL,
    CONFIG_SUBLABEL_RESTORE,
} from '../../constants/Constants'
import { checkThemeColorChange } from '../../helpers'
import { setLocale } from 'react-i18nify'

export function fetchConfig() {
    return (dispatch) => {
        const confPath = process.env.PUBLIC_URL + `/static/config.json`

        //Pull in the safe static/config.json containing the prod config
        //This is to make sure that if getting the config fails it defaults to prod
        return Promise.resolve(true)
            .then((_) => dispatch(requestingConfig()))
            .then((_) => fetch(confPath, { method: 'get' }))
            .then((res) => res.json())
            .then((json) => dispatch(gotConfig(json)))
            .catch((err) => {
                dispatch(configError())
            })
    }
}

function requestingConfig() {
    return {
        type: CONFIG_REQUESTED,
        fetching: true,
    }
}

function gotConfig(config) {
    setLocale('nl' + config.config.whitelabel.name.replace('--', '__'))
    return {
        type: CONFIG_RECEIVED,
        fetching: false,
        config: config.config,
    }
}

function configError() {
    return {
        type: CONFIG_ERROR,
        fetching: false,
    }
}

function sublabelSuccess(whitelabel) {
    return {
        type: CONFIG_SUBLABEL,
        whitelabel: whitelabel,
    }
}

export function checkSublabel(whitelabel, account_types) {
    return (dispatch) => {
        let new_label
        if (whitelabel.sub_labels && account_types.length > 0) {
            let ac_type_codes = account_types.map((at) => at.code)
            ac_type_codes = ac_type_codes.filter((at) => whitelabel.sub_labels.includes(at))
            if (ac_type_codes.length > 0) {
                new_label = whitelabel[ac_type_codes[0]]
            }
        }
        if (new_label) {
            checkThemeColorChange(new_label, account_types)
            dispatch(sublabelSuccess(new_label))
        } else {
            checkThemeColorChange(whitelabel, account_types)
        }
    }
}

export function restoreConfig() {
    return { type: CONFIG_SUBLABEL_RESTORE }
}

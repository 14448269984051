import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { translate, Translate } from 'react-i18nify'
import { Row } from 'react-grid-system'
import Alert from '../Alert/Alert'
import GridCol from '../GridCol/GridCol'
import GridColOffset from '../GridCol/GridColOffset'

export default class FormPasswordReset extends Component {
    constructor(props) {
        super(props)

        this.state = { email: '', password: '', passwordAgain: '' }

        this.emailChange = this.emailChange.bind(this)
        this.passwordChange = this.passwordChange.bind(this)
        this.passwordAgainChange = this.passwordAgainChange.bind(this)
        this.submit = this.submit.bind(this)
    }

    emailChange(event) {
        this.setState({
            email: event.target.value,
        })
    }

    passwordChange(event) {
        this.setState({
            password: event.target.value,
        })
    }

    passwordAgainChange(event) {
        this.setState({
            passwordAgain: event.target.value,
        })
    }

    submit(e) {
        e.preventDefault()
        this.props.onSubmit(this.state.email, this.state.password, this.state.passwordAgain)
    }

    getClassName(ref, val) {
        let classes = 'form__input--text'

        if (this.props.errorFields.indexOf(ref) > -1) {
            classes += ' error'
        }

        if (val && val.length > 0) {
            classes += ' has-value'
        }

        return classes
    }

    render() {
        const { className, errorMessages, errorFields, onSubmit, success, isFetching } = this.props
        return (
            <form className={this.props.className} onSubmit={this.submit}>
                <Row>
                    <GridColOffset />
                    <GridCol>
                        <h1>
                            <Translate value="headers.reset_password" />
                        </h1>

                        <p>
                            <Translate value="texts.reset_password" />
                        </p>
                        {(!errorMessages || errorMessages.length == 0) && (
                            <p>
                                <Translate value="register.password_criteria" />
                            </p>
                        )}

                        {errorMessages && errorMessages.length > 0 && (
                            <Alert type="alert--error">
                                <ul>
                                    {errorMessages.map(function (value) {
                                        return <li key={value}>{value}</li>
                                    })}
                                </ul>
                            </Alert>
                        )}

                        {success && (
                            <Alert
                                alertText={translate('texts.password_reset_success')}
                                type="alert--success"
                            />
                        )}

                        <fieldset className="form__fieldset">
                            <div className="form__item">
                                <i className="form__icon icons icon-envelope" />
                                <input
                                    onChange={this.emailChange}
                                    className={this.getClassName('email', this.state.email)}
                                    type="email"
                                    id="email"
                                    autoFocus
                                    tabIndex="1"
                                />
                                <label className="form__label" htmlFor="email">
                                    <Translate value="labels.email" />
                                </label>
                            </div>
                            <div className="form__item">
                                <i className="form__icon icons icon-lock" />
                                <input
                                    onChange={this.passwordChange}
                                    className={this.getClassName('password', this.state.password)}
                                    type="password"
                                    id="password"
                                    tabIndex="2"
                                />
                                <label className="form__label" htmlFor="password">
                                    <Translate value="labels.password" />
                                </label>
                            </div>
                            <div className="form__item">
                                <input
                                    onChange={this.passwordAgainChange}
                                    className={this.getClassName(
                                        'repeat_password',
                                        this.state.passwordAgain,
                                    )}
                                    type="password"
                                    id="repeat_password"
                                    tabIndex="3"
                                />
                                <label className="form__label" htmlFor="repeat_password">
                                    <Translate value="labels.repeat_password" />
                                </label>
                            </div>
                        </fieldset>

                        <div className="form__button_container">
                            <button
                                type="submit"
                                className={'button--alt' + (isFetching ? ' is-loading' : '')}
                                tabIndex="3"
                            >
                                {translate('buttons.change_password')}
                            </button>
                        </div>
                    </GridCol>
                </Row>
            </form>
        )
    }
}

FormPasswordReset.propTypes = {
    className: PropTypes.string.isRequired,
    errorMessages: PropTypes.array,
    errorFields: PropTypes.array,
    onSubmit: PropTypes.func.isRequired,
    success: PropTypes.bool,
    isFetching: PropTypes.bool,
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react'
import Button from '../Button/Button'

class MapPanel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            info: false,
            selected: null,
            activeMarker: null,
        }
    }

    componentDidMount() {
        console.log('[ MAP ]', this.props.latitude, this.props.longitude, this.state.initialCenter)
    }

    onMarkerClick(props, marker, e) {
        console.log('[ MapPanel ]', 'onMarkerClick', props, marker, e)
        this.setState({
            info: true,
            selected: props.item,
            activeMarker: marker,
        })
    }

    onMapClick(props) {
        if (this.state.info) {
            this.closeInfoWindow()
        }
    }

    closeInfoWindow() {
        this.setState({
            info: false,
            selected: null,
            activeMarker: null,
        })
    }

    render() {
        const { className, headerText, headerIcon } = this.props

        let headerIconClass = 'panel__header__icon icons ' + headerIcon

        return (
            <div className={this.props.className}>
                <div className="panel__header">
                    <h3>
                        <i className={headerIconClass} /> {headerText}
                    </h3>
                </div>
                <div className="panel__body">
                    <div>{this.props.children}</div>

                    <div className="panel__map">
                        {/*{this.props.children[1]}*/}
                        <Map
                            google={this.props.google}
                            zoom={this.props.zoom}
                            initialCenter={{
                                lat: this.props.latitude,
                                lng: this.props.longitude,
                            }}
                            center={{
                                lat: this.props.latitude,
                                lng: this.props.longitude,
                            }}
                            fullscreenControl={false}
                            onClick={this.onMapClick.bind(this)}
                        >
                            {this.props.vacancies.map((item, index) => {
                                return (
                                    <Marker
                                        key={`marker-${index}`}
                                        name={item.name}
                                        position={{
                                            lat: item.organisation_location.visiting_address_lat,
                                            lng: item.organisation_location.visiting_address_lng,
                                        }}
                                        onClick={this.onMarkerClick.bind(this)}
                                        item={item}
                                    />
                                )
                            })}
                        </Map>

                        {this.state.selected && (
                            <div className="panel--map__modal">
                                <div className="">
                                    <h3>{this.state.selected.name}</h3>
                                    <div>{this.state.selected.organisation.name}</div>

                                    <Button
                                        buttonText="Solliciteer"
                                        className="button--cta"
                                        onClick={() =>
                                            this.props.onActionClicked(this.state.selected)
                                        }
                                    />

                                    {/* TODO: make icon show*/}
                                    <div
                                        className="modal__close"
                                        onClick={() => {
                                            this.closeInfoWindow()
                                        }}
                                    >
                                        <i className="icomoon icomoon-close"> </i>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

MapPanel.propTypes = {
    className: PropTypes.string.isRequired,
    headerText: PropTypes.string,
    headerIcon: PropTypes.string,
    vacancies: PropTypes.array,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    zoom: PropTypes.number,
    onActionClicked: PropTypes.func,
}

MapPanel.defaultProps = {
    vacancies: [],
    zoom: 8,
    onActionClicked: () => {},
}

export default GoogleApiWrapper((props) => ({
    apiKey: 'AIzaSyAXjZQS7Ueuk5e9l-UqF71UhHGVgiEciUA',
    fullScreenControl: false,
}))(MapPanel)

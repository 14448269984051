import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ReactSimpleRange from 'react-simple-range'
import { connect } from 'react-redux'
import { addClass, getErrorFields, getErrorMessages, removeClass } from '../../helpers'
import { translate, Translate } from 'react-i18nify'
import { diffProfileItems, editProfileItem } from '../../store/actions/user'
import Actions from '../Actions/Actions'
import Alert from '../Alert/Alert'

let map

class FormLanguages extends Component {
    constructor(props) {
        super(props)

        if (this.props.edit != undefined) {
            let lang = this.props.edit

            this.state = {
                id: lang.id,
                name: lang.name,
                read: lang.read,
                write: lang.write,
                speak: lang.speak,
                edit: true,
                errors: [],
                fields: [],
            }
        } else {
            this.state = {
                errors: [],
                fields: [],
                id: -1,
                name: '',
                read: 5,
                write: 5,
                speak: 5,
                error: '',
                edit: false,
                loading: false,
            }
        }
    }

    handleChange(value, key, changeClass = true) {
        let obj = {}
        obj[key] = value.target.value

        this.setState(obj)

        if (changeClass) {
            if (value.target.value.length > 0) {
                addClass(value.currentTarget, 'has-value')
            } else {
                removeClass(value.currentTarget, 'has-value')
            }
        }
    }

    handleSliderChange(value, key) {
        let obj = {}
        obj[key] = value.value

        this.setState(obj)
    }

    save() {
        let name = this.state.name,
            read = this.state.read,
            write = this.state.write,
            speak = this.state.speak
        //
        //if (name.length < 1) {
        //    this.setState({
        //        error: translate('errors.empty_field')
        //    });
        //
        //    return;
        //}

        let lang = {
            name: name,
            read: read,
            write: write,
            speak: speak,
        }

        this.setState({
            loading: true,
        })

        if (this.state.id != -1) {
            lang.id = this.state.id
        }

        if (this.state.edit) {
            this.props.dispatch(
                editProfileItem(
                    'languages',
                    lang,
                    this.props.auth,
                    () => this.onSuccess(),
                    (status, error) => this.onError(status, error),
                ),
            )
        } else {
            this.props.dispatch(
                diffProfileItems(
                    'languages',
                    [lang],
                    [],
                    this.props.auth,
                    () => {
                        this.onSuccess()
                    },
                    (s, o) => {
                        this.onError(s, o)
                    },
                ),
            )
        }
    }

    onError(status, object) {
        map = {
            name: translate('profile.language'),
            read: 'Lezen',
            write: 'Schrijven',
            speak: 'Spreken',
        }

        this.setState(
            {
                errors: [],
                fields: [],
                loading: false,
            },
            () => {
                if (object.hasOwnProperty('errors')) {
                    let messages = getErrorMessages(object.errors, map)
                    let fields = getErrorFields(object.errors)

                    this.addErrors(messages)
                    this.addErrorFields(fields)
                }
            },
        )
    }

    addErrors(errors) {
        this.setState({
            errors: errors,
        })
    }

    addErrorFields(fields) {
        this.setState({
            fields: fields,
        })
    }

    getClassNames(item, field) {
        let c = 'form__input--text'

        if (item != null && item != '' && item.length > 0) {
            c += ' has-value'
        }

        if (this.state.fields.indexOf(field) != -1) {
            c += ' error'
        }

        return c
    }

    onSuccess() {
        this.state = {
            errors: [],
            fields: [],
            id: -1,
            name: '',
            read: 5,
            write: 5,
            speak: 5,
            error: '',
            edit: false,
            loading: false,
        }
        this.props.onSave()
    }

    cancel() {
        this.state = {
            errors: [],
            fields: [],
            id: -1,
            name: '',
            read: 5,
            write: 5,
            speak: 5,
            error: '',
            edit: false,
            loading: false,
        }
        this.props.onCancel()
    }

    getSaveClasses() {
        let c = 'actions__action--save'

        if (this.state.loading) {
            c += ' is-loading'
        }

        return c
    }

    render() {
        const { className } = this.props

        return (
            <form
                className={this.props.className}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        //Enter pressed
                        e.preventDefault()
                        this.save()
                    }
                }}
            >
                <fieldset className="form__fieldset">
                    {this.state.errors && this.state.errors.length > 0 && (
                        <Alert type="alert--error alert--simple">
                            <ul>
                                {this.state.errors.map(function (value) {
                                    return <li key={value}>{value}</li>
                                })}
                            </ul>
                        </Alert>
                    )}

                    <div className="form__item">
                        <i className="form__icon icons icon-globe" />
                        <input
                            className={this.getClassNames(this.state.name, 'name')}
                            type="text"
                            id="language_name"
                            value={this.state.name}
                            onChange={(value) => this.handleChange(value, 'name')}
                            tabIndex="1"
                            autoFocus
                        />
                        <label className="form__label" htmlFor="language_name">
                            <Translate value="profile.language" />
                        </label>
                    </div>

                    <div className="form__item form__range">
                        <ReactSimpleRange
                            min={1}
                            max={10}
                            label={true}
                            value={this.state.speak}
                            onChange={(value) => this.handleSliderChange(value, 'speak')}
                            sliderColor="rgba(0, 23, 48, 0.1)"
                            trackColor="#4aabe2"
                            thumbColor="#4aabe2"
                            name="speaking"
                            tabIndex="2"
                        />
                        <label className="form__label">
                            <Translate value="profile.speaking" />
                        </label>
                    </div>
                    <div className="form__item form__range">
                        <ReactSimpleRange
                            min={1}
                            max={10}
                            label={true}
                            value={this.state.read}
                            onChange={(value) => this.handleSliderChange(value, 'read')}
                            sliderColor="rgba(0, 23, 48, 0.1)"
                            trackColor="#4aabe2"
                            thumbColor="#4aabe2"
                            name="reading"
                            tabIndex="3"
                        />
                        <label className="form__label">
                            <Translate value="profile.reading" />
                        </label>
                    </div>
                    <div className="form__item form__range">
                        <ReactSimpleRange
                            min={1}
                            max={10}
                            label={true}
                            value={this.state.write}
                            onChange={(value) => this.handleSliderChange(value, 'write')}
                            sliderColor="rgba(0, 23, 48, 0.1)"
                            trackColor="#4aabe2"
                            thumbColor="#4aabe2"
                            name="writing"
                            tabIndex="4"
                        />
                        <label className="form__label">
                            <Translate value="profile.writing" />
                        </label>
                    </div>
                </fieldset>
                <Actions>
                    <Link
                        className="actions__action--cancel"
                        onClick={() => this.cancel()}
                        tabIndex="5"
                    >
                        <Translate value="profile.cancel" />
                    </Link>
                    <Link
                        className={this.getSaveClasses()}
                        onClick={() => this.save()}
                        tabIndex="6"
                    >
                        <Translate value="profile.save" />
                    </Link>
                </Actions>
            </form>
        )
    }
}

FormLanguages.propTypes = {
    className: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    edit: PropTypes.object,
}

function mapStateToProps(state) {
    const { auth, user } = state

    return {
        auth,
        user,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(FormLanguages)

import React, { Component } from 'react'
import Panel from '../../Panel/Panel'
import { translate, Translate } from 'react-i18nify'
import ButtonPanel from '../../Button/ButtonPanel'
import Actions from '../../Actions/Actions'
import { Link } from 'react-router-dom'
import Suggestion from '../../Suggestion/Suggestion'
import ModalForm from '../../Modal/ModalForm'
import ResumeGenerator from '../../../pages/ResumeGenerator'
import { deleteResume, getResumes } from '../../../store/actions/user'
import { connect } from 'react-redux'

class TemplatesPanel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            fetchingTemplates: false,
            templates: [],
            editTemplate: undefined,
        }
    }

    componentDidMount() {
        this.getResumes()
    }

    getResumes() {
        this.setState({ fetchingTemplates: true })
        getResumes(this.props.auth)
            .then((resp) => {
                this.setState({
                    templates: resp,
                    fetchingTemplates: false,
                })
            })
            .catch(() => {
                this.setState({
                    fetchingTemplates: false,
                })
            })
    }

    getTemplatesModal(template) {
        return (
            <ModalForm
                title={
                    this.state.editTemplate === true
                        ? translate('resumes.add_resume')
                        : translate('resumes.edit_resume')
                }
                show={true}
                onClose={() => {
                    this.setState({ editTemplate: undefined })
                }}
            >
                <ResumeGenerator
                    editableName={true}
                    id={this.state.editTemplate !== undefined && this.state.editTemplate.id}
                    onClose={() => {
                        this.setState({ editTemplate: undefined })
                        this.getResumes()
                    }}
                    showActions={true}
                />
            </ModalForm>
        )
    }

    removeTemplate(template) {
        let arr = this.state.templates

        arr.map((currentTemplate, index) => {
            if (template.id === currentTemplate.id) {
                arr.splice(index, 1)
            }
        })

        this.setState({ templates: arr })

        deleteResume(template.id, this.props.auth).then(
            () => {
                //nah
            },
            () => {
                //error
            },
        )
    }

    render() {
        let { templates } = this.state

        if ((templates !== undefined && templates.length > 0) || this.state.fetchingTemplates) {
            return (
                <Panel
                    className={'panel--multi__inner'}
                    headerText={translate('profile.resumes')}
                    headerIcon="icon-folder-alt"
                    addButton={
                        <ButtonPanel
                            buttonText={translate('resumes.add_resume')}
                            className="button--cta"
                            onClick={() => this.setState({ editTemplate: true })}
                        />
                    }
                >
                    <p>{translate('profile.resumes_text')}</p>

                    {this.state.fetchingTemplates && <p className="is-loading--center--small" />}

                    <table className="panel__table">
                        <tbody>
                            {templates.map((template) => {
                                return (
                                    <tr key={template.id + '-' + template.name}>
                                        <td>{template.name}</td>
                                        <td>
                                            <Actions>
                                                <Link
                                                    onClick={() => {
                                                        this.setState({
                                                            editTemplate: template,
                                                        })
                                                    }}
                                                    className="actions__action--edit"
                                                >
                                                    <Translate value="profile.edit" />
                                                </Link>
                                                <Link
                                                    className="actions__action--delete"
                                                    onClick={() => {
                                                        console.log(
                                                            '[ Deleting template ]',
                                                            template,
                                                        )
                                                        if (
                                                            window.confirm(
                                                                translate(
                                                                    'profile.confirm_delete_item',
                                                                    {
                                                                        item: template.name,
                                                                    },
                                                                ),
                                                            )
                                                        ) {
                                                            this.removeTemplate(template)
                                                        }
                                                    }}
                                                >
                                                    <Translate value="profile.remove" />
                                                </Link>
                                            </Actions>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                    {this.state.editTemplate !== undefined && this.getTemplatesModal()}
                </Panel>
            )
        } else {
            return (
                <Panel
                    className="panel--suggestion"
                    headerText={translate('profile.resumes')}
                    headerIcon="icon-folder-alt"
                    addButton={
                        <ButtonPanel
                            buttonText={translate('resumes.add_resume')}
                            className="button--cta"
                            onClick={() => this.setState({ editTemplate: true })}
                        />
                    }
                >
                    <Suggestion iconName="icon-folder-alt">
                        <Translate value="resumes.no_resumes" />
                    </Suggestion>

                    {this.state.editTemplate !== undefined && this.getTemplatesModal()}
                </Panel>
            )
        }
    }
}

function mapStateToProps(state) {
    const { auth, config, user } = state

    return {
        auth,
        config,
        user,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(TemplatesPanel)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Actions from '../Actions/Actions'

import { getErrorFields, getErrorMessages } from '../../helpers'
import { translate, Translate } from 'react-i18nify'

import { diffProfileItems } from '../../store/actions/user'

let map

class FormFlexibleEmployers extends Component {
    constructor(props) {
        super(props)

        let flex_orgs = this.props.user.flexapp_organizations.filter((org) => org.id > 0)
        let checked = flex_orgs
            .map((e) => e.id)
            .filter((org_id) =>
                this.props.user.profile.flex_organisations
                    .map((e) => e.organisation_id)
                    .includes(org_id),
            )

        this.state = {
            errors: [],
            fields: [],
            all: flex_orgs,
            filtered: flex_orgs,
            checked: checked,
            filterValue: '',
            loading: false,
            fetching: true,
        }
    }

    cancel() {
        this.props.onCancel()
    }

    addErrors(errors) {
        this.setState({
            errors: errors,
        })
    }

    addErrorFields(fields) {
        this.setState({
            fields: fields,
        })
    }

    error(status, object) {
        console.log('Error saving flexapp employers', status, object)

        this.setState(
            {
                errors: [],
                fields: [],
                loading: false,
            },
            () => {
                if (object.hasOwnProperty('errors')) {
                    let messages = getErrorMessages(object.errors, map)
                    let fields = getErrorFields(object.errors)

                    this.addErrors(messages)
                    this.addErrorFields(fields)
                }
            },
        )
    }

    filter(e) {
        let newVal = e.target.value.toLowerCase()
        let newAll = this.state.all.filter((org) => org.name.toLowerCase().startsWith(newVal))
        this.setState({
            filterValue: e.value,
            filtered: newAll,
        })
    }

    save() {
        if (!this.state.loading) {
            this.setState({
                loading: true,
            })

            this.sendPrefOrgUpdate()
        }
    }

    sendPrefOrgUpdate() {
        let promises = []
        let toAdd = []
        let toRemove = []

        this.props.user.profile.flex_organisations.map((org) => {
            if (!this.state.checked.some((checked) => checked == org.organisation_id)) {
                toRemove.push(org.id)
            }
        })
        this.state.checked.map((checked) => {
            if (
                !this.props.user.profile.flex_organisations.some(
                    (org) => checked == org.organisation_id,
                )
            ) {
                toAdd.push({ organisation_id: checked })
            }
        })

        this.props.dispatch(
            diffProfileItems(
                'flex_organisations',
                toAdd,
                toRemove,
                this.props.auth,
                () => {
                    this.setState({ loading: false })
                    this.props.onSave()
                },
                (status, object) => {
                    this.error(status, object)
                },
            ),
        )
    }

    handleChange(value, key) {
        let obj = {}
        obj[key] = value.target.value

        this.setState(obj)
    }

    isChecked(item) {
        return this.state.checked.indexOf(item.id) > -1
    }

    getClassNames(item, field) {
        let names = 'form__item'
        if (this.isChecked(item)) {
            names += ' form__item--selected'
        }

        if (field && this.state.fields.indexOf(field) != -1) {
            names += ' error'
        }

        return names
    }

    onCheckboxChange(e, item) {
        if (e.target.checked) {
            this.check(item)
        } else {
            this.uncheck(item)
        }
    }

    check(item) {
        if (!this.isChecked(item)) {
            let checked = this.state.checked

            checked.push(item.id)

            this.setState({
                checked: checked,
            })
        }
    }

    uncheck(item) {
        if (this.isChecked(item)) {
            let checked = this.state.checked

            //Remove id of the item from the checked list.
            checked.splice(checked.indexOf(item.id), 1)

            this.setState({
                checked: checked,
            })
        }
    }

    render() {
        const { className } = this.props

        return (
            <form
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        //Enter pressed
                        this.save()
                    }
                }}
            >
                <div className={'form--small_margin'}>
                    <fieldset className="form__fieldset">
                        <input
                            className="form__input--text"
                            type="text"
                            placeholder={translate('profile.filter_flexapp_employers')}
                            onChange={(e) => this.filter(e)}
                            value={this.state.filterValue}
                            tabIndex="1"
                            autoFocus="autofocus"
                        />
                        <div className="form__items">
                            {this.state.filtered.map((item, index) => {
                                return (
                                    <div
                                        className={this.getClassNames(item)}
                                        key={'flexapp-employer-' + index}
                                    >
                                        <div className="form__checkbox">
                                            <input
                                                type="checkbox"
                                                name={item.id}
                                                id={item.id}
                                                className="form__checkbox__input"
                                                checked={this.isChecked(item)}
                                                onChange={(e) => this.onCheckboxChange(e, item)}
                                            />
                                            <label
                                                className="form__checkbox__label"
                                                htmlFor={item.id}
                                            >
                                                {item.name}
                                            </label>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <hr />
                    </fieldset>
                </div>

                <Actions>
                    <Link
                        className="actions__action--cancel"
                        onClick={() => this.cancel()}
                        tabIndex="2"
                    >
                        <Translate value="profile.cancel" />
                    </Link>
                    <Link
                        className={
                            'actions__action--save' + (this.state.loading ? ' is-loading' : '')
                        }
                        onClick={() => this.save()}
                        tabIndex="3"
                    >
                        <Translate value="profile.save" />
                    </Link>
                </Actions>
            </form>
        )
    }
}

FormFlexibleEmployers.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    const { auth, user } = state
    const { profile } = user
    const { competences } = profile

    return {
        auth,
        user,
        competences,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(FormFlexibleEmployers)

import { translate } from 'react-i18nify'
import { formatYear } from '../../helpers'
import React from 'react'
import { BigTooltip } from '../Tooltip/BigTooltip'

export const isCollapsible = (length = 0) => {
    return length > 3
}

export const getTimeString = (start, end, current) => {
    if (!start || !end) {
        return translate('no_date_known')
    }
    let sDate = new Date(start)
    let eDate = new Date(end)

    let months = [
        translate('profile.january'),
        translate('profile.february'),
        translate('profile.march'),
        translate('profile.april'),
        translate('profile.may'),
        translate('profile.june'),
        translate('profile.july'),
        translate('profile.august'),
        translate('profile.september'),
        translate('profile.october'),
        translate('profile.november'),
        translate('profile.december'),
    ]

    if (
        (current != null && current === 1) ||
        ((end == null || end.length === 0) && end !== undefined)
    ) {
        // month - heden
        return (
            months[sDate.getMonth()] +
            ' ' +
            formatYear(sDate.getFullYear()) +
            ' - ' +
            translate('profile.present_day')
        )
    } else if (end != null) {
        return (
            months[sDate.getMonth()] +
            ' ' +
            formatYear(sDate.getFullYear()) +
            ' - ' +
            months[eDate.getMonth()] +
            ' ' +
            formatYear(eDate.getFullYear())
        )
    } else {
        return months[sDate.getMonth()] + ' ' + formatYear(sDate.getFullYear())
    }
}

export const getLanguageDot = (grade) => {
    let className = 'panel__dot'

    if (grade >= 7) {
        className += ' good'
    } else if (grade >= 4) {
        className += ' medium'
    } else {
        className += ' bad'
    }

    return (
        <td className={className}>
            <BigTooltip title={'' + grade + '/10'} position="top" arrow={true}>
                <span>&#9679;</span>
            </BigTooltip>
        </td>
    )
}

import React, { Component } from 'react'
import Panel from '../../Panel/Panel'
import { getTimeString, isCollapsible } from '../helpers'
import { translate, Translate } from 'react-i18nify'
import ButtonPanel from '../../Button/ButtonPanel'
import ListItem from '../../ListItem/ListItem'
import Suggestion from '../../Suggestion/Suggestion'
import ModalForm from '../../Modal/ModalForm'
import Button from '../../Button/Button'
import newCallApi from '../../../store/actions/api'
import FormStudies from '../../Forms/FormStudies'
import { saveAs } from 'file-saver'
import { connect } from 'react-redux'

class EducationsPanel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            emrexModal: false,
            addEducation: false,
            editEducation: undefined,
            allowEditEducation: false,
        }
    }

    getStudyName(study) {
        let other = study.study_name_other

        let name

        if (other == null || other.length === 0) {
            //Get study id

            let studies = this.props.user.studies
            let study_id = study.study_id

            for (let i = 0; i < studies.length; i++) {
                let s = studies[i]

                if (s.id === study_id) {
                    name = s.name
                    break
                }
            }
        } else {
            name = other
        }
        if (name === undefined) {
            //Return the study level
            if (
                this.props.user.study_levels &&
                this.props.user.study_levels.find &&
                study.study_level_id !== undefined
            ) {
                name = this.props.user.study_levels.find(
                    (studyLevel) => studyLevel.id === study.study_level_id,
                )?.name
            }
        }

        return name
    }

    requestEmrex() {
        newCallApi('POST', this.props.config.API_EMREX, { country: 'NL' }).then((response) => {
            console.log(response)
            let emrexForm = document.createElement('form')
            document.body.appendChild(emrexForm)
            emrexForm.method = 'POST'
            emrexForm.action = response.ncp_url
            let sessionId = document.createElement('input')
            sessionId.type = 'hidden'
            sessionId.name = 'sessionId'
            sessionId.value = response.emrex_session_id
            emrexForm.appendChild(sessionId)
            let returnUrl = document.createElement('input')
            returnUrl.type = 'hidden'
            returnUrl.name = 'returnUrl'
            returnUrl.value = response.return_url
            emrexForm.appendChild(returnUrl)
            emrexForm.submit()
        })
    }

    getEmrexModal() {
        return (
            <ModalForm
                show
                onClose={() => {
                    this.setState({
                        emrexModal: undefined,
                    })
                }}
            >
                <p>{translate('profile.emrex.request_made')}</p>
                <p />
                <Button
                    className={'button--cta'}
                    buttonText={translate('profile.emrex.import_from_duo')}
                    onClick={() => {
                        this.requestEmrex()
                    }}
                />
            </ModalForm>
        )
    }

    downloadDiploma(url, name) {
        newCallApi('GET', url, null, /* auth */ null, true, true, 'blob')
            .then((results) => {
                saveAs(results, `${name}.pdf`)
            })
            .catch((error) => {
                console.log('Got err', error)
            })
    }

    getEducationsModal(edit = false, education = {}) {
        if (edit) {
            return (
                <ModalForm
                    title={translate('profile.change_education')}
                    show
                    onClose={() => {
                        this.setState({
                            editEducation: undefined,
                        })
                    }}
                >
                    <FormStudies
                        areas={this.props.user.studies}
                        onCancel={() => {
                            this.setState({
                                editEducation: undefined,
                                allowEditEduction: false,
                            })
                        }}
                        onSave={() => {
                            this.setState({
                                editEducation: undefined,
                                allowEditEduction: false,
                            })
                            this.props.showAlert()
                        }}
                        className="form"
                        dispatch={this.props.dispatch}
                        studies={this.props.user.studies}
                        edit={education}
                        darkMode={true}
                        showActions={true}
                    />
                </ModalForm>
            )
        } else {
            return (
                <ModalForm
                    title={translate('profile.add_education')}
                    show
                    onClose={() => {
                        this.setState({
                            addEducation: false,
                        })
                    }}
                >
                    <FormStudies
                        areas={this.props.user.studies}
                        onCancel={() => {
                            this.setState({
                                addEducation: false,
                                allowEditEduction: false,
                            })
                        }}
                        onSave={() => {
                            this.setState({
                                addEducation: false,
                                allowEditEduction: false,
                            })
                            this.props.showAlert()
                        }}
                        className="form"
                        dispatch={this.props.dispatch}
                        studies={this.props.user.studies}
                        darkMode={true}
                        showActions={true}
                    />
                </ModalForm>
            )
        }
    }

    render() {
        let { studies } = this.props.user.profile

        if (studies !== undefined && studies.length > 0) {
            return (
                <Panel
                    className={
                        isCollapsible(studies.length)
                            ? 'panel--expand panel--multi__inner'
                            : 'panel--multi__inner'
                    }
                    headerText={translate('profile.education')}
                    headerIcon="icon-graduation"
                    addButton={
                        <ButtonPanel
                            buttonText={translate('profile.add_new')}
                            className="button--cta"
                            onClick={() => {
                                this.setState({
                                    addEducation: true,
                                })
                            }}
                        ></ButtonPanel>
                    }
                    subHeader={
                        <div className={'panel__subheader'}>
                            <ButtonPanel
                                buttonText={translate('profile.emrex.import_from_duo')}
                                className="button--cta"
                                svgIcon={true}
                                svgPath={'images/DUO-logo-icon.svg'}
                                onClick={() => {
                                    this.requestEmrex()
                                }}
                            />
                        </div>
                    }
                >
                    <div className="panel__list">
                        {studies.map((study) => {
                            return (
                                <ListItem
                                    key={study.id + '-' + this.getStudyName(study)}
                                    onEdit={() => {
                                        this.setState({
                                            editEducation: study,
                                        })
                                    }}
                                    onChange={() => {
                                        this.props.showAlert()
                                    }}
                                    onDownload={() => {
                                        this.downloadDiploma(
                                            study.attachment,
                                            `${this.getStudyName(study)}_${study.institute_name}`,
                                        )
                                    }}
                                    dispatch={this.props.dispatch}
                                    auth={this.props.auth}
                                    type="studies"
                                    item={study}
                                    showEdit={this.state.allowEditEducation}
                                    title={this.getStudyName(study)}
                                    hideDownload={!study.verified}
                                    className="include_download"
                                    banner={
                                        study.verified && <img src={'/images/DUO-banner.svg'} />
                                    }
                                >
                                    <p>
                                        {getTimeString(
                                            study.start_date,
                                            study.end_date,
                                            study.current,
                                        )}
                                    </p>
                                    <h3>
                                        <span>{this.getStudyName(study)}</span>
                                        <br />
                                        {study.institute_name}
                                    </h3>
                                    <p>
                                        <Translate value="profile.graduated" /> -{' '}
                                        <span>
                                            {study.finished
                                                ? translate('profile.graduated_yes')
                                                : translate('profile.graduated_no')}
                                        </span>
                                        {study.verified && (
                                            <div className={'diploma_verified_icon'}>
                                                <i className={'icons icon-check'} />
                                                {translate('profile.emrex.verified')}
                                            </div>
                                        )}
                                    </p>
                                </ListItem>
                            )
                        })}
                    </div>

                    {this.state.editEducation !== undefined &&
                        this.getEducationsModal(true, this.state.editEducation)}

                    {this.state.addEducation && this.getEducationsModal()}

                    {this.state.emrexModal && this.getEmrexModal()}
                </Panel>
            )
        } else {
            return (
                <Panel
                    className="panel--suggestion panel--multi__inner"
                    headerText={translate('profile.education')}
                    headerIcon="icon-graduation"
                    addButton={
                        <ButtonPanel
                            buttonText={translate('profile.add_new')}
                            className="button--cta"
                            onClick={() => {
                                this.setState({
                                    addEducation: true,
                                })
                            }}
                        />
                    }
                    subHeader={
                        <div className={'panel__subheader'}>
                            <ButtonPanel
                                buttonText={translate('profile.emrex.import_from_duo')}
                                className="button--cta"
                                svgIcon={true}
                                svgPath={'images/DUO-logo-icon.svg'}
                                onClick={() => {
                                    this.requestEmrex()
                                }}
                            />
                        </div>
                    }
                >
                    <Suggestion iconName="icon-graduation">
                        <Translate value="profile.no_education" />
                    </Suggestion>

                    {this.state.addEducation && this.getEducationsModal()}

                    {this.state.emrexModal && this.getEmrexModal()}
                </Panel>
            )
        }
    }
}

function mapStateToProps(state) {
    const { auth, config, user } = state

    return {
        auth,
        config,
        user,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(EducationsPanel)

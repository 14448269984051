import ModalForm from '../../Modal/ModalForm'
import RoleGenerator from '../../Forms/RoleGenerator'
import { translate } from 'react-i18nify'
import { useDispatch } from 'react-redux'

export const RolesGenerator = ({ handleClose, handleSave }) => {
    const dispatch = useDispatch()
    return (
        <ModalForm title={translate('headers.roles')} show onClose={handleClose}>
            <RoleGenerator
                className="form--small_margin"
                onCancel={handleClose}
                onSave={handleSave}
                dispatch={dispatch}
            />
        </ModalForm>
    )
}

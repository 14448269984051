import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'react-grid-system'

import Alert from '../components/Alert/Alert'
import Button from '../components/Button/Button'
import Card from '../components/Card/Card'
import Form from '../components/Forms/Form'
import GridCol from '../components/GridCol/GridCol'
import GridColOffset from '../components/GridCol/GridColOffset'
import ModalWindow from '../components/Modal/Modal'

class Styleguide extends Component {
    constructor(props) {
        super(props)

        this.modal = React.createRef()
    }

    render() {
        // injected by connect call
        const { dispatch, data } = this.props

        return (
            <div className="react-native-web">
                <Container>
                    <Row>
                        <GridColOffset />
                        <GridCol>
                            <h1 className="styleguide-header">Styleguide</h1>
                        </GridCol>
                    </Row>

                    <Row>
                        <GridColOffset />
                        <GridCol>
                            <h3 className="styleguide-header">Dit zijn alle kleuren:</h3>
                            <div className="styleguide-container">
                                <div className="styleguide-color color-default">
                                    <span>Color Default</span>
                                </div>
                                <div className="styleguide-color color-theme">
                                    <span>Color Theme</span>
                                </div>
                                <div className="styleguide-color color-theme-light">
                                    <span>Color Theme Light</span>
                                </div>
                                <div className="styleguide-color color-theme-dark">
                                    <span>Color Theme Dark</span>
                                </div>
                                <div className="styleguide-color color-alert">
                                    <span>Color Alert</span>
                                </div>
                                <div className="styleguide-color color-alt">
                                    <span>Color Alt</span>
                                </div>
                                <div className="styleguide-color color-grey">
                                    <span>Color Grey</span>
                                </div>
                                <div className="styleguide-color color-grey-80">
                                    <span>Color Grey 80</span>
                                </div>
                                <div className="styleguide-color color-grey-60">
                                    <span>Color Grey 60</span>
                                </div>
                                <div className="styleguide-color color-grey-40">
                                    <span>Color Grey 40</span>
                                </div>
                                <div className="styleguide-color color-grey-20">
                                    <span>Color Grey 20</span>
                                </div>
                                <div className="styleguide-color color-grey-10">
                                    <span>Color Grey 10</span>
                                </div>
                                <div className="styleguide-color color-grey-5">
                                    <span>Color Grey 5</span>
                                </div>
                            </div>
                        </GridCol>
                    </Row>

                    <Row>
                        <GridColOffset />
                        <GridCol>
                            <h3 className="styleguide-header">Dit zijn de tekstelementen:</h3>
                            <div className="styleguide-container">
                                <h1>
                                    {' '}
                                    H1. Dit is een h1 header <br />
                                    over 2 regels
                                </h1>
                                <h1 className="purple">
                                    {' '}
                                    H1. Dit is een h1 header <br />
                                    over 2 regels
                                </h1>
                                <h1 className="blue">
                                    {' '}
                                    H1. Dit is een h1 header <br />
                                    over 2 regels
                                </h1>
                                <h1 className="white"> H1. Dit is een h1 header</h1>

                                <h2>
                                    {' '}
                                    H2. Dit is een h2 header <br />
                                    over 2 regels
                                </h2>
                                <h2 className="red">
                                    {' '}
                                    H2. Dit is een h2 header <br />
                                    over 2 regels
                                </h2>
                                <h2 className="yellow">
                                    {' '}
                                    H2. Dit is een h2 header <br />
                                    over 2 regels
                                </h2>

                                <h3>
                                    {' '}
                                    H3. Dit is een h3 header <br />
                                    over 2 regels
                                </h3>

                                <p>
                                    P. This is a standard paragraph. Lorem ipsum dolor sit amet,
                                    consectetuer adipiscing elit. Aenean commodo ligula eget dolor{' '}
                                    <a href="#">standaard link</a>. Cum sociis natoque penatibus et
                                    magnis dis parturient montes, nascetur ridiculus mus. Donec quam
                                    felis, ultricies nec, pellentesque eu, pretium quis, sem.
                                </p>

                                <ul>
                                    <li>LI. Dit is een standaard list item</li>
                                    <li>LI. Dit is nog een standaard list item</li>
                                    <li>
                                        LI. Dit is nog een standaard list item <br />
                                        over 2 regels
                                    </li>
                                </ul>
                            </div>
                        </GridCol>
                    </Row>

                    <Row>
                        <GridColOffset />
                        <GridCol>
                            <h3 className="styleguide-header">
                                Dit zijn (styling wise) de 3 voorkomende "standaard" buttons:
                            </h3>

                            <div className="styleguide-container">
                                <Row>
                                    <Col lg={6}>
                                        <p>Standard Button:</p>
                                        <Button
                                            className="button"
                                            onClick={function () {}}
                                            buttonText="Button Simple"
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <p> Button Alt:</p>
                                        <Button
                                            className="button--alt"
                                            onClick={function () {}}
                                            buttonText="Button Alt"
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <p> Button Cta:</p>
                                        <Button
                                            className="button--cta"
                                            onClick={function () {}}
                                            buttonText="Button CTA"
                                        />
                                    </Col>

                                    <Col lg={6}>
                                        <p> Button Link:</p>
                                        <Button
                                            className="button--link"
                                            buttonText="Wachtwoord vergeten"
                                            onClick={() => {}}
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <p> Button Small:</p>
                                        <Button
                                            className="button--small"
                                            onClick={function () {}}
                                            buttonText="More info"
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <p> Button Icon:</p>
                                        <Button
                                            className="button--icon icomoon-cross"
                                            onClick={function () {}}
                                        />
                                        <Button
                                            className="button--icon icomoon-check"
                                            onClick={function () {}}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </GridCol>
                    </Row>
                    <Row>
                        <GridColOffset />
                        <GridCol>
                            <h3 className="styleguide-header">
                                Dit is een card in z'n meest basic vorm:
                            </h3>
                            <div className="styleguide-container">
                                <Card className="card">
                                    <p className="card__text">Zou je een</p>
                                    <div className="card__subject">Cognitieve capaciteiten</div>
                                    <p className="card__text">test willen doen?</p>
                                </Card>
                            </div>

                            <h3 className="styleguide-header">
                                Dit is een card met een image/logo:
                            </h3>
                            <div className="styleguide-container">
                                <Card className="card">
                                    <p className="card__text">Zou je bij</p>
                                    <img className="card__image" src="/images/logos/ambiq.png" />
                                    <p className="card__text">willen werken?</p>
                                </Card>
                            </div>

                            <h3 className="styleguide-header">
                                Dit is een card met een achtergrondafbeelding:
                            </h3>
                            <div className="styleguide-container">
                                <Card className="card--bg_img">
                                    <p className="card__text">Spreekt de functie</p>
                                    <div className="card__subject">Persoonlijk begeleider</div>
                                    <p className="card__text">jou aan?</p>
                                </Card>
                            </div>

                            <h3 className="styleguide-header">Dit is een card met een lijst:</h3>
                            <div className="styleguide-container">
                                <Card className="card--list">
                                    <div className="card__header">
                                        Strategisch Communicatieadviseur
                                    </div>
                                    <div className="card__subheader">Saxenburg group</div>
                                    <ul>
                                        <li>
                                            <i className="icons icon-direction" />
                                            Verpleging & Verzorging en Thuiszorg
                                        </li>
                                        <li>
                                            <i className="icons icon-location-pin" />
                                            Hardenberg
                                        </li>
                                        <li>
                                            <i className="icons icon-clock" />
                                            28 tot 36 uur
                                        </li>
                                        <li>
                                            <i className="icons icon-graduation" />
                                            HBO
                                        </li>
                                        <li>
                                            <i className="icons icon-book-open" />
                                            Half jaar
                                        </li>
                                    </ul>
                                </Card>
                            </div>

                            <h3 className="styleguide-header">
                                Dit is de card die je te zien krijgt als je alle cards hebt
                                beantwoord.
                            </h3>
                            <div className="styleguide-container">
                                <Card className="card--last">
                                    <img className={'card__image img-smiley-success'} />
                                    <p className="card__text">
                                        Als we nieuwe suggesties voor jou gevonden hebben dan worden
                                        die hier automatisch weergegeven.
                                    </p>
                                    <Link className="button--cta" to="home">
                                        Terug naar home
                                    </Link>
                                </Card>
                            </div>
                            <h3 className="styleguide-header">
                                Dit is de achterkant van een card:
                            </h3>
                            <div className="styleguide-container">
                                <Card className="card--back">
                                    <h2>
                                        Vacature
                                        <br />
                                        HR-manager ZGT
                                    </h2>
                                    <p>
                                        For many of us, our very first experience of learning about
                                        the celestial bodies begins when we saw our first full moon
                                        in the sky. It is truly a magnificent view even to the naked
                                        eye. If the night is clear, you can see amazing detail of
                                        the lunar surface just star gazing on in your backyard.
                                    </p>

                                    <p>
                                        Naturally, as you grow in your love of astronomy, you will
                                        find many celestial bodies fascinating. But the moon may
                                        always be our first love because is the one far away space
                                        object that has the unique distinction of flying close to
                                        the earth and upon which man has walked.
                                    </p>

                                    <p>
                                        For many of us, our very first experience of learning about
                                        the celestial bodies begins when we saw our first full moon
                                        in the sky. It is truly a magnificent view even to the naked
                                        eye. If the night is clear, you can see amazing detail of
                                        the lunar surface just star gazing on in your backyard.
                                    </p>
                                    <p>
                                        Naturally, as you grow in your love of astronomy, you will
                                        find many celestial bodies fascinating. But the moon may
                                        always be our first love because is the one far away space
                                        object that has the unique distinction of flying close to
                                        the earth and upon which man has walked.
                                    </p>
                                </Card>
                            </div>
                        </GridCol>
                    </Row>
                    <Row>
                        <GridColOffset />
                        <GridCol>
                            <h3 className="styleguide-header">
                                Zo ziet een standaard form op een witte achtergrond er uit:
                            </h3>

                            <div className="styleguide-container">
                                <Row>
                                    <Col sm={12}>
                                        <div className="styleguide-container">
                                            <Form className="form" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <h3 className="styleguide-header">
                                Zo ziet een form met een gekleurde achtergrond/achtergrondafbeelding
                                er uit:
                            </h3>

                            <div className="styleguide-container">
                                <Row>
                                    <Col sm={12}>
                                        <div className="styleguide-container">
                                            <Form className="form--bg" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </GridCol>
                    </Row>

                    <Row>
                        <GridColOffset />
                        <GridCol>
                            <h3 className="styleguide-header">
                                Zo ziet een standaard alert (bijv. in een form) er uit:
                            </h3>
                            <div className="styleguide-container">
                                <Alert
                                    type="alert"
                                    alertText="Dit is een alert om de gebruiker ergens op te wijzen"
                                />
                            </div>
                            <h3 className="styleguide-header">
                                Zo ziet een error alert (bijv. in een form) er uit:
                            </h3>
                            <div className="styleguide-container">
                                <Alert
                                    type="alert--error"
                                    alertText="U heeft geen geldig e-mailadres ingevuld. Probeer het opnieuw"
                                />
                            </div>
                            <h3 className="styleguide-header">
                                Zo ziet een success alert (bijv. in een form) er uit:
                            </h3>
                            <div className="styleguide-container">
                                <Alert
                                    type="alert--success"
                                    alertText="Bedankt voor het invullen van het formulier."
                                />
                            </div>
                        </GridCol>
                    </Row>

                    <Row>
                        <GridColOffset />
                        <GridCol>
                            <h3 className="styleguide-header">
                                Icons kunnen op deze manier worden toegevoegd:
                            </h3>
                            <div className="styleguide-container">
                                <p>
                                    <i className="icons icon-user" />
                                    <i className="icons icon-home purple" />
                                    <i className="icons icon-arrow-up blue" />
                                    <i className="icons icon-arrow-right white" />
                                    <i className="icons icon-arrow-left red" />
                                    <i className="icons icon-arrow-up yellow" />, etc.
                                </p>

                                <p>
                                    Voor een specificatie van beschikbare icons zie{' '}
                                    <a
                                        href="http://simplelineicons.com/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Simple Line Icons
                                    </a>
                                </p>
                            </div>
                        </GridCol>
                    </Row>
                    <Row>
                        <GridColOffset />
                        <GridCol>
                            <h3 className="styleguide-header">Dit is een modal:</h3>
                            <div className="styleguide-container">
                                <Link
                                    className="button--cta"
                                    onClick={() => {
                                        this.modal.current.show()
                                    }}
                                >
                                    Open modal
                                </Link>

                                <ModalWindow title="Modal" ref={this.modal}>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Nunc elit mi, efficitur vel aliquam nec, aliquet hendrerit
                                        ante. Sed in laoreet nisl. Vestibulum eleifend nibh id
                                        dignissim eleifend. Ut eu elit id dui porta tincidunt eget
                                        nec massa. Cras a efficitur arcu, quis sollicitudin nibh.
                                        Pellentesque sit amet posuere dui. Suspendisse potenti.
                                        Fusce sed imperdiet tellus. Cras rhoncus elementum
                                        malesuada. Ut hendrerit, ante quis efficitur euismod, lacus
                                        orci scelerisque lorem, et dapibus tortor risus porta justo.
                                        Cum sociis natoque penatibus et magnis dis parturient
                                        montes, nascetur ridiculus mus. Curabitur dignissim est
                                        suscipit pharetra volutpat. Quisque mollis libero libero, a
                                        luctus massa dictum eu. Etiam tempus sem eu metus vulputate,
                                        et tempor tellus consequat. Pellentesque tempor iaculis
                                        purus, laoreet blandit enim ullamcorper sit amet. Donec
                                        venenatis, augue et volutpat laoreet, nunc mi elementum
                                        quam, id vehicula nisi nisl porttitor nunc. Vestibulum
                                        commodo varius lorem a malesuada. Vestibulum ante ipsum
                                        primis in faucibus orci luctus et ultrices posuere cubilia
                                        Curae; Proin eros augue, molestie in ipsum nec, pharetra
                                        auctor neque. Nunc consectetur lobortis est sit amet
                                        vehicula. Maecenas at lorem semper elit cursus pharetra nec
                                        a lectus. Morbi at libero ac magna pharetra condimentum non
                                        sit amet ex. Morbi sodales lacinia ante, id tincidunt justo
                                        commodo vel. Integer malesuada, felis non pellentesque
                                        viverra, sem tellus egestas nisi, id lobortis est metus eget
                                        lorem. Vestibulum euismod lectus sed tincidunt mattis.
                                        Integer ac sagittis augue. Nullam nibh augue, elementum a
                                        bibendum a, euismod id libero. Nam et interdum tortor, eget
                                        pretium dolor. Nunc commodo nibh at velit scelerisque
                                        consectetur. Nulla facilisi. Donec diam justo, vulputate non
                                        luctus a, convallis sed turpis. Etiam congue nisl vel nibh
                                        elementum, nec pulvinar justo euismod. Pellentesque placerat
                                        molestie pellentesque. Donec euismod varius interdum. Morbi
                                        bibendum orci non tellus congue, et condimentum arcu
                                        scelerisque. Phasellus aliquam finibus lobortis. Cras
                                        iaculis faucibus elit id mollis. Donec quis malesuada dui.
                                        Aliquam erat volutpat. Cras turpis dui, rhoncus quis lacinia
                                        non, fermentum ut orci. Morbi laoreet lectus congue orci
                                        suscipit imperdiet. Cras et lorem at lacus porttitor
                                        vestibulum eu ut risus. Nunc sed justo imperdiet, imperdiet
                                        sapien et, tristique velit. Integer at aliquam leo. Ut sed
                                        ornare justo. Aliquam at malesuada arcu. Sed ac scelerisque
                                        tellus, sit amet tincidunt felis. Nunc quis finibus justo.
                                        Sed iaculis sit amet libero et ultricies. Pellentesque
                                        ullamcorper purus sed massa sodales, id euismod mi ornare.
                                        Sed at magna sit amet nunc rhoncus viverra vel egestas
                                        purus. Morbi hendrerit, ligula in sodales pharetra, libero
                                        ligula eleifend elit, non feugiat nunc lorem eget massa. Nam
                                        a dictum nisi. Curabitur sit amet tincidunt nunc. Praesent
                                        in velit quis felis ullamcorper aliquam. Duis ultrices
                                        condimentum mi, in faucibus lacus fermentum pulvinar.
                                        Praesent odio turpis, elementum semper ipsum elementum,
                                        feugiat laoreet dolor. Proin consectetur odio et nisi ornare
                                        placerat. Phasellus ac libero at mi pretium fermentum.
                                        Nullam eu fringilla eros, vel tristique enim. Vivamus et
                                        feugiat arcu, a pharetra felis. Phasellus interdum ultricies
                                        elementum.
                                    </p>
                                </ModalWindow>
                            </div>
                        </GridCol>
                    </Row>
                </Container>
            </div>
        )
    }
}

Styleguide.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

const select = (state) => state

// Wrap the component to inject dispatch and state into it
export default connect(select)(Styleguide)

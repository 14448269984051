import { combineReducers } from 'redux'

import { routerReducer } from 'react-router-redux'

import { auth } from './auth'
import { suggestions } from './suggestions'
import { user } from './user'
import { config } from './config'

export const portfolioReducer = combineReducers({
    auth,
    suggestions,
    user,
    config,
    routing: routerReducer,
})

export default portfolioReducer

import React from 'react'
import { Translate } from 'react-i18nify'
import { Field } from 'formik'
import { CitySelector } from '../../CitySelector'

export const CityFormikSelector = () => {
    return (
        <>
            <Field name="city">
                {({ field, form, meta }) => (
                    <CitySelector
                        controller={{
                            ...field,
                            ...meta,
                            onChange: (value) => form.setFieldValue('city', value),
                        }}
                    />
                )}
            </Field>

            <label className="form__label" htmlFor="city">
                <Translate value="labels.city" />
            </label>
        </>
    )
}

import React, { Component } from 'react'
import Panel from '../../Panel/Panel'
import { connect } from 'react-redux'
import { translate, Translate } from 'react-i18nify'
import ButtonPanel from '../../Button/ButtonPanel'
import Suggestion from '../../Suggestion/Suggestion'
import {
    diffProfileItems,
    fetchProfile,
    getEventsOfInterest,
    getEventsRegisteredFor,
} from '../../../store/actions/user'
import Tag from '../../Tag/Tag'
import { isCollapsible } from '../helpers'
import Event from '../../Event/Event'
import ModalForm from '../../Modal/ModalForm'
import InterestsGenerator from '../../Forms/InterestsGenerator'

class EventsPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loadingEventsRegistered: false,
            loadingEventsOfInterest: false,
            generateInterests: false,
            allowEditJobInterests: false,
        }
    }

    componentDidMount() {
        this.updateEvents()
    }

    updateEvents() {
        this.setState({ loadingEventsRegistered: true, loadingEventsOfInterest: true })
        this.props.dispatch(
            getEventsRegisteredFor(this.props.auth, () =>
                this.setState({ loadingEventsRegistered: false }),
            ),
        )
        this.props.dispatch(
            getEventsOfInterest(
                this.props.user.profile.fields_of_interest.map((interest) => interest.id),
                this.props.auth,
                () => this.setState({ loadingEventsOfInterest: false }),
            ),
        )
    }

    getUpcomingEventsPanel() {
        return this.getEventsPanel(
            translate('profile.events.upcoming_events.title'),
            translate('profile.events.upcoming_events.support_text'),
            Object.values(this.props.user.events_registered_for)
                .filter((event) => new Date(event.item_start) >= new Date().setHours(0, 0, 0, 0))
                .slice(0, 2),
        )
    }

    getAllRegisteredEventsPanel() {
        return this.getEventsPanel(
            translate('profile.events.all_registered_events.title'),
            translate('profile.events.all_registered_events.support_text'),
            this.props.user.events_registered_for,
        )
    }

    getBrowseAndRegisterForEventsPanel() {
        return this.getEventsPanel(
            translate('profile.events.browse_and_register_for_events.title'),
            translate('profile.events.browse_and_register_for_events.support_text'),
            this.props.user.events_of_interest,
        )
    }

    getEventsPanel(title, support_text, events = []) {
        if (events.length > 0) {
            return (
                <div>
                    <Panel
                        className={
                            isCollapsible(events.length)
                                ? 'panel--events panel--expand panel--multi__inner'
                                : 'panel--events panel--multi__inner'
                        }
                        headerText={title}
                        headerIcon="icon-calendar"
                        supportText={<div className="text__replacement">{support_text}</div>}
                    >
                        {events.map((event) => [
                            <div key={event.id * 2} className="spacer-hr" />,
                            <Event
                                key={event.id * 2 + 1}
                                event={event}
                                registered={
                                    this.props.user.events_registered_for
                                        .map((event) => event.id)
                                        .indexOf(event.id) >= 0
                                }
                                dispatch={this.props.dispatch}
                            />,
                        ])}
                    </Panel>
                    <div className="spacer"></div>
                    <div className="spacer-hr"></div>
                </div>
            )
        } else {
            return null
        }
    }

    getEventFieldsOfInterestPanel() {
        let fields_of_interest = this.props.user.profile.fields_of_interest
        return (
            <Panel
                className={
                    'panel--events panel--multi__inner' +
                    (fields_of_interest && fields_of_interest.length > 0
                        ? ''
                        : ' panel--suggestion')
                }
                headerText={translate('profile.events.fields_of_interest.title')}
                headerIcon="icon-calendar"
                supportText={
                    <div className="text__replacement" style={{ marginRight: 24 }}>
                        {translate('profile.events.fields_of_interest.support_text')}
                    </div>
                }
                addButton={
                    <ButtonPanel
                        buttonText={translate('profile.add_new')}
                        className="button--cta"
                        showText={false}
                        onClick={() => {
                            this.setState({
                                generateInterests: true,
                            })
                        }}
                    />
                }
            >
                {this.state.generateInterests && this.getInterestsGenerator()}
                {fields_of_interest && fields_of_interest.length > 0 ? (
                    <div className="panel__form">
                        <div className="panel__tags">
                            {fields_of_interest.map((interest) => {
                                return (
                                    <Tag
                                        key={interest.id + '-' + interest.name}
                                        onRemove={() => {
                                            this.props.dispatch(
                                                diffProfileItems(
                                                    'fields_of_interest',
                                                    [],
                                                    [interest.id],
                                                    this.props.auth,
                                                    () => {
                                                        this.updateEvents()
                                                    },
                                                ),
                                            )
                                        }}
                                        showEdit={this.state.allowEditJobInterests}
                                        title={interest.name}
                                    >
                                        {interest.name}
                                    </Tag>
                                )
                            })}
                        </div>
                    </div>
                ) : (
                    <Suggestion iconName="icon-calendar">
                        <Translate value="profile.events.fields_of_interest.none_selected" />
                    </Suggestion>
                )}
            </Panel>
        )
    }

    getInterestsGenerator() {
        return (
            <ModalForm
                title={translate('profile.field_of_interest.title')}
                show
                onClose={() => {
                    this.setState({
                        generateInterests: false,
                    })
                }}
            >
                <InterestsGenerator
                    className="form--small_margin"
                    onCancel={() => {
                        this.setState({
                            generateInterests: false,
                        })
                    }}
                    onSave={() => {
                        this.setState({
                            generateInterests: false,
                        })
                        this.props.dispatch(
                            fetchProfile(
                                this.props.auth,
                                (status, errs) => {},
                                () => {
                                    this.updateEvents()
                                },
                            ),
                        )
                    }}
                    dispatch={this.props.dispatch}
                />
            </ModalForm>
        )
    }

    render() {
        return (
            <div>
                <Panel className="panel--multi">
                    {this.getUpcomingEventsPanel()}
                    {this.getAllRegisteredEventsPanel()}
                    {this.getBrowseAndRegisterForEventsPanel()}
                    {this.getEventFieldsOfInterestPanel()}
                </Panel>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
        state,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(EventsPage)

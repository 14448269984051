import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { activate, logoutUser, receiveLogin, resetPassword } from '../store/actions/auth'
import { addClass, getErrorMessages, removeClass } from '../helpers'
import { translate, Translate } from 'react-i18nify'
import { Container, Row } from 'react-grid-system'

import Button from '../components/Button/Button'
import GridCol from '../components/GridCol/GridCol'
import GridColOffset from '../components/GridCol/GridColOffset'
import Alert from '../components/Alert/Alert'

import jwt_decode from 'jwt-decode'

class Activate extends Component {
    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this)
        this.success = this.success.bind(this)
        this.error = this.error.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)

        this.state = {
            success: false,
            error: '',
            activateAndReset: false,
            resetToken: '',
            email: '',
            password: '',
            repeat_password: '',
            activationMessage: '',
            account_types: [],
            user_info: {},
            cvsRedirectUrl: '',
        }
    }

    componentDidMount() {
        console.log('[ Activate ]', 'Mounted')
        this.props.dispatch(logoutUser(this.props.auth, null, () => {}, false))

        if (this.props.match.params.cvsRedirect && this.props.config.whitelabel.cvs_url) {
            this.setState({ cvsRedirectUrl: this.props.config.whitelabel.cvs_url })
        }
        if (this.props.match.params.reset) {
            // There is a reset token
            this.setState({ activateAndReset: true, resetToken: this.props.match.params.reset })
        } else {
            activate(
                this.props.match.params.token,
                (user_info) => this.success(user_info),
                (status, obj) => this.error(status, obj),
                this.props.state.auth,
            )
        }
    }

    handleChange(field, event) {
        if (field === 'email') this.setState({ email: event.target.value })
        if (field === 'password') this.setState({ password: event.target.value })
        if (field === 'repeat_password') this.setState({ repeat_password: event.target.value })

        if (event.target.value && event.target.value.length > 0) {
            addClass(event.target, 'has-value')
        } else {
            removeClass(event.target, 'has-value')
        }
    }

    handleSubmit(event) {
        event.preventDefault()
        resetPassword(
            this.state.resetToken,
            this.state.email,
            this.state.password,
            this.state.repeat_password,
            () => {
                activate(
                    this.props.match.params.token,
                    (user_info) => this.success(user_info),
                    (status, obj) => this.error(status, obj),
                    this.props.state.auth,
                )
            },
            (status, error) => this.error(status, error),
        )
    }

    confirmLogin() {
        this.props.dispatch(
            receiveLogin(
                this.state.user_info,
                jwt_decode(this.state.user_info.token),
                this.state.user_info.id,
            ),
        )

        if (this.state.cvsRedirectUrl) {
            window.location.href = this.state.cvsRedirectUrl
        } else if (this.state.account_types.some((type) => type.code === 'evaluator')) {
            window.location.href = 'https://www.mijnportfolio.eu/auth/'
        } else {
            window.location.href = '/login'
        }
    }

    success(user_info) {
        console.log('[ Activate ]', 'Activated.', user_info)

        let activationMessage = 'texts.activated'
        if (
            user_info.account_types &&
            user_info.account_types.some(
                (type) =>
                    type.code === 'corona' ||
                    type.code === 'planner' ||
                    type.code === 'flexapp-employee',
            )
        ) {
            activationMessage = 'texts.flexapp_activated'
        } else if (
            user_info.account_types &&
            user_info.account_types.some((type) => type.code === 'evaluator')
        ) {
            activationMessage = 'texts.evaluator_activated'
        }
        setTimeout(() => {
            this.confirmLogin()
        }, 60000)

        this.setState({
            success: true,
            account_types: user_info.account_types,
            activationMessage: activationMessage,
            user_info,
        })
    }

    error(status, obj) {
        console.warn('[ Activate ]', 'Activation error', status, obj)
        if (obj.hasOwnProperty('errors')) {
            let messages = getErrorMessages(obj.errors, {})

            this.setState({
                success: false,
                error: messages,
            })
        } else {
            this.setState({
                success: false,
                error: translate('errors.general'),
            })
        }
    }

    render() {
        // injected by connect call
        const { dispatch, state, isFetching, isAuthenticated, errorMessage } = this.props
        return (
            <div className="react-native-web">
                <Container>
                    <Row>
                        <GridColOffset />
                        {this.state.activateAndReset ? (
                            <GridCol>
                                <form onSubmit={this.handleSubmit} className="form--bg">
                                    <h1>
                                        <Translate value="buttons.activate_header" />
                                    </h1>
                                    {this.state.success && (
                                        <img
                                            className={'form__image img-smiley-success'}
                                            width="192"
                                        />
                                    )}
                                    <div>
                                        <strong>
                                            {this.state.success && (
                                                <Translate value={this.state.activationMessage} />
                                            )}

                                            {!this.state.success &&
                                                this.state.error &&
                                                this.state.error.length > 0 && (
                                                    <Alert type="alert--error">
                                                        <ul>
                                                            {this.state.error.map(function (value) {
                                                                return <li key={value}>{value}</li>
                                                            })}
                                                        </ul>
                                                    </Alert>
                                                )}
                                        </strong>
                                    </div>
                                    {this.state.success && (
                                        <Button
                                            buttonText={translate('buttons.login')}
                                            className="button--alt inline-block"
                                            onClick={() => {
                                                this.confirmLogin()
                                            }}
                                        />
                                    )}
                                    {!this.state.success && (
                                        <div>
                                            <div style={{ maxWidth: '500px', margin: '0 auto' }}>
                                                <fieldset className="form__fieldset">
                                                    <div className="form__item">
                                                        <i className="form__icon icons icon-envelope" />
                                                        <input
                                                            className="form__input--text"
                                                            type="email"
                                                            id="email"
                                                            onChange={(e) =>
                                                                this.handleChange('email', e)
                                                            }
                                                            tabIndex="1"
                                                            autoFocus
                                                        />
                                                        <label
                                                            className="form__label"
                                                            htmlFor="email"
                                                        >
                                                            <Translate value="labels.email" />
                                                        </label>
                                                    </div>
                                                    <div className="form__item">
                                                        <i className="form__icon icons icon-lock" />
                                                        <input
                                                            className="form__input--text"
                                                            type="password"
                                                            id="password"
                                                            onChange={(e) =>
                                                                this.handleChange('password', e)
                                                            }
                                                            tabIndex="2"
                                                        />
                                                        <label
                                                            className="form__label"
                                                            htmlFor="password"
                                                        >
                                                            <Translate value="labels.password" />
                                                        </label>
                                                    </div>
                                                    <div className="form__item">
                                                        <i className="form__icon icons icon-lock" />
                                                        <input
                                                            className="form__input--text"
                                                            type="password"
                                                            id="repeat_password"
                                                            onChange={(e) =>
                                                                this.handleChange(
                                                                    'repeat_password',
                                                                    e,
                                                                )
                                                            }
                                                            tabIndex="2"
                                                        />
                                                        <label
                                                            className="form__label"
                                                            htmlFor="repeat_password"
                                                        >
                                                            <Translate value="labels.repeat_password" />
                                                        </label>
                                                    </div>
                                                </fieldset>
                                                <div className="form__button_container">
                                                    <button
                                                        type="submit"
                                                        className={
                                                            'button--alt' +
                                                            (isFetching ? ' is-loading' : '')
                                                        }
                                                        tabIndex="3"
                                                    >
                                                        {translate('buttons.activate')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </form>
                            </GridCol>
                        ) : (
                            <GridCol>
                                <div className="form--bg">
                                    <h1>
                                        {this.state.success && (
                                            <Translate value="headers.activated" />
                                        )}

                                        {!this.state.success &&
                                            this.state.error &&
                                            this.state.error.length > 0 && (
                                                <Translate value="headers.activate_error" />
                                            )}

                                        {!this.state.success &&
                                            (!this.state.error || this.state.error.length == 0) && (
                                                <Translate value="headers.activating" />
                                            )}
                                    </h1>

                                    {this.state.success && (
                                        <img
                                            className={'form__image img-smiley-success'}
                                            width="192"
                                        />
                                    )}

                                    {!this.state.success &&
                                        this.state.error &&
                                        this.state.error.length > 0 && (
                                            <img
                                                className={'form__image img-smiley-404'}
                                                width="136"
                                            />
                                        )}

                                    <div>
                                        <strong>
                                            {this.state.success && (
                                                <Translate value={this.state.activationMessage} />
                                            )}

                                            {!this.state.success &&
                                                this.state.error &&
                                                this.state.error.length > 0 && (
                                                    <strong>{this.state.error[0]}</strong>
                                                )}

                                            {!this.state.success &&
                                                (!this.state.error ||
                                                    this.state.error.length == 0) && (
                                                    <Translate value="texts.activating" />
                                                )}
                                        </strong>
                                    </div>

                                    {this.state.success && (
                                        <Button
                                            buttonText={translate('buttons.login')}
                                            className="button--alt inline-block"
                                            onClick={() => {
                                                this.confirmLogin()
                                            }}
                                        />
                                    )}
                                </div>
                            </GridCol>
                        )}
                    </Row>
                </Container>
            </div>
        )
    }
}

Activate.propTypes = {
    dispatch: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
}

const select = (state) => state

function mapStateToProps(state) {
    const { test, auth, config } = state
    const { isFetching, isAuthenticated, errorMessage } = auth

    return {
        auth,
        state,
        isFetching,
        isAuthenticated,
        errorMessage,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(Activate)

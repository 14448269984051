import thunk from 'redux-thunk'
import { applyMiddleware, compose } from 'redux'
import LocalStorage from '../components/LocalStorage'
import history from '../history'
import {
    CHECK_GO_BACK_TO,
    PATH_ACTIVATE,
    PATH_FORGOT_PASSWORD,
    PATH_LOGIN,
    PATH_REGISTER,
    PATH_RESET_PASSWORD,
    SET_GO_BACK_TO,
} from '../constants/Constants'

const ACTIONS = [CHECK_GO_BACK_TO, SET_GO_BACK_TO]

const redirector = (store) => (next) => (action) => {
    let goBackTo = LocalStorage.getItem('goBackTo')
    if (ACTIONS.includes(action.type)) {
        function routeToGoBackTo(route) {
            history.push(route)
            LocalStorage.removeItem('goBackTo')
        }
        let loc = window.location.pathname
        switch (action.type) {
            case SET_GO_BACK_TO:
                if (
                    !loc.startsWith('/' + PATH_LOGIN) &&
                    !loc.startsWith('/' + PATH_ACTIVATE) &&
                    !loc.startsWith('/' + PATH_REGISTER) &&
                    !loc.startsWith('/' + PATH_FORGOT_PASSWORD) &&
                    !loc.startsWith('/' + PATH_RESET_PASSWORD)
                ) {
                    LocalStorage.setItem('goBackTo', window.location.pathname)
                }
                break
            case CHECK_GO_BACK_TO:
                if (goBackTo) {
                    routeToGoBackTo(goBackTo)
                } else if (loc.startsWith('/') || loc.startsWith('/' + PATH_LOGIN)) {
                    history.push(store.getState().config.whitelabel.home_page)
                }
                break
            default:
                return next(action)
        }
    }
    return next(action)
}

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const enhancer = composeEnhancer(applyMiddleware(redirector, thunk))

export default enhancer

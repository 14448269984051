import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Suggestion extends Component {
    render() {
        const { iconName } = this.props

        let iconClass = 'suggestion__icon icons ' + iconName

        return (
            <div className="suggestion">
                <i className={iconClass} />
                {this.props.children}
            </div>
        )
    }
}

Suggestion.propTypes = {
    iconName: PropTypes.string.isRequired,
}

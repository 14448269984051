import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ModalWindow from './Modal'

export default class ModalForm extends Component {
    constructor(props) {
        super(props)

        let toShow = this.props.show !== undefined ? this.props.show : false

        this.state = { show: toShow }

        this.show = this.show.bind(this)
        this.close = this.close.bind(this)
    }

    show() {
        document.body.style.overflowY = 'hidden'
        this.setState({ show: true })
    }

    close() {
        document.body.style.overflowY = 'scroll'
        this.setState({ show: false })
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    render() {
        const { title, hideClose, size } = this.props

        return (
            <div
                onKeyDown={(e) => {
                    //Esc
                    if (e.keyCode === 27) {
                        this.close()
                    }
                }}
            >
                <ModalWindow
                    modalClass={'modal--form'}
                    size={size}
                    title={title}
                    containerClassName={
                        'modal__container' + (this.props.padding ? '' : ' no__padding')
                    }
                    show={this.state.show}
                    onClose={this.close}
                >
                    {this.props.children}
                </ModalWindow>
            </div>
        )
    }
}

ModalForm.propTypes = {
    title: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    hideClose: PropTypes.bool,
    onClose: PropTypes.func,
    show: PropTypes.bool.isRequired,
    padding: PropTypes.bool,
    size: PropTypes.string,
}

ModalForm.defaultProps = {
    padding: true,
}

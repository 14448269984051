import ModalForm from '../../Modal/ModalForm'
import { translate, Translate } from 'react-i18nify'
import Panel from '../../Panel/Panel'
import ButtonPanel from '../../Button/ButtonPanel'
import Loading from '../../Loading/Loading'
import ListItem from '../../ListItem/ListItem'
import { getTimeString } from '../helpers'
import Suggestion from '../../Suggestion/Suggestion'
import Pagination from '../../Pagination/Pagination'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import FormApplications from '../../Forms/FormApplications'
import newCallApi from '../../../store/actions/api'

class ApplicationsPanel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            applications: { data: [], meta: {} },
            addApplication: false,
            editApplication: undefined,
            loadingApplications: false,
            applicationsPage: 1,
        }
    }

    componentDidMount() {
        this.updateApplication(1)
    }

    updateApplication(applicationsPage) {
        this.setState({ loadingApplications: true })
        if (applicationsPage) this.setState({ applicationsPage })
        newCallApi(
            'GET',
            this.props.config.API_APPLICATIONS.replace('{account}', this.props.user.profile.id) +
                `?page=${applicationsPage}`,
        )
            .then((applications) => {
                this.setState({ applications: applications })
            })
            .catch(() => {})
            .then(() => this.setState({ loadingApplications: false }))
    }

    getApplicationsModal(item) {
        return (
            <ModalForm
                title={
                    item
                        ? translate('profile.edit_application')
                        : translate('profile.add_application')
                }
                show
                onClose={() => this.setState({ addApplication: false, editApplication: undefined })}
            >
                <FormApplications
                    userId={this.props.user.profile.id}
                    editItem={item}
                    className="form"
                    onCancel={() =>
                        this.setState({ addApplication: false, editApplication: undefined })
                    }
                    onSave={() => {
                        this.setState({ addApplication: false, editApplication: undefined })
                        this.updateApplication(1)
                    }}
                    dispatch={this.props.dispatch}
                />
            </ModalForm>
        )
    }

    render() {
        return (
            <Panel
                className="panel--multi__inner"
                headerText={translate('profile.applications')}
                headerIcon="icon-cup"
                addButton={
                    <ButtonPanel
                        buttonText={translate('profile.add_new')}
                        className="button--cta"
                        onClick={() => {
                            this.setState({ addApplication: true })
                        }}
                    />
                }
            >
                {this.state.loadingApplications ? (
                    <Loading />
                ) : (
                    <div className="panel__list">
                        {this.state.applications.data.map((application, i) => {
                            return (
                                <ListItem
                                    key={`sol_${i}_${application.id}`}
                                    dispatch={this.props.dispatch}
                                    onEdit={() => this.setState({ editApplication: application })}
                                    auth={this.props.auth}
                                    onChange={() => this.updateApplication(1)}
                                    type="applications"
                                    item={application}
                                    showEdit={!application.readonly}
                                    hideEdit={application.readonly}
                                    hideDelete={application.readonly}
                                    title={application.company}
                                >
                                    <p>{getTimeString(application.date)}</p>
                                    <h3>
                                        <span>{application.vacancy}</span>
                                        <br />
                                        {application.company}
                                    </h3>
                                    {application.remarks && application.remarks !== '' && (
                                        <div>
                                            <b>
                                                <Translate value="profile.remarks" />: &nbsp;
                                            </b>
                                            {application.remarks}
                                        </div>
                                    )}
                                </ListItem>
                            )
                        })}
                        {!this.state.applications.data.length && (
                            <Suggestion iconName="icon-notebook">
                                <Translate value="profile.no_application" />
                            </Suggestion>
                        )}
                        <Pagination
                            pageCount={this.state.applications.meta.last_page}
                            onPageChange={(count) => this.updateApplication(count.selected + 1)}
                            forcePage={this.state.applicationsPage - 1}
                        />
                    </div>
                )}
                {this.state.addApplication && this.getApplicationsModal()}
                {this.state.editApplication !== undefined &&
                    this.getApplicationsModal(this.state.editApplication)}
            </Panel>
        )
    }
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(ApplicationsPanel)

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { Container, Row } from 'react-grid-system'

import { translate, Translate } from 'react-i18nify'

import Button from '../components/Button/Button'
import GridCol from '../components/GridCol/GridCol'
import GridColOffset from '../components/GridCol/GridColOffset'
import history from '../history'

class page404 extends Component {
    render() {
        // injected by connect call
        const { dispatch } = this.props

        return (
            <div className="react-native-web">
                <Container>
                    <Row>
                        <GridColOffset />
                        <GridCol>
                            <div className="form--bg">
                                <h1>
                                    <Translate value="headers.oops" />
                                    <br />
                                    <Translate value="headers.something_wrong" />
                                </h1>

                                <img className={'form__image img-smiley-404'} width="136" />

                                <p>
                                    <strong>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: translate('texts.not_found'),
                                            }}
                                        />
                                    </strong>
                                </p>
                                <Button
                                    buttonText={translate('buttons.previous_page')}
                                    className="button--alt inline-block"
                                    onClick={history.goBack}
                                />
                            </div>
                        </GridCol>
                    </Row>
                </Container>
            </div>
        )
    }
}

page404.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

const select = (state) => state

export default connect()(page404)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import Panel from '../../Panel/Panel'
import { translate, Translate } from 'react-i18nify'
import ButtonPanel from '../../Button/ButtonPanel'
import Tag from '../../Tag/Tag'
import { diffProfileItems } from '../../../store/actions/user'
import Suggestion from '../../Suggestion/Suggestion'
import ModalForm from '../../Modal/ModalForm'
import FormFlexibleEmployers from '../../Forms/FormFlexibleEmployers'

class FlexibleShiftsPanel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            addCompetences: false,
            allowEditCompetences: false,
            showRoles: false,
            confirmCardsRedirect: true,
        }
    }

    getFlexibleShiftEmployerModal() {
        return (
            <ModalForm
                title={translate('profile.flexible_shift_employers')}
                show
                onClose={() => {
                    this.setState({
                        addFlexibleEmployer: false,
                    })
                }}
                dispatch={this.props.dispatch}
            >
                <FormFlexibleEmployers
                    onSave={() => {
                        this.setState({
                            addFlexibleEmployer: false,
                        })
                    }}
                    onCancel={() => {
                        this.setState({
                            addFlexibleEmployer: false,
                        })
                    }}
                    dispatch={this.props.dispatch}
                    user={this.props.user}
                />
            </ModalForm>
        )
    }

    render() {
        let pref_orgs = this.props.user.profile.flex_organisations || []
        pref_orgs.map((org) => {
            let tmp = this.props.user.flexapp_organizations.find(
                (flex) => flex.id == org.organisation_id,
            )
            if (tmp) {
                org.name = tmp.name
            } else {
                org.name = ''
            }
        })

        return (
            <div>
                {pref_orgs.length > 0 ? (
                    <Panel
                        className={pref_orgs.length > 10 ? 'panel panel--expand' : 'panel'}
                        headerText={translate('profile.flexible_shift_employers')}
                        headerIcon="icon-rocket"
                        addButton={
                            <ButtonPanel
                                buttonText={translate('profile.add_new')}
                                className="button--cta"
                                showText={false}
                                onClick={() => {
                                    this.setState({
                                        addFlexibleEmployer: true,
                                    })
                                }}
                            />
                        }
                    >
                        <div className="panel__form">
                            <div className="panel__tags">
                                {pref_orgs.map((org) => {
                                    return (
                                        <Tag
                                            key={org.id + '-' + org.name}
                                            showEdit={true}
                                            onRemove={() => {
                                                this.props.dispatch(
                                                    diffProfileItems(
                                                        'flex_organisations',
                                                        [],
                                                        [org.id],
                                                        this.props.auth,
                                                    ),
                                                )
                                            }}
                                            title={org.name}
                                        >
                                            {org.name}
                                            {org.status === 0 && (
                                                <span>
                                                    {' '}
                                                    (
                                                    <Translate value="profile.flexapp_organisation_pending" />
                                                    )
                                                </span>
                                            )}
                                            {org.status === 2 && (
                                                <span>
                                                    {' '}
                                                    (
                                                    <Translate value="profile.flexapp_organisation_rejected" />
                                                    )
                                                </span>
                                            )}
                                        </Tag>
                                    )
                                })}
                            </div>
                        </div>

                        {this.state.addFlexibleEmployer && this.getFlexibleShiftEmployerModal()}
                    </Panel>
                ) : (
                    <Panel
                        className="panel--suggestion panel"
                        headerText={translate('profile.flexible_shift_employers')}
                        headerIcon="icon-people"
                        addButton={
                            <ButtonPanel
                                buttonText={translate('profile.add_new')}
                                className="button--cta"
                                showText={false}
                                onClick={() => {
                                    this.setState({
                                        addFlexibleEmployer: true,
                                    })
                                }}
                            />
                        }
                    >
                        <Suggestion iconName="icon-people">
                            <Translate value="profile.no_flexible_shift_employers" />
                        </Suggestion>

                        {this.state.addFlexibleEmployer && this.getFlexibleShiftEmployerModal()}
                    </Panel>
                )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(FlexibleShiftsPanel)

import {
    CLEAR_PROFILE,
    LOGIN_FAILURE,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    TOKEN_RENEW_ERROR,
    TOKEN_RENEWED,
    TOKEN_RENEWING,
    TOKEN_USED,
    UPDATE_USER_DATA,
} from '../../constants/Constants'

/**
 * Reducer to change the application state for all auth state changes
 * @param state
 * @param action
 */
export function auth(
    state = {
        isFetching: false,
        isAuthenticated: false,
        token: null,
        errorMessage: '',
        renewing: false,
        renewed: true,
        firstname: '',
        lastname: '',
        email: '',
        id: -1,
        exp: 0,
        iat: 0,
    },
    action,
) {
    switch (action.type) {
        case CLEAR_PROFILE:
            console.log('[ Clear auth profile ]', 'Clearing auth state')
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticated: false,
                token: null,
                errorMessage: '',
                renewing: false,
                renewed: true,
                firstname: '',
                lastname: '',
                email: '',
                id: -1,
                exp: 0,
                iat: 0,
            })
        case LOGIN_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                isAuthenticated: false,
                errorMessage: '',
            })
        case LOGIN_SUCCESS:
            console.log('[ login success ]', action)
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticated: true,
                errorMessage: '',
                token: action.token,
                firstname: action.firstname,
                lastname: action.lastname,
                email: action.email,
                id: action.id,
                exp: action.exp,
                iat: action.iat,
            })
        case LOGIN_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticated: false,
                errorMessage: action.message,
            })
        case LOGOUT_REQUEST:
            return Object.assign({}, state, {})
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticated: false,
                token: null,
                firstname: '',
                lastname: '',
                email: '',
                errorMessage: '',
            })
        case TOKEN_RENEWING:
            return Object.assign({}, state, {
                renewing: true,
                renewed: false,
            })
        case TOKEN_RENEWED:
            return Object.assign({}, state, {
                renewing: false,
                renewed: true,
                token: action.token,
            })
        case TOKEN_RENEW_ERROR:
            return Object.assign({}, state, {
                renewing: false,
                renewed: false,
                token: null,
                isAuthenticated: false,
            })
        case TOKEN_USED:
            return Object.assign({}, state, {
                renewing: false,
                renewed: false,
            })
        case UPDATE_USER_DATA:
            return Object.assign({}, state, {
                firstname: action.first,
                lastname: action.last,
            })
        default:
            return state
    }
}

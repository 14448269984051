import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { Col, Container, Row } from 'react-grid-system'
import Panel from '../components/Panel/Panel'
import ButtonPanel from '../components/Button/ButtonPanel'
import ListProfile from '../components/ListProfile/ListProfile'
import ModalForm from '../components/Modal/ModalForm'
import Alert from '../components/Alert/Alert'
import { newCallApi } from '../store/actions/api'
import { Link } from 'react-router-dom'
import Loading from '../components/Loading/Loading'
import Suggestion from '../components/Suggestion/Suggestion'
import Actions from '../components/Actions/Actions'
import CheckboxList from '../components/CheckboxList/CheckboxList'
import { translate, Translate } from 'react-i18nify'
import { addClass, removeClass } from '../helpers'
import { fetchAccountFields } from '../store/actions/user'

class Sharing extends Component {
    constructor(props) {
        super(props)
        this.state = {
            shareProfile: false,
            edit: false,
            editProfile: false,
            sharing: [],
            sharees: [],
            email: '',
            items: [],
            errors: [],
            loading: false,
            loading_sharedWithMe: true,
            loading_sharedByMe: true,
        }
        this.state.items = {
            personal: [],
            job_functions: [],
            studies: [],
            courses: [],
            references: [],
            competences: [],
            job_interests: [],
            languages: [],
            interests: [],
            skills: [],
            files: [],
            account_fields: [],
            basics: [],
            tests: [],
            school_subjects: [],
        }
        this.startSharing = this.startSharing.bind(this)
        this.stopSharing = this.stopSharing.bind(this)
        this.possibleBasics = {
            date_of_birth: { name: translate('labels.birthday') },
            place_of_birth: { name: translate('labels.birthplace') },
            email: { name: translate('profile.email') },
            phone_number: { name: translate('labels.phonenumber') },
            mobile_number: { name: translate('labels.mobilenumber') },
            address: { name: translate('profile.address') },
            quote: { name: translate('profile.quote') },
            tagline: { name: translate('profile.function') },
        }
    }

    componentDidMount() {
        // List sharing with
        this.listSharingWith()
        // List sharees
        this.listSharedBy()
        // Fetch uploaded documents
        newCallApi(
            'GET',
            this.props.config.API_DOCUMENT_AND_TYPES.replace(
                '{account}',
                this.props.user.profile.id,
            ),
        )
            .then((uploadedDocuments) => {
                const allFiles = []
                uploadedDocuments.forEach((type) => {
                    if (type.files && type.files.length)
                        type.files.forEach((file) =>
                            allFiles.push({ ...file, typeName: type.name }),
                        )
                })
                this.setState({ uploadedDocuments, allFiles })
            })
            .catch(() => {})
        // Fetch EELLOO tests
        newCallApi(
            'GET',
            this.props.config.API_EELLOO_TESTS.replace('{account}', this.props.user.profile.id),
        )
            .then((result) => {
                let eellooTests = result.filter(
                    (r) => r.status === 1 && r.testable.reports.length > 0,
                )
                eellooTests.forEach((e) => {
                    const name = e.testable.service
                        ? e.testable.service.name
                        : e.testable.template.name
                    const date = e.created_at ? e.created_at.split(' ')[0] : ''
                    e.name = date ? `${name} (${date})` : name
                })
                this.setState({ eellooTests })
            })
            .catch(() => {})
        // Setup account fields
        fetchAccountFields(this.props.user.profile.account_types, null, (field_names) => {
            let accountFields = []
            for (let i = 0; i < (this.props.user.profile.account_fields || []).length; i++) {
                let field = this.props.user.profile.account_fields[i]
                if (field && field.value) {
                    accountFields.push({
                        id: field.id,
                        name:
                            (
                                field_names.find((item) => {
                                    return item.code === field.code
                                }) || {}
                            ).name +
                            ': ' +
                            (field.value.toString() === 'true'
                                ? '✓'
                                : field.value.toString() === 'false'
                                ? '✕'
                                : field.value),
                    })
                }
            }

            this.setState({
                accountFields: accountFields,
            })
        })
    }

    emptySharer() {
        this.setState({
            email: '',
            items: {
                personal: [],
                job_functions: [],
                studies: [],
                courses: [],
                references: [],
                competences: [],
                job_interests: [],
                languages: [],
                interests: [],
                skills: [],
                files: [],
                account_fields: [],
                basics: [],
                tests: [],
                school_subjects: [],
            },
        })
    }

    getSaveClasses() {
        let c = 'actions__action--save'
        if (this.state.loading) {
            c += ' is-loading'
        }
        return c
    }

    updateState(category, checked) {
        let items = this.state.items
        let text = category.replace(/['"]+/g, '')
        items[text] = checked
        this.setState({
            items: items,
        })
    }

    handleInputChange(event, name) {
        const obj = {}
        obj[name] = event.target.value
        this.setState(obj)
        if (event.target.value && event.target.value.length > 0) {
            addClass(event.target, 'has-value')
        } else {
            removeClass(event.target, 'has-value')
        }
    }

    getClassNames(item) {
        let c = 'form__input--text'
        if (item != null && item != '' && item.length > 0) {
            c += ' has-value'
        }
        return c
    }

    startSharing(editProfile) {
        this.emptySharer()
        this.setState({ shareProfile: true, errors: [] })
        if (editProfile) {
            const items = this.state.items
            editProfile.items.forEach((item) => {
                if (
                    this.possibleBasics.hasOwnProperty(item.type) &&
                    !!this.possibleBasics[item.type] &&
                    !item.id
                ) {
                    items.basics.push(item.type)
                } else {
                    items[item.type] && items[item.type].push(item.id)
                }
            })
            this.setState({ editProfile, items, email: editProfile.shared_with.email, edit: true })
        }
    }

    stopSharing() {
        this.emptySharer()
        this.setState({ shareProfile: false, editProfile: false, edit: false })
    }

    listSharingWith() {
        newCallApi(
            'GET',
            this.props.config.API_SHARING.replace('{account}', this.props.user.profile.id),
        ).then((sharing) => this.setState({ sharing, loading_sharedWithMe: false }))
    }

    listSharedBy() {
        newCallApi('GET', this.props.config.API_SHAREES).then((sharees) =>
            this.setState({ sharees, loading_sharedByMe: false }),
        )
    }

    delete() {
        this.setState({ loading: true })
        newCallApi(
            'DELETE',
            this.props.config.API_SHARING.replace('{account}', this.props.user.profile.id) +
                '/' +
                this.state.editProfile.id,
        )
            .then(() => {
                this.stopSharing()
                this.listSharingWith()
                this.setState({ errors: [] })
            })
            .catch((e) => {
                this.setState({ errors: e.errors })
            })
            .then(() => this.setState({ loading: false }))
    }

    share() {
        this.setState({ loading: true })
        const allItems = this.state.items
        const submitItems = []
        for (let key in allItems) {
            if (
                allItems.hasOwnProperty(key) &&
                allItems[key] &&
                typeof allItems[key] === 'object' &&
                allItems[key].length
            ) {
                for (let i = 0; i < allItems[key].length; i++) {
                    if (key === 'basics') {
                        submitItems.push({
                            type: allItems[key][i],
                        })
                    } else {
                        submitItems.push({
                            type: key,
                            id: allItems[key][i],
                        })
                    }
                }
            }
        }
        if (this.state.editProfile) {
            newCallApi(
                'PATCH',
                this.props.config.API_SHARING.replace('{account}', this.props.user.profile.id) +
                    '/' +
                    this.state.editProfile.id,
                {
                    email: this.state.email,
                    items: submitItems,
                },
            )
                .then(() => {
                    this.stopSharing()
                    this.listSharingWith()
                    this.setState({ errors: [] })
                })
                .catch((e) => {
                    this.setState({ errors: e.errors })
                })
                .then(() => this.setState({ loading: false }))
        } else {
            newCallApi(
                'POST',
                this.props.config.API_SHARING.replace('{account}', this.props.user.profile.id),
                {
                    email: this.state.email,
                    items: submitItems,
                },
            )
                .then(() => {
                    this.stopSharing()
                    this.listSharingWith()
                })
                .catch((e) => {
                    this.setState({ errors: e.errors })
                })
                .then(() => this.setState({ loading: false }))
        }
    }

    getBasicVal(basic) {
        let val = this.props.user.profile[basic]
        if (basic === 'address') {
            let addressValue = ''
            if (!!this.props.user.profile.address)
                addressValue += this.props.user.profile.address + ' '
            if (!!this.props.user.profile.address_number)
                addressValue += this.props.user.profile.address_number
            if (!!this.props.user.profile.address_number_suffix) {
                addressValue += ' ' + this.props.user.profile.address_number_suffix + ', '
            } else {
                addressValue += ', '
            }
            if (!!this.props.user.profile.postal_code)
                addressValue += this.props.user.profile.postal_code + ' '
            if (!!this.props.user.profile.city_name)
                addressValue += this.props.user.profile.city_name
            val = addressValue
        } else if (basic === 'quote') val = this.props.user.profile.quote_text
        return val
    }

    render() {
        const { dispatch } = this.props
        let isEmpty = true
        const basicsList = []
        Object.keys(this.possibleBasics).forEach((basic) => {
            const val = this.getBasicVal(basic)
            if (!!val) {
                basicsList.push({
                    id: basic,
                    name: this.possibleBasics[basic].name,
                    subtext: val,
                })
            }
        })

        return (
            <div className="react-native-web">
                {this.state.shareProfile && (
                    <ModalForm
                        title={translate('profile.share_profile')}
                        show={true}
                        onClose={() => {
                            this.stopSharing()
                        }}
                        onSave={() => {}}
                        actionText={translate('buttons.save')}
                    >
                        {!!this.state.errors.length && (
                            <Alert type="alert--error alert--simple">
                                <ul>
                                    {this.state.errors.map((value, index) => {
                                        return <li key={'err_' + index}>{value.message}</li>
                                    })}
                                </ul>
                            </Alert>
                        )}
                        <div className="form form__content">
                            {!this.state.editProfile && (
                                <fieldset className="form__fieldset">
                                    <div className="form__item">
                                        <input
                                            tabIndex="1"
                                            className={this.getClassNames(
                                                this.state.email,
                                                'email',
                                            )}
                                            type="email"
                                            id="email"
                                            value={this.state.email}
                                            onChange={(event) =>
                                                this.handleInputChange(event, 'email')
                                            }
                                            autoFocus
                                        />
                                        <label className="form__label" htmlFor="email">
                                            <Translate value="resumes.email" />
                                        </label>
                                    </div>
                                </fieldset>
                            )}
                            <ul className="checkbox_list">
                                <CheckboxList
                                    itemName="basics"
                                    title={translate('profile.personal_data')}
                                    itemText="name"
                                    itemSubText="subtext"
                                    arr={basicsList}
                                    edit={this.state.edit}
                                    checked={this.state.items.basics}
                                    onCheckChange={(checked) => {
                                        this.updateState('basics', checked)
                                    }}
                                    onToggleAll={(checked) => {
                                        this.updateState('basics', checked)
                                    }}
                                />

                                <CheckboxList
                                    itemName="job_functions"
                                    title={translate('profile.experience')}
                                    itemText="function_title"
                                    itemSubText="company_name"
                                    arr={this.props.user.profile.job_functions || []}
                                    edit={this.state.edit}
                                    checked={this.state.items.job_functions}
                                    onCheckChange={(checked) => {
                                        this.updateState('job_functions', checked)
                                    }}
                                    onToggleAll={(checked) => {
                                        this.updateState('job_functions', checked)
                                    }}
                                />

                                <CheckboxList
                                    itemName="education"
                                    title={translate('profile.education')}
                                    itemText="study_id"
                                    itemSubText="institute_name"
                                    arr={this.props.user.profile.studies || []}
                                    edit={this.state.edit}
                                    checked={this.state.items.studies}
                                    onCheckChange={(checked) => {
                                        this.updateState('studies', checked)
                                    }}
                                    onToggleAll={(checked) => {
                                        this.updateState('studies', checked)
                                    }}
                                    studies={this.props.user.studies}
                                />

                                <CheckboxList
                                    itemName="courses"
                                    title={translate('profile.courses')}
                                    itemText="name"
                                    itemSubText="achieved"
                                    arr={this.props.user.profile.courses || []}
                                    edit={this.state.edit}
                                    checked={this.state.items.courses}
                                    onCheckChange={(checked) => {
                                        this.updateState('courses', checked)
                                    }}
                                    onToggleAll={(checked) => {
                                        this.updateState('courses', checked)
                                    }}
                                />

                                <CheckboxList
                                    itemName="references"
                                    title={translate('profile.references')}
                                    itemText="name"
                                    itemSubText="company"
                                    arr={this.props.user.profile.references || []}
                                    edit={this.state.edit}
                                    checked={this.state.items.references}
                                    onCheckChange={(checked) => {
                                        this.updateState('references', checked)
                                    }}
                                    onToggleAll={(checked) => {
                                        this.updateState('references', checked)
                                    }}
                                />

                                {this.props.config.whitelabel.show_school_subjects && (
                                    <CheckboxList
                                        itemName="school_subjects"
                                        title={translate('profile.school_subjects.title')}
                                        itemText="school_subject_name"
                                        itemSubText="school_subject_level_name"
                                        arr={this.props.user.profile.school_subjects || []}
                                        edit={this.state.edit}
                                        checked={this.state.items.school_subjects}
                                        onCheckChange={(checked) => {
                                            this.updateState('school_subjects', checked)
                                        }}
                                        onToggleAll={(checked) => {
                                            this.updateState('school_subjects', checked)
                                        }}
                                    />
                                )}

                                <CheckboxList
                                    itemName="competences"
                                    title={translate('profile.competences')}
                                    itemText="name"
                                    itemSubText=""
                                    arr={this.props.user.profile.competences || []}
                                    edit={this.state.edit}
                                    checked={this.state.items.competences}
                                    onCheckChange={(checked) => {
                                        this.updateState('competences', checked)
                                    }}
                                    onToggleAll={(checked) => {
                                        this.updateState('competences', checked)
                                    }}
                                />

                                <CheckboxList
                                    itemName="job_interests"
                                    title={translate('profile.job_interest')}
                                    itemText="name"
                                    itemSubText=""
                                    arr={this.props.user.profile.job_interests || []}
                                    edit={this.state.edit}
                                    checked={this.state.items.job_interests}
                                    onCheckChange={(checked) => {
                                        this.updateState('job_interests', checked)
                                    }}
                                    onToggleAll={(checked) => {
                                        this.updateState('job_interests', checked)
                                    }}
                                />

                                <CheckboxList
                                    itemName="languages"
                                    title={translate('profile.languages')}
                                    itemText="name"
                                    itemSubText=""
                                    arr={this.props.user.profile.languages || []}
                                    edit={this.state.edit}
                                    checked={this.state.items.languages}
                                    onCheckChange={(checked) => {
                                        this.updateState('languages', checked)
                                    }}
                                    onToggleAll={(checked) => {
                                        this.updateState('languages', checked)
                                    }}
                                />

                                <CheckboxList
                                    itemName="skills"
                                    title={translate('profile.skills')}
                                    itemText="name"
                                    itemSubText=""
                                    arr={this.props.user.profile.skills || []}
                                    edit={this.state.edit}
                                    checked={this.state.items.skills}
                                    onCheckChange={(checked) => {
                                        this.updateState('skills', checked)
                                    }}
                                    onToggleAll={(checked) => {
                                        this.updateState('skills', checked)
                                    }}
                                />

                                <CheckboxList
                                    itemName="interests"
                                    title={translate('profile.interests')}
                                    itemText="name"
                                    itemSubText=""
                                    arr={this.props.user.profile.interests || []}
                                    edit={this.state.edit}
                                    checked={this.state.items.interests}
                                    onCheckChange={(checked) => {
                                        this.updateState('interests', checked)
                                    }}
                                    onToggleAll={(checked) => {
                                        this.updateState('interests', checked)
                                    }}
                                />

                                {this.state.allFiles && this.state.allFiles.length > 0 && (
                                    <CheckboxList
                                        itemName="files"
                                        title={translate('profile.attachments')}
                                        itemText="typeName"
                                        itemSubText="name"
                                        arr={this.state.allFiles || []}
                                        edit={this.state.edit}
                                        checked={this.state.items.files}
                                        onCheckChange={(checked) => {
                                            this.updateState('files', checked)
                                        }}
                                        onToggleAll={(checked) => {
                                            this.updateState('files', checked)
                                        }}
                                        expanded={this.props.expandedCategory === 'files'}
                                    />
                                )}

                                {this.state.eellooTests && this.state.eellooTests.length > 0 && (
                                    <CheckboxList
                                        itemName="tests"
                                        title={translate('profile.eelloo_tests')}
                                        itemText="name"
                                        itemSubText=""
                                        arr={this.state.eellooTests || []}
                                        edit={this.state.edit}
                                        checked={this.state.items.tests}
                                        onCheckChange={(checked) => {
                                            this.updateState('tests', checked)
                                        }}
                                        onToggleAll={(checked) => {
                                            this.updateState('tests', checked)
                                        }}
                                        expanded={this.props.expandedCategory === 'tests'}
                                    />
                                )}

                                {this.state.accountFields &&
                                    this.state.accountFields.length > 0 &&
                                    !isEmpty && (
                                        <CheckboxList
                                            itemName="account_fields"
                                            title={translate('profile.extra_info')}
                                            itemText="name"
                                            itemSubText=""
                                            arr={this.state.accountFields || []}
                                            edit={this.state.edit}
                                            checked={this.state.items.account_fields}
                                            onCheckChange={(checked) => {
                                                this.updateState('account_fields', checked)
                                            }}
                                            onToggleAll={(checked) => {
                                                this.updateState('account_fields', checked)
                                            }}
                                        />
                                    )}
                            </ul>
                            <div>
                                <Actions>
                                    <Link
                                        className="actions__action--cancel"
                                        onClick={() => this.stopSharing()}
                                    >
                                        <Translate value="profile.cancel" />
                                    </Link>
                                    <Link
                                        className={this.getSaveClasses()}
                                        onClick={() => this.share()}
                                    >
                                        <Translate value="buttons.save" />
                                    </Link>
                                    {!!this.state.editProfile && (
                                        <Link
                                            className="actions__action--delete"
                                            onClick={() => this.delete()}
                                            style={{
                                                position: 'absolute',
                                                left: '50%',
                                                transform: 'translate(-50%)',
                                            }}
                                        >
                                            <Translate value="buttons.remove" />
                                        </Link>
                                    )}
                                </Actions>
                            </div>
                        </div>
                    </ModalForm>
                )}
                <Container>
                    <Row>
                        <Col sm={12} md={6} xl={6}>
                            {!!this.state.sharees.length ? (
                                <Panel
                                    className="panel--sharing"
                                    headerText={translate('profile.shared_with_me')}
                                    headerIcon="icon-share-alt"
                                >
                                    {this.state.sharees.map((item) => {
                                        return (
                                            <ListProfile
                                                key={'sharee_' + item.id}
                                                user={{
                                                    name: item.name,
                                                    email: item.email,
                                                    id: item.id,
                                                    has_profile_picture: true,
                                                }}
                                            />
                                        )
                                    })}
                                </Panel>
                            ) : (
                                <Panel
                                    className="panel--suggestion"
                                    headerText={translate('profile.shared_with_me')}
                                    headerIcon="icon-share-alt"
                                >
                                    {this.state.loading_sharedWithMe ? (
                                        <Loading />
                                    ) : (
                                        <Suggestion iconName="icon-user">
                                            <Translate value="profile.no_shared_with_me" />
                                        </Suggestion>
                                    )}
                                </Panel>
                            )}
                        </Col>
                        <Col sm={12} md={6} xl={6}>
                            {!!this.state.sharing.length ? (
                                <Panel
                                    className="panel--sharing"
                                    headerText={translate('profile.shared_by_me')}
                                    headerIcon="icon-share-alt"
                                    addButton={
                                        <ButtonPanel
                                            buttonText={translate('profile.share_profile')}
                                            className="button--cta"
                                            showText={false}
                                            onClick={() => {
                                                this.startSharing()
                                            }}
                                        />
                                    }
                                >
                                    {this.state.sharing.map((item) => {
                                        return (
                                            <ListProfile
                                                onClick={() => this.startSharing(item)}
                                                key={'sharing_' + item.id}
                                                user={{
                                                    name: item.shared_with.name,
                                                    email: item.shared_with.email,
                                                    id: item.shared_with.id,
                                                    has_profile_picture: false,
                                                }}
                                            />
                                        )
                                    })}
                                </Panel>
                            ) : (
                                <Panel
                                    className="panel--suggestion"
                                    headerText={translate('profile.shared_by_me')}
                                    headerIcon="icon-share-alt"
                                >
                                    {this.state.loading_sharedByMe ? (
                                        <Loading />
                                    ) : (
                                        <div>
                                            <Suggestion iconName="icon-user">
                                                <Translate value="profile.no_shared_by_me" />
                                            </Suggestion>
                                            <ButtonPanel
                                                buttonText={translate('profile.share_profile')}
                                                className="button--cta"
                                                onClick={() => {
                                                    this.startSharing()
                                                }}
                                            />
                                        </div>
                                    )}
                                </Panel>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

Sharing.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        config,
        user,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(Sharing)

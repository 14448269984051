import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Actions from '../Actions/Actions'
import { connect } from 'react-redux'
import { Translate } from 'react-i18nify'

import { addClass, getErrorFields, getErrorMessages, removeClass } from '../../helpers'

import Alert from '../Alert/Alert'

import { diffProfileItems, editProfileItem } from '../../store/actions/user'
import 'react-dates/lib/css/_datepicker.css'
import Datepicker from '../Datepicker/Datepicker'

let map

var moment = require('moment')

class FormCourses extends Component {
    constructor(props) {
        super(props)

        if (this.props.edit != undefined) {
            let course = this.props.edit

            let expiration = null
            if (course.expiration_date != null && course.expiration_date.length > 0) {
                expiration = moment(course.expiration_date)
            }

            this.state = {
                id: course.id,
                name: course.name,
                achieved: moment(course.achieved),
                expiration: expiration,
                edit: true,
                loading: false,
                error: '',
                errors: [],
                fields: [],
            }
        } else {
            this.state = {
                id: -1,
                name: '',
                achieved: moment(),
                expiration: null,
                edit: false,
                loading: false,
                error: '',
                errors: [],
                fields: [],
            }
        }
    }

    handleStartDate(date) {
        this.setState({
            achieved: date,
        })
    }

    handleEndDate(date) {
        this.setState({
            expiration: date,
        })
    }

    getDateClassNames(item, field) {
        let c = 'form__input--date has-value'

        if (this.state.fields.indexOf(field) != -1) {
            c += ' error'
        }

        return c
    }

    getClassNames(item) {
        if (item != null && item != '' && item.length > 0) {
            return 'form__input--text has-value'
        } else {
            return 'form__input--text'
        }
    }

    handleChange(value, key, changeClass = true) {
        let obj = {}
        obj[key] = value.target.value

        this.setState(obj)

        if (changeClass) {
            if (value.target.value.length > 0) {
                addClass(value.currentTarget, 'has-value')
            } else {
                removeClass(value.currentTarget, 'has-value')
            }
        }
    }

    cancel() {
        this.state = {
            id: -1,
            name: '',
            achieved: '',
            expiration: '',
            edit: false,
            loading: false,
            error: '',
            errors: [],
            fields: [],
        }

        this.props.onCancel()
    }

    save() {
        this.setState({
            loading: true,
        })

        let name = this.state.name,
            achieved = this.state.achieved,
            expiration = this.state.expiration

        //if (name.length < 1 || achieved.length < 1) {
        //    this.setState({
        //        error: translate('errors.empty_field')
        //    });
        //
        //    return;
        //}

        if (expiration == undefined || expiration == null || expiration.length < 1) {
            expiration = null
        } else {
            expiration = expiration.format('YYYY-MM-DD')
        }

        if (achieved && typeof achieved.format === 'function') {
            achieved = achieved.format('YYYY-MM-DD')
        }

        let course = {
            name: name,
            achieved: achieved,
            expiration_date: expiration,
        }

        if (this.state.id != -1) {
            course.id = this.state.id
        }

        if (this.state.edit) {
            this.props.dispatch(
                editProfileItem(
                    'courses',
                    course,
                    this.props.auth,
                    () => this.onSuccess(),
                    (status, error) => this.onError(status, error),
                ),
            )
        } else {
            this.props.dispatch(
                diffProfileItems(
                    'courses',
                    [course],
                    [],
                    this.props.auth,
                    () => {
                        this.onSuccess()
                    },
                    (s, o) => {
                        this.onError(s, o)
                    },
                ),
            )
        }
    }

    onError(status, object) {
        //TODO: Translate
        map = {
            name: 'Cursusnaam',
            achieved: 'Behaald op',
            expiration_date: 'Verloopt op',
        }

        this.setState(
            {
                errors: [],
                fields: [],
                loading: false,
            },
            () => {
                if (object.hasOwnProperty('errors')) {
                    let messages = getErrorMessages(object.errors, map)
                    let fields = getErrorFields(object.errors)

                    this.addErrors(messages)
                    this.addErrorFields(fields)
                }
            },
        )
    }

    addErrors(errors) {
        this.setState({
            errors: errors,
        })
    }

    addErrorFields(fields) {
        this.setState({
            fields: fields,
        })
    }

    getClassNames(item, field) {
        let c = 'form__input--text'

        if (item != null && item != '' && item.length > 0) {
            c += ' has-value'
        }

        if (this.state.fields.indexOf(field) != -1) {
            c += ' error'
        }

        return c
    }

    onSuccess() {
        this.state = {
            id: -1,
            name: '',
            achieved: '',
            expiration: '',
            edit: false,
            loading: false,
            error: '',
            errors: [],
            fields: [],
        }

        this.props.onSave()
    }

    getSaveClasses() {
        let c = 'actions__action--save'

        if (this.state.loading) {
            c += ' is-loading'
        }

        return c
    }

    render() {
        const { className } = this.props

        return (
            <form
                className={this.props.className}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        //Enter pressed
                        e.preventDefault()
                        this.save()
                    }
                }}
            >
                <fieldset className="form__fieldset">
                    {this.state.errors && this.state.errors.length > 0 && (
                        <Alert type="alert--error alert--simple">
                            <ul>
                                {this.state.errors.map(function (value) {
                                    return <li key={value}>{value}</li>
                                })}
                            </ul>
                        </Alert>
                    )}

                    <div className="form__item">
                        <i className="form__icon icons icon-book-open" />
                        <input
                            className={this.getClassNames(this.state.name, 'name')}
                            type="text"
                            id="course_name"
                            value={this.state.name}
                            onChange={(value) => this.handleChange(value, 'name')}
                            tabIndex="1"
                            autoFocus
                        />
                        <label className="form__label" htmlFor="course_name">
                            <Translate value="profile.course_name" />
                        </label>
                    </div>
                    <div className="form__item">
                        <i className="form__icon icons icon-calendar" />
                        <Datepicker
                            date={this.state.achieved}
                            onDateChange={(date) => this.handleStartDate(date)}
                            classNames={this.getDateClassNames(this.state.achieved, 'achieved')}
                            id={'course_from'}
                        />
                        <label className="form__label" htmlFor="course_from">
                            <Translate value="profile.graduated_at" />
                        </label>
                    </div>
                    <div className="form__item">
                        <Datepicker
                            date={this.state.expiration}
                            onDateChange={(date) => this.handleEndDate(date)}
                            classNames={this.getDateClassNames(
                                this.state.expiration,
                                'expiration_date',
                            )}
                            required={false}
                            futureOnly
                            id={'course_until'}
                        />
                        <label className="form__label" htmlFor="course_until">
                            <Translate value="profile.expires_on" />
                        </label>
                    </div>
                </fieldset>
                <Actions>
                    <Link
                        className="actions__action--cancel"
                        onClick={() => this.cancel()}
                        tabIndex="4"
                    >
                        <Translate value="profile.cancel" />
                    </Link>
                    <Link
                        className={this.getSaveClasses()}
                        onClick={() => this.save()}
                        tabIndex="5"
                    >
                        <Translate value="profile.save" />
                    </Link>
                </Actions>
            </form>
        )
    }
}

FormCourses.propTypes = {
    className: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    edit: PropTypes.object,
}

function mapStateToProps(state) {
    const { auth, user } = state

    return {
        auth,
        user,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(FormCourses)

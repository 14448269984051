import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class AlertBox extends Component {
    getClass() {
        return (
            'alert--box' +
            (this.props.type ? `--${this.props.type}` : '') +
            ' ' +
            this.props.className
        )
    }

    render() {
        const { alertText, type } = this.props
        return (
            <div className={this.getClass()}>
                <ul>
                    {this.props.messages.map((item, index) => {
                        return (
                            <li
                                className={
                                    this.props.messages.length === 1 ? 'alert--box__no_list' : ''
                                }
                                key={'error-' + index}
                            >
                                {item}
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }
}

AlertBox.propTypes = {
    messages: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    className: PropTypes.string,
}

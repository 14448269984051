import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Container, Hidden, Row, Visible } from 'react-grid-system'
import { connect } from 'react-redux'
import { translate, Translate } from 'react-i18nify'
import { ToastContainer } from 'react-toastr'

import MapPanel from '../components/Panel/MapPanel'
import Panel from '../components/Panel/Panel'
import Suggestion from '../components/Suggestion/Suggestion'
import ListItem from '../components/ListItem/ListItem'
import Loading from '../components/Loading/Loading'
import Modal from 'simple-react-modal'
import ApplyJobOffer from './ApplyJobOffer'
import { findVacancies, searchCities } from '../store/actions/jobOffers'
import { logoutUser } from '../store/actions/auth'

import Select from 'react-select'
import { CitySelector } from '../components/CitySelector'

class Vacancies extends Component {
    constructor(props) {
        super(props)

        if (!this.props.auth || !this.props.auth.isAuthenticated)
            this.props.dispatch(logoutUser(this.props.auth, null))

        this.state = {
            loading: false,
            loadingCity: false,
            city: null,
            range: { label: '10 km', value: 10 },
            map: {
                lat: 52.24567871187243,
                lng: 6.168033841669057,
            },
            vacancies: [],
        }
        this.toastContainer = React.createRef()
    }

    componentDidMount() {
        this.setCityByConfig()
    }

    setCityByConfig() {
        if (!this.state.city && !this.state.loadingCity) {
            this.setState({ loadingCity: true })

            const { city_name } = this.props.user.profile

            if (city_name) {
                searchCities(this.props.auth, city_name)
                    .then((cities) => {
                        const cityInfo = {
                            value: cities[0].id,
                            label: cities[0].name,
                            latitude: cities[0].latitude,
                            longitude: cities[0].longitude,
                        }

                        this.setState(
                            {
                                city: cityInfo,
                                map: {
                                    lat: +cityInfo.latitude,
                                    lng: +cityInfo.longitude,
                                    zoom: 15,
                                },
                            },
                            () => {
                                this.fetch()
                            },
                        )
                    })
                    .finally(() => {
                        this.setState({ loadingCity: false })
                    })
            } else {
                const { vacancy_map_city } = this.props.config.whitelabel

                if (vacancy_map_city) {
                    this.getCitySuggestions(vacancy_map_city)
                        .then(([firstCity]) => {
                            this.onCitySelected(firstCity, {})
                        })
                        .finally(() => {
                            this.setState({ loadingCity: false })
                        })
                }
            }
        }
    }

    fetch() {
        this.setState(
            {
                loading: true,
            },
            () => {
                if (this.state.city) {
                    findVacancies(
                        this.props.auth,
                        this.state.city.value,
                        this.state.range.value,
                    ).then(
                        (resp) => {
                            console.log('[ Vacancies ]', resp)
                            this.setState({
                                vacancies: resp,
                                loading: false,
                            })
                        },
                        (status, err) => {
                            console.warn('[ Vacancies ]', status, err)
                            this.setState({
                                loading: false,
                                vacancies: [],
                            })
                        },
                    )
                }
            },
        )
    }

    getCitySuggestions(input) {
        return searchCities(this.props.auth, input).then((resp) => {
            console.log('[ Vacancies ]', 'Found cities for input', input, resp)
            let arr = []
            resp.map((city) => {
                arr.push({
                    value: city.id,
                    label: city.name,
                    latitude: city.latitude,
                    longitude: city.longitude,
                })
            })
            return arr
        })
    }

    onCitySelected(value, event) {
        console.log('[ Vacancies ]', 'City selected', value, event)
        if (value) {
            this.setState(
                {
                    city: value,
                    map: {
                        lat: Number(value.latitude),
                        lng: Number(value.longitude),
                        zoom: 15,
                    },
                },
                () => {
                    this.fetch()
                },
            )
        } else {
            this.setState({
                city: null,
                vacancies: [],
            })
        }
    }

    onRangeSelected(value) {
        this.setState(
            {
                range: value,
            },
            () => {
                if (this.state.city) {
                    this.fetch()
                }
            },
        )
    }

    onApply(item) {
        if (item.external_vacancy_url) {
            return window.open(item.external_vacancy_url)
        } else {
            console.log('[ Vacancies ]', 'Should apply for', item)
            this.setState({
                apply: item,
            })
        }
    }

    close() {
        this.setState({
            apply: undefined,
        })
    }

    showSuccessMessage(title, message) {
        this.toastContainer.current.success(message, title, {
            timeOut: 10000,
            extendedTimeOut: 1000,
        })
    }

    render() {
        // injected by connect call
        const { dispatch } = this.props

        return (
            <div className="react-native-web">
                <Container>
                    <Row>
                        {/*VACANCIES*/}
                        <Col sm={12} md={6} xl={6}>
                            {/* TODO set correct header */}

                            {!this.state.loading && this.state.vacancies.length == 0 && (
                                <Panel
                                    className="panel--suggestion panel--suggestion--no-vacancies"
                                    headerText={translate('menu.vacancies')}
                                    headerIcon="icon-folder-alt"
                                >
                                    <Suggestion iconName="icon-folder-alt">
                                        <Hidden sm xs>
                                            {!this.state.city && (
                                                <Translate value="vacancies.no_city_entered" />
                                            )}
                                        </Hidden>

                                        <Visible sm xs>
                                            {!this.state.city && (
                                                <Translate value="vacancies.no_city_entered" />
                                            )}
                                        </Visible>

                                        {this.state.city &&
                                            (!this.state.vacancies ||
                                                this.state.vacancies.length <= 0) && (
                                                <Translate value="vacancies.no_vacancies" />
                                            )}
                                    </Suggestion>
                                </Panel>
                            )}

                            {!this.state.loading && this.state.vacancies.length > 0 && (
                                <Panel
                                    className="panel"
                                    headerText={translate('menu.vacancies')}
                                    headerIcon="icon-folder-alt"
                                >
                                    {!this.state.loading &&
                                        this.state.vacancies.map((item, index) => {
                                            return (
                                                <ListItem
                                                    hideDelete={true}
                                                    className="list_item--small"
                                                    dispatch={this.props.dispatch}
                                                    type={'vacancies'}
                                                    item={item}
                                                    key={'vacancy-' + index}
                                                    auth={this.props.auth}
                                                    href={item.external_vacancy_url}
                                                    onEdit={() => {
                                                        this.onApply(item)
                                                    }}
                                                    positiveText={translate('vacancies.view')}
                                                    actionType="actions__action--view pull-right"
                                                >
                                                    <h4 className="item__title vacancy-title">
                                                        {item.name}
                                                    </h4>
                                                    <h3 className="item__subtitle">
                                                        {item.organisation.name}
                                                    </h3>
                                                </ListItem>
                                            )
                                        })}
                                </Panel>
                            )}

                            {this.state.loading && (
                                <Panel
                                    className="panel"
                                    headerText={translate('menu.vacancies')}
                                    headerIcon="icon-folder-alt"
                                >
                                    <Loading />
                                </Panel>
                            )}
                        </Col>

                        {/*MAP*/}
                        <Col sm={12} md={6} xl={6}>
                            <MapPanel
                                className="panel--map"
                                headerText="Kaart"
                                headerIcon="icon-map"
                                latitude={this.state.map.lat}
                                longitude={this.state.map.lng}
                                zoom={this.state.map.zoom}
                                vacancies={this.state.vacancies}
                                onActionClicked={this.onApply.bind(this)}
                            >
                                <div className="panel__filter form">
                                    <Row style={{ width: '100%', margin: 0 }}>
                                        <Col md={12} lg={8}>
                                            <CitySelector
                                                controller={{
                                                    value: this.state.city,
                                                    onChange: this.onCitySelected.bind(this),
                                                    error: false,
                                                }}
                                                placeholder={translate(
                                                    'vacancies.hint_search_city',
                                                )}
                                                searchPromptText={translate(
                                                    'vacancies.hint_search_prompt',
                                                )}
                                            />
                                        </Col>
                                        <Col md={12} lg={4}>
                                            <Select
                                                className="select--alt"
                                                classNamePrefix="select"
                                                name="range"
                                                onChange={this.onRangeSelected.bind(this)}
                                                value={this.state.range}
                                                isSearchable={false}
                                                clearable={false}
                                                options={[
                                                    { value: 10, label: '10 km' },
                                                    { value: 25, label: '25 km' },
                                                    { value: 50, label: '50 km' },
                                                    { value: 75, label: '75 km' },
                                                    { value: 100, label: '100 km' },
                                                ]}
                                                noOptionsMessage={() =>
                                                    translate('vacancies.noOptions')
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </MapPanel>
                        </Col>
                    </Row>

                    <ToastContainer ref={this.toastContainer} className="toast-bottom-right" />

                    {this.state.apply && (
                        <Modal
                            className="modal--vacancy"
                            containerClassName="modal__container"
                            closeOnOuterClick={true}
                            show={true}
                            onClose={() => {
                                this.close()
                            }}
                        >
                            <ApplyJobOffer
                                dispatch={this.props.dispatch}
                                job_offer={this.state.apply.id}
                                onClose={(sent, saved) => {
                                    this.close()

                                    if (sent) {
                                        this.showSuccessMessage(
                                            translate('vacancies.title_success'),
                                            translate('vacancies.message_success'),
                                        )
                                    } else if (saved) {
                                        this.showSuccessMessage(
                                            translate('vacancies.title_saved'),
                                            translate('vacancies.message_saved'),
                                        )
                                    }
                                }}
                            />
                            <a className="modal__close" onClick={this.close.bind(this)}>
                                ×
                            </a>
                        </Modal>
                    )}
                </Container>

                <ToastContainer ref={this.toastContainer} className="toast-bottom-right" />
            </div>
        )
    }
}

Vacancies.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    const { auth, user, config } = state
    return {
        auth,
        user,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(Vacancies)

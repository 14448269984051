import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { translate } from 'react-i18nify'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'
import Select from 'react-select'

export default class Datepicker extends Component {
    constructor(props) {
        super(props)

        const maxYear = moment().year() + 75
        this.state = {
            focused: false,
            months: moment.months().map((month, idx) => ({ value: idx, label: month })),
            years: [...Array(150).keys()].map((i) => ({ value: maxYear - i, label: maxYear - i })),
        }
    }

    renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
        return (
            <div>
                <select
                    value={month.month()}
                    onChange={(e) => onMonthSelect(month, e.target.value)}
                    className="month"
                >
                    {moment.months().map((label, value) => (
                        <option key={label} value={value}>
                            {label}
                        </option>
                    ))}
                </select>
                <select
                    value={month.year()}
                    onChange={(e) => onYearSelect(month, e.target.value)}
                    className="year"
                >
                    {this.state.years.map((year) => (
                        <option value={year.value} key={year.value}>
                            {year.label}
                        </option>
                    ))}
                </select>
            </div>
        )
    }

    render() {
        let days_to_add = this.props.futureOnly ? 1 : 0

        return (
            <SingleDatePicker
                displayFormat="DD-MM-YYYY"
                required
                date={
                    this.props.date instanceof Object
                        ? this.props.date
                        : this.required
                        ? moment().add(days_to_add, 'days')
                        : undefined
                }
                onDateChange={(date) => this.props.onDateChange(date)}
                focused={this.state.focused}
                onFocusChange={({ focused }) => this.setState({ focused })}
                hideKeyboardShortcutsPanel={true}
                className={this.props.classNames}
                noBorder={true}
                numberOfMonths={1}
                placeholder={translate('profile.datepicker')}
                id={this.props.id}
                renderMonthElement={this.renderMonthElement}
                isOutsideRange={(date) => {
                    if (this.props.futureOnly) {
                        return date.isBefore(moment())
                    } else {
                        return date.isAfter(moment())
                    }
                }}
                readOnly={true}
            />
        )
    }
}

Datepicker.propTypes = {
    onDateChange: PropTypes.func,
    date: PropTypes.string,
    classNames: PropTypes.string,
    id: PropTypes.string,
    futureOnly: PropTypes.bool,
}

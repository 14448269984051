import React, { Component } from 'react'
import { translate, Translate } from 'react-i18nify'
import { Hidden } from 'react-grid-system'
import Button from '../../Button/Button'
import Panel from '../../Panel/Panel'
import Actions from '../../Actions/Actions'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import ModalWindow from '../../Modal/Modal'
import FileUploader from '../../FileUploader/FileUploader'
import FileLister from '../../FileLister/FileLister'
import { fetchDocumentsList } from '../../../store/actions/user'
import { connect } from 'react-redux'

class Documents extends Component {
    constructor(props) {
        super(props)
        this.state = {
            save: undefined,
            cancel: undefined,
        }

        this.save = this.save.bind(this)
        this.formRef = React.createRef()
    }

    save() {
        this.props.nextStep()
    }

    getDocumentsModals(files_to_upload) {
        const fileSelectCallBack = () => {
            this.setState({ uploaddoc: undefined })
            this.props.dispatch(fetchDocumentsList(this.props.auth))
        }

        return (
            <div>
                {this.state.uploaddoc && (
                    <ModalWindow
                        show={true}
                        title={translate('vacancies.select_a_file')}
                        onClose={() => {
                            this.setState({
                                uploaddoc: undefined,
                            })
                        }}
                    >
                        <FileUploader
                            onUploaded={fileSelectCallBack}
                            allowedTypes={files_to_upload}
                            type={this.state.uploaddoc}
                            auth={this.props.auth}
                        />
                    </ModalWindow>
                )}

                {this.state.viewdoc && (
                    <ModalWindow
                        show={true}
                        title={this.state.viewdoc.name}
                        onClose={() => {
                            this.setState({
                                viewdoc: undefined,
                            })
                        }}
                    >
                        <FileLister
                            dispatch={this.props.dispatch}
                            type={this.state.viewdoc.code}
                            auth={this.props.auth}
                            onFileDeleted={fileSelectCallBack}
                        />
                    </ModalWindow>
                )}
            </div>
        )
    }

    render() {
        let files_to_upload = this.props.user.file_upload_types

        return (
            <div className="card--simple">
                <div className="onboarding__content">
                    <h1 className="mixed-case">{translate('onboarding.documents.title')}</h1>
                    <p>{translate('onboarding.documents.description')}</p>
                    <Panel
                        className="panel--multi transparent no-shadow"
                        bodyClassName={'transparent'}
                    >
                        {
                            <div>
                                {files_to_upload &&
                                    files_to_upload.allowed_document_types &&
                                    files_to_upload.allowed_document_types.length > 0 && (
                                        <div>
                                            <Panel
                                                headerClass="panel__header__light"
                                                className="panel--multi__inner"
                                                headerText={translate('profile.upload_files')}
                                                headerIcon="icon-cloud-upload"
                                            >
                                                <table className="panel__table">
                                                    <tbody>
                                                        {files_to_upload.allowed_document_types.map(
                                                            (uploadDocument) => {
                                                                return (
                                                                    <tr
                                                                        key={
                                                                            uploadDocument.id +
                                                                            '-' +
                                                                            uploadDocument.name
                                                                        }
                                                                    >
                                                                        <td>
                                                                            {uploadDocument.name}
                                                                        </td>
                                                                        <td className="table__cell--icon">
                                                                            {uploadDocument.count &&
                                                                            uploadDocument.count !==
                                                                                0
                                                                                ? uploadDocument.count +
                                                                                  ' '
                                                                                : translate(
                                                                                      'common.none',
                                                                                  ) + ' '}
                                                                            {translate(
                                                                                'profile.uploaded_files',
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <Actions>
                                                                                {!!(
                                                                                    uploadDocument.count &&
                                                                                    parseInt(
                                                                                        uploadDocument.count,
                                                                                    ) > 0
                                                                                ) && (
                                                                                    <Link
                                                                                        onClick={() => {
                                                                                            this.setState(
                                                                                                {
                                                                                                    viewdoc:
                                                                                                        uploadDocument,
                                                                                                },
                                                                                            )
                                                                                        }}
                                                                                        className="actions__action--view"
                                                                                    >
                                                                                        {translate(
                                                                                            'buttons.view',
                                                                                        )}
                                                                                    </Link>
                                                                                )}
                                                                                <Link
                                                                                    onClick={() => {
                                                                                        this.setState(
                                                                                            {
                                                                                                uploaddoc:
                                                                                                    uploadDocument.code,
                                                                                            },
                                                                                        )
                                                                                    }}
                                                                                    className="actions__action--upload"
                                                                                >
                                                                                    {translate(
                                                                                        'buttons.upload',
                                                                                    )}
                                                                                </Link>
                                                                            </Actions>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            },
                                                        )}
                                                    </tbody>
                                                </table>
                                            </Panel>
                                        </div>
                                    )}

                                {(!files_to_upload ||
                                    !files_to_upload.allowed_document_types ||
                                    files_to_upload.allowed_document_types.length <= 0) && (
                                    <Panel
                                        headerClass="panel__header__light"
                                        className="panel--multi__inner"
                                        headerText={translate('profile.upload_files')}
                                        headerIcon="icon-cloud-upload"
                                    >
                                        <p style={{ color: 'black' }}>
                                            <Translate value="profile.no_uploading" />
                                        </p>
                                    </Panel>
                                )}
                            </div>
                        }

                        {this.getDocumentsModals(this.props.user.file_upload_types)}
                    </Panel>
                    <div className="card--simple__buttons">
                        <div>
                            <Button
                                className={
                                    'button--alt inline-block' +
                                    (this.state.loading ? ' is-loading' : '')
                                }
                                buttonText={translate('buttons.continue')}
                                onClick={() => {
                                    this.save()
                                }}
                            />
                        </div>
                        {!this.state.loading && (
                            <Hidden xs>
                                <button
                                    onClick={() => {
                                        this.props.prevStep()
                                    }}
                                    className="button--link inline-block"
                                >
                                    <i className="mdi mdi-arrow-left" />
                                    <Translate value="onboarding.button_back" />
                                </button>
                            </Hidden>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth, config, user } = state

    return {
        auth,
        config,
        user,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(Documents)

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'
import { Hidden, Visible } from 'react-grid-system'

export default class Pagination extends Component {
    render() {
        return (
            <div className="pagination-container">
                {this.props.pageCount && this.props.pageCount !== 1 && (
                    <div>
                        <Hidden xs sm>
                            <ReactPaginate
                                pageCount={this.props.pageCount}
                                onPageChange={this.props.onPageChange}
                                marginPagesDisplayed={0}
                                pageRangeDisplayed={3}
                                previousLabel="Vorige"
                                nextLabel="Volgende"
                                previousClassName="previous"
                                nextClassName="next"
                                forcePage={this.props.forcePage}
                            />
                        </Hidden>
                        <Visible xs sm>
                            <ReactPaginate
                                pageCount={this.props.pageCount}
                                onPageChange={this.props.onPageChange}
                                marginPagesDisplayed={0}
                                pageRangeDisplayed={3}
                                previousLabel="<"
                                nextLabel=">"
                                previousClassName="previous"
                                nextClassName="next"
                                forcePage={this.props.forcePage}
                            />
                        </Visible>
                    </div>
                )}
            </div>
        )
    }
}

Pagination.propTypes = {
    pageCount: PropTypes.number,
    onPageChange: PropTypes.func,
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import Panel from '../../Panel/Panel'
import { translate, Translate } from 'react-i18nify'
import ButtonPanel from '../../Button/ButtonPanel'
import Tag from '../../Tag/Tag'
import { diffProfileItems } from '../../../store/actions/user'
import Suggestion from '../../Suggestion/Suggestion'

class FlexappFunctionsPanel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            addFlexappFunction: false,
            allowEditJobInterests: false,
        }
    }

    render() {
        let functions = this.props.user.profile.flex_job_functions
        if (functions !== undefined && functions.length > 0) {
            return (
                <Panel
                    className={functions.length > 5 ? 'panel--expand' : 'panel'}
                    headerText={translate('profile.flexapp_function_interest')}
                    headerIcon="icon-briefcase"
                    addButton={
                        <ButtonPanel
                            buttonText={translate('profile.add_new')}
                            className="button--cta"
                            showText={false}
                            onClick={() => {
                                this.setState({
                                    addFlexappFunction: true,
                                })
                            }}
                        />
                    }
                >
                    <div className="panel__form">
                        <div className="panel__tags">
                            {this.state.addFlexappFunction &&
                                this.props.getJobsGenerator('addFlexappFunction', () => {
                                    this.setState({ addFlexappFunction: false })
                                })}
                            {functions.map((func) => {
                                return (
                                    <Tag
                                        key={func.id + '-' + func.name}
                                        onRemove={() => {
                                            this.props.dispatch(
                                                diffProfileItems(
                                                    'flex_job_functions',
                                                    [],
                                                    [func.id],
                                                    this.props.auth,
                                                ),
                                            )
                                        }}
                                        showEdit={this.state.allowEditJobInterests}
                                        title={func.name}
                                    >
                                        {func.name}
                                    </Tag>
                                )
                            })}
                        </div>
                    </div>
                </Panel>
            )
        } else {
            return (
                <Panel
                    className="panel--suggestion"
                    headerText={translate('profile.flexapp_function_interest')}
                    headerIcon="icon-briefcase"
                    addButton={
                        <ButtonPanel
                            buttonText={translate('profile.add_new')}
                            className="button--cta"
                            showText={false}
                            onClick={() => {
                                this.setState({
                                    addFlexappFunction: true,
                                })
                            }}
                        />
                    }
                >
                    {this.state.addFlexappFunction &&
                        this.props.getJobsGenerator('addFlexappFunction', () => {
                            this.setState({ addFlexappFunction: false })
                        })}

                    <Suggestion iconName="icon-briefcase">
                        <Translate value="profile.no_flexapp_functions" />
                    </Suggestion>
                </Panel>
            )
        }
    }
}

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        auth,
        user,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(FlexappFunctionsPanel)

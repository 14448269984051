import React, { Component } from 'react'
import { translate } from 'react-i18nify'

class DropdownDiplomaOption extends Component {
    constructor(props) {
        super(props)
    }

    getClassName() {
        let className = 'select__option dropdown__option--diploma'

        if (this.props.isFocused) className += ' select__option--is-focused'
        if (this.props.isSelected) className += ' select__option--is-selected'

        return className
    }

    getDates() {
        let years = ''
        let start_year = parseInt(this.props.data.validity_ranges[0].valid_from.slice(0, 4))
        let end_year = 0

        this.props.data.validity_ranges.map((range) => {
            let year = parseInt(range.valid_from.slice(0, 4))
            if (year < start_year) {
                start_year = year
            }

            let enroll_year = null
            let valid_until = null

            if (range.enroll_until) {
                enroll_year = parseInt(range.enroll_until.slice(0, 4))
            } else if (range.valid_until) {
                valid_until = parseInt(range.valid_until.slice(0, 4))
            }

            end_year = Math.max(valid_until || 0, enroll_year || 0, end_year)
        })

        years = start_year + ' - '
        if (end_year) {
            years += end_year
        } else {
            years += translate('labels.in_progress')
        }

        return years
    }

    getCrebo() {
        let arr = []
        this.props.data.validity_ranges.map((range) => {
            if (!arr.includes(range.crebo)) {
                arr.push(range.crebo)
            }
        })
        return arr.join(', ')
    }

    render() {
        let val_ranges = this.props.data.validity_ranges
        val_ranges = val_ranges.length > 0 ? val_ranges : null
        return (
            <div {...this.props.innerProps} className={this.getClassName()}>
                <div>{this.props.data.label}</div>
                {val_ranges && (
                    <small>
                        {translate('profile.education_from')}: {this.getDates()}
                    </small>
                )}
                {val_ranges && (
                    <small>
                        {translate('diplomas.crebo_no')}: {this.getCrebo()}
                    </small>
                )}
                {!val_ranges && <small>{translate('diplomas.school_specific')}</small>}
            </div>
        )
    }
}

// Wrap the component to inject dispatch and state into it
export default DropdownDiplomaOption

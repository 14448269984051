import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { checkGoBackTo, loginError, loginSSO, loginUser, loginUser2FA } from '../store/actions/auth'
import { checkSublabel, restoreConfig } from '../store/actions/config'
import { clearProfileState } from '../store/actions/user'
import Form2FA from '../components/Forms/Form2FA'
import FormLogin from '../components/Forms/FormLogin'

class Login extends Component {
    constructor(props) {
        super(props)

        this.onSuccess = this.onSuccess.bind(this)
        this.state = { requiresTwoFactor: false, twoFactorToken: '' }

        if (this.props.auth && this.props.auth.isAuthenticated) {
            console.log('[ Login ]', 'User is already authenticated', this.props.auth)
            // Redirect to stored value from App.js, not suggestions
            this.props.dispatch(checkGoBackTo())
        } else {
            //Reset all fields to make sure there are not error messages left in the stored state.
            console.log('[ Login ]', 'User is not authenticated, fire clearProfileState()')
            this.props.dispatch(clearProfileState())
        }
    }

    componentDidMount() {
        if (this.props.user.profile.account_types) {
            this.props.dispatch(
                checkSublabel(this.props.config.whitelabel, this.props.user.profile.account_types),
            )
        } else {
            this.props.dispatch(restoreConfig())
        }

        if (this.props.match.params && this.props.match.params.ssoToken) {
            this.props.dispatch(loginSSO(this.props.match.params.ssoToken))
        }
    }

    handleSubmit(dispatch, username, password) {
        let credentials = {
            username: username,
            password: password,
        }

        //No errors found during simple validation, hide them if they were still showing from previous attempts.
        this.props.dispatch(loginError(''))
        //Send the login request
        this.props.dispatch(
            loginUser(
                credentials,
                this.props.auth,
                (resp) => this.onSuccess(resp),
                (status, error) => {
                    if (typeof error === 'object' && error['2fa']) {
                        console.log(error)
                        this.setState({ requiresTwoFactor: true, twoFactorToken: error.token })
                    }
                },
            ),
        )
    }

    handle2FaSubmit(smsToken) {
        console.log(this.state.twoFactorToken, smsToken)
        //No errors found during simple validation, hide them if they were still showing from previous attempts.
        this.props.dispatch(loginError(''))
        //Send the login request
        this.props.dispatch(
            loginUser2FA(
                {
                    smsToken: smsToken,
                    token: this.state.twoFactorToken,
                },
                this.props.auth,
                (resp) => this.onSuccess(resp),
                (status, error) => {
                    if (typeof error === 'object' && error['2fa']) {
                        this.setState({ requiresTwoFactor: true, twoFactorToken: error.token })
                    }
                },
            ),
        )
    }

    /**
     * Login was successful, redirect the user to the home page.
     */
    onSuccess(resp) {
        console.log('[ login successful ]', resp)
        if (resp.password_reset_url) {
            //Reset password
            window.location.href = resp.password_reset_url
        }
        this.props.dispatch(checkGoBackTo())
    }

    render() {
        // injected by connect call
        const { dispatch, state, auth } = this.props

        if (auth == null) {
            auth.isFetching = false
            auth.errorMessage = ''
        } else if (auth.errorMessage === 'requires-2fa') {
            auth.errorMessage = ''
        }

        if (this.state.requiresTwoFactor) {
            return (
                <div className="react-native-web">
                    <Form2FA
                        className="form--bg"
                        isFetching={auth.isFetching}
                        errorMessage={auth.errorMessage}
                        onSubmit={(smsToken) => this.handle2FaSubmit(smsToken)}
                    />
                </div>
            )
        } else {
            return (
                <div className="react-native-web">
                    <FormLogin
                        className="form--bg"
                        isFetching={auth.isFetching}
                        errorMessage={auth.errorMessage}
                        onSubmit={(username, password) =>
                            this.handleSubmit(dispatch, username, password)
                        }
                    />
                </div>
            )
        }
    }
}

Login.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

const select = (state) => state

function mapStateToProps(state) {
    const { auth, user, config } = state

    return {
        state,
        config,
        user,
        auth,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(Login)

/**
 * Actions indicate what needs to be done, the reducers handle state changes.
 */

import {
    CHECK_GO_BACK_TO,
    LOGIN_FAILURE,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    PATH_LOGIN,
    PATH_PROFILE,
    SET_GO_BACK_TO,
    TOKEN_RENEW_ERROR,
    TOKEN_RENEWED,
    TOKEN_RENEWING,
    TOKEN_USED,
    UPDATE_USER_DATA,
} from '../../constants/Constants'
import Raven from 'raven-js'
import newCallApi from './api'
import LocalStorage from '../../components/LocalStorage'
import { clearProfileState } from './user'
/**
 * Redirect based on the platform
 */
import { store } from '../index'
import history from '../../history'
import { restoreConfig } from './config'

import jwt_decode from 'jwt-decode'

/**
 * Send a login request to the server to retrieve a JWT token.
 * @param credentials
 * @returns {{type: *, isFetching: boolean, isAuthenticated: boolean, credentials: *}}
 */
function requestLogin(credentials) {
    return {
        type: LOGIN_REQUEST,
        isFetching: true,
        isAuthenticated: false,
        credentials,
    }
}

/**
 * When a successful response is received from the server (200), sign in the user.
 * @returns {{type: *, isFetching: boolean, isAuthenticated: boolean, id_token: *}}
 * @param response
 * @param decoded
 * @param id
 */
export function receiveLogin(response, decoded, id) {
    return {
        type: LOGIN_SUCCESS,
        isFetching: false,
        isAuthenticated: true,
        token: response.token,
        firstname: decoded.first_name,
        lastname: decoded.last_name,
        id: id,
        exp: decoded.exp,
        iat: decoded.iat,
    }
}

/**
 * Handle errors in the sign in flow
 * @param message
 * @returns {{type: *, isFetching: boolean, isAuthenticated: boolean, message: *}}
 */
export function loginError(message) {
    return {
        type: LOGIN_FAILURE,
        isFetching: false,
        isAuthenticated: false,
        message: message,
    }
}

/**
 * Sign in a user by making an API request and handling the response. Depending on the response, the reducer will change
 * the state accordingly.
 * @param credentials
 * @param state
 * @param success
 * @returns {Function}
 */
export function loginUser(credentials, state, success, error = (status, error) => {}) {
    console.log('[ logging in ]', store.getState())

    let creds = {
        email: credentials.username,
        password: credentials.password,
    }

    if (credentials.app) {
        creds.app = true
    }

    if (
        credentials.device_token != null &&
        credentials.device_token != undefined &&
        credentials.device_token.length > 0
    ) {
        creds.device_token = credentials.device_token
    }

    return (dispatch) => {
        dispatch(requestLogin(credentials))
        return newCallApi('POST', store.getState().config.API_LOGIN, creds, /* auth */ null).then(
            (response) => {
                if (response['2fa_required']) {
                    dispatch(loginError('requires-2fa'))
                    error(200, {
                        '2fa': true,
                        token: response.token,
                    })
                } else {
                    if (response.token) {
                        const decoded = jwt_decode(response.token)
                        dispatch(receiveLogin(response, decoded, response.id))
                    }
                    success(response)
                }
            },
            (response) => {
                dispatch(loginError(response.errors[0].message))
                error(response.status, response.errors[0].message)
            },
        )
    }
}

/**
 * Sign in a user using 2FA by making an API request and handling the response. Depending on the response, the reducer will change
 * the state accordingly.
 * @param credentials
 * @param state
 * @param success
 * @returns {Function}
 */
export function loginUser2FA(credentials, state, success, error = (status, error) => {}) {
    console.log('[ logging in ]', store.getState())

    let creds = {
        token: credentials.smsToken,
    }

    if (credentials.app) {
        creds.app = true
    }

    if (
        credentials.device_token != null &&
        credentials.device_token != undefined &&
        credentials.device_token.length > 0
    ) {
        creds.device_token = credentials.device_token
    }

    return (dispatch) => {
        dispatch(requestLogin(credentials))
        return newCallApi('POST', store.getState().config.API_2FA, creds, {
            isAuthenticated: true,
            token: credentials.token,
        }).then(
            (response) => {
                if (response['2fa_required']) {
                    dispatch(loginError('requires-2fa'))
                    error(200, {
                        '2fa': true,
                        token: response.token,
                    })
                } else {
                    if (response.token) {
                        const decoded = jwt_decode(response.token)
                        dispatch(receiveLogin(response, decoded, response.id))
                    }
                    success(response)
                }
            },
            (response) => {
                dispatch(loginError(response.errors[0].message))
                error(response.status, response.errors[0].message)
            },
        )
    }
}

/**
 * Change the state of the application when a sign out is requested.
 * @returns {{type: *, isFetching: boolean, isAuthenticated: boolean}}
 */
function requestLogout() {
    return {
        type: LOGOUT_REQUEST,
        isFetching: true,
        isAuthenticated: true,
    }
}

/**
 * When sign out is complete, change the state of the application accordingly.
 * @returns {{type: *, isFetching: boolean, isAuthenticated: boolean}}
 */
function receiveLogout() {
    return {
        type: LOGOUT_SUCCESS,
        isFetching: false,
        isAuthenticated: false,
    }
}

/**
 * Sign out a user by removing the id token from the local storage.
 * @returns {Function}
 */
export function logoutUser(auth, navigator = null, success = () => {}, redirect = true) {
    Raven.setUserContext()

    return (dispatch, getState) => {
        const authState = getState().auth

        dispatch(restoreConfig())
        if (!authState || !authState.isAuthenticated || !authState.token) {
            console.log('[ Logout ]', 'Immediate logout (no token known)')

            dispatch(requestLogout())
            dispatch(clearProfileState())
            dispatch(receiveLogout())

            if (redirect) redirectToLogin(navigator)

            return
        }

        console.log('[ Logout ]', 'Calling API logout')
        dispatch(requestLogout())
        newCallApi('POST', store.getState().config.API_LOGOUT, null, /* auth */ null, true, false)
            .catch(() => {
                console.log('[ Logout ]', 'Got error during logout')
            })
            .finally(() => {
                console.log('[ Logout ]', 'Finishing logout after API response')
                dispatch(clearProfileState())
                dispatch(receiveLogout())

                if (redirect) redirectToLogin(navigator)
            })
    }
}

function redirectToLogin(navigator) {
    history.push('/' + PATH_LOGIN)
}

/**
 * Send a registration request
 * @param body
 * @param success
 * @param error
 * @param state
 */
export function register(body, success, error, state) {
    newCallApi(
        'POST',
        store.getState().config.API_REGISTER,
        body,
        /* auth */ null,
        true,
        false,
    ).then(
        () => {
            success()
        },
        (response) => {
            error(response.status, response)
        },
    )
}

/**
 * Activate a new account
 * @param token - The activation token
 * @param success
 * @param error
 * @param state
 */
export function activate(token, success, error, state) {
    newCallApi(
        'GET',
        store.getState().config.API_ACTIVATE + '/' + token,
        null,
        /* auth */ null,
        true,
        true,
    ).then(
        (user_info) => {
            success(user_info)
        },
        (response) => {
            error(response.status, response)
        },
    )
}

/**
 * Request a new password link from the api
 * @param email - The email address to reset the password for
 * @param success
 * @param error
 */
export function requestPasswordReset(email, success, error) {
    var body = {
        email: email,
    }

    newCallApi(
        'POST',
        store.getState().config.API_FORGOT_PASSWORD,
        body,
        /* auth */ null,
        true,
        false,
    ).then(
        () => {
            success()
        },
        (response) => {
            error(response)
        },
    )
}

export function resetPassword(token, email, password, passwordAgain, success, error) {
    var body = {
        // The API does require an e-mail address to be send
        email: email,
        token: token,
        password: password,
        password_confirmation: passwordAgain,
    }

    newCallApi(
        'POST',
        store.getState().config.API_RESET_PASSWORD,
        body,
        /* auth */ null,
        true,
        false,
    ).then(
        () => {
            success()
        },
        (response) => {
            error(response.status, response)
        },
    )
}

function renewing() {
    return {
        type: TOKEN_RENEWING,
    }
}

function renewed(token) {
    return {
        type: TOKEN_RENEWED,
        token: token,
    }
}

function renewError() {
    return {
        type: TOKEN_RENEW_ERROR,
    }
}

export function tokenUsed() {
    return {
        type: TOKEN_USED,
    }
}

export function refreshToken(auth, onSuccess, onError) {
    return (dispatch) => {
        dispatch(renewing())
        newCallApi('POST', store.getState().config.API_RENEW_TOKEN, null, /* auth */ null).then(
            (response) => {
                dispatch(renewed(response.token))
                onSuccess()
            },
            (response) => {
                dispatch(renewError())
                onError(response)
            },
        )
    }
}

export function checkZorgLogin(onSuccess, onError, onIgnore) {
    function getCookie(name) {
        var value = '; ' + document.cookie
        var parts = value.split('; ' + name + '=')
        if (parts.length == 2) return parts.pop().split(';').shift()
    }

    function devMessage(message, good) {
        if (LocalStorage.getItem('dev')) {
            console.info('Dev message: ' + (good === 1 ? '✅' : '❌') + ' ', message)
        }
    }

    return (dispatch) => {
        if (getCookie('zg_session')) {
            devMessage('Found zorgselect session', 1)
        } else {
            devMessage("Didn't find zorgselect session", 0)
        }
        if (getCookie('zg_session')) {
            var request = new XMLHttpRequest()
            request.open(
                'GET',
                store.getState().config.PARENT_BASE_URL + '/auth/api/accounts/session',
                true,
            )
            request.setRequestHeader('X-Requested-With', 'XmlHttpRequest')
            request.setRequestHeader('X-Csrf-Token', getCookie('zg_session'))

            request.onload = function () {
                if (request.status >= 200 && request.status < 400) {
                    devMessage('Good response from Zorgselect', 1)
                    var resp = request.responseText
                    var object = JSON.parse(resp)
                    if (object.auth) {
                        try {
                            var decoded = jwt_decode(object.token)
                            dispatch(receiveLogin(object, decoded, decoded.id))
                            devMessage('Received login from JWT', 1)
                            onSuccess(object)
                        } catch (error) {
                            devMessage('Unable to decode JWT', 0)
                            onError()
                        }
                    } else {
                        devMessage('No `auth` in Zorgselect response', 0)
                        onError()
                    }
                } else {
                    devMessage('Bad response from Zorgselect', 0)
                    onError()
                }
            }
            request.onerror = function () {}
            request.send()
        } else {
            onIgnore()
        }
    }
}

export function updateUserData(firstname, lastname) {
    return {
        type: UPDATE_USER_DATA,
        first: firstname,
        last: lastname,
    }
}

/**
 * Checks if session is running on server and returns auth token if it is
 * @returns {String}
 */
export function getAuthSession(auth, onSuccess, onError) {
    // Check if session token exists
    return newCallApi('GET', store.getState().config.API_SESSION, null, /* auth */ null).then(
        (response) => {
            onSuccess()
            return response.token
        },
        (response) => {
            onError()
        },
    )
}

/**
 * Login using a short-living SSO token
 * @param {*} token Base64-encoded JWT token
 */
export function loginSSO(base64Token) {
    const token = window.atob(base64Token)
    return (dispatch) => {
        // Exchange for a long-living token
        newCallApi('POST', store.getState().config.API_RENEW_TOKEN, null, {
            token: token,
            isAuthenticated: true,
        }).then((response) => {
            console.log(response)
            if (response.token) {
                dispatch(receiveLogin(response, jwt_decode(response.token), response.id))
                history.push('/' + PATH_PROFILE)
            }
        })
    }
}

export function checkGoBackTo() {
    return (dispatch) => {
        dispatch({
            type: CHECK_GO_BACK_TO,
        })
    }
}

export function setGoBackTo() {
    return (dispatch) => {
        dispatch({
            type: SET_GO_BACK_TO,
        })
    }
}

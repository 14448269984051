import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Container, Row } from 'react-grid-system'
import Button from '../components/Button/Button'
import { Link } from 'react-router-dom'

import { connect } from 'react-redux'
import LocalStorage from '../components/LocalStorage'
import ResumeGenerator from './ResumeGenerator'
import Loading from '../components/Loading/Loading'
import InlineFileUploader from '../components/FileUploader/InlineFileUploader'

//Import actions we need
import { fetchJobOffer } from '../store/actions/jobOffers'

import {
    addedDocument,
    addToResume,
    editProfileItem,
    fetchProfile,
    getResumes,
    saveApplication,
    updateApplication,
    uploadCV,
} from '../store/actions/user'

import Actions from '../components/Actions/Actions'

import { PATH_VACANCIES } from '../constants/Constants'

import AlertBox from '../components/Alert/AlertBox'
import { logoutUser } from '../store/actions/auth'
import { translate, Translate } from 'react-i18nify'
import { getErrorMessages, sanitize } from '../helpers'
import history from '../history'

var he = require('he')

class ApplyJobOffer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            job: null,
            resume_id: null,
            loading: true,
            saving: false,
            sending: false,
            expanded: false,
            content: '',
            errors: [],
            uploading: false,
            reaction_id: -1,
            uploadErrors: [],
            step: 1,
            steps: 2,
            createNewResume: false,
            file: null,
            uploadedFile: null,
            editable: false,
            updateExisting: false,
            editResumeId: null,
            status: 200,
            employer: undefined,
            employer_id: undefined,
            saveTemplate: false,
            resumes: [],
            editCV: false,
            concept: true,
        }
        this.generator = React.createRef()
    }

    componentDidMount() {
        let reaction_id = this.props.reaction_id || -1
        let job_offer = this.props.job_offer
        let content = this.props.content !== null ? this.props.content : ''
        let reaction = this.props.reaction || null
        let resume_id = reaction ? reaction.account_resume_id : this.props.resume_id || null

        // Possible fix for "#STE Web => Profile picture does not load on the /vacature/ page"
        if (
            ('; ' + document.cookie).split('; zg_session=').length == 2 &&
            !LocalStorage.getItem('hasRefreshed')
        ) {
            LocalStorage.setItem('hasRefreshed', '1')
            window.location.reload(true)
        }
        this.setState({
            content: content,
            resume_id: resume_id,
            reaction_id: reaction_id,
            reaction: reaction,
            editable: reaction ? reaction.concept && reaction.vacancy.state : true,
        })

        if (Object.keys(this.props.user.profile).length === 0) {
            this.props.dispatch(fetchProfile(this.props.auth))
        }

        // Fetch job offer details
        fetchJobOffer(
            job_offer,
            this.props.auth,
            (job) => {
                if (
                    reaction_id < 0 &&
                    !reaction &&
                    job.vacancy_reaction &&
                    job.vacancy_reaction.length > 0 &&
                    job.vacancy_reaction[0].id
                ) {
                    let r = job.vacancy_reaction[0]
                    this.setState({
                        job: job,
                        loading: false,
                        reaction: r,
                        reaction_id: r.id,
                        editable: r.concept && job.state,
                        content: r.content,
                        resume_id: r.account_resume_id,
                    })

                    console.log('[ Reaction ]', job.vacancy_reaction[0])
                } else {
                    this.setState({
                        job: job,
                        loading: false,
                    })
                }

                //TODO check if editable
            },
            (status, errors) => {
                if (status === 403) {
                    if (errors.hasOwnProperty('organisation_id')) {
                        this.setState({
                            loading: false,
                            status: status,
                            employer: errors.organisation_name,
                            employer_id: errors.organisation_id,
                        })
                    } else {
                        let msgs = getErrorMessages(errors.errors, {})
                        if (msgs.length <= 0) {
                            msgs = [translate('errors.general')]
                        }

                        this.setState({
                            loading: false,
                            status: 403,
                            errors: msgs,
                        })
                    }
                } else {
                    this.setState({
                        loading: false,
                        status: 404,
                    })
                }
            },
        )

        // Get user's resumes
        getResumes(this.props.auth)
            .then((resp) => {
                if (
                    this.state.reaction &&
                    this.state.reaction.account_resume &&
                    this.state.reaction.account_resume.id
                ) {
                    resp.push({
                        name: translate('resumes.sentResume'),
                        id: this.state.reaction.account_resume.id,
                    })
                }
                console.log('[ Gotten resumes  ]', resp)
                this.setState({
                    resumes: resp,
                })
                if (!resume_id && resp.length > 0) {
                    this.setState({
                        resume_id: resp[0].id,
                        resume_name: resp[0].name,
                    })
                } else if (!resume_id && resp.length <= 0) {
                    this.setState({
                        resume_name: `CV ${this.state.job.name}`,
                    })
                }
            })
            .catch(() => {})
    }

    /**
     * Start saving the application.
     **/
    save(concept = false) {
        this.setState({
            createNewResume: false,
            errors: [],
        })
        let resume = this.state.resume_name
        console.log('[ CHECK SAVE ]', resume, this.state.reaction, this.state.job)
        if (!this.state.reaction) {
            let found = this.state.resumes.find((item) => {
                return item.id === this.state.resume_id
            })

            console.log('[ FOUND RESUME WITHOUT REACTION ]', found)
            if (found) {
                resume = found.name
            } else {
                resume = `CV ${this.state.job.name}`
            }
        }
        if (!resume && (!this.state.reaction || !this.state.reaction.account_resume)) {
            resume = `CV ${this.state.job.name}`
        } else if (this.state.reaction && this.state.reaction.account_resume) {
            resume = this.state.resume_name
                ? this.state.resume_name
                : this.state.reaction.account_resume.name
        }

        console.log('[ New Flow ]', 'Found resume', resume)

        if (concept) {
            this.resumeCreated({
                id: this.state.resume_id,
            })
        } else {
            console.log('[ RESUME CREATION ]', resume, this.state.resume_id, this.state.resumes)
            this.generator.current.apply(resume, this.state.editResumeId).then(
                (resume) => {
                    console.log('[ Job Offer ]', 'Saved Resume', resume)
                    this.resumeCreated(resume)
                },
                ({ status, errors }) => {
                    this.resumeFailed({ status, errors })
                },
            )
        }

        if (this.state.saveTemplate) {
            this.generator.current.apply(resume, null, false)
        }
        this.setState({
            loading: true,
        })
    }

    resumeCreated(res) {
        console.log('[ New Flow ]', 'Resume created', res)
        this.setState({
            resume_id: res.id,
            old_resume_id: this.state.resume_id,
        })

        this.uploadCV(res)
    }

    resumeFailed({ status, errors }) {
        console.warn('[ ApplyJobOffer ]', 'Error occurred. Catch promise reject.', status, errors)
        if (status === 401) {
            this.props.dispatch(logoutUser(this.props.auth, null))
        } else if (errors) {
            let msgs = getErrorMessages(errors, {})
            if (msgs.length > 0) {
                this.failure(msgs)
            } else {
                this.failure([translate('errors.general')])
            }
        } else {
            this.failure([translate('errors.general')])
        }
    }

    /**
     * If a new CV is selected. Upload the CV. Otherwise, save the application
     **/
    uploadCV(resume) {
        let file = this.state.file

        console.log('[ New Flow ]', 'Start file upload', resume)
        if (file) {
            uploadCV(this.props.auth, file).then(
                (file) => {
                    this.props.dispatch(addedDocument(file))

                    console.log('[ New Flow ]', 'Uploaded file', file)

                    this.setState({
                        uploadedFile: file,
                    })

                    this.addCvToResume(file, resume)
                },
                ({ status, errors }) => {
                    console.warn('[ New Flow ]', 'Error uploading file', errors)
                    if (status === 401) {
                        this.props.dispatch(logoutUser(this.props.auth))
                    } else {
                        let msgs = getErrorMessages(errors.errors, {})
                        if (msgs.length > 0) {
                            this.failure(msgs)
                        } else {
                            this.failure([translate('errors.general')])
                        }
                    }
                },
            )
        } else {
            this.sendApplication(resume)
        }
    }

    addCvToResume(file, resume) {
        console.log('[ Add to CV ]', file)
        addToResume(this.props.auth, resume.id, 'files', file.id).then(
            (resp) => {
                this.sendApplication(resume)
            },
            ({ status, errors }) => {
                if (status === 401) {
                    this.props.dispatch(logoutUser(this.props.auth))
                } else {
                    let msgs = getErrorMessages(errors.errors, {})
                    if (msgs.length > 0) {
                        this.failure(msgs)
                    } else {
                        this.failure([translate('errors.general')])
                    }
                }
            },
        )
    }

    sendApplication(res) {
        let obj = {
            concept: this.state.concept,
        }
        //Set the content if exists
        let content = this.state.content
        if (content && content !== null && content !== '' && content.length > 0) {
            obj.content = content
        }

        if (res) {
            obj.account_resume_id = res.id
        }

        obj.account_id = this.props.auth.id

        if (this.state.uploadedFile) {
            obj.account_file_id = this.state.uploadedFile.id
        }

        console.log('[ New Flow ]', 'Save resume', obj)

        if (!this.state.updateExisting) {
            this.props.dispatch(
                saveApplication(
                    this.state.job.id,
                    obj,
                    this.props.auth,
                    (application) => {
                        //Saved successful
                        this.failure()

                        console.log('[ New Flow ]', 'Saved application.')
                        this.props.onClose(!obj.concept, obj.concept)
                    },
                    (status, resp) => {
                        console.warn(
                            '[ New Flow ]',
                            'Error occurred saving application. ',
                            status,
                            resp,
                        )
                        this.failure()

                        // if (this.state.resume_id) {
                        //     deleteResume(obj.account_resume_id, this.props.auth);
                        // }

                        let msgs = getErrorMessages(resp.errors, {})
                        if (msgs.length > 0) {
                            this.setState({
                                errors: msgs,
                            })
                        } else {
                            this.setState({ errors: [translate('errors.general')] })
                        }
                    },
                ),
            )
        } else if (this.state.reaction_id) {
            console.log('[ New Flow ]', 'Update Existing application', this.state.reaction_id)
            this.props.dispatch(
                updateApplication(
                    this.state.reaction_id,
                    this.state.job.id,
                    obj,
                    this.props.auth,
                    (application) => {
                        //Saved successful
                        this.failure()
                        console.log('[ New Flow ]', 'Updated application.')
                        this.props.onClose(false, true)
                    },
                    (status, resp) => {
                        console.warn(
                            '[ New Flow ]',
                            'Error occurred updating application. ',
                            status,
                            resp,
                        )
                        this.failure()

                        let msgs = getErrorMessages(resp.errors, {})
                        if (msgs.length > 0) {
                            this.setState({
                                errors: msgs,
                            })
                        } else {
                            this.setState({ errors: [translate('errors.general')] })
                        }
                    },
                ),
            )
        }
    }

    concept() {
        const concept = !this.state.updateExisting
        this.setState(
            {
                saving: concept ? true : this.state.saving,
                sending: !concept ? true : this.state.sending,
                concept: concept,
            },
            () => {
                this.save(concept)
            },
        )
    }

    send() {
        this.setState(
            {
                sending: true,
                concept: false,
            },
            () => {
                this.save(false)
            },
        )
    }

    failure(msgs = null) {
        if (msgs) {
            this.setState({
                errors: msgs,
                saving: false,
                sending: false,
                loading: false,
                createNewResume: false,
            })
        } else {
            this.setState({
                saving: false,
                sending: false,
                loading: false,
                createNewResume: false,
            })
        }
    }

    selectChanged(evt) {
        let id = evt.target.value
        let found = (this.state.resumes || []).find((item) => {
            return Number(item.id) === Number(id)
        })

        console.log('[ RESUME ]', found ? found.name : `CV ${this.state.job.name}`)
        this.setState({
            resume_id: id,
            resume_name: found ? found.name : `CV ${this.state.job.name}`,
        })
    }

    textareaChanged(evt) {
        this.setState({
            content: evt.target.value,
        })
    }

    getResumeName() {
        let found = (this.state.resumes || []).find((item) => {
            return item.id == this.state.reaction.account_resume_id
        })

        console.log('[ Resume Name ]', found, this.state.reaction.account_resume)
        if (found) {
            return found.name
        } else if (this.state.reaction.account_resume) {
            return this.state.reaction.account_resume.name
        }
    }

    toggleDescription() {
        this.setState({
            expanded: !this.state.expanded,
        })
    }

    isEditable() {
        return this.state.editable
    }

    render() {
        if (this.state.loading) {
            return <Loading />
        } else if (this.state.status === 403) {
            let org = this.props.user.profile.organisation_id
            let hasOrg = org && org > 0
            if (this.state.employer_id) {
                return (
                    <Container>
                        <div className="text-center">
                            <h1>
                                {translate('headers.vacancy_internal')}
                                <br />
                            </h1>

                            <p>
                                <strong>
                                    <span>
                                        {translate('texts.wrong_employer', {
                                            employer: this.state.employer,
                                        })}
                                    </span>
                                    {!hasOrg && (
                                        <span>
                                            {' '}
                                            {translate('texts.wrong_employer_work_at', {
                                                employer: this.state.employer,
                                            })}
                                        </span>
                                    )}
                                </strong>
                            </p>
                            {!hasOrg && (
                                <Button
                                    buttonText={translate('buttons.set_employer', {
                                        employer: this.state.employer,
                                    })}
                                    className="button--cta inline-block"
                                    onClick={() => {
                                        this.setState(
                                            {
                                                loading: true,
                                                status: 200,
                                            },
                                            () => {
                                                let update = {
                                                    organisation_id: this.state.employer_id,
                                                }

                                                this.props.dispatch(
                                                    editProfileItem(
                                                        'general',
                                                        update,
                                                        this.props.auth,
                                                        () => {
                                                            window.location.reload()
                                                        },
                                                        (status, errors) => {
                                                            window.location.reload()
                                                        },
                                                    ),
                                                )
                                            },
                                        )
                                    }}
                                />
                            )}
                        </div>
                    </Container>
                )
            } else {
                return (
                    <Container>
                        <div className="text-center">
                            <h1>
                                {translate('headers.vacancy_not_available')}
                                <br />
                            </h1>

                            <strong>
                                {this.state.errors &&
                                    this.state.errors.map((item) => {
                                        return <div>{item}</div>
                                    })}
                            </strong>
                        </div>
                    </Container>
                )
            }
        } else if (this.state.status === 404 || this.state.status === 400) {
            return (
                <div className="text-center">
                    <h1>
                        <Translate value="headers.vacancy_not_found" />
                    </h1>

                    <img className={'form__image img-smiley-404'} width="136" />

                    <p>
                        <strong>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: translate('texts.vacancy_not_found'),
                                }}
                            />
                        </strong>
                    </p>

                    <Button
                        buttonText={translate('buttons.view_vacancies')}
                        className="button--cta inline-block"
                        onClick={() => {
                            history.push('/' + PATH_VACANCIES)
                        }}
                    />
                </div>
            )
        }

        const { config } = this.props
        const { hours_from = 0, hours_till = 0, job_working_time = null } = this.state.job

        // vota has timefactor while other whitelabels have time in hours
        const calculatedHoursFrom =
            config.whitelabel.name === '--vota' ? +hours_from * 40 : +hours_from
        const calculatedHoursTill =
            config.whitelabel.name === '--vota' ? +hours_till * 40 : +hours_till

        return (
            <div className="react-native-web apply-job-offer">
                {!this.state.loading && this.state.step === 1 && this.state.status === 200 && (
                    <div>
                        <Row>
                            <Col xs={12}>
                                <div className="modal__step text-center">Stap 1/2</div>
                                <h2 className="text-center">
                                    <span>
                                        {this.state.job !== null ? this.state.job.name : 'Vacature'}
                                    </span>
                                    <br />
                                    {this.state.job.organisation.name}
                                </h2>
                            </Col>
                        </Row>

                        {this.state.errors.length > 0 && (
                            <AlertBox messages={this.state.errors} type="error" />
                        )}

                        <div className="modal--vacancy__table">
                            <Row>
                                <Col xs={12} sm={6}>
                                    {this.state.job.job_working_area
                                        ? this.state.job.job_working_area.name && (
                                              <p>
                                                  <strong>
                                                      {translate('profile.experience_area')}
                                                  </strong>
                                                  <br />
                                                  {he.decode(this.state.job.job_working_area.name)}
                                              </p>
                                          )
                                        : this.state.job.industry && (
                                              <p>
                                                  <strong>
                                                      {translate('profile.experience_area')}
                                                  </strong>
                                                  <br />
                                                  {he.decode(this.state.job.industry.name)}
                                              </p>
                                          )}
                                    {this.state.job.study_level && this.state.job.study_level.name && (
                                        <p>
                                            <strong>{translate('vacancies.required_study')}</strong>
                                            <br />
                                            {he.decode(this.state.job.study_level.name)}
                                        </p>
                                    )}
                                    {this.state.job.start_date_text !== undefined &&
                                        this.state.job.start_date_text !== null && (
                                            <p>
                                                <strong>
                                                    {translate('vacancies.availability')}
                                                </strong>
                                                <br />
                                                {he.decode(this.state.job.start_date_text)}
                                            </p>
                                        )}
                                    {this.state.job.visible_internal &&
                                        this.state.job.organisation_id ===
                                            this.props.user.profile.organisation_id && (
                                            <p>
                                                <strong>Soort vacature</strong>
                                                <br />
                                                {translate('vacancies.internal')}
                                            </p>
                                        )}
                                </Col>
                                <Col xs={12} sm={6}>
                                    {job_working_time
                                        ? job_working_time.name && (
                                              <p>
                                                  <strong>
                                                      {translate('vacancies.working_time')}
                                                  </strong>
                                                  <br />
                                                  {he.decode(job_working_time.name)}
                                              </p>
                                          )
                                        : calculatedHoursTill > 0 && (
                                              <p>
                                                  <strong>
                                                      {translate('vacancies.working_time')}
                                                  </strong>
                                                  <br />
                                                  {Math.round(calculatedHoursFrom)} -{' '}
                                                  {Math.round(calculatedHoursTill)}
                                                  {translate('vacancies.hours_per_week')}
                                              </p>
                                          )}
                                    {!job_working_time &&
                                        calculatedHoursTill === 0 &&
                                        calculatedHoursFrom === 0 && (
                                            <p>
                                                <strong>
                                                    {translate('vacancies.working_time')}
                                                </strong>
                                                <br />
                                                {translate('vacancies.unknown')}
                                            </p>
                                        )}
                                    {this.state.job.organisation_location &&
                                        this.state.job.organisation_location.name && (
                                            <p>
                                                <strong>{translate('vacancies.location')}</strong>
                                                <br />
                                                {he.decode(
                                                    this.state.job.organisation_location.name,
                                                )}
                                            </p>
                                        )}
                                    {this.state.job.contact_person !== null && (
                                        <p>
                                            <strong>{translate('vacancies.contact')}</strong>
                                            <br />
                                            {he.decode(this.state.job.contact_person)}
                                        </p>
                                    )}
                                </Col>
                            </Row>
                        </div>

                        {!this.state.expanded && (
                            <button
                                className="button--expand"
                                onClick={() => this.toggleDescription()}
                            >
                                {translate('vacancies.expand_job_description')}{' '}
                                <i className="icons icon-arrow-down" />
                            </button>
                        )}
                        {this.state.expanded && (
                            <button
                                className="button--expand"
                                onClick={() => this.toggleDescription()}
                            >
                                {translate('vacancies.hide_job_description')}{' '}
                                <i className="icons icon-arrow-up" />
                            </button>
                        )}

                        {this.state.expanded && (
                            <div className="modal--vacancy__expanded_block">
                                <base target="_blank" />
                                <Row>
                                    <Col xs={12}>
                                        <div
                                            className="modal--vacancy__text"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    '<i>' +
                                                    he.decode(
                                                        sanitize(
                                                            this.state.job.content.replace(
                                                                /h1/g,
                                                                'h3',
                                                            ),
                                                        ),
                                                    ) +
                                                    '</i>',
                                            }}
                                        />
                                    </Col>
                                </Row>

                                {this.state.job.requirements && (
                                    <Row>
                                        <Col xs={12}>
                                            <div className="modal--vacancy__text">
                                                <h3>
                                                    {this.state.job.requirements_title ||
                                                        translate('vacancies.fit_for_you')}
                                                </h3>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            '<i>' +
                                                            he.decode(
                                                                sanitize(
                                                                    this.state.job.requirements,
                                                                ),
                                                            ) +
                                                            '</i>',
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                {this.state.job.classification && (
                                    <Row>
                                        <Col xs={12}>
                                            <div className="modal--vacancy__text">
                                                <h3>
                                                    {this.state.job.classification_title ||
                                                        translate('vacancies.salary')}
                                                </h3>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            '<i>' +
                                                            he.decode(
                                                                sanitize(
                                                                    this.state.job.classification,
                                                                ),
                                                            ) +
                                                            '</i>',
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                )}

                                {this.state.job.remarks && (
                                    <Row>
                                        <Col xs={12}>
                                            <div className="modal--vacancy__text">
                                                <h3>
                                                    {this.state.job.remarks_title ||
                                                        translate('vacancies.remarks')}
                                                </h3>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            '<i>' +
                                                            he.decode(
                                                                sanitize(this.state.job.remarks),
                                                            ) +
                                                            '</i>',
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </div>
                        )}

                        {this.state.job.comments !== null && (
                            <Row>
                                <Col xs={12}>
                                    <p>
                                        <i>{sanitize(this.state.job.comments)}</i>
                                    </p>
                                </Col>
                            </Row>
                        )}

                        {(this.props.showMotivation === undefined || this.props.showMotivation) && (
                            <div className="modal--vacancy__form">
                                {this.isEditable() && (
                                    <div>
                                        <Row>
                                            <Col xs={12}>
                                                <h3>{translate('vacancies.motivation')}</h3>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <form className="form">
                                                    <fieldset className="form__fieldset--single">
                                                        <div className="form__item">
                                                            <i className="form__icon icons icon-pencil" />
                                                            <textarea
                                                                onInput={(evt) =>
                                                                    this.textareaChanged(evt)
                                                                }
                                                                className={
                                                                    'form__input--textarea--dynamic ' +
                                                                    (this.state.content
                                                                        ? ' has-value'
                                                                        : '')
                                                                }
                                                                type="text"
                                                                id="quote"
                                                                value={this.state.content}
                                                                tabIndex="1"
                                                            />
                                                            <label
                                                                className="form__label"
                                                                htmlFor="quote"
                                                            >
                                                                <Translate value="vacancies.placeholder_enter_motivation" />
                                                            </label>
                                                        </div>
                                                    </fieldset>
                                                </form>
                                            </Col>
                                        </Row>
                                    </div>
                                )}

                                {!this.isEditable() &&
                                    this.state.reaction &&
                                    this.state.reaction.content !== null &&
                                    this.state.reaction.content.length > 0 && (
                                        <Row>
                                            <Col xs={12}>
                                                <h3>{translate('vacancies.motivation')}</h3>
                                                <div
                                                    className="motivation"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            '<i>' +
                                                            (
                                                                this.state.reaction.content || ''
                                                            ).replace(/\n/g, '<br/>') +
                                                            '</i>',
                                                    }}
                                                />
                                                <Actions>
                                                    <Link
                                                        className={
                                                            'actions__action pull-left green ' +
                                                            (this.state.saving ? ' is-loading' : '')
                                                        }
                                                        onClick={() => {
                                                            this.setState({
                                                                editable: true,
                                                                updateExisting: true,
                                                                editCV: false,
                                                                step: 1,
                                                                editResumeId: this.state.reaction
                                                                    ? this.state.reaction
                                                                          .account_resume_id
                                                                    : null,
                                                            })
                                                        }}
                                                        tabIndex="6"
                                                    >
                                                        {translate('buttons.edit')}
                                                    </Link>
                                                </Actions>
                                            </Col>
                                        </Row>
                                    )}
                            </div>
                        )}

                        {this.state.reaction && this.state.reaction.account_file && (
                            <Row>
                                <Col xs={12} sm={6}>
                                    <h3>{translate('vacancies.sent_cv_file')}</h3>
                                    <div>{this.state.reaction.account_file.name}</div>
                                </Col>
                            </Row>
                        )}

                        {this.state.reaction && this.state.reaction.account_resume_id && (
                            <div>
                                <h3>
                                    <Translate value="vacancies.sent_cv" />
                                </h3>
                                <Row>
                                    <Col xs={12}>
                                        <span className="modal--vacancy__uploaded_file">
                                            {this.getResumeName()
                                                ? this.getResumeName()
                                                : '\u00a0 - \u00a0'}
                                        </span>
                                        <Button
                                            style={{ display: 'none' }}
                                            className="button--cta inline-block--small"
                                            buttonText={translate('vacancies.edit_cv')}
                                            onClick={() => {
                                                //Make editable.
                                                this.setState({
                                                    editable: true,
                                                    updateExisting: true,
                                                    editCV: true,
                                                    step: 2,
                                                    editResumeId: this.state.reaction
                                                        ? this.state.reaction.account_resume_id
                                                        : null,
                                                })
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        )}

                        {(this.props.showActions === undefined || this.props.showActions) && (
                            <div>
                                <Actions>
                                    <Row>
                                        <Col xs={12}>
                                            {this.props.cancelable && (
                                                <Link
                                                    className="actions__action"
                                                    onClick={() => {
                                                        if (!this.state.updateExisting) {
                                                            this.props.onClose()
                                                        } else {
                                                            this.setState({
                                                                editable: false,
                                                                updateExisting: false,
                                                            })
                                                        }
                                                    }}
                                                    tabIndex="5"
                                                >
                                                    {translate('buttons.cancel')}
                                                </Link>
                                            )}

                                            <Link
                                                className={
                                                    'actions__action pull-right ' +
                                                    (this.state.saving ? ' is-loading' : '') +
                                                    (this.state.content ? ' green' : 'disabled')
                                                }
                                                onClick={() => {
                                                    if (this.state.content) {
                                                        this.setState({
                                                            step: 2,
                                                        })
                                                    }
                                                }}
                                                tabIndex="6"
                                            >
                                                {translate('buttons.next')}
                                            </Link>

                                            {this.isEditable() && this.state.content && (
                                                <Link
                                                    className={
                                                        'actions__action--between pull-right' +
                                                        (this.state.saving ? ' is-loading' : '')
                                                    }
                                                    onClick={() => {
                                                        this.concept()
                                                    }}
                                                    tabIndex="7"
                                                >
                                                    {translate('buttons.save')}
                                                </Link>
                                            )}
                                        </Col>
                                    </Row>
                                </Actions>
                            </div>
                        )}
                    </div>
                )}

                <div
                    style={{
                        display: this.state.loading || this.state.step !== 2 ? 'none' : 'block',
                    }}
                >
                    <div>
                        {this.isEditable() && (
                            <div>
                                <div className="text-center">
                                    <div className="modal__step">
                                        {translate('vacancies.apply_step')} 2/2
                                    </div>
                                    <h2>
                                        <Translate value="vacancies.select_resume" />
                                    </h2>
                                    <p>
                                        <Translate value="vacancies.select_shared_fields" />
                                    </p>
                                </div>

                                {this.state.errors.length > 0 && (
                                    <AlertBox messages={this.state.errors} type="error" />
                                )}

                                {this.state.resumes !== null &&
                                    this.state.resumes !== undefined &&
                                    this.state.resumes.length > 0 && (
                                        <div className="text-center">
                                            <p>
                                                <strong>
                                                    <Translate value="vacancies.choose_template" />
                                                </strong>
                                            </p>
                                            <div className="select--solo">
                                                <i className="select__icon_down icons icon-arrow-down" />
                                                <select
                                                    value={this.state.resume_id || ''}
                                                    onChange={(evt) => this.selectChanged(evt)}
                                                    tabIndex="2"
                                                >
                                                    {this.state.resumes
                                                        .slice(0)
                                                        .reverse()
                                                        .map(function (el, index) {
                                                            return (
                                                                <option
                                                                    key={'resume-' + index}
                                                                    value={el.id}
                                                                >
                                                                    {el.name}
                                                                </option>
                                                            )
                                                        })}
                                                </select>
                                            </div>
                                        </div>
                                    )}

                                <div className="spacer" />
                            </div>
                        )}
                    </div>

                    <div>
                        <ResumeGenerator
                            ref={this.generator}
                            dispatch={this.props.dispatch}
                            showActions={false}
                            id={this.state.resume_id > 0 ? this.state.resume_id : undefined}
                            intro={translate('vacancies.resume_intro')}
                            canEdit={false}
                            onClose={(json) => {}}
                            expandedCategory={this.state.uploadedFile ? 'files' : null}
                            created={(res) => {
                                this.resumeCreated(res)
                            }}
                            failed={(err) => {
                                this.resumeFailed(err)
                            }}
                        />

                        <h3>
                            <Translate value="vacancies.saveTemplate" />
                        </h3>

                        <p>{translate('vacancies.saveText')}</p>

                        <div className={'form'}>
                            <div className="form__item--checkbox">
                                <div className="form__checkbox form__gc">
                                    <input
                                        type="checkbox"
                                        id="saveTemplate"
                                        className={'form__checkbox__input'}
                                        name="saveTemplate"
                                        value={this.state.saveTemplate}
                                        onChange={(e) => {
                                            this.setState({ saveTemplate: e.target.checked })
                                        }}
                                    />
                                    <label
                                        className={'form__checkbox__label'}
                                        htmlFor="saveTemplate"
                                    >
                                        {translate('vacancies.checkboxSave')}
                                    </label>
                                </div>
                            </div>
                        </div>

                        <h3>
                            <Translate value="vacancies.upload_resume" />
                        </h3>

                        {!this.state.uploadedFile && (
                            <InlineFileUploader
                                auth={this.props.auth}
                                onFileSelected={(file) => {
                                    this.setState({
                                        file: file,
                                    })
                                }}
                                type="cv"
                                dispatch={this.props.dispatch}
                                fileSize={65536}
                                accept={'.pdf,.doc,.docx,.png,.jpg,.jpeg,.bmp'}
                            />
                        )}

                        {this.state.uploadedFile && (
                            <div>{translate('vacancies.file_uploaded_and_attached')}</div>
                        )}
                    </div>

                    <p className="text-muted">
                        <br />
                        <p
                            dangerouslySetInnerHTML={{
                                __html: translate('vacancies.apply_explanation'),
                            }}
                        />
                    </p>

                    {(this.props.showActions === undefined || this.props.showActions) && (
                        <Row>
                            <Actions>
                                <Col xs={12}>
                                    <Link
                                        className="actions__action"
                                        onClick={() => {
                                            this.setState({
                                                step: 1,
                                            })
                                        }}
                                        tabIndex="5"
                                    >
                                        {translate('buttons.previous')}
                                    </Link>

                                    {this.isEditable() && !this.state.updateExisting && (
                                        <Link
                                            className={
                                                'actions__action pull-right green' +
                                                (this.state.sending ? ' is-loading' : '')
                                            }
                                            onClick={() => {
                                                this.send()
                                            }}
                                            tabIndex="6"
                                        >
                                            <span>
                                                {translate('vacancies.button_send_application')}
                                            </span>
                                        </Link>
                                    )}

                                    {this.isEditable() && this.state.updateExisting && (
                                        <Link
                                            className={
                                                'actions__action pull-right green' +
                                                (this.state.saving ? ' is-loading' : '')
                                            }
                                            onClick={() => {
                                                this.concept()
                                            }}
                                            tabIndex="4"
                                        >
                                            {translate('buttons.save')}
                                        </Link>
                                    )}
                                </Col>
                            </Actions>
                        </Row>
                    )}
                </div>
            </div>
        )
    }
}

ApplyJobOffer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    job_offer: PropTypes.number,
    content: PropTypes.string,
    resume_id: PropTypes.number,
    reaction_id: PropTypes.number,
    reaction: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    editable: PropTypes.bool,
    cancelable: PropTypes.bool,
    showMotivation: PropTypes.bool,
}

ApplyJobOffer.defaultProps = {
    editable: true,
    cancelable: true,
}

function mapStateToProps(state) {
    const { auth, user, config } = state
    const { documents } = user
    return {
        auth,
        documents,
        user,
        config,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(ApplyJobOffer)

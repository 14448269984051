import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { addClass, removeClass } from '../helpers'

import Actions from '../components/Actions/Actions'
import Alert from '../components/Alert/Alert'
import Loading from '../components/Loading/Loading'
import CheckboxList from '../components/CheckboxList/CheckboxList'

import { addResume, editResume, getResume } from '../store/actions/user'
import { translate, Translate } from 'react-i18nify'
import { TESTABLE_TYPE_EELLOO } from '../constants/Constants'

class ResumeGenerator extends Component {
    constructor(props) {
        super(props)
        this.state = { loading: true, saving: false, content: '' }

        this.state = {
            confirmCardsRedirect: false,
            accountFields: [],
            saving: false,
            uploadedDocuments: [],
            errors: [],
            personal: false,
            checked: [],
            checkedItems: [],
            save_resume: false,
            resume_name: '',
            edit: false,
            fields: [],
            itemsLoaded: false,
            canSave: true,
            loadingElements: false,
            eellooTests: [],
        }

        this.state.checkedItems = {
            personal: [],
            job_functions: [],
            studies: [],
            courses: [],
            references: [],
            competences: [],
            job_interests: [],
            languages: [],
            interests: [],
            files: [],
            account_fields: [],
            tests: [],
            school_subjects: [],
        }
    }

    componentDidMount() {
        this.load()
        this.setState({ accountFields: this.constructAccountFields() })
    }

    constructAccountFields() {
        let accountFields = []
        this.props.user.profile.account_fields.map((field) => {
            if (field && field.value) {
                accountFields.push({
                    id: field.id,
                    name:
                        field.name +
                        ': ' +
                        (field.value.toString() === 'true'
                            ? '✓'
                            : field.value.toString() === 'false'
                            ? '✕'
                            : field.value),
                })
            }
        })
        console.log('accountFields', accountFields)
        return accountFields
    }

    load() {
        console.log('[ ResumeGenerator ]', 'Load', this.props.id)
        this.setState(
            {
                loadingElements: true,
                checkedItems: {
                    personal: [],
                    job_functions: [],
                    studies: [],
                    courses: [],
                    references: [],
                    competences: [],
                    job_interests: [],
                    languages: [],
                    interests: [],
                    files: [],
                    account_fields: [],
                    tests: [],
                    school_subjects: [],
                },
            },
            () => {
                let json = this.props.user.profile.files

                if (!this.props.user.file_upload_types.allowed_document_types) {
                    json = []
                }

                json.map((file) => {
                    this.props.user.file_upload_types.allowed_document_types.map((doc_type) => {
                        if (doc_type.code !== file.type) {
                            file.name = doc_type.name + ': ' + file.name
                        }
                    })
                })

                if (this.props.id !== undefined) {
                    this.setState(
                        {
                            edit: true,
                            fetching: false,
                            uploadedDocuments: json,
                        },
                        () => {
                            getResume(this.props.id, this.props.auth).then(
                                (resp) => {
                                    resp.resume_elements.map((item, index) => {
                                        this.state.checkedItems[item.table].push(item.element_id)
                                    }, this)

                                    this.setState({
                                        loadingElements: false,
                                        resume_name: resp.name,
                                    })
                                },
                                (status, errors) => {
                                    if (status === 404) {
                                        this.setState({
                                            fetching: false,
                                            uploadedDocuments: json,
                                            loadingElements: false,
                                        })
                                    }
                                },
                            )
                        },
                    )
                } else {
                    this.setState({
                        fetching: false,
                        uploadedDocuments: json,
                        loadingElements: false,
                    })
                }
                // eelloo tests
                let eellooTests = this.props.user.profile.tests.filter(
                    (t) =>
                        t.status === 1 &&
                        t.testable_type === TESTABLE_TYPE_EELLOO &&
                        t.testable.reports.length > 0,
                )
                eellooTests.forEach((e) => {
                    const name = e.testable.service
                        ? e.testable.service.name
                        : e.testable.template.name
                    const date = e.created_at ? e.created_at.split(' ')[0] : ''
                    e.name = date ? `${name} (${date})` : name
                })
                this.setState({ eellooTests: eellooTests })
            },
        )
    }

    updateState(category, checked) {
        let checkedItems = this.state.checkedItems
        let text = category.replace(/['"]+/g, '')
        checkedItems[text] = checked
        this.setState({
            checkedItems: checkedItems,
        })
    }

    saveModal() {
        this.setState({
            save_resume: true,
        })
    }

    getSaveClasses() {
        let c = 'actions__action--save'

        if (this.state.saving) {
            c += ' is-loading'
        }

        return c
    }

    save() {
        if (!this.state.resume_name) {
            if ((this.state.errors || []).indexOf(translate('errors.empty_field')) === -1)
                this.setState({ errors: [translate('errors.empty_field')] })
            return
        } else if (this.state.resume_name.trim().length == 0) {
            if ((this.state.errors || []).indexOf(translate('errors.empty_field')) === -1)
                this.setState({ errors: [translate('errors.empty_field')] })
            return
        } else {
            let errors = this.state.errors || []
            let index = errors.indexOf(translate('errors.empty_field'))
            if (index > -1) {
                errors.splice(index, 1)
            }
            this.setState({ errors })
        }

        this.setState({
            saving: true,
            canSave: false,
        })

        let resume_el = []
        Object.entries(this.state.checkedItems).forEach(function (item) {
            if (item[1].length > 0) {
                if (item[0] != 'personal') {
                    item[1].forEach(function (el) {
                        resume_el.push({
                            table: item[0],
                            element_id: el,
                        })
                    })
                }
            }
        })

        let obj = {
            account_id: this.props.auth.id,
            name: this.state.resume_name,
            resume_elements: resume_el,
        }

        if (!this.state.edit) {
            addResume(this.props.auth, obj).then(
                (json) => {
                    this.setState({
                        checked: [],
                        checkedItems: [],
                        save_resume: false,
                        resume_name: '',
                        saving: false,
                    })

                    this.props.onClose(json)
                },
                (status, errors) => {
                    console.log(status, errors)
                },
            )
        } else {
            editResume(this.props.id, this.props.auth, obj).then(
                () => {
                    this.setState({
                        checked: [],
                        checkedItems: [],
                        save_resume: false,
                        resume_name: '',
                        saving: false,
                    })

                    this.props.onClose()
                },
                ({ status, errors }) => {
                    console.log(status, errors)
                },
            )
        }
    }

    getClassNames(item) {
        let c = 'form__input--text'

        if (item != null && item != '' && item.length > 0) {
            c += ' has-value'
        }
        return c
    }

    handleChange(value, key, changeClass = true) {
        let obj = {}
        obj[key] = value.target.value

        this.setState(obj)

        if (changeClass) {
            if (value.target.value.length > 0) {
                addClass(value.currentTarget, 'has-value')
            } else {
                removeClass(value.currentTarget, 'has-value')
            }
        }
    }

    saveName(value, key, changeClass = true) {
        this.setState({
            resume_name: value.target.value,
        })
        this.handleChange(value, key, changeClass)
    }

    cancel() {
        this.props.onClose()
    }

    apply(name, resume_id = null, hidden = true) {
        console.log('[ ResumeGenerator ]', 'Start apply call.', name, resume_id)
        let resume_el = []
        Object.entries(this.state.checkedItems).forEach(function (item) {
            if (item[1].length > 0) {
                if (item[0] != 'personal') {
                    item[1].forEach(function (el) {
                        resume_el.push({
                            table: item[0],
                            element_id: el,
                        })
                    })
                }
            }
        })

        let obj = {
            account_id: this.props.auth.id,
            name: name || this.state.resume_name,
            resume_elements: resume_el,
            hidden: hidden,
        }

        console.log('[ ResumeGenerator ]', 'Start api call for resume creation')
        return new Promise((resolve, reject) => {
            if (!resume_id) {
                addResume(this.props.auth, obj).then(
                    (json) => {
                        console.log('[ ResumeGenerator ]', 'Resume created', json)
                        resolve(json)
                    },
                    ({ status, errors }) => {
                        console.log(
                            '[ ResumeGenerator ]',
                            'Could not create resume',
                            obj,
                            status,
                            errors,
                        )
                        reject({ status, errors })
                    },
                )
            } else {
                editResume(resume_id, this.props.auth, obj).then(
                    (json) => {
                        console.log('[ ResumeGenerator ]', 'Edited existing resume', json)
                        resolve(json)
                    },
                    ({ status, errors }) => {
                        reject({ status, errors })
                    },
                )
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.id !== this.props.id) {
            this.load()
        }

        if (!prevProps.createNewResume && this.props.createNewResume) {
            this.apply(this.props.createName, this.props.createId).then(
                (res) => {
                    this.props.created(res)
                },
                ({ status, err }) => {
                    this.props.failed({ status, err })
                },
            )
        }
    }

    render() {
        const { dispatch, auth, user } = this.props
        let profile = user.profile

        let fieldValues = this.props.user.profile.account_fields
        let fieldElements = {}
        let isEmpty = true
        if (fieldValues) {
            for (let i = 0; i < fieldValues.length; i++) {
                fieldElements[fieldValues[i].code] = fieldValues[i].value
            }
        }
        ;(this.props.user.account_field_names || []).map(function (item) {
            if (fieldElements[item.code]) isEmpty = false
        })

        return (
            <div style={this.props.style}>
                {this.state.loadingElements && (
                    <div>
                        <Loading />
                    </div>
                )}

                {!this.state.save_resume && !this.state.loadingElements && (
                    <div className="form">
                        {this.state.errors && this.state.errors.length > 0 && (
                            <fieldset className="form__fieldset">
                                <Alert type="alert--error alert--simple">
                                    <ul>
                                        {this.state.errors.map(function (value) {
                                            return <li key={value}>{value}</li>
                                        })}
                                    </ul>
                                </Alert>
                            </fieldset>
                        )}

                        <fieldset className="form__fieldset">
                            {this.props.editableName && (
                                <div className="form__item">
                                    <input
                                        tabIndex="1"
                                        className={this.getClassNames(
                                            this.state.resume_name,
                                            'resume_name',
                                        )}
                                        type="text"
                                        id="name"
                                        value={this.state.resume_name}
                                        onChange={(value) => this.saveName(value)}
                                        autoFocus
                                    />
                                    <label className="form__label" htmlFor="name">
                                        <Translate value="resumes.name" />
                                    </label>
                                </div>
                            )}
                        </fieldset>

                        {/*<p className="modal__intro">{this.props.intro ? this.props.intro : translate('resumes.create_intro')}</p>*/}
                        <ul className="checkbox_list">
                            <CheckboxList
                                itemName="personal"
                                title={translate('profile.personal_data')}
                                itemText=""
                                itemSubText=""
                                arr={profile}
                                edit={this.state.edit}
                                defChecked={true}
                                checked={this.state.checkedItems.personal}
                                onCheckChange={(checked) => {
                                    // The component generates an array of all checked items within this category,
                                    // Use this array to replace the current state of checkedItems.studies by the new array
                                    this.updateState('personal', checked)
                                }}
                                onToggleAll={(checked) => {
                                    this.updateState('personal', checked)
                                }}
                                // Only needed if defChecked is used above
                                onAutoCheck={(checked) => {
                                    this.updateState('personal', checked)
                                }}
                            />

                            <CheckboxList
                                itemName="job_functions"
                                title={translate('profile.experience')}
                                itemText="function_title"
                                itemSubText="company_name"
                                arr={profile.job_functions || []}
                                edit={this.state.edit}
                                checked={this.state.checkedItems.job_functions}
                                onCheckChange={(checked) => {
                                    this.updateState('job_functions', checked)
                                }}
                                onToggleAll={(checked) => {
                                    this.updateState('job_functions', checked)
                                }}
                            />

                            <CheckboxList
                                itemName="education"
                                title={translate('profile.education')}
                                itemText="study_id"
                                itemSubText="institute_name"
                                arr={profile.studies || []}
                                edit={this.state.edit}
                                checked={this.state.checkedItems.studies}
                                onCheckChange={(checked) => {
                                    this.updateState('studies', checked)
                                }}
                                onToggleAll={(checked) => {
                                    this.updateState('studies', checked)
                                }}
                                studies={this.props.user.studies}
                            />

                            <CheckboxList
                                itemName="courses"
                                title={translate('profile.courses')}
                                itemText="name"
                                itemSubText="achieved"
                                arr={profile.courses || []}
                                edit={this.state.edit}
                                checked={this.state.checkedItems.courses}
                                onCheckChange={(checked) => {
                                    this.updateState('courses', checked)
                                }}
                                onToggleAll={(checked) => {
                                    this.updateState('courses', checked)
                                }}
                            />

                            <CheckboxList
                                itemName="references"
                                title={translate('profile.references')}
                                itemText="name"
                                itemSubText="company"
                                arr={profile.references || []}
                                edit={this.state.edit}
                                checked={this.state.checkedItems.references}
                                onCheckChange={(checked) => {
                                    this.updateState('references', checked)
                                }}
                                onToggleAll={(checked) => {
                                    this.updateState('references', checked)
                                }}
                            />

                            {this.props.config.whitelabel.show_school_subjects && (
                                <CheckboxList
                                    itemName="school_subjects"
                                    title={translate('profile.school_subjects.title')}
                                    itemText="school_subject_name"
                                    itemSubText="school_subject_level_name"
                                    arr={profile.school_subjects || []}
                                    edit={this.state.edit}
                                    checked={this.state.checkedItems.school_subjects}
                                    onCheckChange={(checked) => {
                                        this.updateState('school_subjects', checked)
                                    }}
                                    onToggleAll={(checked) => {
                                        this.updateState('school_subjects', checked)
                                    }}
                                />
                            )}

                            <CheckboxList
                                itemName="competences"
                                title={translate('profile.competences')}
                                itemText="name"
                                itemSubText=""
                                arr={profile.competences || []}
                                edit={this.state.edit}
                                checked={this.state.checkedItems.competences}
                                onCheckChange={(checked) => {
                                    this.updateState('competences', checked)
                                }}
                                onToggleAll={(checked) => {
                                    this.updateState('competences', checked)
                                }}
                            />

                            <CheckboxList
                                itemName="job_interests"
                                title={translate('profile.job_interest')}
                                itemText="name"
                                itemSubText=""
                                arr={profile.job_interests || []}
                                edit={this.state.edit}
                                checked={this.state.checkedItems.job_interests}
                                onCheckChange={(checked) => {
                                    this.updateState('job_interests', checked)
                                }}
                                onToggleAll={(checked) => {
                                    this.updateState('job_interests', checked)
                                }}
                            />

                            <CheckboxList
                                itemName="languages"
                                title={translate('profile.languages')}
                                itemText="name"
                                itemSubText=""
                                arr={profile.languages || []}
                                edit={this.state.edit}
                                checked={this.state.checkedItems.languages}
                                onCheckChange={(checked) => {
                                    this.updateState('languages', checked)
                                }}
                                onToggleAll={(checked) => {
                                    this.updateState('languages', checked)
                                }}
                            />

                            <CheckboxList
                                itemName="interests"
                                title={translate('profile.interests')}
                                itemText="name"
                                itemSubText=""
                                arr={profile.interests || []}
                                edit={this.state.edit}
                                checked={this.state.checkedItems.interests}
                                onCheckChange={(checked) => {
                                    this.updateState('interests', checked)
                                }}
                                onToggleAll={(checked) => {
                                    this.updateState('interests', checked)
                                }}
                            />

                            {this.state.uploadedDocuments.length > 0 && (
                                <CheckboxList
                                    itemName="files"
                                    title={translate('profile.attachments')}
                                    itemText="name"
                                    itemSubText=""
                                    arr={this.state.uploadedDocuments || []}
                                    edit={this.state.edit}
                                    checked={this.state.checkedItems.files}
                                    onCheckChange={(checked) => {
                                        this.updateState('files', checked)
                                    }}
                                    onToggleAll={(checked) => {
                                        this.updateState('files', checked)
                                    }}
                                    expanded={this.props.expandedCategory === 'files'}
                                />
                            )}

                            {this.state.eellooTests && this.state.eellooTests.length > 0 && (
                                <CheckboxList
                                    itemName="tests"
                                    title={translate('profile.eelloo_tests')}
                                    itemText="name"
                                    itemSubText=""
                                    arr={this.state.eellooTests || []}
                                    edit={this.state.edit}
                                    checked={this.state.checkedItems.tests}
                                    onCheckChange={(checked) => {
                                        this.updateState('tests', checked)
                                    }}
                                    onToggleAll={(checked) => {
                                        this.updateState('tests', checked)
                                    }}
                                    expanded={this.props.expandedCategory === 'tests'}
                                />
                            )}

                            {this.state.accountFields.length > 0 && !isEmpty && (
                                <CheckboxList
                                    itemName="account_fields"
                                    title={translate('profile.extra_info')}
                                    itemText="name"
                                    itemSubText=""
                                    arr={this.state.accountFields || []}
                                    edit={this.state.edit}
                                    checked={this.state.checkedItems.account_fields}
                                    onCheckChange={(checked) => {
                                        this.updateState('account_fields', checked)
                                    }}
                                    onToggleAll={(checked) => {
                                        this.updateState('account_fields', checked)
                                    }}
                                />
                            )}
                        </ul>

                        {this.props.showActions && (
                            <div>
                                <Actions>
                                    <Link
                                        className="actions__action--cancel"
                                        onClick={() => this.cancel()}
                                        tabIndex="10"
                                    >
                                        <Translate value="profile.cancel" />
                                    </Link>
                                    <Link
                                        className={this.getSaveClasses()}
                                        onClick={() => this.save()}
                                        tabIndex="11"
                                    >
                                        <Translate value="buttons.save" />
                                    </Link>
                                </Actions>
                            </div>
                        )}
                    </div>
                )}
            </div>
        )
    }
}

ResumeGenerator.propTypes = {
    resume: PropTypes.object,
    id: PropTypes.any,
    onClose: PropTypes.func,
    showActions: PropTypes.bool,
    intro: PropTypes.string,
    createNewResume: PropTypes.bool,
    createName: PropTypes.string,
    createId: PropTypes.any,
    created: PropTypes.func,
    failed: PropTypes.func,
    expanededCategory: PropTypes.bool,
    editableName: PropTypes.bool,
}

ResumeGenerator.defaultProps = {
    showActions: true,
    createNewResume: false,
    editableName: false,
    created: (res) => {},
    failed: (err) => {},
}

function mapStateToProps(state) {
    const { auth, user, config } = state
    const { documents } = user
    return {
        auth,
        user,
        config,
        documents,
    }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, null, null, { forwardRef: true })(ResumeGenerator)
